import { useSelector } from "react-redux";
import { t } from "i18next";

const EmptyPage = ({ pageName }) => {

  const profileUser = useSelector(state => state.profileUserSlice.profileUser)
  const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

  const user = profileUser && profileUser.user;

  const username = user && user.username
  
  const pageData = {
    all_posts_list: {
      title: t('empty-allPosts-title'),
      subTitle: t('empty-allPosts-subTitle')
    },
    following: {
      title: t('empty-following-title'),
      subTitle: t('empty-following-subTitle')
    },
    saved: {
      title: t('empty-saved-title'),
      subTitle: t('empty-saved-subTitle')
    },
    profile_messages: 
      authorizedUser.username === username
      ? {
          title: t('empty-mineProfile-title'),
          subTitle: t('empty-mineProfile-subTitle')
        }
      : {
          title: t('empty-profile-title'),
          subTitle: t('empty-profile-subTitle')
      },
  };

  const data = pageData[pageName] || {};

  return (
    <div className="empty_page_container">
        <p className='pb-2'>{t(`navigate-${pageName}`)}</p>
        <strong className="text-xl">{data.title}</strong>
        <div className="text-grey">{data.subTitle}</div>
    </div>
  );
};

export default EmptyPage;