import * as ReactDOMClient from 'react-dom/client'
import React, { Suspense } from "react";

import './styles/index.css';
import './styles/inputs.css';
import './styles/buttons.css';
import './styles/animations.css';
import './styles/checkboxes.css';
// import './styles/calendar.css';

import './components/little_components/18n'
import CentralBlock from './components/central_block';
import { Provider } from 'react-redux';
import store, {persistor} from './components/store';
import { PersistGate } from 'redux-persist/integration/react';
// import Spinner from './components/little_components/spinner_loading';
import Main from './main';

// const csrftoken = Cookies.get('csrftoken');
// axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

const app = ReactDOMClient.createRoot(document.querySelector('#app'))

app.render(


  // Выявление дополнительных проблем
  <React.StrictMode>
    <Provider store={store}>
      
      <PersistGate loading={null} persistor={persistor}>
        {/* Контейнер для портала Попапов */}
        <div id="popups"></div>

        {/* <div className="sticky h-screen top-0 -z-[1] bg-current" style={{ backgroundImage: 'url("images/background_main2.jpg")' }}></div> */}

        {/* Задерживает свою загрузку */}
        {/* <Suspense fallback={<Spinner />}> */}
        <Suspense>
          <Main />
          <CentralBlock />
        </Suspense>

        {/* Центральный компонент */}
      </PersistGate>

    </Provider>
  </React.StrictMode>
)
