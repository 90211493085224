import React, { useState, useEffect } from "react"
import axios from 'axios'
import Cookies from 'js-cookie';
import { baseBackendUrl } from './websocket_comp';
import Paginator from "./posts_components/paginator";
import EmptyPage from "./posts_components/empty_page";
import Post from "./posts_components/post";
import { useSelector } from 'react-redux';





const Posts = ({ pageName, userLink }) => {
  // pageName - определяем какие посты выдавать
  // userLink - определяем посты какого пользователя в профиле показывать

  const [data, setData] = useState('');

  const [pageNumber, setPageNumber] = useState(1)

  const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
  const updatePosts = useSelector(state => state.postsSlice.updatePosts)
    
  const user = userLink ? userLink : authorizedUser?.username;

  const fetchDataPosts = async ( pageName, user ) => {
    const headers = { headers: { 'X-CSRFToken': Cookies.get('csrftoken') } };
  
    let url = `${baseBackendUrl}/${pageName}`
    const data = { page: pageNumber, user: user};
    const response = await axios.post( url, data, headers );
  
    return response.data;
  };

  const fetchData = async () => { 
    const response = await fetchDataPosts( pageName, user )
    setData(response); 
  
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { 
    fetchData() 
    // console.log(pageNumber)
    // eslint-disable-next-line
  }, [pageName, updatePosts, userLink, pageNumber]);

  const posts = data.posts ?? []
  const paginator = data.paginator

  // Раскладываем все посты по компоненту ПОСТ
  const allPosts = () => {
    const postElements = [];

    for (let i = 0; i < posts.length; i++) {
      postElements.push(<Post key={posts[i].id} authorizedUser={authorizedUser?.username} post={posts[i]}  />)
    }

    return postElements
  }
  
  if (posts.length) {

    return (
        <div className="flex flex-col gap-2 sm:items-center sm:w-screen  w-[800px] h-full pb-2">
          {/* Рендерим все посты тут */}
          {allPosts()}
          
          <Paginator paginatorData={paginator} fetchDataPosts={fetchData} changePage={setPageNumber} />
        </div>
      )
  } else return <EmptyPage pageName={pageName}/>
}

// export default withTranslation()(Posts);
export default Posts;
