import { t } from "i18next";
import Language from "../little_components/language";
import TitleChange from "../little_components/title_change";
import RoundedGradientAvatar from "../little_components/rounded_gradient_avatar";

// import ConfettiExplosion from 'react-confetti-explosion';


const Commercial = () => {

    return (
        <div className="overflow-hidden">

            <TitleChange />
            <Language />

            <div className="flex flex-col gap-3">

                <div className="main_white_container_without_padding sm:w-screen">
                    {/* <div className="flex items-center justify-center relative ">
                        <ConfettiExplosion
                            className="absolute top-[150px] "
                            particleCount={20}
                            particleSize={20}
                            // duration={2000}
                            colors={['#448AF7', '#EA4C5B']}
                            force={0.2}
                            height={'100vh'}
                            wigth={100}
                        />

                        <ConfettiExplosion
                            className="absolute top-[150px]"
                            particleCount={100}
                            particleSize={5}
                            // duration={2000}
                            colors={['#EA4C5B']}
                            force={0.2}
                            height={'100vh'}
                            wigth={100}
                        />

                        <ConfettiExplosion
                            className="absolute top-[150px]"
                            particleCount={50}
                            particleSize={8}
                            // duration={2000}
                            colors={['#EA4C5B']}
                            force={0.2}
                            height={'100vh'}
                            wigth={10}
                        />
                    </div> */}



                    {/* ЗАГОЛОВОК */}
                    <div className="relative text-[100px] sm:text-[80px] p-10 sm:left-0 flex flex-col items-center justify-center overflow-hidden">
                        
                        <div className="absolute flex items-center justify-center top-12 cursor-s-resize scale-[340%] sm:scale-[420%]">
                            <RoundedGradientAvatar border={0} />
                        </div>

                        <div className="relative">
                            {/* Текст */}
                            <p className="hidden">D</p><p className="pl-20 text-white">estIny</p>
                            {/* Анимация D */}
                            <div className="w-[100dvw] h-[100dvh] absolute -top-11 sm:-top-[66px] left-0">
                                <div className="w-[100px] h-[100px] absolute top-16 rounded-r-full border-4 border-white bg-gradient-to-r  from-purple-300 to-pink-600 shadow-inside animation_bounce_from_left_commercial z-[1]"></div>
                            </div>

                            {/* i верхняя */}
                            <div className="w-[100dvw] h-[100dvh] absolute top-[0px] left-[230px] sm:left-[198px]">
                                <div className="w-[30px] h-[30px] absolute top-16 rounded-full border-4 border-white bg-gradient-to-r  from-purple-300 to-pink-600 shadow-inside animation_bounce_from_top_commercial z-[1]"></div>
                            </div>
                        </div>

                        <div className="relative flex">
                            <p className="text-white">MatrI</p><p className="hidden">x</p>

                            {/* X одна часть */}
                            <div className="absolute -top-2 left-[265px] sm:-top-8 sm:left-[215px]">
                                <div className="w-[60px] h-[60px] absolute top-16 rounded-full border-4 border-white bg-gradient-to-r  from-blue-500 to-blue-100 animation_bounce_from_top_commercial_x_3 z-[1]"></div>
                            </div>

                            {/* X вторая часть */}
                            <div className="absolute -top-2 left-[265px] sm:-top-8 sm:left-[215px]">
                                <div className="w-[60px] h-[60px] absolute top-16 rounded-full border-4 border-white bg-gradient-to-r  from-blue-500 to-blue-100 animation_bounce_from_top_commercial_x_4 z-[1]"></div>
                            </div>

                            {/* i нижняя */}
                            <div className="w-[100dvw] h-[100dvh] absolute top-[0px] left-[235px] sm:left-[184px]">
                                <div className="w-[30px] h-[30px] absolute top-16 rounded-full border-4 border-white bg-gradient-to-r  from-blue-500 to-blue-100 shadow-inside animation_bounce_from_top_commercial z-[1]"></div>
                            </div>

                        </div>

                        <div className="relative">
                            <p className="pl-12 z-10">Net</p>

                            {/* точка */}
                            <div className="absolute top-3 sm:-top-3 left-[10]">
                                <div className="w-[40px] h-[40px] absolute top-16 rounded-full border-4 border-white bg-gradient-to-r  from-purple-600 to-pink-200 shadow-inside animation_bounce_from_top_commercial_2 z-[1]"></div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="main_white_container_without_padding sm:w-screen">
                    {/* Блок 1 */}
                    <div className="flex sm:flex-col items-center justify-center pb-10 p-2 gap-4 sm:gap-10">

                        <div className="sm:w-[370px] w-[600px] flex items-center justify-center aspect-square rounded-full overflow-hidden border shadow-xl">
                            <img className="w-fit z-[1]" src="images/join_page/screen_1.png" alt="logo" />
                        </div>

                        <div className="w-[600px] sm:w-full flex flex-col gap-4 z-[1] relative">
                            <div className="flex flex-col p-10 gap-5">
                                <p className="z-[1]">{t('join-post_1_1')}</p>
                                <p className="z-[1]">{t('join-post_1_2')}</p>
                                <p className="z-[1]">{t('join-post_1_3')}</p>
                            </div>

                            <div className="absolute w-full h-full top-0 -left-1 rounded-full border-4 border-white bg-gradient-to-r  from-purple-100 to-blue-100 shadow-inside animation_bounce_random_figure_2"></div>
                        </div>
                    </div>
                </div>

                <div className="main_white_container_without_padding sm:w-screen">
                    {/* Блок 2 */}
                    <div className="flex sm:flex-col items-center justify-center pb-10 p-2 gap-4">
                        <div className="w-[600px] sm:w-full flex flex-col gap-4 z-[1] relative">
                            <div className="flex flex-col p-10">
                                <p className="z-[1]">{t('join-post_2')}</p>
                            </div>

                            <div className="absolute w-full h-full top-0 -left-1 rounded-full border-4 border-white bg-gradient-to-r  from-green-100 to-blue-100 shadow-inside animation_bounce_random_figure_3"></div>
                        </div>

                        <div className="sm:w-[370px] w-[600px] flex items-center justify-center aspect-square rounded-full overflow-hidden border shadow-xl">
                            <img className="w-fit z-[1]" src="images/join_page/screen_2.png" alt="logo" />
                        </div>
                    </div>
                </div>

                <div className="main_white_container_without_padding sm:w-screen">
                    {/* Блок 3 */}
                    <div className="flex sm:flex-col items-center justify-center pb-10 p-2 gap-4">
                        <div className="sm:w-[370px] w-[600px] flex items-center justify-center aspect-square rounded-full overflow-hidden border shadow-xl">
                            <img className="w-fit z-[1]" src="images/join_page/screen_3.png" alt="logo" />
                        </div>
                        {/* текст к блоку матрицы */}


                        <div className="w-[600px] sm:w-full flex flex-col gap-4 z-[1] relative">
                            <div className="flex flex-col p-10">
                                <p className="z-[1]">{t('join-post_3')}</p>
                            </div>

                            <div className="absolute w-full h-full top-0 -left-1 rounded-full border-4 border-white bg-gradient-to-r  from-purple-100 to-pink-100 shadow-inside animation_bounce_random_figure_1"></div>
                        </div>
                    </div>
                </div>

                <div className="main_white_container_without_padding sm:w-screen">
                    {/* Блок 4 */}
                    <div className="flex sm:flex-col items-center justify-center pb-10 p-2 gap-4">
                        <div className="w-[600px] sm:w-full flex flex-col gap-4 z-[1] relative">
                            <div className="flex flex-col p-10">
                                <p className="z-[1]">{t('join-post_4')}</p>
                            </div>

                            <div className="absolute w-full h-full top-0 -left-1 rounded-full border-4 border-white bg-gradient-to-r  from-purple-300 to-pink-100 shadow-inside animation_bounce_random_figure_2"></div>
                        </div>

                        <div className="sm:w-[370px] w-[600px] flex items-center justify-center aspect-square rounded-full overflow-hidden border shadow-xl">
                            <img className="w-fit z-[1]" src="images/join_page/screen_4.png" alt="logo" />
                        </div>
                    </div>
                </div>

                <div className="main_white_container_without_padding sm:w-screen">
                    {/* Блок 5 */}
                    <div className="flex sm:flex-col items-center justify-center pb-10 p-2 gap-4">
                        <div className="sm:w-[370px] w-[600px] flex items-center justify-center aspect-square rounded-full overflow-hidden border shadow-xl">
                            <img className="w-fit z-[1]" src="images/join_page/screen_5.png" alt="logo" />
                        </div>
                        {/* текст к блоку матрицы */}


                        <div className="w-[600px] sm:w-full flex flex-col gap-4 z-[1] relative">
                            <div className="flex flex-col p-10">
                                <p className="z-[1]">{t('join-post_5')}</p>
                            </div>

                            <div className="absolute w-full h-full top-0 -left-1 rounded-full border-4 border-white bg-gradient-to-r  from-green-100 to-green-200 shadow-inside animation_bounce_random_figure_3"></div>

                        </div>


                    </div>
                </div>

                <div className="main_white_container_without_padding sm:w-screen">
                    {/* Блок 6 */}
                    <div className="flex sm:flex-col items-center justify-center pb-10 p-2 gap-4">
                        <div className="w-[600px] sm:w-full flex flex-col gap-4 z-[1] relative">
                            <div className="flex flex-col p-10">
                                <p className="z-[1]">{t('join-post_6')}</p>
                            </div>

                            <div className="absolute w-full h-full top-0 -left-1 rounded-full border-4 border-white bg-gradient-to-r  from-orange-200 to-yellow-100 shadow-inside animation_bounce_random_figure_1"></div>
                        </div>

                        <div className="sm:w-[370px] w-[600px] flex items-center justify-center aspect-square rounded-full overflow-hidden border shadow-xl">
                            <img className="w-fit z-[1]" src="images/join_page/screen_6.png" alt="logo" />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-end p-10 gap-3">
                    <div className="flex sm:flex-col items-end text-end gap-2">
                        <div className="flex sm:flex-col gap-2 text-center items-center justify-center">
                            <p>{t('about-writeToMe')}</p>
                            <p className="text-blue-500">propoved@mail.ru</p>
                        </div>

                    </div>
                    <p className="flex items-center justify-center text-center">{t('about-autor')} Vadim Monroe © 2023 - 2024</p>

                </div>

            </div>
        </div>

    )
}

export default Commercial;