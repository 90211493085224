import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Astronomy } from "../exported_comps/astronomy";
import SunCalc from "suncalc"
import { formattedDateWithoutClock, formattedTime, formattedTimeMin } from "../services";
import { t } from "i18next";


export const ZodiacCalculations = () => {
    // Компонент считает зодиак и в год кого родился

    const [zodiac, setZodiac] = useState('');
    const [zodiacElement, setZodiacElement] = useState('');

    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    const user = profileUser?.user
    const birthDate = user?.birth_date

    // Вычисляем Какой Зодиак по дате рождения //
    // eslint-disable-next-line no-unused-vars
    const [birthDay1, birthMonth1, birthYear1] = formattedDateWithoutClock(birthDate).split("-");
    const birthMonth = Number(birthMonth1)
    const birthDay = Number(birthDay1)
    const birthYear = Number(birthYear1)

    useEffect(() => {
        if ((birthMonth === 3 && birthDay >= 21) || (birthMonth === 4 && birthDay <= 20)) {
            setZodiac('aries'); // овен
            setZodiacElement('fire')
        } else if ((birthMonth === 4 && birthDay >= 21) || (birthMonth === 5 && birthDay <= 21)) {
            setZodiac('taurus'); // телец
            setZodiacElement('earth')
        } else if ((birthMonth === 5 && birthDay >= 22) || (birthMonth === 6 && birthDay <= 21)) {
            setZodiac('gemini'); // близнецы
            setZodiacElement('wind')
        } else if ((birthMonth === 6 && birthDay >= 22) || (birthMonth === 7 && birthDay <= 22)) {
            setZodiac('cancer'); // рак
            setZodiacElement('water')
        } else if ((birthMonth === 7 && birthDay >= 23) || (birthMonth === 8 && birthDay <= 23)) {
            setZodiac('leo'); // лев
            setZodiacElement('fire')
        } else if ((birthMonth === 8 && birthDay >= 24) || (birthMonth === 9 && birthDay <= 22)) {
            setZodiac('virgo'); // дева
            setZodiacElement('earth')
        } else if ((birthMonth === 9 && birthDay >= 23) || (birthMonth === 10 && birthDay <= 23)) {
            setZodiac('libro'); // весы
            setZodiacElement('wind')
        } else if ((birthMonth === 10 && birthDay >= 24) || (birthMonth === 11 && birthDay <= 22)) {
            setZodiac('scorpio'); // скорпион
            setZodiacElement('water')
        } else if ((birthMonth === 11 && birthDay >= 23) || (birthMonth === 12 && birthDay <= 21)) {
            setZodiac('sagittarius'); // стрелец
            setZodiacElement('fire')
        } else if ((birthMonth === 12 && birthDay >= 22) || (birthMonth === 1 && birthDay <= 20)) {
            setZodiac('capricorn'); // козерог
            setZodiacElement('earth')
        } else if ((birthMonth === 1 && birthDay >= 21) || (birthMonth === 2 && birthDay <= 18)) {
            setZodiac('aquarius'); // водолей
            setZodiacElement('wind')
        } else if ((birthMonth === 2 && birthDay >= 19) || (birthMonth === 3 && birthDay <= 20)) {
            setZodiac('pisces'); // рыбы
            setZodiacElement('water')
        } else {
            console.log("Какая-то ошибка в знаках зодиака");
        }
        // eslint-disable-next-line
    }, [profileUser])


    // const chineseZodiac = ["Дракон", "Змея", "Лошадь", "Овца(Коза)", "Обезьяна", "Петух", "Собака", "Свинья", "Крыса", "Бык", "Тигр", "Кролик(Кот)"];
    const chineseZodiac = ["dragon", "snake", "horse", "goat", "monkey", "rooster", "dog", "pig", "rat", "bull", "tiger", "rabbit"];
    // const chineseElements = ["Металл","Металл", "Вода","Вода", "Дерево","Дерево", "Огонь","Огонь", "Земля", "Земля"];
    const chineseElements = ["metall", "metall", "water", "water", "tree", "tree", "fire", "fire", "earth", "earth"];

    let personChineseZodiac;
    let personChineseElement;


    // Начальный год цикла китайского календаря
    const startYear = 2000;

    if (birthYear >= startYear) {
        const yearDifference = birthYear - startYear
        personChineseZodiac = chineseZodiac[yearDifference % 12]
        personChineseElement = chineseElements[yearDifference % 10]
    } else if (birthYear < startYear) {
        const yearDifference = startYear - birthYear

        chineseZodiac.push('dragon');
        chineseZodiac.reverse();
        chineseZodiac.pop();
        chineseElements.push("metall")
        chineseElements.reverse();
        chineseElements.pop();

        personChineseZodiac = chineseZodiac[yearDifference % 12]
        personChineseElement = chineseElements[yearDifference % 10]
    }

    return [zodiac, zodiacElement, personChineseZodiac, personChineseElement]
}


export const moonPosition = (date, latLong) => {
    // Установите библиотеку с помощью npm: npm install suncalc

    const moonIllumination = SunCalc.getMoonIllumination(date);
    // console.log(moonIllumination)

    // moonIllumination.angle - средний угол в радианах освещенного края Луны, 
    // отсчитываемый к востоку от северной точки диска; Луна растущая, если угол 
    // отрицательный, и убывающая, если положительный.

    // Угол тени?
    const moonAngle = moonIllumination.angle
    // console.log(moonAngle * 180 / Math.PI)

    // Определите процент освещенности Луны
    const moonPhasePercent = Math.round(moonIllumination.fraction * 100);

    // Фаза луны
    const moonPhase = moonIllumination.phase
    const moonAngleDegrees = moonIllumination.phase * -360 + 90

    let moonFraction;
    if (moonPhase === 0) {
        moonFraction = t('profile-moon-newMoon')
    } else if (moonPhase > 0 & moonPhase < 0.25) {
        moonFraction = t('profile-moon-waxingCrescent')
    } else if (moonPhase === 0.25) {
        moonFraction = t('profile-moon-firstQuarter')
    } else if (moonPhase > 0.25 & moonPhase < 0.5) {
        moonFraction = t('profile-moon-waxingGibbous')
    } else if (moonPhase === 0.5) {
        moonFraction = t('profile-moon-fullMoon')
    } else if (moonPhase > 0.5 & moonPhase < 0.75) {
        moonFraction = t('profile-moon-waningGibbous')
    } else if (moonPhase === 0.75) {
        moonFraction = t('profile-moon-lastQuarter')
    } else if (moonPhase > 0.75) {
        moonFraction = t('profile-moon-waningCrescent')
    }

    const moonPosition = SunCalc.getMoonPosition(date, latLong[0], latLong[1])

    // Солнечный центр
    // Heliocentric - ЦЕНТР СОЛНЦЕ



    const getLilitDate = () => {
        let currentDateForGetLilit = new Date(date);

        let daysToSubtract = 1;
        let change;

        while (true) {

            let currentMoonPosition = SunCalc.getMoonPosition(currentDateForGetLilit, latLong[0], latLong[1]);
            let currentMoonDistance = currentMoonPosition.distance;

            let prevDate = new Date(currentDateForGetLilit);
            prevDate.setHours(currentDateForGetLilit.getHours() - 4);

            currentDateForGetLilit.setHours(currentDateForGetLilit.getHours() - 4)

            let prevMoonPosition = SunCalc.getMoonPosition(prevDate, latLong[0], latLong[1]);
            let prevMoonDistance = prevMoonPosition.distance;

            if (daysToSubtract === 1) {
                change = prevMoonDistance < currentMoonDistance;
            }

            if (change !== (prevMoonDistance < currentMoonDistance)) {
                // console.log(currentMoonPosition.distance, prevMoonDistance)
                if (currentMoonPosition.distance > 380000) {
                    return prevDate
                } else {
                    change = !change
                }
            }
            // console.log(daysToSubtract)
            daysToSubtract++;

            // 166 вызывов
            if (daysToSubtract === 200) {
                break;
            }
        }
    }

    const lilitDate = getLilitDate()
    // console.log(lilitDate)

    // const lilitDate = date

    // console.log(Astronomy)


    const earth = Astronomy.Body[3].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const earthAngleInDegrees = planetAngle(earth.x, earth.y)

    const mercury = Astronomy.Body[1].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const mercuryAngleInDegrees = planetAngle(mercury.x, mercury.y)

    const venus = Astronomy.Body[2].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const venusAngleInDegrees = planetAngle(venus.x, venus.y)

    const mars = Astronomy.Body[5].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const marsAngleInDegrees = planetAngle(mars.x, mars.y)

    const cerera = Astronomy.Body[6].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const cereraAngleInDegrees = planetAngle(cerera.x, cerera.y)

    const jupiter = Astronomy.Body[16].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const jupiterAngleInDegrees = planetAngle(jupiter.x, jupiter.y)

    const saturn = Astronomy.Body[17].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const saturnAngleInDegrees = planetAngle(saturn.x, saturn.y)

    const uran = Astronomy.Body[18].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const uranAngleInDegrees = planetAngle(uran.x, uran.y)

    const neptun = Astronomy.Body[19].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const neptunAngleInDegrees = planetAngle(neptun.x, neptun.y)

    const pluto = Astronomy.Body[20].EclipticCartesianCoordinates(Astronomy.DayValue(new Date(date)))
    const plutoAngleInDegrees = planetAngle(pluto.x, pluto.y)


    // Земной центр
    // Geocentric - ЦЕНТР ЗЕМЛЯ

    const calculateRetrogrades = (planet) => {
        let retrogradeToday = false
        let retrogradeToday2 = false
        let nextRetrograde;
        let changeDirection = 'false';
        let nowRetrogradEnds;

        let currentDateForRetrogrades = new Date(date); // создаем объект с текущей датой и временем
        let prevDate = new Date(currentDateForRetrogrades); // создаем новый объект с датой предыдущего дня
        prevDate.setDate(currentDateForRetrogrades.getDate() - 1); // устанавливаем предыдущую дату

        const planetFromEarth = Astronomy.Body[planet].GeocentricCoordinates(Astronomy.DayValue(currentDateForRetrogrades))
        const planetAngleInDegreesFromEarth = planetAngle(planetFromEarth.x, planetFromEarth.y)

        const prevDatePlanetFromEarth = Astronomy.Body[planet].GeocentricCoordinates(Astronomy.DayValue(prevDate))
        const prevDatePlanetAngleInDegreesFromEarth = planetAngle(prevDatePlanetFromEarth.x, prevDatePlanetFromEarth.y)
        if ((planetAngleInDegreesFromEarth < 0 && prevDatePlanetAngleInDegreesFromEarth < 0) || (planetAngleInDegreesFromEarth > 0 && prevDatePlanetAngleInDegreesFromEarth > 0)) {

            if (planetAngleInDegreesFromEarth < prevDatePlanetAngleInDegreesFromEarth) {
                retrogradeToday = true
                retrogradeToday2 = true
            } else {
                retrogradeToday = false
                retrogradeToday2 = false


            }
        }

        while (true) {
            if (retrogradeToday2) {
                currentDateForRetrogrades.setDate(currentDateForRetrogrades.getDate() + 1)
                prevDate.setDate(prevDate.getDate() + 1)

                const planetFromEarth = Astronomy.Body[planet].GeocentricCoordinates(Astronomy.DayValue(currentDateForRetrogrades))
                const planetAngleInDegreesFromEarth = planetAngle(planetFromEarth.x, planetFromEarth.y)

                const prevDatePlanetFromEarth = Astronomy.Body[planet].GeocentricCoordinates(Astronomy.DayValue(prevDate))
                const prevDatePlanetAngleInDegreesFromEarth = planetAngle(prevDatePlanetFromEarth.x, prevDatePlanetFromEarth.y)

                if ((planetAngleInDegreesFromEarth < 0 && prevDatePlanetAngleInDegreesFromEarth < 0) || (planetAngleInDegreesFromEarth > 0 && prevDatePlanetAngleInDegreesFromEarth > 0)) {

                    if (planetAngleInDegreesFromEarth < prevDatePlanetAngleInDegreesFromEarth) {
                        if (changeDirection !== (planetAngleInDegreesFromEarth < prevDatePlanetAngleInDegreesFromEarth)) {
                            changeDirection = (planetAngleInDegreesFromEarth < prevDatePlanetAngleInDegreesFromEarth)
                            retrogradeToday2 = true

                        }
                    } else {
                        // if (changeDirection === (planetAngleInDegreesFromEarth > prevDatePlanetAngleInDegreesFromEarth)) {

                            nowRetrogradEnds = new Date(prevDate)

                            retrogradeToday2 = false

                            changeDirection = 'false'
                        }
                    // }
                }
            } else {

                currentDateForRetrogrades.setDate(currentDateForRetrogrades.getDate() + 1)
                prevDate.setDate(prevDate.getDate() + 1)

                const planetFromEarth = Astronomy.Body[planet].GeocentricCoordinates(Astronomy.DayValue(currentDateForRetrogrades))
                const planetAngleInDegreesFromEarth = planetAngle(planetFromEarth.x, planetFromEarth.y)

                const prevDatePlanetFromEarth = Astronomy.Body[planet].GeocentricCoordinates(Astronomy.DayValue(prevDate))
                const prevDatePlanetAngleInDegreesFromEarth = planetAngle(prevDatePlanetFromEarth.x, prevDatePlanetFromEarth.y)

                if ((planetAngleInDegreesFromEarth < 0 && prevDatePlanetAngleInDegreesFromEarth < 0) || (planetAngleInDegreesFromEarth > 0 && prevDatePlanetAngleInDegreesFromEarth > 0)) {
                    if (planetAngleInDegreesFromEarth < prevDatePlanetAngleInDegreesFromEarth) {

                        if (changeDirection !== (planetAngleInDegreesFromEarth < prevDatePlanetAngleInDegreesFromEarth)) {
                            changeDirection = (planetAngleInDegreesFromEarth < prevDatePlanetAngleInDegreesFromEarth)

                            nextRetrograde = new Date(prevDate)

                            // console.log(formattedDateWithoutClock(nextRetrograde))

                            // console.log(changeDirection)

                        }


                    } else {
                        // console.log(changeDirection)
                        if (changeDirection === (planetAngleInDegreesFromEarth > prevDatePlanetAngleInDegreesFromEarth)) {

                            return [retrogradeToday, formattedDateWithoutClock(nextRetrograde), formattedDateWithoutClock(prevDate), formattedDateWithoutClock(nowRetrogradEnds)]
                        }
                    }
                }


            }

        }
    }

    let retrogrades = {
        mercury: calculateRetrogrades(1),
        venus: calculateRetrogrades(2),
        mars: calculateRetrogrades(5),
        jupiter: calculateRetrogrades(16),
        saturn: calculateRetrogrades(17),
        uran: calculateRetrogrades(18),
        neptun: calculateRetrogrades(19),
        pluto: calculateRetrogrades(20)
    }



    const sunFromEarth = Astronomy.Body[0].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const sunAngleInDegreesFromEarth = planetAngle(sunFromEarth.x, sunFromEarth.y)

    const mercuryFromEarth = Astronomy.Body[1].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const mercuryAngleInDegreesFromEarth = planetAngle(mercuryFromEarth.x, mercuryFromEarth.y)

    const venusFromEarth = Astronomy.Body[2].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const venusAngleInDegreesFromEarth = planetAngle(venusFromEarth.x, venusFromEarth.y)

    const moonFromEarth = Astronomy.Body[4].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const moonAngleInDegreesFromEarth = planetAngle(moonFromEarth.x, moonFromEarth.y)

    const lilitMoonFromEarth = Astronomy.Body[4].GeocentricCoordinates(Astronomy.DayValue(new Date(lilitDate)))
    const lilitMoonAngleInDegreesFromEarth = planetAngle(lilitMoonFromEarth.x, lilitMoonFromEarth.y)

    const marsFromEarth = Astronomy.Body[5].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const marsAngleInDegreesFromEarth = planetAngle(marsFromEarth.x, marsFromEarth.y)

    const cereraFromEarth = Astronomy.Body[6].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const cereraAngleInDegreesFromEarth = planetAngle(cereraFromEarth.x, cereraFromEarth.y)

    const jupiterFromEarth = Astronomy.Body[16].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const jupiterAngleInDegreesFromEarth = planetAngle(jupiterFromEarth.x, jupiterFromEarth.y)

    const saturnFromEarth = Astronomy.Body[17].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const saturnAngleInDegreesFromEarth = planetAngle(saturnFromEarth.x, saturnFromEarth.y)

    const uranFromEarth = Astronomy.Body[18].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const uranAngleInDegreesFromEarth = planetAngle(uranFromEarth.x, uranFromEarth.y)

    const neptunFromEarth = Astronomy.Body[19].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const neptunAngleInDegreesFromEarth = planetAngle(neptunFromEarth.x, neptunFromEarth.y)

    const plutoFromEarth = Astronomy.Body[20].GeocentricCoordinates(Astronomy.DayValue(new Date(date)))
    const plutoAngleInDegreesFromEarth = planetAngle(plutoFromEarth.x, plutoFromEarth.y)

    // console.log(Astronomy)
    // console.log(Astronomy.NextFullMoonDay(new Date(date)),0,0)

    // console.log(
    //     'высота луны над горизонтом в радианах:', moonPosition.altitude,
    //     'Азимут Луны в радианах:', moonPosition.azimuth, 
    //     'Ащимут луны в градусах:', moonPosition.azimuth * 180 / Math.PI,
    //     'Дистанция до луны в км:', moonPosition.distance, 
    //     'Параллактический угол луны в радианах (сдвиг луны в объёме в одну и др стороны):', moonPosition.parallacticAngle
    // )

    const moonAzimuthDegrees = moonPosition.azimuth * 180 / Math.PI
    const moonParallacticAngle = moonPosition.parallacticAngle

    const moonDistance = moonPosition.distance
    const moonDistancePercent = Math.abs(356400 / moonPosition.distance - 1)


    const moonTimes = SunCalc.getMoonTimes(date, latLong[0], latLong[1])

    // Надо решить вопрос с рассветом Луны, почему-то перестал рассчитываться
    const moonRise = moonTimes.rise ? formattedTime(moonTimes.rise) : null
    const moonSet = formattedTime(moonTimes.set)


    // Рассчитайте позицию Солнца и Луны
    const sunPosition = SunCalc.getPosition(date, latLong[0], latLong[1]); // (время, широта, долгота)
    // console.log(SunCalc)


    const sunAzimuth = sunPosition.azimuth
    const sunAzimuthRadians = sunAzimuth * 180 / Math.PI

    // console.log(SunCalc.times)
    var times = SunCalc.getTimes(date, latLong[0], latLong[1]); // (дата, широта, долгота)

    const sunSunriseStart = formattedTimeMin(times.sunrise)
    const sunSunriseEnd = formattedTimeMin(times.sunriseEnd)
    const sunSunset = formattedTimeMin(times.sunset)
    const zenith = formattedTimeMin(times.solarNoon)
    const nadir = formattedTimeMin(times.nadir)

    // console.log(
    //     times,
    //     SunCalc.times,
    //     'Солнечный полдень - Зенит:', formattedTimeMin(times.solarNoon),
    //     'Надир (противоположность зениту):', formattedTimeMin(times.nadir),

    //     'Восход:', formattedTimeMin(times.sunrise),
    //     'Восход заканчивается:', formattedTimeMin(times.sunriseEnd),
    //     'Закат:', formattedTimeMin(times.sunset),
    // )
    // console.log(retrogrades)

    return [moonPhasePercent, moonPhase, moonFraction, moonAngle, moonDistance, moonDistancePercent, formattedDateWithoutClock(date), moonRise, moonSet, moonParallacticAngle,
        moonAzimuthDegrees, moonAngleDegrees, sunAzimuthRadians, sunSunriseStart, sunSunriseEnd, sunSunset, zenith, nadir,
        earthAngleInDegrees, mercuryAngleInDegrees, venusAngleInDegrees, marsAngleInDegrees, cereraAngleInDegrees, jupiterAngleInDegrees,
        saturnAngleInDegrees, uranAngleInDegrees, neptunAngleInDegrees, plutoAngleInDegrees,

        sunAngleInDegreesFromEarth, mercuryAngleInDegreesFromEarth, venusAngleInDegreesFromEarth,
        moonAngleInDegreesFromEarth, marsAngleInDegreesFromEarth, cereraAngleInDegreesFromEarth,
        jupiterAngleInDegreesFromEarth, saturnAngleInDegreesFromEarth, uranAngleInDegreesFromEarth,
        neptunAngleInDegreesFromEarth, plutoAngleInDegreesFromEarth,
        lilitMoonAngleInDegreesFromEarth,
        retrogrades

    ]
    // 0 1 2 3 4 5 6 7 8 9(moonParallacticAngle)
    // 10 11 12 13 14 15 16 17
    // 18(earh) 19(mercury) 20(venus) 21(mars) 22(cerera) 23(jupiter)
    // 24(saturn) 25(uran) 26(neptun) 27(pluto)

    // sunAngleInDegreesFromEarth, mercuryAngleInDegreesFromEarth, venusAngleInDegreesFromEarth,
    // moonAngleInDegreesFromEarth, marsAngleInDegreesFromEarth, cereraAngleInDegreesFromEarth, 
    // jupiterAngleInDegreesFromEarth, saturnAngleInDegreesFromEarth, uranAngleInDegreesFromEarth, 
    // neptunAngleInDegreesFromEarth, plutoAngleInDegreesFromEarth,
    // 28 29 30 31 32 33 34 35 36 37 38(plutoAngleInDegreesFromEarth)
    // 38 lilit
    //39 retrogrades
}

export const planetAngle = (planetX, planetY) => (Math.atan2(planetY, planetX) * 180) / Math.PI

export const citiesLatLong = {
    "Москва": [55.7558, 37.6176],
    "Санкт-Питербург": [59.56, 30.19],

    "Нью-Йорк": [40.7128, -74.0060],
    "Лос-Анджелес": [34.0522, -118.2437],
    "Лондон": [51.5074, -0.1278],
    "Париж": [48.8566, 2.3522],
    "Токио": [35.6895, 139.6917],
    "Пекин": [39.9042, 116.4074],
    "Дели": [28.7041, 77.1025],
    "Сан-Паулу": [-23.5505, -46.6333],
    "Мехико": [19.4326, -99.1332],
    "Каир": [30.0444, 31.2357],
    "Мумбаи": [19.0760, 72.8777],
    "Истанбул": [41.0082, 28.9784],
    "Сеул": [37.5665, 126.9780],
    "Шанхай": [31.2304, 121.4737]
};

// [1] //Меркурий
// [2] //Венера
// [3] //Земля
// [4] //Луна
// [5] //Марс

// [6] //Церера
// [7] //Палада
// [8] //Юнона
// [9] //Веста
// [10] //Ида
// [11] //Гаспра
// [12] //9P/T1
// [13] //19P/B
// [14] //67P/C-G
// [15] //81P/W2

// [16] //Юпитер
// [17] //Сатурн
// [18] //Уран
// [19] //Нептун
// [20] //Плутон
