import ExitCrossSVG from "../../../svg/exitCross_svg";
import { baseBackendUrl } from "../websocket_comp";
import axios from "axios";
import { useState, useEffect } from "react";
import UserCard from "../search_components/user_card_comp";
import { formattedDateWithoutClock } from "../services";
import { t } from "i18next";
import PopupContainer from "../popups_root";
import { useSelector, useDispatch } from "react-redux";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const MyMatricesPopup = ({ close, additionInfo }) => {

    const setMatrixClose = additionInfo

    const dispatch = useDispatch()

    useEffect(() => {
        setMatrixClose(true)
        dispatch(updateDisplayNonOverflow(true))
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
        setMatrixClose(false)
    }

    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    const [allUsers, setAllUsers] = useState([])
    const [searchData, setSearchData] = useState('')
    const [formNotReady, setFormNotReady] = useState(false)
    const [allMatrices, getAllMatrices] = useState([])
    const [updatePopup, setUpdatePopup] = useState(false)
    const [friendMatrixPopup, setFriendMatrixPopup] = useState(false);
    const [selectedFriend, setSelectedFriend] = useState(null);

    const fetchAddedUsersMatrix = async () => {
        const url = `${baseBackendUrl}/added_matrices`;
        const data = { authorizedUser: authorizedUser?.username };

        try {
            const response = await axios.post(url, data);

            getAllMatrices(response.data.allMatrices);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchAddedUsersMatrix()
        // eslint-disable-next-line
    }, [updatePopup])

    const [formData, setFormData] = useState({
        authorized_user: authorizedUser.username,
        firstname: '',
        status: '',
        birthdate: "2000-01-01",
        gender: '',
        username: '',
    });

    const fetchAllUsersInfo = async () => {
        const url = `${baseBackendUrl}/all_users_list_empty`;
        const data = { authorizedUser: authorizedUser?.username };

        try {
            const response = await axios.post(url, data);

            setAllUsers(response.data.all_users);

        } catch (error) {
            console.log(error);
        }
    };

    const deletePair = async (id) => {
        const url = `${baseBackendUrl}/delete_relationship`;
        const data = { authorizedUser: authorizedUser?.username, id: id };
        try {
            await axios.post(url, data);

        } catch (error) {
            console.log(error);
        }
        setUpdatePopup(!updatePopup)
    }

    useEffect(() => {
        fetchAllUsersInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendUserToBase = async () => {
        const url = `${baseBackendUrl}/add_relationship`;
        const data = { data: formData };
        await axios.post(url, data);
        setUpdatePopup(!updatePopup)

    }

    const addUserToForm = (user) => {
        setFormData((prevState) => ({
            ...prevState,
            firstname: user.first_name,
            username: user.username,
            birthdate: user.birth_date,
            gender: user.gender,
        }));
        setSearchData('')
    }

    useEffect(() => {
        if (formData.birthdate === "2000-01-01" && !formData.firstname && !formData.gender) {
            setFormNotReady(false)
        } else {
            setFormNotReady(true)
        }
    }, [formData]);

    const renderSearchUsers = () => {
        let searchUsersCount = 0; // Инициализируем счетчик

        const searchResults = allUsers.map(user => {
            if (
                user.username.toLowerCase().includes(searchData) ||
                user.first_name.toLowerCase().includes(searchData) ||
                user.last_name.toLowerCase().includes(searchData) ||
                formattedDateWithoutClock(user.birth_date).includes(searchData)
            ) {
                searchUsersCount++; // Увеличиваем счетчик при совпадении
                return (
                    <div className="flex flex-col pb-2 w-full" key={user.id}>
                        <div className="">
                            <UserCard
                                username={user.username}
                                profile_pic={user.profile_pic}
                                first_name={user.first_name}
                                last_name={user.last_name}
                                birth_date={user.birth_date}
                                is_online={user.is_online}
                                last_time_online={user.last_time_online}
                            />

                        </div>
                        <div className="w-full flex justify-center">
                            <button className="title_style_addon z-[1] button_blue_rounded !overflow-visible !w-fit !h-6 !p-0 !pl-5 !pr-5"
                                onClick={() => addUserToForm(user)}
                            >
                                {t('profile-addButton')}
                            </button>
                        </div>
                    </div>

                );
            }
            return null; // Если пользователь не соответствует условиям, возвращаем null
        });

        if (searchUsersCount === 0) {
            return (
                <div>
                    <p>{t('friends-empty-search')}</p>
                </div>
            );
        }

        return searchResults; // Возвращаем результаты поиска
    };

    const handleInputInfoChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    const handleInputChange = (e) => {
        setSearchData(e.target.value.toLowerCase());
    };

    // Искать друга в зарегестрированных пользователях
    const searchRegisteredUsers = () => {
        return (
            <div className="w-full flex flex-col gap-2 p-2">
                <p>{t('profile-relationshipPair-findPair')}:</p>
                <input type="text" placeholder={t('friends-search')} className='input_style' onChange={handleInputChange}
                    autoFocus
                    value={searchData}
                />

                {
                    searchData
                        ? (renderSearchUsers())
                        : null
                }
            </div>
        )
    }

    // Введение своих параметров друга
    const inputsToAddFriend = () => {
        return (
            <div className="flex flex-col gap-2 border rounded-3xl p-4">
                <p>{t('profile-relationshipPair-findPairUnputs')}:</p>

                <input
                    type="text" name="firstname" placeholder={t('reg-first-name')} autoComplete="off" required
                    onChange={handleInputInfoChange}
                    value={formData.firstname}
                />

                <input
                    type="text" name="status" placeholder={t('profile_input_status')} autoComplete="off" required
                    onChange={handleInputInfoChange}
                    value={formData.status}
                />

                <div className="flex shadow rounded-3xl bg-blue-300 h-10 pl-2 pr-2">
                    <label htmlFor="cover" className="flex text-white items-center justify-center text-center text-[14px]">
                        {t('reg-helpTitle-birthDate')}:
                    </label>

                    <input
                        type="date" name="birthdate" min="1500-01-01" max="3000-01-01"
                        value={formData.birthdate}
                        placeholder={t('reg-birthdate')} autoComplete="off" required
                        onChange={handleInputInfoChange}
                    />
                </div>

                <select className="input_style"
                    name="gender" size="1" required
                    onChange={handleInputInfoChange}
                    value={formData.gender}
                >
                    <option value="" disabled>{t('reg-choose-gender')}</option>
                    <option value="Male">{t('reg-Male')}</option>
                    <option value="Female">{t('reg-Female')}</option>
                </select>

                <button className={` ${formNotReady ? 'button_blue_rounded' : 'button_grey_like_green'}`} disabled={!formNotReady}
                    onClick={sendUserToBase}
                >
                    {t('profile-addButton')}
                </button>
            </div>
        )
    }

    // Список всех друзей добавленных в моих матрицах
    const allFriendsList = () => {
        const myFriendsList = [];

        if (allMatrices) {
            for (let i = 0; i < allMatrices.length; i++) {
                if (allMatrices[i].family_status !== 'relationship') {
                    myFriendsList.push(allMatrices[i]);
                }
            }
        }

        const openPopup = (friend) => {
            setSelectedFriend(friend);
            setFriendMatrixPopup(true);
        };

        // const closePopup = () => {
        //     setSelectedFriend(null);
        //     setFriendMatrixPopup(false);
        // };


        // ПОПАП САМОЙ ИНФЫ ДОБАВЛЕННОГО ДРУГА
        return (
            myFriendsList.reverse().map((oneMatrix, index) => (
                <div key={index}>

                    <div className="cursor-pointer hover:bg-slate-500 w-full grid grid-cols-5 items-center gap-10 sm:gap-1 border rounded-full p-1 sm:text-[11px]"
                        onClick={() => openPopup(oneMatrix)}>

                        {/* eslint-disable-next-line */}
                        <div className="absoulte border-2 border-white bg-cover bg-no-repeat bg-center w-[40px] h-[40px] rounded-full" style={{ backgroundImage: `url(${baseBackendUrl + '/media/' + `${oneMatrix.family_profile_pic ? oneMatrix.family_profile_pic : 'no_pic.png'}`})` }} />

                        <p className="col-span-1 flex items-center justify-center">{oneMatrix.family_name}</p>

                        {oneMatrix.family_status ? <p className="flex items-center justify-center">({oneMatrix.family_status})</p> : <p className="flex items-center justify-center">-</p>}

                        <p className="flex items-center justify-center">{oneMatrix.family_date}</p>

                        <div className="flex items-center justify-end">
                            <button className="button_red !p-0 sm:!w-10 !h-10 flex items-center justify-center" onClick={() => deletePair(oneMatrix.id)}>
                                <p className="sm:hidden">{t('profile-relationshipPair-delete')}</p>
                                <div className="lg:hidden"><ExitCrossSVG className="fill-white w-6 h-6" /></div>
                            </button>
                        </div>

                    </div>

                    {friendMatrixPopup && selectedFriend === oneMatrix
                        ? <PopupContainer choosePopup={'friend_matrix_popup'} setPopupOpen={setFriendMatrixPopup} additionInfo={selectedFriend} />
                        : null}

                    {/* {friendMatrixPopup ? <PopupContainer choosePopup={'friend_matrix_popup'} setPopupOpen={setFriendMatrixPopup} additionInfo={selectedFriend} /> : null} */}
                </div>
            ))
        );
    };

    const searchRegisteredUsersStatic = searchRegisteredUsers()
    const inputsToAddFriendStatic = inputsToAddFriend()
    const allFriendsListStatic = allFriendsList()

    return (
        // Основа
        <div className="popup_window !bg-transparent !p-0 w-[700px] sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window !w-full h-full  p-8 sm:p-3">
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                <div className="relative flex w-full flex-col items-center overflow-x-hidden p-1">

                    {/* ЗАГОЛОВОК */}
                    <p className="popup_title">{t('profile-myMatrix')}</p>

                    {/* Основа */}
                    <div className="w-full flex flex-col gap-3">


                        {searchRegisteredUsersStatic}
                        {inputsToAddFriendStatic}
                        {allFriendsListStatic}

                        <button className="button_red bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MyMatricesPopup;