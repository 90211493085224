const PlusSVG = ({className}) => {
    return (

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="1.5em" height="1.5em" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
        className={className}>
       
       <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="white"
       >
       
       <path d="M1447 5109 c-627 -72 -1164 -508 -1362 -1103 -85 -257 -87 -280 -83
       -1511 3 -958 5 -1068 21 -1147 75 -378 264 -710 537 -946 256 -220 560 -353
       895 -392 122 -13 2088 -13 2210 0 636 73 1168 501 1368 1101 88 261 89 279 85
       1514 -4 1168 -1 1107 -69 1337 -36 122 -127 317 -200 425 -158 237 -361 419
       -609 547 -195 100 -368 153 -572 175 -114 13 -2114 13 -2221 0z m2337 -491
       c406 -112 722 -428 834 -835 l27 -98 0 -1125 0 -1125 -27 -98 c-70 -253 -225
       -483 -430 -637 -103 -78 -276 -162 -403 -197 l-100 -28 -1125 0 -1125 0 -98
       27 c-253 70 -482 225 -639 431 -77 102 -160 274 -196 403 l-27 99 0 1125 0
       1125 27 98 c119 432 460 755 893 847 86 18 142 19 1190 17 l1100 -2 99 -27z"/>

       <path d="M2486 4176 c-54 -20 -85 -45 -121 -95 l-30 -43 -3 -624 -2 -624 -611
       0 c-659 0 -640 1 -707 -55 -77 -64 -101 -192 -54 -282 26 -50 84 -100 132
       -113 21 -6 277 -10 638 -10 l602 0 0 -610 c0 -660 -1 -641 55 -708 64 -77 192
       -101 282 -54 50 26 100 84 113 132 6 21 10 277 10 638 l0 602 603 0 c360 0
       616 4 637 10 19 5 52 24 73 41 109 90 111 265 5 354 -67 56 -48 55 -708 55
       l-610 0 0 603 c0 360 -4 616 -10 637 -13 48 -63 106 -112 131 -53 27 -132 33
       -182 15z"/>
       
       </g>
       </svg>
    )
}

export default PlusSVG;
