import ExitCrossSVG from "../../../svg/exitCross_svg";
import { t } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const PifagorDescriptionPopup = ({ close, additionInfo}) => {

    const pifagorDescription = additionInfo[0]
    const yourNumber = additionInfo[1]
    const number = additionInfo[2]

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    return (
            // Основа
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">
            
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                        {t('pifagor-title')}
                    </div>

                    <div className="flex w-full flex-col gap-5 p-5">
                    {/* Основа */}
                    
                        <p>{t(`pifagor-${number}-description`)}</p>
                        <div className="flex w-fit items-center justify-center   gap-5">
                            <p>{t('pifagor-yourNumber')}</p>
                            <p className="bg-blue-500 text-white rounded-full pl-2 pr-2 m-1">{yourNumber}</p>
                        </div>
                        <p className="">{pifagorDescription}</p>
                
                {/* Основа */}
                </div>
            </div>
            <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
        </div>
    </div>
    )
}

export default PifagorDescriptionPopup;