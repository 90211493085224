export const MoonRiseSVG = ({ className }) => {
    return (

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="1.5em" height="1.5em"
            // fill="currentColor"
            // stroke="currentColor"

            viewBox="0 0 100.000000 100.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M416 914 c-71 -61 -85 -83 -60 -91 5 -2 34 17 64 42 l55 45 5 -162
c4 -136 7 -163 20 -163 13 0 16 27 20 164 l5 164 55 -47 c52 -45 70 -51 77
-29 4 11 -140 143 -156 143 -4 0 -42 -30 -85 -66z"/>
                <path d="M395 423 c-43 -16 -50 -22 -56 -53 -9 -42 -34 -62 -73 -58 -24 2 -34
-3 -53 -31 -31 -46 -53 -123 -53 -187 l0 -53 -77 -3 c-61 -2 -78 -6 -78 -18 0
-13 62 -15 495 -15 433 0 495 2 495 15 0 12 -17 16 -77 18 l-78 3 0 42 c0 35
-5 47 -31 70 -32 28 -33 34 -14 94 10 31 -80 124 -152 160 -71 34 -176 41
-248 16z m105 -170 c43 -49 84 -128 95 -181 l7 -32 -141 0 c-116 0 -141 3
-141 14 0 30 31 48 71 41 31 -5 39 -4 39 9 0 19 -30 36 -65 36 -32 0 -85 -50
-85 -80 0 -18 -5 -21 -37 -18 l-38 3 1 65 c1 88 32 160 69 160 40 0 81 26 95
59 l12 31 39 -31 c21 -16 56 -51 79 -76z"/>
            </g>
        </svg>
    )
}

export const MoonSunsetSVG = ({ className }) => {
    return (

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="1.5em" height="1.5em"
            // fill="currentColor"
            // stroke="currentColor"
            viewBox="0 0 100.000000 100.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M485 968 c-3 -7 -6 -82 -7 -166 l-3 -152 -55 45 c-54 44 -70 50 -77
28 -4 -11 143 -143 158 -143 16 0 160 132 156 143 -7 22 -25 16 -77 -29 l-55
-47 -5 163 c-4 118 -8 165 -17 168 -7 2 -15 -2 -18 -10z"/>
                <path d="M395 423 c-43 -16 -50 -22 -56 -53 -9 -42 -34 -62 -73 -58 -24 2 -34
-3 -53 -31 -31 -46 -53 -123 -53 -187 l0 -53 -77 -3 c-61 -2 -78 -6 -78 -18 0
-13 62 -15 495 -15 433 0 495 2 495 15 0 12 -17 16 -77 18 l-78 3 0 42 c0 35
-5 47 -31 70 -32 28 -33 34 -14 94 10 31 -80 124 -152 160 -71 34 -176 41
-248 16z m105 -170 c43 -49 84 -128 95 -181 l7 -32 -141 0 c-116 0 -141 3
-141 14 0 30 31 48 71 41 31 -5 39 -4 39 9 0 19 -30 36 -65 36 -32 0 -85 -50
-85 -80 0 -18 -5 -21 -37 -18 l-38 3 1 65 c1 88 32 160 69 160 40 0 81 26 95
59 l12 31 39 -31 c21 -16 56 -51 79 -76z"/>
            </g>
        </svg>
    )
}
