const InfoSVG = ({className}) => {
    return (
            <svg 
            className={className} 
            width="1.5em" height="1.5em"
            fill="currentColor"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"  
            viewBox="0 0 128 128">
            
            <path  d="M64,117c-29.2,0-53-23.8-53-53s23.8-53,53-53s53,23.8,53,53S93.2,117,64,117z M64,17 c-25.9,0-47,21.1-47,47s21.1,47,47,47s47-21.1,47-47S89.9,17,64,17z"/>
            <path  d="M64 42.7c-1.7 0-3 1.3-3 3s1.3 3 3 3c1.7 0 3-1.3 3-3S65.7 42.7 64 42.7zM64 93c-1.7 0-3-1.3-3-3V62.3c0-1.7 1.3-3 3-3 1.7 0 3 1.3 3 3V90C67 91.7 65.7 93 64 93z"/>
            </svg>
    )
}

export default InfoSVG;
