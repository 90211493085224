import ExitCrossSVG from "../../../svg/exitCross_svg";
import { useSelector, useDispatch} from "react-redux";
import { useEffect } from "react";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const HealthMapPopup = ({ close, additionInfo}) => {
  const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const friendsListEnergies = additionInfo ? additionInfo : null

    const cardsUrl = useSelector(state => state.optionsSlice.cardStyleUrl)
    const matrixListOfEnergies = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    const listOfHealthEnergies = friendsListEnergies ? friendsListEnergies : matrixListOfEnergies[3]


    const chakras = [
        {
          id: 1,
          color: "#B977E2",
          image: "/images/chakras/1.png",
          title: t('healthMap-sahasrara'),
          description: t('healthMap-sahasraraDescription'),
          health: t('healthMap-sahasraraHealth'),
          info: [listOfHealthEnergies[0], listOfHealthEnergies[1], listOfHealthEnergies[2]],
        },
        {
          id: 2,
          color: "#98A4FD",
          image: "/images/chakras/2.png",
          title: t('healthMap-ajna'),
          description: t('healthMap-ajnaDescription'),
          health: t('healthMap-ajnaHealth'),
          info: [listOfHealthEnergies[3], listOfHealthEnergies[4], listOfHealthEnergies[5]],
        },
        {
          id: 3,
          color: "#97FDFF",
          image: "/images/chakras/3.png",
          title: t('healthMap-vishuddha'),
          description: t('healthMap-vishuddhaDescription'),
          health: t('healthMap-vishuddhaHealth'),
          info: [listOfHealthEnergies[6], listOfHealthEnergies[7], listOfHealthEnergies[8]],
        },
        {
          id: 4,
          color: "#E8FE9C",
          image: "/images/chakras/4.png",
          title: t('healthMap-anahata'),
          description: t('healthMap-anahataDescription'),
          health: t('healthMap-anahataHealth'),
          info: [listOfHealthEnergies[9], listOfHealthEnergies[10], listOfHealthEnergies[11]],
        },
        {
          id: 5,
          color: "#FFFE7A",
          image: "/images/chakras/5.png",
          title: t('healthMap-manipura'),
          description: t('healthMap-manipuraDescription'),
          health: t('healthMap-manipuraHealth'),
          info: [listOfHealthEnergies[12], listOfHealthEnergies[13], listOfHealthEnergies[14]],
        },
        {
          id: 6,
          color: "#FDD843",
          image: "/images/chakras/6.png",
          title: t('healthMap-svadhisthana'),
          description: t('healthMap-svadhisthanaDescription'),
          health: t('healthMap-svadhisthanaHealth'),
          info: [listOfHealthEnergies[15], listOfHealthEnergies[16], listOfHealthEnergies[17]],
        },
        {
          id: 7,
          color: "#FA615B",
          image: "/images/chakras/7.png",
          title: t('healthMap-muladhara'),
          description: t('healthMap-muladharaDescription'),
          health: t('healthMap-muladharaHealth'),
          info: [listOfHealthEnergies[18], listOfHealthEnergies[19], listOfHealthEnergies[20]],
        },
      ];

    return (
              // Основа
              <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex  w-full flex-col items-center overflow-auto rounded-[12px]">
    
                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                      {t('healthMap-title')}
                    </div>
    
                    <div className="flex w-full flex-col gap-5 p-5">
                     {/* Основа */}
        
                        <p className="text-xs">{t('healthMap-basicDescription')}</p>
                        <div className="w-full ease-in-out duration-700 open:bg-white open:h-[500px] overflow-auto open:ring-1 open:ring-black/5 open:shadow-inner rounded-lg">
                            
                            {/* ПЛАШКА С НАЗВАНИЯМИ ТАБЛИЦЫ */}

                            <div className="flex justify-around items-center w-full rounded-full hover:animate-pulse bg-gray-100 sm:text-[7px] text-black">
                                <div className="flex-initial w-[100px] sm:w-10"></div>
                                <p className="flex w-[500px] sm:w-[200vw] justify-center font-bold">{t('healthMap-chakraName')}</p>
                                <div className="flex w-[320px] justify-around font-bold gap-2">
                                    <p>{t('healthMap-physicsTitle')}</p>
                                    <p>{t('healthMap-energysTitle')}</p>
                                    <p>{t('healthMap-emotionsTitle')}</p>
                                </div>
                            </div>

                            {/* ЧАКРЫ ВНУТРЯГ */}
                            {chakras.map((chakra) => (
                                <div className="" key={chakra.id}>
                                    <div className={`flex sm:p-2 justify-around items-center w-full rounded-full bg-[${chakra.color}]`}>
                                       
                                       {/* КАРТИНКА */}
                                       <img className="flex-initial w-[100px] sm:w-10" src={chakra.image} alt={chakra.id}/>
                                        
                                        {/* ОПИСАНИИЕ */}
                                        <div className="flex flex-col w-[500px] sm:w-[200vw] justify-center font-bold text-black">
                                            <p className="w-[10px] font-bold sm:text-[10px]">{chakra.title}</p>
                                            <p className="text-[14px] sm:text-[10px]">{chakra.description}</p>
                                            <p className="text-[12px] sm:text-[8px] text-blue-800">{chakra.health}</p>

                                        </div>

                                        {/* САМИ ЦИФРЫ */}
                                        <div className="flex w-[320px] justify-around font-bold gap-2 text-black">
                                            {chakra.info.map((info, index) => (
                                                <p key={index}>{info}</p>
                                            ))}
                                        </div>



                                    </div>

                                    <div className="p-2">
                                      
                                      <details className="details_tag text-[10px]">
                                        
                                        {/* <div className="w-full flex items-center justify-center p-2"> */}
                                          <summary className="summary_tag button_blue_rounded w-full flex items-center justify-center !h-6">
                                            {t('healthMap-open')}
                                          </summary>
                                        {/* </div> */}

                                        <div className="flex flex-col gap-1 paragraph_tag_description p-2 pl-2">

                                          {chakra.info.map((info, index) => (
                                            <div key={index} className="flex flex-col gap-1">
                                              <p className="bg-blue-300 text-black rounded-full w-fit pl-2 pr-2">{info} - {t(`healthMap-description-${info}_name`)}</p>
                                              <div className="flex sm:flex-col items-center justify-center gap-2">
                                              <div className="taro_style_upper w-[100px]"><img className="taro_style dance_button" src={`${cardsUrl}/${info}.jpg`} alt="cards"/></div>
                                                <div className="flex flex-col gap-2 w-[90%]">
                                                  <p >{t(`healthMap-description-${info}`)}</p>
                                                  <p className="text-green-500 rounded w-fit pl-2 pr-2">{t(`healthMap-description-${info}_forHealth`)}</p>
                                                </div>
                                              </div>
                                              
                                            </div>
                                            
                                        ))}

                                        </div>

                                      </details>
                                    </div>

                                    


                                </div>

                                ))}


                        {/* ИТОГ */}
                        <div className="flex justify-around items-center w-full rounded-full hover:animate-pulse bg-gray-100 sm:text-[7px] text-black">
                                <p className="flex w-[500px] sm:w-[200vw] justify-center font-bold">
                                  {t('healthMap-result')}
                                </p>
                                <div className="flex w-[320px] justify-around font-bold gap-2">
                                    <p>{listOfHealthEnergies[21]}</p>
                                    <p>{listOfHealthEnergies[22]}</p>
                                    <p>{listOfHealthEnergies[23]}</p>
                                </div>
                            </div>
                        </div>


                {/* Основа */}
                </div>
            </div>
            <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
        </div>
     </div>
    )
}

export default HealthMapPopup;