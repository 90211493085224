import ExitCrossSVG from "../../../svg/exitCross_svg";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import React, { useState } from "react"
import axios from 'axios'
import Cookies from 'js-cookie';
import { baseBackendUrl } from "../websocket_comp";
import { useNavigate, Link } from 'react-router-dom';
import PopupContainer from "../popups_root";
import { updateAuthorizedUser } from "../../store/authorizedUserSlice";

const LoginPopupJoin = ({ close, additionInfo }) => {
    const [data, setData] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [forgetPopupOpen, setForgetPopupOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = { username: username.username.toLowerCase(), password: password.password }

        const response = await axios.post(
            `${baseBackendUrl}/login`,
            data,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken')
                }
            }
        );
        setData(response.data);

        if (response.data.approve === 'yes') {
            dispatch(updateAuthorizedUser(response.data.user))

            try {
                navigate(`/${username.username.toLowerCase()}`);
            } catch {
                window.location.reload()
            }
        }
    };

    const handleKeyPress = (event) => {
        if ((event.key === 'Enter' || event.type === 'click') && username && password) {
            handleSubmit(event);
            dispatch(updateDisplayNonOverflow(false))
            close()
        }
    };

    const errorMessage = () => {
        if (data.message) {
            return <span className="flex flex-col text-xs p-1 text-red-500">{data.message}</span>
        }
    }

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    return (
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 h-fit sm:h-full sm:w-full w-fit">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                {/* <img className="shadow" alt="index_matrix" src="/images/index_matrix.png" /> */}
                <img className="w-[300px]" alt="index_matrix" src="/images/logo.png" />

                <form method="POST" action={baseBackendUrl + 'login'} id="login-form" className="flex flex-col gap-2 w-[300px]" onKeyDown={handleKeyPress}>
                    {/* <input type="hidden" name="csrfmiddlewaretoken" value={Cookies.get('csrftoken')} /> */}

                    <input className="appearance-none shadow rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm"
                        type="text" name="username" placeholder={t('login-username-placeholder')} autoComplete="off" autoFocus onChange={(e) => setUsername({ username: e.target.value })} />
                    <input className="appearance-none shadow rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm"
                        type="password" name="password" placeholder={t('login-password-placeholder')} autoComplete="off" onChange={(e) => setPassword({ password: e.target.value })} />

                    {/* <!--button--> */}
                    <input id="login_submit_button" className="button_blue" type="button" value={t('login-button')} onClick={handleKeyPress} />
                    {errorMessage()}

                    <div className="flex justify-between text-sm">
                        <p>{t('login-dontHaveAccount')}</p>
                        <Link to="/register" id="dont_have_account_button" className="link_blue">{t('login-signup-link')}</Link>
                    </div>

                    {/* <!-- Если забыли пароль --> */}
                    <div className="flex justify-between text-sm">
                        <p>{t("reg-forgetPassword")}</p>
                        <p id="forget_password" className="link_blue" onClick={() => setForgetPopupOpen(!forgetPopupOpen)}>{t('reg-restorePassword')}</p>
                        {forgetPopupOpen ? <PopupContainer t={t} choosePopup={'forget_password'} setPopupOpen={setForgetPopupOpen} additionInfo='' /> : null}
                    </div>
                </form>

            </div>
        </div>
    )
}

export default LoginPopupJoin;