import { t } from "i18next";

const NotFoundPage = () => {
    return (
        <div className="empty_page_container">
            <strong className="text-2xl">{t('pageDoesNotExists')}</strong>
        </div>
        )
}

export default NotFoundPage;
