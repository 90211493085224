import {createSlice} from '@reduxjs/toolkit';

const optionsSlice = createSlice ({
    name: 'options',
    initialState: {
        cardStyleUrl: !localStorage.getItem('cardsStyleUrl') ? '/images/cards_classic' : localStorage.getItem('cardsStyleUrl'),
        // postsOpen: localStorage.getItem('postsOpen') || false,
        // profileAddonInfoOpen: localStorage.getItem('profileAddonInfoOpen') || false,
    },
    reducers: {
        updateCardStyleUrl(state, action) {
            state.cardStyleUrl= action.payload
        },
        // updatePostsOpen(state, action) {
        //     state.postsOpen = action.payload
        //     localStorage.setItem('postsOpen', state.postsOpen)
        // },
    }
})

export const {updateCardStyleUrl} = optionsSlice.actions; 

export default optionsSlice.reducer;