import ExitCrossSVG from "../../../svg/exitCross_svg";
import { t } from "i18next";
import { useState } from "react";
import MatrixVisual from "../profile_components/matrix_visual_comp";
import { matrixCalculationFunc } from "../calculations/profile_matrix_calculation";
import MatrixVisualPair from "../profile_components/matrix_visual_pair";
import { baseBackendUrl } from "../websocket_comp";
import { useSelector } from "react-redux";
import HandEyeSVG from "../../../svg/svg_hand_eye";
import SnakeTaleSVG from "../../../svg/svg_snake_tale";
import OrbitYearSVG from "../../../svg/svg_orbit_year";
import PinkHeartSVG from "../../../svg/pink_heart";
import WomanSignSVG from "../../../svg/svg_woman_sign";
import ManSignSVG from "../../../svg/svg_man_sign";
import PopupContainer from "../popups_root";
import Checkbox from "../checkbox";

const FriendMatrixPopup = ({ close, additionInfo }) => {

    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
    const oneMatrix = additionInfo

    // POPUPS
    const [healthMapPopupOpen, setHealthMapPopupOpen] = useState(false);
    const [carmicProgramPopupOpen, setCarmicProgramPopupOpen] = useState(false);
    const [purposesPopupOpen, setPurposesPopupOpen] = useState(false);
    const [yearEnergyPopupOpen, setYearEnergyPopupOpen] = useState(false);
    const [materialKarmaPopupOpen, setMaterialKarmaPopupOpen] = useState(false);
    const [parentsKarmaPopupOpen, setParentsKarmaPopupOpen] = useState(false);
    const [compatibilityCheckbox, setCompatibilityCheckbox] = useState(false);
    // eslint-disable-next-line
    const [friendMatrixEnergiesList, setFriendMatrixEnergiesList] = useState(matrixCalculationFunc(oneMatrix.family_date))

    const handleClose = () => {
        close()
    }

    // const friendMatrixEnergiesList = matrixCalculationFunc(oneMatrix.family_date)

    const visualisationMatrixEnergies = () => {

        if (compatibilityCheckbox) {
            return (
                <div>
                    <p className="popup_title">{t('profile-relationshipPair-pairMatrix_with')}</p>
                    <MatrixVisualPair
                        birthDateInner={authorizedUser.birth_date}
                        secondBirthDate={oneMatrix.family_date}
                    />
                </div>
            )
        } else {
            return (
                <div className="flex sm:flex-col">
                    <div className="flex flex-col">

                        <p className="popup_title">{t('profile-matrixButton')}</p>

                        <MatrixVisual
                            matrixListOfEnergiesInner={friendMatrixEnergiesList}
                            birthDateInner={oneMatrix.family_date}
                            gender={oneMatrix.family_gender}
                        />

                        <div className="flex flex-col gap-2 p-2">

                            <div className="br_border_line_gray" />

                            <p className="popup_title !text-red-400">{t('profile-currentTasks_title')}</p>

                            <ul className="flex justify-center items-center sm:items-start gap-3 sm:gap-1 text-[9px] ">

                                <li className="flex flex-col gap-3 items-center w-[70px]">
                                    <div className="button_near_matrix w-10 h-10" onClick={() => setPurposesPopupOpen(!purposesPopupOpen)}><HandEyeSVG className='w-8 h-8 fill-red-300 hover:fill-white ' /></div>
                                    <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-destiny')}</p>
                                </li>

                                <li className="flex flex-col gap-3 items-center w-[70px]">
                                    <div className="button_near_matrix w-10 h-10" onClick={() => setCarmicProgramPopupOpen(!carmicProgramPopupOpen)}><SnakeTaleSVG className='w-8 h-8 fill-red-300 hover:fill-white' /></div>
                                    <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-carma')}</p>
                                </li>

                                <li className="flex flex-col gap-3 items-center w-[70px]">
                                    <div className="button_near_matrix w-10 h-10" onClick={() => setYearEnergyPopupOpen(!yearEnergyPopupOpen)}><OrbitYearSVG className='w-8 h-8 fill-red-300 hover:fill-white' /></div>
                                    <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-year')}</p>
                                </li>

                                <li className="flex flex-col gap-3 items-center w-[70px]">
                                    <div className="button_near_matrix w-10 h-10 overflow-hidden" onClick={() => setMaterialKarmaPopupOpen(!materialKarmaPopupOpen)}><SnakeTaleSVG className='w-10 h-10 translate-y-4 fill-red-300 hover:fill-white' /></div>
                                    <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-materialKarma')}</p>
                                </li>

                                <li className="flex flex-col gap-3 items-center w-[70px]">
                                    <div className="button_near_matrix w-10 h-10" onClick={() => setParentsKarmaPopupOpen(!parentsKarmaPopupOpen)}>
                                        <WomanSignSVG className='w-10 h-10 fill-red-300 hover:fill-white' />
                                        <ManSignSVG className='w-10 h-10 fill-red-300 hover:fill-white' />
                                    </div>
                                    <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-parentsKarma')}</p>
                                </li>

                            </ul>

                            {/* ------- */}
                            <div className="br_border_line_gray" />
                            <div className="br_border_line_gray" />

                            <p className="popup_title !text-green-400">{t('profile-healthMap_title')}</p>

                            <div className="flex flex-col gap-3 items-center justify-center text-[9px]">
                                <div className="button_near_matrix w-10 h-10" onClick={() => setHealthMapPopupOpen(!healthMapPopupOpen)}><PinkHeartSVG className='w-6 h-6 fill-green-300 hover:fill-white' /></div>
                                <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-health')}</p>
                            </div>

                            {purposesPopupOpen ? <PopupContainer choosePopup={'purposes'} setPopupOpen={setPurposesPopupOpen} additionInfo={[friendMatrixEnergiesList[0], oneMatrix.family_gender]} /> : null}
                            {parentsKarmaPopupOpen ? <PopupContainer choosePopup={'parents_karma'} setPopupOpen={setParentsKarmaPopupOpen} additionInfo={[friendMatrixEnergiesList[0], oneMatrix.family_gender]} /> : null}
                            {materialKarmaPopupOpen ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setMaterialKarmaPopupOpen} additionInfo={['zone_year_soulTask', friendMatrixEnergiesList[0][2], oneMatrix.family_gender]} /> : null}
                            {carmicProgramPopupOpen ? <PopupContainer choosePopup={'carmic_program'} setPopupOpen={setCarmicProgramPopupOpen} additionInfo={[friendMatrixEnergiesList[0], oneMatrix.family_gender]} /> : null}
                            {healthMapPopupOpen ? <PopupContainer choosePopup={'health_map'} setPopupOpen={setHealthMapPopupOpen} additionInfo={friendMatrixEnergiesList[3]} /> : null}
                            {yearEnergyPopupOpen ? <PopupContainer choosePopup={'year_energy_description'} setPopupOpen={setYearEnergyPopupOpen} additionInfo={friendMatrixEnergiesList[5]} /> : null}
                        </div>
                    </div>


                </div>
            )
        }
    }

    const finalPhotoUrl = `${baseBackendUrl}/media/${oneMatrix.family_profile_pic ? oneMatrix.family_profile_pic : 'no_pic.png'}`;

    const friendsTableName = () => {
        return (
            <div className="w-fit pr-4 grid grid-cols-4 items-center gap-1 border rounded-full p-1 sm:text-[11px]">


                <div
                    className="absoulte border-2 border-white bg-cover bg-no-repeat bg-center w-[40px] h-[40px] rounded-full"
                    style={{ backgroundImage: `url(${finalPhotoUrl})` }}
                />

                <p className="col-span-1 flex items-center justify-center">{oneMatrix.family_name}</p>

                {oneMatrix.family_status ? <p className="flex items-center justify-center">({oneMatrix.family_status})</p> : <p className="flex items-center justify-center">-</p>}

                <p className="flex items-center justify-center">{oneMatrix.family_date}</p>

            </div>
        )
    }

    return (
        // Основа
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-0 w-fit h-fit sm:h-full sm:w-full">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                <div className="flex w-full h-fit flex-col items-center overflow-x-hidden pt-3">

                    {/* Основа */}
                    <div className="flex w-full flex-col gap-5 p-2 relative items-center justify-center">

                        {friendsTableName()}

                        <Checkbox
                            text={t('profile-relationshipPair-pairMatrix_with')}
                            handleCheckboxInner={() => setCompatibilityCheckbox(!compatibilityCheckbox)}
                        />

                        {visualisationMatrixEnergies()}

                    </div>

                </div>

                <div className="w-full p-2">
                    <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
                </div>

            </div>
        </div>
        // Основа
    )
}

export default FriendMatrixPopup;