import { useState, useEffect, useRef } from "react";
import PostDeleteSVG from "../../../svg/postDelete_svg";
import EditPostSVG from "../../../svg/editPost_svg";
import DropDownSVG from "../../../svg/dropDown_svg";
import PopupContainer from "../popups_root"
import { t } from "i18next";

const DropDownMenu = ({ postIdInner, user, contentInner, buttonColor='currentColor' }) => {
  const [menuBool, setMenuBool] = useState(false);
  const [popupDeleteOpen, setDeletePopupOpen] = useState(false);
  const [popupEditOpen, setEditPopupOpen] = useState(false);

  // console.log(postIdInner, user, contentInner )

  // useEffect(()=>console.log(menuBool),[menuBool])

  const popupRef = useRef(null);

  useEffect(() => {
    if (popupDeleteOpen || popupEditOpen) { setMenuBool(false) }

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeAllPopups();
      }
    };


    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        closeAllPopups();
      }
    };


    const closeAllPopups = () => {
      if (menuBool) {
        setMenuBool(!menuBool)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuBool]);




  return (
    <>
      {/* // <!-- Кнопка выподающего меню --> */}
      <button className=" icon-btn dropdown-toggle hover:rotate-90 ease-in-out duration-300"
        type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => setMenuBool(!menuBool)}><DropDownSVG className={buttonColor} /></button>

      {/* // <!-- Само меню --> */}
      {
        menuBool ? (
          <div ref={popupRef} id="dropdown-menu" className="absolute p-2 navigation-bg border-[1px] border-color-popup rounded-lg -ml-[179px] -mt-[25px] shadow-xl">

            <button id="confirm_delete_button" className="flex items-center p-1 text-red-600  hover:text-blue-500 w-full gap-1" onClick={() => setDeletePopupOpen(!popupDeleteOpen)}>
              <PostDeleteSVG className='hover:rotate-90' />
              <p>{t('profile-deletePost')}</p>
            </button>

            <button id="edit_post_button" className="flex items-center p-1 text-lime-600 hover:text-blue-500 gap-1" onClick={() => setEditPopupOpen(!popupEditOpen)}>
              <EditPostSVG className='hover:-rotate-90' />
              <p>{t('profile-editPost')}</p>
            </button>

          </div>
        ) : null
      }

      {popupDeleteOpen ? <PopupContainer choosePopup={'delete_post'} setPopupOpen={setDeletePopupOpen} additionInfo={[postIdInner]} /> : null}
      {popupEditOpen ? <PopupContainer choosePopup={'edit_post'} setPopupOpen={setEditPopupOpen} additionInfo={['edit', contentInner, postIdInner]} /> : null}

    </>
  )
}

export default DropDownMenu;