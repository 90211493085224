const TiltWindowComp = ({tiltContent, tiltTitle, click, classNameInner}) => {
    return (
        <div className={`tilt-card-wrapper hover:z-10 ${classNameInner}`} onClick={click}>
            <div className="tilt-card-inner"/>
            <div className="tilt-card-inner"/>
            <div className="tilt-card-inner"/>
            <div className="tilt-card-inner"/>
            <div className="tilt-card-inner"/>
            <div className="tilt-card-inner"/>
            <div className="tilt-card-inner"/>
            <div className="tilt-card-inner"/>
            <div className="tilt-card-inner"/>

            <div className="tilt-content">{tiltContent}            
                <div className="tilt-content-p w-[150px] sm:w-[100px]">{tiltTitle}</div>
            </div>
        </div>

    )
}

export default TiltWindowComp;