import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// import { baseBackendUrl } from "../websocket_comp";
// import axios from "axios";
import { useDispatch } from "react-redux";
import { updateAuthorizedUser } from "../store/authorizedUserSlice";

const Logout = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // handleBlur()

    // localStorage.removeItem('authenticatedUser');
    dispatch(updateAuthorizedUser({}))

    // let user = '';
    // updateNavigationLogout({ user });

    setTimeout(() => { navigate('/join') }, 600)

    // navigate('/join'); // Redirect to the homepage ("/")
    // window.location.reload()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return null;
};

export default Logout;