import { useSelector } from "react-redux"
import { useState } from "react"
import PopupContainer from "../popups_root"
import { t } from "i18next"
import OrbitYearSVG from "../../../svg/svg_orbit_year"
import { moonPosition } from "../calculations/zodiac_calculations"
import { citiesLatLong } from "../calculations/zodiac_calculations"

export const EveryDayInfluence = () => {
    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    const user = profileUser?.user.first_name


    const matrixList = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    // const matrixListOfEnergies = matrixList[0]
    const matrixYear = matrixList[5]

    const [yearEnergyPopupOpen, setYearEnergyPopupOpen] = useState(false);

    const moonInfo = moonPosition(new Date(), citiesLatLong['Москва'])

    return (
        <div className="flex flex-col sm:items-start items-center justify-center p-10 gap-10">
            <p className="popup_title">{t('profile_today_influence')} {user}:</p>

            <div className="w-[300px] flex">

                <div className="flex gap-3 items-center justify-center" >
                    <p className="">{t('profile-matrix-instructions-year')} - </p>

                    <div className="button_blue !w-10 !h-10 !rounded-full flex items-center justify-center" onClick={() => setYearEnergyPopupOpen(!yearEnergyPopupOpen)}>
                        {/* <OrbitYearSVG className='w-8 h-8 fill-red-300 hover:fill-white' /> */}
                        {matrixYear}
                    </div>
                </div>

                {yearEnergyPopupOpen ? <PopupContainer choosePopup={'year_energy_description'} setPopupOpen={setYearEnergyPopupOpen} additionInfo={null} /> : null}

            </div>


            <div className='w-[300px] flex items-center justify-center flex-col gap-2 text-[10px]'>

                {
                    moonInfo[40]?.mercury[0]
                        ?
                        <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.mercury[0] ? 'text-red-500' : null}`}>
                            <p className='text-[16px]'>{t('zodiac-mercury')}</p>
                            {moonInfo[40]?.mercury[0] ? <p>{t('retrograd_now_to')}{moonInfo[40]?.mercury[3]}</p> : null}
                            <p>Следующий ретроград c {moonInfo[40]?.mercury[1]} - {moonInfo[40]?.mercury[2]}</p>
                        </div>
                        : null
                }


                {moonInfo[40]?.venus[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.venus[0] ? 'text-red-500' : null}`}>
                        <p className='text-[16px]'>{t('zodiac-venus')}</p>
                        {moonInfo[40]?.venus[0] ? <p>{t('retrograd_now_to')}{moonInfo[40]?.venus[3]}</p> : null}
                        <p>Следующий ретроград c {moonInfo[40]?.venus[1]} - {moonInfo[40]?.venus[2]}</p>
                    </div>
                    : null}


                {moonInfo[40]?.mars[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.mars[0] ? 'text-red-500' : null}`}>
                        <p className='text-[16px]'>{t('zodiac-mars')}</p>
                        {moonInfo[40]?.mars[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.mars[3]} </p> : null}
                        <p>Следующий ретроград c {moonInfo[40]?.mars[1]} - {moonInfo[40]?.mars[2]}</p>
                    </div>
                    : null}


                {moonInfo[40]?.jupiter[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.jupiter[0] ? 'text-red-500' : null}`}>
                        <p className='text-[16px]'>{t('zodiac-jupiter')}</p>
                        {moonInfo[40]?.jupiter[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.jupiter[3]} </p> : null}
                        <p>Следующий ретроград c {moonInfo[40]?.jupiter[1]} - {moonInfo[40]?.jupiter[2]}</p>
                    </div>
                    : null}


                {moonInfo[40]?.saturn[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.saturn[0] ? 'text-red-500' : null}`}>
                        <p className='text-[16px]'>{t('zodiac-saturn')}</p>
                        {moonInfo[40]?.saturn[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.saturn[3]} </p> : null}
                        <p>Следующий ретроград c {moonInfo[40]?.saturn[1]} - {moonInfo[40]?.saturn[2]}</p>
                    </div>
                    : null}


                {
                    moonInfo[40]?.uran[0]
                        ?
                        <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.uran[0] ? 'text-red-500' : null}`}>
                            <p className='text-[16px]'>{t('zodiac-uran')}</p>
                            {moonInfo[40]?.uran[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.uran[3]} </p> : null}
                            <p>Следующий ретроград c {moonInfo[40]?.uran[1]} - {moonInfo[40]?.uran[2]}</p>
                        </div>
                        : null
                }


                {moonInfo[40]?.neptun[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.neptun[0] ? 'text-red-500' : null}`}>
                        <p className='text-[16px]'>{t('zodiac-neptun')}</p>
                        {moonInfo[40]?.neptun[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.neptun[3]} </p> : null}
                        <p>Следующий ретроград c {moonInfo[40]?.neptun[1]} - {moonInfo[40]?.neptun[2]}</p>
                    </div>
                    : null
                }


                {moonInfo[40]?.pluto[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.pluto[0] ? 'text-red-500' : null}`}>
                        <p className='text-[16px]'>{t('zodiac-pluto')}</p>
                        {moonInfo[40]?.pluto[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.pluto[3]} </p> : null}
                        <p>Следующий ретроград c {moonInfo[40]?.pluto[1]} - {moonInfo[40]?.pluto[2]}</p>

                    </div>
                    : null
                }


            </div>
        </div>
    )
}