import ExitCrossSVG from "../../../svg/exitCross_svg";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { formattedDateWithoutClock } from "../services";
import { useState, useEffect } from "react";
import PopupContainer from "../popups_root";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import moment from "moment";
import { baseBackendUrl } from "../websocket_comp";
import axios from "axios";
import { updateProfileAction } from "../../store/profileUserInfoSlice";
import ProfileAboutMe from "../profile_components/profile_about_me";
import ProfileRelationship from "../profile_components/profile_relationship_matr";

const ProfileInfoPopup = ({ close, additionInfo }) => {
    const dispatch = useDispatch()

    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    const user = profileUser.user
    const username = user.username
    const birthDate = user.birth_date
    // const credits = user.credits

    const actualYearsBefore = (Math.floor((moment(moment().format('YYYY-MM-DD')).diff(moment(birthDate), 'months') / 12) * 10) / 10)
    const actualYears = Math.floor(actualYearsBefore * 2) / 2;

    const actualTimeOnline = formattedDateWithoutClock(new Date())
    const actualCreditsDayBonusDate = user.credits_day_bonus_date

    const postsCount = profileUser.posts_count
    const followingCount = profileUser.following_count
    const followings = profileUser.followings

    const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)

    const [followingPopupOpen, setFollowingPopupOpen] = useState(false);
    // const [creditsPopupOpen, setCreditsPopupOpen] = useState(false);

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }


    // const [last_time_online, credits_day_bonus_date, credits,] = [actualTimeOnline, actualCreditsDayBonusDate, user.credits]

    const addBonusInServer = async () => {
        let url = `${baseBackendUrl}/credits_day_bonus`;

        const data = { username: username, last_time_online_date: actualTimeOnline };
        await axios.post(url, data);
        dispatch(updateProfileAction(!updateProfile))
    }

    return (
        // Основа
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 w-fit h-fit sm:rounded-xl">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                <div className="flex w-full flex-col items-center overflow-auto ">

                    {/* ЗАГОЛОВОК */}
                    {/* <p className="popup_title">{t('profile-matrix-yearEnergyTitle')}</p> */}

                    <div className="flex w-full flex-col gap-5 p-5 justify-center items-center">
                        {/* Основа */}

                        <p>@{username}</p>

                        {/* Дата рождения */}
                        <div className="flex gap-2 items-center text-sm">
                            <img className="w-4 h-4" src={`images/bday.png`} alt="zodiac" />
                            <p>{formattedDateWithoutClock(birthDate)}</p>
                            <p>( {actualYears} {t('profile-yearsOld')} )</p>
                        </div>

                        {/* <!-- Кол-во Постов Подписок Фоловеров --> */}

                        {/* Публикации */}
                        <div className="flex gap-2 items-center">
                            <p>{t('profile-posts-count')}:</p>
                            <strong className="text-[16px]">{postsCount}</strong>
                        </div>

                        {/* Подписки */}
                        <div className="flex gap-2 items-center" onClick={() => followingCount ? setFollowingPopupOpen(!followingPopupOpen) : ''}>
                            <p>{t('profile-following')}:</p>
                            <strong id="following__count" className="text-[16px]">{followingCount || 0}</strong>
                        </div>


                        {followingPopupOpen ? <PopupContainer choosePopup={'following'} setPopupOpen={setFollowingPopupOpen} additionInfo={followings} /> : null}


                        <>
                            <ProfileAboutMe />
                            {/* {userBio ? <div className="br_border_line_gray" /> : null} */}
                            <ProfileRelationship />
                        </>


                        {/* БАЛЛЫ */}
                        <div className="flex flex-col items-center justify-center gap-5  shadow-lg rounded-3xl p-3 border-[1px] border-slate-700 ">

                            <div className="flex gap-2">
                                <p>{t('profile-credits_popup')}:</p>
                                <p>{user.credits}</p>
                            </div>


                            {user.username === authorizedUser.username
                                ?
                                <div className={'flex flex-col items-center justify-center gap-5 '}>
                                    <div className="br_border_line_gray" />

                                    <p>{t(`profile-credits_everyDayBonus`)}:</p>

                                    {/* Кнопка получить */}
                                    <div className="relative">

                                        {/* Красный кружочек */}
                                        {actualTimeOnline !== actualCreditsDayBonusDate
                                            ? <div className="absolute w-[10px] h-[10px] bg-red-500 border border_color_rounded_elements right-[1px] top-[1px] rounded-full z-[1] duration-500" />
                                            : null}

                                        {/* Сама кнопка */}
                                        <div className={`${actualTimeOnline !== actualCreditsDayBonusDate ? "button_blue_rounded !w-fit" : "button_gray_rounded"} `}
                                            onClick={() => {
                                                if (actualTimeOnline !== actualCreditsDayBonusDate) {
                                                    addBonusInServer()
                                                }
                                            }}
                                        >
                                            {/* Наименование кнопки */}
                                            {t('profile-credits_everyDayBonus_button')}
                                        </div>

                                    </div>

                                    <div className="br_border_line_gray" />
                                    <p>{t('profile-credits_everyPostBonus')}</p>


                                </div>
                                : null
                            }


                        </div>


                    </div>
                </div>
                <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
            </div>
        </div>
    )
}

export default ProfileInfoPopup;