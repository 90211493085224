const HandEyeSVG = ({className}) => {
    return (

        <svg 
        className={className} 
        // fill="currentColor"
        // stroke="currentColor"

        // width="3em" height="3em"
        fill="#000000" stroke="none"
        version="1.0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 96.000000 96.000000"
        preserveAspectRatio="xMidYMid meet">
       <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)">
       <path d="M455 878 c-19 -15 -39 -21 -62 -19 -26 2 -40 -4 -63 -27 l-30 -30 0
       -140 c0 -112 3 -142 15 -152 12 -10 18 -10 30 0 11 9 15 43 17 148 l3 137 25
       0 25 0 5 -125 5 -125 25 0 25 0 5 145 5 145 25 0 25 0 5 -145 5 -145 25 0 25
       0 5 125 5 125 25 0 25 0 5 -135 5 -135 25 0 c25 0 25 2 30 95 5 93 5 95 30 95
       24 0 25 -3 28 -62 3 -68 21 -95 47 -73 10 9 15 32 15 77 0 58 -3 67 -29 94
       -20 19 -40 29 -60 29 -21 0 -32 6 -36 20 -10 32 -56 61 -90 58 -21 -1 -41 6
       -60 20 -15 12 -40 22 -55 22 -15 0 -40 -10 -55 -22z"/>
       <path d="M787 524 c-4 -4 -7 -62 -7 -129 0 -108 -3 -127 -23 -165 -38 -72 -84
       -90 -231 -90 -108 0 -125 3 -157 23 -54 33 -241 215 -234 227 10 17 32 12 106
       -26 67 -33 72 -34 95 -19 18 12 24 24 24 54 0 46 -22 70 -45 51 -8 -7 -15 -21
       -15 -31 0 -16 -6 -15 -56 12 -31 16 -68 29 -83 29 -39 0 -87 -34 -96 -67 -6
       -26 2 -36 122 -150 167 -159 174 -163 339 -163 114 0 135 3 179 24 106 49 137
       117 133 296 -3 117 -4 125 -23 128 -12 2 -24 0 -28 -4z"/>
       <path d="M485 425 c-95 -34 -126 -126 -62 -190 72 -72 202 -72 274 0 106 106
       -50 247 -212 190z m113 -51 c38 -8 72 -38 72 -64 0 -65 -142 -92 -199 -39 -60
       56 23 124 127 103z"/>
       <path d="M530 335 c-15 -18 -10 -45 13 -59 34 -22 73 27 47 59 -16 19 -44 19
       -60 0z"/>
       </g>
       </svg>
    )
}

export default HandEyeSVG;
