// import { Link } from "react-router-dom";
import { t } from "i18next";

const About = () => {
    return (
        <div className="sm:w-[100dvw] w-[800px] h-[50dvh] flex items-center  justify-center">
            <div className="main_white_container text-center">
         
                <div className="w-full text-center flex sm:flex-col gap-2 items-cetner justify-center">
                    <p>{t('about-autor')}</p>
                    <p className="text-blue-500">Vadim Monroe</p>
                    {/* <Link to='/propoved' className="hover:text-blue-300 text-blue-500">Vadim Monroe</Link> */}
                    <p>© 2023 - 2024</p>
                </div>

                <p>{t('about-writeToMe')}</p>
                <p className="text-blue-500">propoved@mail.ru</p>
            </div>
        </div>

    )
}

export default About;