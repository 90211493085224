const MatrixYearsBackgroundSVG = ({className}) => {
    return (


        <svg viewBox="0 0 841 837" 
        className={className}
        width="290px" height="290px"
        // fill="currentColor"
        stroke="currentColor"
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <g style={{}}>
            <title>text1</title>
            <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre' }} x="56.394" y="380.361">0</text>
            <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre' }} x="51.966" y="361.231">1-2,5</text>
            <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre' }} x="58.556" y="329.282">2,5-3,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, 0, 0)"><tspan x="67.315" y="301.77">3,5-4</tspan><tspan x="67.315" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, 12.231692, -27.689075)"><tspan x="67.315" y="301.77">5</tspan><tspan x="67.315" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, 23.014982, -52.709965)"><tspan x="67.315" y="301.77">6-7,5</tspan><tspan x="67.315" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, 37.004803, -78.247139)"><tspan x="67.315" y="301.77">7,5-8,5</tspan><tspan x="67.315" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, 50.443954, -99.463654)"><tspan x="67.315" y="301.77">8,5-9</tspan><tspan x="67.315" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="190.844" y="145.977">10</text>
        </g>
        <g style={{}}>
          <title>text2</title>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="194.378" y="133.06">11-12,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="239.964" y="103.633">13,5-14</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="268.196" y="90.196">15</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="290.032" y="83.002">16-17,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="320.193" y="71.557">17,5-18,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="345.101" y="60.949">18,5-19</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="216.354" y="118.628">12,5-13,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="461.796" y="38.892">20</text>
        </g>
        <g style={{}}>
          <title>text3</title>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="461.428" y="64.251">21-22,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="477.25" y="76.105">22,5-23,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="512.564" y="87.303">23,5-24</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="560.417" y="91.082">25</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="563.651" y="107.346">26-27,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="580.926" y="121.022">27,5-28,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="611.986" y="136.595">28,5-29</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="701.376" y="195.366">30</text>
        </g>
        <g style={{}}>
          <title>text4</title>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="685.922" y="214.348">31-32,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="688.564" y="232.177">32,5-33,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="709.199" y="253.624">33,5-34</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="743.086" y="279.953">35</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="732.053" y="305.541">36-37,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="732.721" y="331.829">37,5-38,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="747.111" y="362.636">38,5-39</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="766.776" y="379.944">40</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="746.279" y="476.108">41-42,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="731.927" y="508.298">42,5-43,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="730.997" y="534.938">43,5-44</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="743.479" y="559.774">45</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="712.097" y="582.705">46-47,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="692.355" y="603.853">47,5-48,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="685.709" y="624.751">48,5-49</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="700.883" y="645.509">50</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="603.95" y="705.482">51-52,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="576.807" y="720.3">52,5-53,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="559.675" y="735.457">53,5-54</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="555.864" y="749.849">55</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="511.352" y="754.887">56-57,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="475.014" y="765.922">57,5-58,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="459.399" y="778.475">58,5-59</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="366.227" y="784.893">60</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="342.64" y="774.169">61-62,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="315.744" y="762.438">62,5-63,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="288.977" y="752.502">63,5-64</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="265.211" y="744.801">65</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="239.251" y="731.1">66-67,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="217.321" y="718.223">67-68,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="192.773" y="703.585">68,5-69</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="125.003" y="644.07">70</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} x="115.569" y="627.497">71-72,5</text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, 5.833687, 9.081874)"><tspan x="97.244" y="597.932">72,5-73,5</tspan><tspan x="97.244" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, -7.853704, -15.161709)"><tspan x="97.244" y="597.932">73,5-74</tspan><tspan x="97.244" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, -16.807489, -36.874733)"><tspan x="97.244" y="597.932">75</tspan><tspan x="97.244" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, -30.09, -63.634411)"><tspan x="97.244" y="597.932">76-77,5</tspan><tspan x="97.244" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, -37.397484, -90.200401)"><tspan x="97.244" y="597.932">77,5-78,5</tspan><tspan x="97.244" dy="1em">​</tspan></text>
          <text style={{ fill: 'rgba(0, 0, 0, 0.52)', fontFamily: 'Arial, sans-serif', fontSize: '10px', whiteSpace: 'pre'}} transform="matrix(1, 0, 0, 1, -44.339611, -120.440659)"><tspan x="97.244" y="597.932">78,5-79</tspan><tspan x="97.244" dy="1em">​</tspan></text>
        </g>
      </svg>

    )
}

export default MatrixYearsBackgroundSVG;
