import Language from "../little_components/language";
import { useState, useEffect } from "react";
import React, { Suspense } from "react";
import PopupContainer from "../little_components/popups_root";
import CalendarSVG from "../../svg/calendar_svg";
import { formattedDateWithoutClock } from "../little_components/services";
import { t } from "i18next";
import { useSelector, useDispatch } from 'react-redux';
import { updateCardStyleUrl } from "../store/optionsSlice";
import { useNavigate } from "react-router-dom";
import ButtonPilotRed from "../little_components/button_pilot_red"

const OptionsPage = () => {

    const [logoutButtonChecked, setLogoutButtonChecked] = useState(true);

    const [deletePopup, setDeletePopup] = useState(false)
    const [timer, setTimer] = useState(17);
    const [colorTheme, setColorTheme] = useState(localStorage.getItem('colorTheme') || "light")

    const dispatch = useDispatch();

    localStorage.setItem('cardsStyleUrl', '/images/cards_classic')

    if (!localStorage.getItem('cardsStyleUrl')) {
        localStorage.setItem('cardsStyleUrl', '/images/cards_classic')
    }

    const [cardsStyleUrl, setCardsStyleUrl] = useState(localStorage.getItem('cardsStyleUrl'))

    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    const user = authorizedUser
    const username = user.username

    const dateJoined = user.date_joined

    const cardsClassicUrl = '/images/cards_classic'
    const cardsDurerUrl = '/images/cards_durer'

    useEffect(() => {
        localStorage.setItem('cardsStyleUrl', cardsStyleUrl)
        dispatch(updateCardStyleUrl(cardsStyleUrl))
        // eslint-disable-next-line
    }, [cardsStyleUrl])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const randomNumber = Math.floor(Math.random() * 22) + 1;
            setTimer(randomNumber);
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    // меняем цветовую опцию
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', colorTheme)
        localStorage.setItem('colorTheme', colorTheme);
    }, [colorTheme])

    const navigate = useNavigate();
    setTimeout(() => { if (!logoutButtonChecked) { navigate('/logout') } }, 600)

    return (
        <div className="flex items-center">
            <div className="main_white_container w-[800px] sm:w-screen">
                <div className="grid grid-cols-2 p-4 gap-4">

                    <p className="h-12 flex items-center">{t(`navigate-logout`)}:</p>
                    {/* Кнопка выхода */}
                    <div className="flex justify-center">
                        {username === authorizedUser.username ? <ButtonPilotRed checked={logoutButtonChecked} setChecket={setLogoutButtonChecked} /> : null}
                    </div>

                    <div className="br_border_line_gray" /><div className="br_border_line_gray" />

                    <p className="">{t('options-choose_cards')}:</p><div></div>

                    <div className={`${cardsStyleUrl === cardsClassicUrl ? 'bg_bright_blue' : null} relative flex flex-col justify-center items-center gap-2 hover:bg-blue-400 p-1 rounded-lg cursor-pointer`} onClick={() => setCardsStyleUrl(cardsClassicUrl)}>
                        {/* {cardsStyleUrl === cardsClassicUrl ? <div className="absolute bg_bright_blue w-[200px] sm:w-[150px] h-[400px] sm:h-[300px] opacity-30 rounded-xl"></div> : null} */}
                        <div className="taro_style_upper pointer-events-none w-[200px] sm:w-[150px]">
                            <img className="taro_style dance_button" src={`/images/cards_classic/${timer}.jpg`} alt="classic_cards" />
                        </div>
                        <p>{t('options-cards-rider_white')}</p>
                    </div>


                    <div className={`${cardsStyleUrl === cardsDurerUrl ? 'bg_bright_blue' : null} relative flex flex-col justify-center items-center gap-2 hover:bg-blue-400 p-1 rounded-lg cursor-pointer`} onClick={() => setCardsStyleUrl(cardsDurerUrl)}>

                        {/* {cardsStyleUrl === cardsDurerUrl  ? <div className="absolute bg_bright_blue w-[200px] sm:w-[150px] h-[400px] sm:h-[300px] opacity-30 rounded-lg"></div> : null} */}

                        <div className="taro_style_upper w-[170px] sm:w-[130px] pointer-events-none ">
                            <img className="taro_style dance_button" src={`/images/cards_durer/${timer}.jpg`} alt="durer_cards" />
                        </div>

                        <p className="sm:text-sm">{t('options-cards-albrecht-durer')}</p>

                    </div>

                    <div className="br_border_line_gray" /><div className="br_border_line_gray" />

                    <p className="h-12 flex items-center">{t('options-chooseColor')}:</p>
                    <div className="flex items-center justify-center gap-2">
                        <button className="w-10 h-10 bg-blue-500 rounded-full border border-gray-400" onClick={() => { setColorTheme('blue') }}></button>
                        <button className="w-10 h-10 bg-orange-500 rounded-full border border-gray-400" onClick={() => { setColorTheme('orange') }}></button>
                        <button className="w-10 h-10 bg-gray-700 rounded-full border border-gray-400" onClick={() => { setColorTheme('dark') }}></button>
                        <button className="w-10 h-10 bg-gray-100 rounded-full border border-gray-400" onClick={() => { setColorTheme('light') }}></button>
                    </div>

                    <div className="br_border_line_gray" /><div className="br_border_line_gray" />

                    <p className="h-12 flex items-center">{t('options-support')}:</p>
                    <p className="flex items-center justify-center">propoved@mail.ru</p>

                    <p className="h-12 flex items-center sm:text-sm">{t('options-changeLanguage')} (Change Language):</p>
                    <div className="flex items-center justify-center">
                        <Suspense fallback={<img src="/images/spinner.gif" alt="spinner" id='spinner' />}>
                            <Language />
                        </Suspense>
                    </div>
                    <div className="br_border_line_gray" /><div className="br_border_line_gray" />

                    <p>{t('profile-joined')}:</p>
                    <div className="flex gap-1 items-center justify-center">
                        <CalendarSVG />
                        <p></p>{formattedDateWithoutClock(dateJoined)}
                    </div>

                    <div className="br_border_line_gray" /><div className="br_border_line_gray" />

                    <p className="h-12 flex items-center">{t('options-delete-account')}:</p>
                    <div className="flex items-center justify-center">
                        <button className="button_red h-8" onClick={() => { setDeletePopup(!deletePopup) }}>{t('options-delete-button')}</button>
                        {deletePopup ? <PopupContainer t={t} choosePopup={'delete_account_popup'} setPopupOpen={setDeletePopup} additionInfo={user} /> : null}
                    </div>

                    {/* <div className="br_border_line_gray" /><div className="br_border_line_gray" /> */}


                    {/* setColorTheme */}

                    {/* <p className="">{t('options-statistic')}:</p><div></div>

                        <p>{t('options-time-on-site')}:</p>
                        <div className="flex items-center justify-center gap-2">
                            {Math.trunc((authorizedUser.how_much_time_online / 60) / 60)}
                            <p>{t('options-h')}</p>
                            {Math.trunc(authorizedUser.how_much_time_online / 60) % 60}
                            <p>{t('options-m')}</p>
                            <p>{authorizedUser.how_much_time_online % 60}</p>
                            <p>{t('options-s')}</p>
                        </div> */}


                </div>
            </div>
        </div>
    )
}

export default OptionsPage;
