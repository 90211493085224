import { useState } from "react"
import PopupContainer from "../popups_root"
import { formattedDateWithoutClock } from "../services"
import { t } from "i18next"


export const getRealNumbers = (baseBirthNumber) => {
    baseBirthNumber = String(baseBirthNumber)
    let finalBirthNumber = 0
  
    if (baseBirthNumber < 23) {
      return Number(baseBirthNumber)
    } else {
      for (let i = 0; i < baseBirthNumber.length; i++) {
        finalBirthNumber += Number(baseBirthNumber[i])
      }
      return getRealNumbers(finalBirthNumber)
    }
}

function getInterimYears (yearStart, yearEnd) {
    const middle = getRealNumbers(yearStart + yearEnd)
    const middle_left_center = getRealNumbers(yearStart + middle)
    const middle_left_center_left = getRealNumbers(yearStart + middle_left_center)
    const middle_left_center_right = getRealNumbers(middle_left_center + middle)
  
    const middle_right_center = getRealNumbers(middle + yearEnd)
    const middle_right_center_left = getRealNumbers(middle + middle_right_center)
    const middle_right_center_right = getRealNumbers(middle_right_center + yearEnd)
    return [middle_left_center_left, middle_left_center, middle_left_center_right, middle, middle_right_center_left, middle_right_center, middle_right_center_right]
  }

const matrixCalculationFunc = (birthDateInner, secondBirthDateInner) => {

      const [birthDayBefore, birthMonthBefore, birthYearBefore] = formattedDateWithoutClock(birthDateInner).split("-");
      const [birthDayBefore2, birthMonthBefore2, birthYearBefore2] = formattedDateWithoutClock(secondBirthDateInner).split("-");

    // ОСНОВНЫЕ ЭНЕРГИИ
    const A_birthDay1 = getRealNumbers(birthDayBefore)
    const A_birthDay2 = getRealNumbers(birthDayBefore2)
    const A_birthDay = getRealNumbers(A_birthDay1 + A_birthDay2)

    // earth /\
  
    const B_birthMonth1 = getRealNumbers(birthMonthBefore)
    const B_birthMonth2 = getRealNumbers(birthMonthBefore2)
    const B_birthMonth = getRealNumbers(B_birthMonth1 + B_birthMonth2)


    // heaven /\
  
    const C_birthYear1 = getRealNumbers(birthYearBefore)
    const C_birthYear2 = getRealNumbers(birthYearBefore2)
    const C_birthYear = getRealNumbers(C_birthYear1 + C_birthYear2)

    const D_karma1 = getRealNumbers(A_birthDay1 + B_birthMonth1 + C_birthYear1)
    const D_karma2 = getRealNumbers(A_birthDay2 + B_birthMonth2 + C_birthYear2)
    const D_karma = getRealNumbers(D_karma1 + D_karma2)

    const E_comfort1 = getRealNumbers(A_birthDay1 + B_birthMonth1 + C_birthYear1 + D_karma1)
    const E_comfort2 = getRealNumbers(A_birthDay2 + B_birthMonth2 + C_birthYear2 + D_karma2)
    const E_comfort = getRealNumbers(E_comfort1 + E_comfort2)
    // middle /\
  
    const O_child_parent_1 = getRealNumbers(A_birthDay + E_comfort)
    const P_child_parent_2 = getRealNumbers(A_birthDay + O_child_parent_1)
    const Q_emotion_heart_3 = getRealNumbers(O_child_parent_1 + E_comfort)
    // A_addition /\
  
    const R_familyGift_1 = getRealNumbers(B_birthMonth + E_comfort)
    const S_godTalent_2 = getRealNumbers(B_birthMonth + R_familyGift_1)
    const T_emotion_heart_3 = getRealNumbers(R_familyGift_1 + E_comfort)
    // B_addition /\
  
    const F_father_talent1 = getRealNumbers(A_birthDay1 + B_birthMonth1)
    const F_father_talent2 = getRealNumbers(A_birthDay2 + B_birthMonth2)
    const F_father_talent = getRealNumbers(F_father_talent1 + F_father_talent2)

    const W_father_1 = getRealNumbers(O_child_parent_1 + R_familyGift_1)
    const X_father_2 = getRealNumbers(P_child_parent_2 + S_godTalent_2)
  
    const G_mother_talent1 = getRealNumbers(B_birthMonth1 + C_birthYear1)
    const G_mother_talent2 = getRealNumbers(B_birthMonth2 + C_birthYear2)
    const G_mother_talent = getRealNumbers(G_mother_talent1 + G_mother_talent2)
  
    const H_father_karma1 = getRealNumbers(C_birthYear1 + D_karma1)
    const H_father_karma2 = getRealNumbers(C_birthYear2 + D_karma2)
    const H_father_karma = getRealNumbers(H_father_karma1 + H_father_karma2)

    const I_mother_karma1 = getRealNumbers(D_karma1 + A_birthDay1)
    const I_mother_karma2 = getRealNumbers(D_karma2 + A_birthDay2)
    const I_mother_karma = getRealNumbers(I_mother_karma1 + I_mother_karma2)
    // parents /\
  
    const J_relationship = getRealNumbers(E_comfort + D_karma)
    const U_relationship_middle = getRealNumbers(J_relationship + D_karma)
  
    const K_money_block = getRealNumbers(E_comfort + C_birthYear)
    const V_money_block_middle = getRealNumbers(K_money_block + C_birthYear)
  
    const L_balance_money_rel = getRealNumbers(J_relationship + K_money_block)
    const M_ideal_partner = getRealNumbers(J_relationship + L_balance_money_rel)
    const N_implementation_scope = getRealNumbers(L_balance_money_rel + K_money_block)
    // money and relatioship /\
  
    const earthSumm = getRealNumbers(A_birthDay + C_birthYear)
    const heavenSumm = getRealNumbers(B_birthMonth + D_karma)
    const firstPurpose_20_40 = getRealNumbers(earthSumm + heavenSumm)
  
    const fatherSumm = getRealNumbers(F_father_talent + H_father_karma)
    const motherSumm = getRealNumbers(G_mother_talent + I_mother_karma)
    const secondPurpose_40_60 = getRealNumbers(fatherSumm + motherSumm)
  
    const thirdPurpose_60 = getRealNumbers(firstPurpose_20_40 + secondPurpose_40_60)
    // purposes /\
  
    const Y_mother_1 = getRealNumbers(R_familyGift_1 + K_money_block)
    const Z_mother_2 = getRealNumbers(S_godTalent_2 + V_money_block_middle)
    // mother middle  /\
  
    const AA_father_karma_1 = getRealNumbers(K_money_block + J_relationship)
    const BB_father_karma_2 = getRealNumbers(H_father_karma + AA_father_karma_1)
    const CC_mother_karma_1 = getRealNumbers(J_relationship + O_child_parent_1)
    const DD_mother_karma_2 = getRealNumbers(CC_mother_karma_1 + I_mother_karma)
    // parents_karma_addition  /\

    const familyStrength = getRealNumbers(F_father_talent + H_father_karma + G_mother_talent + I_mother_karma)
    const sexProgram = getRealNumbers(familyStrength + E_comfort)

    // parents father program
    const parents_father_programm_33 = getRealNumbers(AA_father_karma_1 + BB_father_karma_2)
    const parents_mother_programm_34 = getRealNumbers(CC_mother_karma_1 + DD_mother_karma_2)

    const parents_carma_35 = getRealNumbers(H_father_karma + I_mother_karma)

    const fourthPurpose_42 = getRealNumbers(secondPurpose_40_60 + thirdPurpose_60)


    const listOfEnergies = [
      A_birthDay, B_birthMonth, C_birthYear, D_karma, E_comfort, // 5
      F_father_talent, G_mother_talent, H_father_karma, I_mother_karma, // 9
      J_relationship, K_money_block, L_balance_money_rel, M_ideal_partner, N_implementation_scope, // 14
      O_child_parent_1, P_child_parent_2, Q_emotion_heart_3, // 17
      R_familyGift_1, S_godTalent_2, T_emotion_heart_3, // 20
      U_relationship_middle, V_money_block_middle,
       W_father_1, X_father_2, // 23
      Y_mother_1, Z_mother_2, 
      AA_father_karma_1, BB_father_karma_2, CC_mother_karma_1, DD_mother_karma_2, // 29
      firstPurpose_20_40, secondPurpose_40_60, thirdPurpose_60, parents_father_programm_33 , parents_mother_programm_34, // 34
      parents_carma_35, earthSumm, heavenSumm, fatherSumm, motherSumm, familyStrength, sexProgram, fourthPurpose_42 ] // 42

    const healthResult_1 = getRealNumbers(A_birthDay + B_birthMonth)
    const healthResult_2 = getRealNumbers(P_child_parent_2 + S_godTalent_2)
    const healthResult_3 = getRealNumbers(O_child_parent_1 + R_familyGift_1)
    const healthResult_4 = getRealNumbers(Q_emotion_heart_3 + T_emotion_heart_3)
    const healthResult_5 = getRealNumbers(E_comfort + E_comfort)
    const healthResult_6 = getRealNumbers(K_money_block + J_relationship)
    const healthResult_7 = getRealNumbers(C_birthYear + D_karma)

    const listOfHealthEnergies = [
                                  A_birthDay, B_birthMonth, healthResult_1,
                                  P_child_parent_2, S_godTalent_2, healthResult_2,
                                  O_child_parent_1, R_familyGift_1, healthResult_3,
                                  Q_emotion_heart_3, T_emotion_heart_3, healthResult_4,
                                  E_comfort, E_comfort, healthResult_5,
                                  K_money_block, J_relationship, healthResult_6,
                                  C_birthYear, D_karma, healthResult_7,

                                  getRealNumbers(A_birthDay + P_child_parent_2 + O_child_parent_1 + Q_emotion_heart_3 + E_comfort + K_money_block + C_birthYear),
                                  getRealNumbers(B_birthMonth + S_godTalent_2 + R_familyGift_1 + T_emotion_heart_3 + E_comfort + J_relationship + D_karma),
                                  getRealNumbers(healthResult_1 + healthResult_2 + healthResult_3 + healthResult_4 + healthResult_5 + healthResult_6 + healthResult_7)
                                ]

    // фиолетовый фиолетовый красный красный жёлтый рыжий голубой синий зелёный
    const descriptionEnergyColors = ['bg-[#B977E2]', 'bg-[#B977E2]', 'bg-[#FA615B]', 'bg-[#FA615B]', 'bg-[#FFFE7A]',
      'border border-black', 'border border-black', 'border border-black', 'border border-black', 'bg-[#FDD843]', 'bg-[#FDD843]', 'border border-black',
      'border border-black', 'border border-black', 'bg-[#97FDFF]', 'bg-[#98A4FD]', 'bg-[#E8FE9C]', 'bg-[#97FDFF]', 'bg-[#98A4FD]', 'bg-[#E8FE9C]',
      'border border-black', 'border border-black']
    
    const descriptionZones = [
      'zonePair_start_meetings_left_A',
      'zonePair_start_meetings_top_B',
      'zonePair_couple_tasks_rightCarma_C',
      'zonePair_couple_tasks_bottomCarma_D',
      'zonePair_comfort_zone_center_E',
      'zonePair_father_top_tasks',
      'zonePair_mother_top_tasks',
      'zonePair_father_bottom_tasks',
      'zonePair_mother_bottom_tasks',
      'zonePair_couple_tasks_bottomCarma_D_little',
      'zonePair_couple_tasks_rightCarma_C_little',
      'zonePair_couple_balance',
      'zonePair_couple_love',
      'zonePair_couple_money',
      'zonePair_start_meetings_left_A_little',
      'zonePair_start_meetings_left_A_middle',
      'zone_emotionalBottom',
      'zonePair_start_meetings_top_B_middle',
      'zonePair_start_meetings_top_B_little',
      'zone_emotionalTop',
      'zonePair_couple_tasks_bottomCarma_D_middle',
      'zonePair_couple_tasks_rightCarma_C_middle'
    ]
    return [ listOfEnergies, descriptionEnergyColors, descriptionZones, listOfHealthEnergies ]
  }


  const listOfYearsFunc = (energyList) => {
      // eslint-disable-next-line
      const [A_birthDay, B_birthMonth, C_birthYear, D_karma, E_comfort, F_father_talent, G_mother_talent, H_father_karma, I_mother_karma] = energyList

        // ЭНЕРГИЯ ГОДОВ
        const year_0 = A_birthDay
  
        const year_10 = F_father_talent
        const [year_1__2_5, year_2_5__3_5, year_3_5__4, year_5, year_6__7_5, year_7_5__8_5, year_8_5__9] = getInterimYears(year_0, year_10)
        // 0 до 10 /\
      
        const year_20 = B_birthMonth
        const [year_11__12_5, year_12_5__13_5, year_13_5__14, year_15, year_16__17_5, year_17_5__18_5, year_18_5__19] = getInterimYears(year_10, year_20)
        // 10 до 20 /\
      
        const year_30 = G_mother_talent
        const [year_21__22_5, year_22_5__23_5, year_23_5__24, year_25, year_26__27_5, year_27_5__28_5, year_28_5__29] = getInterimYears(year_20, year_30)
        // 20 до 30 /\
      
        const year_40 = C_birthYear
        const [year_31__32_5, year_32_5__33_5, year_33_5__34, year_35, year_36__37_5, year_37_5__38_5, year_38_5__39] = getInterimYears(year_30, year_40)
        // 30 до 40 /\
      
        const year_50 = H_father_karma
        const [year_41__42_5, year_42_5__43_5, year_43_5__44, year_45, year_46__47_5, year_47_5__48_5, year_48_5__49] = getInterimYears(year_40, year_50)
        // 40 до 50 /\
      
        const year_60 = D_karma
        const [year_51__52_5, year_52_5__53_5, year_53_5__54, year_55, year_56__57_5, year_57_5__58_5, year_58_5__59] = getInterimYears(year_50, year_60)
        // 50 до 60 /\
      
        const year_70 = I_mother_karma
        const [year_61__62_5, year_62_5__63_5, year_63_5__64, year_65, year_66__67_5, year_67_5__68_5, year_68_5__69] = getInterimYears(year_60, year_70)
        // 60 до 70 /\
      
        const [year_71__72_5, year_72_5__73_5, year_73_5__74, year_75, year_76__77_5, year_77_5__78_5, year_78_5__79] = getInterimYears(year_70, year_0)
        // 70 до 80 /\
      
        const listOfYears = [year_0, year_1__2_5, year_2_5__3_5, year_3_5__4, year_5, year_6__7_5, year_7_5__8_5, year_8_5__9,
          year_10, year_11__12_5, year_12_5__13_5, year_13_5__14, year_15, year_16__17_5, year_17_5__18_5, year_18_5__19, year_20,
          year_21__22_5, year_22_5__23_5, year_23_5__24, year_25, year_26__27_5, year_27_5__28_5, year_28_5__29, year_30,
          year_31__32_5, year_32_5__33_5, year_33_5__34, year_35, year_36__37_5, year_37_5__38_5, year_38_5__39, year_40,
          year_41__42_5, year_42_5__43_5, year_43_5__44, year_45, year_46__47_5, year_47_5__48_5, year_48_5__49, year_50,
          year_51__52_5, year_52_5__53_5, year_53_5__54, year_55, year_56__57_5, year_57_5__58_5, year_58_5__59, year_60,
          year_61__62_5, year_62_5__63_5, year_63_5__64, year_65, year_66__67_5, year_67_5__68_5, year_68_5__69, year_70,
          year_71__72_5, year_72_5__73_5, year_73_5__74, year_75, year_76__77_5, year_77_5__78_5, year_78_5__79]

          return listOfYears;
  }


  const CreateMatrixVisualComponentFunc = (t, energies, years, gender) => {

    // const [purposesPopupOpen, setPurposesPopupOpen] = useState(false);

    const [openEnergyPopup, setOpenEnergyPopup] = useState({
      A: false,
      B: false,
      C: false,
      D: false,
      E: false,
      F_10: false,
      F_30: false,
      F_50: false,
      F_70: false,
      J: false,
      K: false,
      L: false,
      M: false,
      N: false,
      O: false,
      P: false,
      Q: false,
      R: false,
      S: false,
      T: false,
      U: false,
      V: false,
      W: false,
      X: false,
      Y: false,
      Z: false,
      AA: false,
      BB: false,
      CC: false,
      DD: false,
      firstPurpose: false,
      secondPurpose: false,
      thirdPurpose: false,
    })

    return (
      <div className="absolute">
        {/* дополнения сверху снизу (предназначения) */}
        <div className="relative">

            {/* вертикальная палка для дуги */}
            <div className="absolute w-[1px] h-[20px] border-gray-200 top-9 left-[199px] border-[1px] border-dashed"></div>
            {/* горизонтальная палка для дуги */}
            <div className="absolute w-[20px] h-[1px] border-gray-200 top-[199px] left-9 border-[1px] border-dashed"></div>
            {/* <div className="absolute w-[1px] h-[150px] bg-gray-200 left-[37px] top-[55px] rotate-[11deg]"></div> */}
            
            {/* ДУГА Первого предназначения */}
            <div className="absolute w-[180px] h-[180px] border-gray-200 border-t-[1px] border-l-[1px] rounded-tl-[100px] left-[25px] top-[25px] rotate-[0deg]"></div>

            {/* ДУГА Третьего предназначения */}
            <div className="absolute w-[285px] h-[285px] border-gray-200 border-t-[1px] border-l-[1px] rounded-tl-[250px] rounded-tr-[20px] rounded-bl-[20px] -left-[25px] top-[95px] rotate-[-68deg] border-dashed"></div>


            {/* Вертикаль */}
            <div id="heavenSumm" data-tooltip={t('zonePair_first_earthHeaven_purpose')} className="title-style title_style_addon !border-gray-300 select-none mt-[20px] ml-[190px] tag_middle_energy tag_border_energy text-black"
                // onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, DD: !prevState.DD}))}
                >{energies[37]}</div>

            {/* горизонталь */}
            <div id="earthSumm" data-tooltip={t('zonePair_first_earthHeaven_purpose')} className="title-style title_style_addon !border-gray-300 select-none mt-[190px] ml-[20px] tag_middle_energy tag_border_energy text-black"
                // onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, DD: !prevState.DD}))}
                >{energies[36]}</div>

            {/* первое предназначение */}
            <div id="firstPurpose" data-tooltip={t('zonePair_first_earthHeaven_purpose')} className="title-style title_style_addon button_matrix tag_big_energy mt-[38px] ml-[38px] tag_big_energy tag_border_energy !bg-gray-200 text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, firstPurpose: !prevState.firstPurpose}) )}
                >{energies[30]}</div>
                  
            {openEnergyPopup.firstPurpose ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, firstPurpose: false}) ) : null} additionInfo={[ 'zonePair_first_earthHeaven_purpose', energies[30], gender ]} /> : null}


            {/* поперечные палки соединяющие дугу */}
            <div className="absolute w-[1px] h-[20px] border-gray-200 top-[300px] left-[89px] border-[1px] border-dashed rotate-[45deg]"></div>
            <div className="absolute w-[1px] h-[20px] border-gray-200 top-[300px] left-[310px] border-[1px] border-dashed rotate-[-45deg]"></div>
            {/* <div className="absolute w-[1px] h-[150px] bg-gray-200 left-[37px] top-[55px] rotate-[11deg]"></div> */}
            
            {/* ДУГА Второго предназначения */}
            <div className="absolute w-[180px] h-[180px] border-gray-200 border-b-[1px] border-l-[1px] rounded-bl-[100px] left-[110px] top-[235px] rotate-[-43deg]"></div>
            
            {/* ДУГА четвёртого предназначения */}
            {/* <div className="absolute w-[160px] h-[160px] border-dotted border-gray-200 border-b-[1px] border-l-[1px] rounded-bl-[100px] left-[30px] top-[270px] rotate-[-5deg]"></div> */}

            <div id="fatherSumm" data-tooltip={t('zonePair_second_parents_purpose')} className="title-style title_style_addon !border-gray-300 select-none mt-[315px] ml-[315px] tag_middle_energy tag_border_energy text-black"
                // onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, DD: !prevState.DD}))}
                >{energies[38]}</div>

            <div id="motherSumm" data-tooltip={t('zonePair_second_parents_purpose')} className="title-style title_style_addon !border-gray-300 select-none mt-[310px] ml-[70px] tag_middle_energy tag_border_energy text-black"
                // onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, DD: !prevState.DD}))}
                >{energies[39]}</div>        

            {/* второе предназначение */}
            <div id="secondPurpose" data-tooltip={t('zonePair_second_parents_purpose')} className="title-style title_style_addon button_matrix tag_big_energy mt-[395px] ml-[185px] tag_big_energy tag_border_energy !bg-gray-300 text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, secondPurpose: !prevState.secondPurpose}) )}
                >{energies[31]}</div>
            {openEnergyPopup.secondPurpose ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, secondPurpose: false}) ) : null} additionInfo={[ 'zonePair_second_parents_purpose', energies[31], gender ]} /> : null}


            {/* третье предназначение */}
            <div id="zonePair_third_purpose" data-tooltip={t('matrix-purposes-third-title')} className="title-style title_style_addon button_matrix tag_big_energy mt-[265px] ml-[15px] tag_big_energy tag_border_energy !bg-gray-400 text-white"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, thirdPurpose: !prevState.thirdPurpose}) )}
                >{energies[32]}</div>

            {openEnergyPopup.thirdPurpose ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, thirdPurpose: false}) ) : null} additionInfo={[ 'zonePair_third_purpose', energies[32], gender ]} /> : null}

        </div>
        

        <div className="absolute top-[50px] left-[50px]">

            <div className="relative">

              <div className="relative">
                {/* круг */}
                <div className="absolute w-[270px] h-[270px] top-[15px] left-[15px] border border-gray-300 rounded-full"></div>

                {/* центральные палки */}
                <div className="absolute w-[1px] h-[300px] bg-gray-200 left-[149px]"></div>
                <div className="absolute w-[300px] h-[1px] bg-gray-200 top-[149px]"></div>

                {/* анимационные палки */}
                <div className="absolute w-[1px] bg-blue-300 left-[149px] matrix_visual_animation_vertical_stick"></div>
                <div className="absolute h-[1px] bg-blue-300 top-[149px] matrix_visual_animation_horisontal_stick"></div>

                {/* родовые палки */}
                <div className="absolute w-[1px] h-[200px] bg-gray-200 left-[55px] top-[55px]"></div>
                <div className="absolute w-[200px] h-[1px] bg-gray-200 left-[55px] top-[55px]"></div>
                <div className="absolute w-[1px] h-[200px] bg-gray-200 left-[245px] top-[55px]"></div>
                <div className="absolute w-[200px] h-[1px] bg-gray-200 left-[55px] top-[245px]"></div>

              </div>

              {/* ромб */}
              <div className="relative left-[150px] -top-[63px] rotate-[45deg]">
                {/* сам ромб */}
                <div className="absolute w-[1px] h-[190px] bg-gray-200 left-[55px] top-[55px]"></div>
                <div className="absolute w-[190px] h-[1px] bg-gray-200 left-[55px] top-[55px]"></div>
                <div className="absolute w-[1px] h-[190px] bg-gray-200 left-[245px] top-[55px]"></div>
                <div className="absolute w-[190px] h-[1px] bg-gray-200 left-[55px] top-[245px]"></div>

                {/* палки */}
                <div className="absolute w-[1px] h-[300px] left-[149px] border-[1px] border-dashed border-gray-200"></div>
                <div className="absolute w-[300px] h-[1px] top-[149px] border-[1px] border-dashed border-gray-200"></div>

                <div className="absolute w-[1px] h-[140px] bg-gray-200 left-[217px] top-[85px]"></div>
              </div>
            </div>
            

            {/* точки */}
            <div className="">

              <div id="dots_1" className="dots_color_style matrix_dots mt-[126px] ml-[15px]"></div>
              <div id="dots_2.5" className="dots_color_style matrix_dots mt-[115px] ml-[17px]"></div>
              <div id="dots_3.5" className="dots_color_style matrix_dots mt-[106px] ml-[20px]"></div>
              <div id="dots_5" className="dots_color_style matrix_dots mt-[97px] ml-[23px]"></div>
              <div id="dots_6" className="dots_color_style matrix_dots mt-[88px] ml-[27px]"></div>
              <div id="dots_7.5" className="dots_color_style matrix_dots mt-[79px] ml-[32px]"></div>
              <div id="dots_8.5" className="dots_color_style matrix_dots mt-[70px] ml-[38px]"></div>

              <div id="dots_11" className="dots_color_style matrix_dots mt-[40px] ml-[68px]"></div>
              <div id="dots_12.5" className="dots_color_style matrix_dots mt-[34px] ml-[77px]"></div>
              <div id="dots_13.5" className="dots_color_style matrix_dots mt-[29px] ml-[85px]"></div>
              <div id="dots_15" className="dots_color_style matrix_dots mt-[25px] ml-[94px]"></div>
              <div id="dots_16" className="dots_color_style matrix_dots mt-[21px] ml-[104px]"></div>
              <div id="dots_17.5" className="dots_color_style matrix_dots mt-[18px] ml-[114px]"></div>
              <div id="dots_18.5" className="dots_color_style matrix_dots mt-[15px] ml-[126px]"></div>

              <div id="dots_21" className="dots_color_style matrix_dots mt-[15px] ml-[168px]"></div>
              <div id="dots_22.5" className="dots_color_style matrix_dots mt-[17px] ml-[178px]"></div>
              <div id="dots_23.5" className="dots_color_style matrix_dots mt-[20px] ml-[188px]"></div>
              <div id="dots_25" className="dots_color_style matrix_dots mt-[24px] ml-[198px]"></div>
              <div id="dots_26" className="dots_color_style matrix_dots mt-[28px] ml-[208px]"></div>
              <div id="dots_27.5" className="dots_color_style matrix_dots mt-[33px] ml-[218px]"></div>
              <div id="dots_28.5" className="dots_color_style matrix_dots mt-[40px] ml-[228px]"></div>

              <div id="dots_31" className="dots_color_style matrix_dots mt-[70px] ml-[258px]"></div>
              <div id="dots_32.5" className="dots_color_style matrix_dots mt-[79px] ml-[264px]"></div>
              <div id="dots_33.5" className="dots_color_style matrix_dots mt-[88px] ml-[269px]"></div>
              <div id="dots_35" className="dots_color_style matrix_dots mt-[97px] ml-[273px]"></div>
              <div id="dots_36" className="dots_color_style matrix_dots mt-[106px] ml-[276px]"></div>
              <div id="dots_37.5" className="dots_color_style matrix_dots mt-[115px] ml-[278px]"></div>
              <div id="dots_38.5" className="dots_color_style matrix_dots mt-[126px] ml-[281px]"></div>

              <div id="dots_41" className="dots_color_style matrix_dots mt-[168px] ml-[281px]"></div>
              <div id="dots_42.5" className="dots_color_style matrix_dots mt-[178px] ml-[278px]"></div>
              <div id="dots_43.5" className="dots_color_style matrix_dots mt-[188px] ml-[276px]"></div>
              <div id="dots_45" className="dots_color_style matrix_dots mt-[198px] ml-[273px]"></div>
              <div id="dots_46" className="dots_color_style matrix_dots mt-[208px] ml-[269px]"></div>
              <div id="dots_47.5" className="dots_color_style matrix_dots mt-[218px] ml-[264px]"></div>
              <div id="dots_48.5" className="dots_color_style matrix_dots mt-[228px] ml-[257px]"></div>

              <div id="dots_51" className="dots_color_style matrix_dots mt-[257px] ml-[227px]"></div>
              <div id="dots_52.5" className="dots_color_style matrix_dots mt-[263px] ml-[219px]"></div>
              <div id="dots_53.5" className="dots_color_style matrix_dots mt-[267px] ml-[210px]"></div>
              <div id="dots_55" className="dots_color_style matrix_dots mt-[272px] ml-[200px]"></div>
              <div id="dots_56" className="dots_color_style matrix_dots mt-[276px] ml-[190px]"></div>
              <div id="dots_57.5" className="dots_color_style matrix_dots mt-[279px] ml-[180px]"></div>
              <div id="dots_58.5" className="dots_color_style matrix_dots mt-[281px] ml-[170px]"></div>

              <div id="dots_61" className="dots_color_style matrix_dots mt-[280px] ml-[124px]"></div>
              <div id="dots_62.5" className="dots_color_style matrix_dots mt-[278px] ml-[114px]"></div>
              <div id="dots_63.5" className="dots_color_style matrix_dots mt-[275px] ml-[104px]"></div>
              <div id="dots_65" className="dots_color_style matrix_dots mt-[271px] ml-[94px]"></div>
              <div id="dots_66" className="dots_color_style matrix_dots mt-[266px] ml-[84px]"></div>
              <div id="dots_67.5" className="dots_color_style matrix_dots mt-[261px] ml-[75px]"></div>
              <div id="dots_68.5" className="dots_color_style matrix_dots mt-[255px] ml-[67px]"></div>

              <div id="dots_71" className="dots_color_style matrix_dots mt-[228px] ml-[38px]"></div>
              <div id="dots_72.5" className="dots_color_style matrix_dots mt-[218px] ml-[32px]"></div>
              <div id="dots_73.5" className="dots_color_style matrix_dots mt-[208px] ml-[27px]"></div>
              <div id="dots_75" className="dots_color_style matrix_dots mt-[198px] ml-[23px]"></div>
              <div id="dots_76" className="dots_color_style matrix_dots mt-[188px] ml-[20px]"></div>
              <div id="dots_77.5" className="dots_color_style matrix_dots mt-[178px] ml-[17px]"></div>
              <div id="dots_78.5" className="dots_color_style matrix_dots mt-[168px] ml-[15px]"></div>
            </div>

            <div>

              {/* года */}
              <p id="1" data-energy={years[1]} className="energy_visual_matrix mt-[120px] ml-[0px]">{years[1]}</p>
              <p id="2.5" data-energy={years[2]} className="energy_visual_matrix mt-[110px] ml-[2px]">{years[2]}</p>
              <p id="3.5" data-energy={years[3]} className="energy_visual_matrix mt-[100px] ml-[5px]">{years[3]}</p>
              <p id="5" data-energy={years[4]} className="energy_visual_matrix mt-[90px] ml-[9px]">{years[4]}</p>
              <p id="6" data-energy={years[5]} className="energy_visual_matrix mt-[80px] ml-[14px]">{years[5]}</p>
              <p id="7.5" data-energy={years[6]} className="energy_visual_matrix mt-[70px] ml-[19px]">{years[6]}</p>
              <p id="8.5" data-energy={years[7]} className="energy_visual_matrix mt-[60px] ml-[25px]">{years[7]}</p>

              <p id="11" data-energy={years[9]} className="energy_visual_matrix mt-[28px] ml-[62px]">{years[9]}</p>
              <p id="12.5" data-energy={years[10]} className="energy_visual_matrix mt-[20px] ml-[72px]">{years[10]}</p>
              <p id="13.5" data-energy={years[11]} className="energy_visual_matrix mt-[14px] ml-[82px]">{years[11]}</p>
              <p id="15" data-energy={years[12]} className="energy_visual_matrix mt-[9px] ml-[92px]">{years[12]}</p>
              <p id="16" data-energy={years[13]} className="energy_visual_matrix mt-[6px] ml-[102px]">{years[13]}</p>
              <p id="17.5" data-energy={years[14]} className="energy_visual_matrix mt-[3px] ml-[112px]">{years[14]}</p>
              <p id="18.5" data-energy={years[15]} className="energy_visual_matrix mt-[1px] ml-[122px]">{years[15]}</p>

              <p id="21" data-energy={years[17]} className="energy_visual_matrix mt-[1px] ml-[170px]">{years[17]}</p>
              <p id="22.5" data-energy={years[18]} className="energy_visual_matrix mt-[3px] ml-[180px]">{years[18]}</p>
              <p id="23.5" data-energy={years[19]} className="energy_visual_matrix mt-[6px] ml-[190px]">{years[19]}</p>
              <p id="25" data-energy={years[20]} className="energy_visual_matrix mt-[9px] ml-[200px]">{years[20]}</p>
              <p id="26" data-energy={years[21]} className="energy_visual_matrix mt-[15px] ml-[210px]">{years[21]}</p>
              <p id="27.5" data-energy={years[22]} className="energy_visual_matrix mt-[20px] ml-[220px]">{years[22]}</p>
              <p id="28.5" data-energy={years[23]} className="energy_visual_matrix mt-[27px] ml-[230px]">{years[23]}</p>

              <p id="31" data-energy={years[25]} className="energy_visual_matrix mt-[60px] ml-[267px]">{years[25]}</p>
              <p id="32.5" data-energy={years[26]} className="energy_visual_matrix mt-[70px] ml-[275px]">{years[26]}</p>
              <p id="33.5" data-energy={years[27]} className="energy_visual_matrix mt-[80px] ml-[279px]">{years[27]}</p>
              <p id="35" data-energy={years[28]} className="energy_visual_matrix mt-[90px] ml-[284px]">{years[28]}</p>
              <p id="36" data-energy={years[29]} className="energy_visual_matrix mt-[100px] ml-[287px]">{years[29]}</p>
              <p id="37.5" data-energy={years[30]} className="energy_visual_matrix mt-[110px] ml-[290px]">{years[30]}</p>
              <p id="38.5" data-energy={years[31]} className="energy_visual_matrix mt-[120px] ml-[290px]">{years[31]}</p>

              <p id="41" data-energy={years[33]} className="energy_visual_matrix mt-[168px] ml-[290px]">{years[33]}</p>
              <p id="42.5" data-energy={years[34]} className="energy_visual_matrix mt-[178px] ml-[290px]">{years[34]}</p>
              <p id="43.5" data-energy={years[35]} className="energy_visual_matrix mt-[188px] ml-[287px]">{years[35]}</p>
              <p id="45" data-energy={years[36]} className="energy_visual_matrix mt-[198px] ml-[284px]">{years[36]}</p>
              <p id="46" data-energy={years[37]} className="energy_visual_matrix mt-[208px] ml-[279px]">{years[37]}</p>
              <p id="47.5" data-energy={years[38]} className="energy_visual_matrix mt-[218px] ml-[275px]">{years[38]}</p>
              <p id="48.5" data-energy={years[39]} className="energy_visual_matrix mt-[228px] ml-[267px]">{years[39]}</p>

              <p id="51" data-energy={years[41]} className="energy_visual_matrix mt-[266px] ml-[230px]">{years[41]}</p>
              <p id="52.5" data-energy={years[42]} className="energy_visual_matrix mt-[273px] ml-[220px]">{years[42]}</p>
              <p id="53.5" data-energy={years[43]} className="energy_visual_matrix mt-[279px] ml-[210px]">{years[43]}</p>
              <p id="55" data-energy={years[44]} className="energy_visual_matrix mt-[283px] ml-[200px]">{years[44]}</p>
              <p id="56" data-energy={years[45]} className="energy_visual_matrix mt-[286px] ml-[190px]">{years[45]}</p>
              <p id="57.5" data-energy={years[46]} className="energy_visual_matrix mt-[289px] ml-[180px]">{years[46]}</p>
              <p id="58.5" data-energy={years[47]} className="energy_visual_matrix mt-[291px] ml-[170px]">{years[47]}</p>

              <p id="61" data-energy={years[49]} className="energy_visual_matrix mt-[291px] ml-[122px]">{years[49]}</p>
              <p id="62.5" data-energy={years[50]} className="energy_visual_matrix mt-[289px] ml-[112px]">{years[50]}</p>
              <p id="63.5" data-energy={years[51]} className="energy_visual_matrix mt-[286px] ml-[102px]">{years[51]}</p>
              <p id="65" data-energy={years[52]} className="energy_visual_matrix mt-[283px] ml-[92px]">{years[52]}</p>
              <p id="66" data-energy={years[53]} className="energy_visual_matrix mt-[279px] ml-[82px]">{years[53]}</p>
              <p id="67.5" data-energy={years[54]} className="energy_visual_matrix mt-[273px] ml-[72px]">{years[54]}</p>
              <p id="68.5" data-energy={years[55]} className="energy_visual_matrix mt-[266px] ml-[62px]">{years[55]}</p>

              <p id="71" data-energy={years[57]} className="energy_visual_matrix mt-[228px] ml-[25px]">{years[57]}</p>
              <p id="72.5" data-energy={years[58]} className="energy_visual_matrix mt-[218px] ml-[19px]">{years[58]}</p>
              <p id="73.5" data-energy={years[59]} className="energy_visual_matrix mt-[208px] ml-[14px]">{years[59]}</p>
              <p id="75" data-energy={years[60]} className="energy_visual_matrix mt-[198px] ml-[9px]">{years[60]}</p>
              <p id="76" data-energy={years[61]} className="energy_visual_matrix mt-[188px] ml-[5px]">{years[61]}</p>
              <p id="77.5" data-energy={years[62]} className="energy_visual_matrix mt-[178px] ml-[2px]">{years[62]}</p>
              <p id="78.5" data-energy={years[63]} className="energy_visual_matrix mt-[168px] ml-[0px]">{years[63]}</p>
            </div>


            {/* <!-- 28 - 14 размер блока --> */}

            <div>
                {/* Портрет / Ресурс */}
                <div id="0" data-tooltip={t('zonePair_start_meetings_left_A')} 
                className="title-style title_style_addon button_matrix tag_big_energy mt-[136px] text-white tag_big_energy bg-[#B977E2]"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, A: !prevState.A}) )}
                >{energies[0]}</div>

                {openEnergyPopup.A ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, A: false}) ) : null} additionInfo={[ 'zonePair_start_meetings_left_A', energies[0], gender ]} /> : null}


                {/* Талант Свыше / Вдохновенье */}
                <div id="20" data-tooltip={t('zonePair_start_meetings_top_B')} className="title-style title_style_addon button_matrix tag_big_energy border-gray-300  text-white bg-[#B977E2] ml-[136px]"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, B: !prevState.B}) )}
                >{energies[1]}</div>
              
                {openEnergyPopup.B ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, B: false}) ) : null} additionInfo={[ 'zonePair_start_meetings_top_B', energies[1], gender ]} /> : null}


                {/* Задача Души / Карма материального */}
                <div id="40" data-tooltip={t('zonePair_couple_tasks_rightCarma_C')} className="title-style title_style_addon button_matrix tag_big_energy border-gray-300  mt-[136px] ml-[272px] text-white tag_big_energy bg-[#F84842]"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, C: !prevState.C}) )}
                >{energies[2]}</div>
                
                {openEnergyPopup.C ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, C: false}) ) : null} additionInfo={[ 'zonePair_couple_tasks_rightCarma_C', energies[2], gender ]} /> : null}

                {/* Кармический хвост */}
                <div id="60" data-tooltip={t('zonePair_couple_tasks_bottomCarma_D')} className="title-style title_style_addon button_matrix tag_big_energy border-gray-300  mt-[272px] ml-[136px] text-white tag_big_energy bg-[#F84842]"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, D: !prevState.D}) )}
                >{energies[3]}</div>
                
                {openEnergyPopup.D ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, D: false}) ) : null} additionInfo={[ 'zonePair_couple_tasks_bottomCarma_D', energies[3], gender ]} /> : null}

                
                {/* Зона комфорта души */}
                <div id="E" data-tooltip={t('zonePair_comfort_zone_center_E')} className="title-style title_style_addon button_matrix tag_big_energy border-gray-300  mt-[136px] ml-[136px] tag_big_energy bg-[#FFFE7A] text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, E: !prevState.E}) )}
                >{energies[4]}</div>

                {openEnergyPopup.E ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, E: false}) ) : null} additionInfo={[ 'zonePair_comfort_zone_center_E', energies[4], gender ]} /> : null}

                {/* Верхняя слева по мужской */}
                <div id="10" data-tooltip={t('zonePair_father_top_tasks')} className="title-style title_style_addon button_matrix tag_big_energy mt-[38px] ml-[38px] tag_big_energy tag_border_energy text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, F_10: !prevState.F_10}) )}
                >{energies[5]}</div>
                {openEnergyPopup.F_10 ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, F_10: false}) ) : null} additionInfo={[ 'zonePair_father_top_tasks', energies[5], gender ]} /> : null}

                {/* Верхняя справа по Женской */}
                <div id="30" data-tooltip={t('zonePair_mother_top_tasks')}  className="title-style title_style_addon button_matrix tag_big_energy mt-[38px] ml-[232px] tag_big_energy tag_border_energy text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, F_30: !prevState.F_30}) )}
                >{energies[6]}</div>
                {openEnergyPopup.F_30 ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, F_30: false}) ) : null} additionInfo={[ 'zonePair_mother_top_tasks', energies[6], gender ]} /> : null}

                {/* Нижняя справа по мужской */}
                <div id="50" data-tooltip={t('zonePair_father_bottom_tasks')} className="title-style title_style_addon button_matrix tag_big_energy mt-[232px] ml-[232px] tag_big_energy tag_border_energy text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, F_50: !prevState.F_50}) )}
                >{energies[7]}</div>
                {openEnergyPopup.F_50 ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, F_50: false}) ) : null} additionInfo={[ 'zonePair_father_bottom_tasks', energies[7], gender ]} /> : null}

                {/* Нижняя слева по женской */}
                <div id="70" data-tooltip={t('zonePair_mother_bottom_tasks')} className="title-style title_style_addon button_matrix tag_big_energy mt-[232px] ml-[38px] tag_big_energy tag_border_energy text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, F_70: !prevState.F_70}) )}
                >{energies[8]}</div>
                {openEnergyPopup.F_70 ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, F_70: false}) ) : null} additionInfo={[ 'zonePair_mother_bottom_tasks', energies[8], gender ]} /> : null}

                {/* Мелкая энергия кармического хвоста */}
                <div id="J" data-tooltip={t('zonePair_couple_tasks_bottomCarma_D_little')} className="title-style title_style_addon button_matrix_little tag_big_energy mt-[236px] ml-[142px] tag_little_energy bg-[#FDD843] text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, J: !prevState.J}) )}
                >{energies[9]}</div>
                {openEnergyPopup.J ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, J: false}) ) : null} additionInfo={[ 'zonePair_couple_tasks_bottomCarma_D_little', energies[9], gender ]} /> : null}

                {/* Вход в денежный канал рыжая */}
                <div id="K" data-tooltip={t('zonePair_couple_tasks_rightCarma_C_little')} className="title-style title_style_addon button_matrix_little mt-[142px] ml-[236px] tag_little_energy bg-[#FDD843] text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, K: !prevState.K}) )}
                >{energies[10]}</div>
                {openEnergyPopup.K ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, K: false}) ) : null} additionInfo={[ 'zonePair_couple_tasks_rightCarma_C_little', energies[10], gender ]} /> : null}

                <div id="L" data-tooltip={t('zonePair_couple_balance')} className="title-style title_style_addon button_matrix_little mt-[188px] ml-[188px] tag_little_energy tag_border_energy text-[8px] text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, L: !prevState.L}) )}
                >{energies[11]}</div>
                {openEnergyPopup.L ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, L: false}) ) : null} additionInfo={[ 'zonePair_couple_balance', energies[11], gender ]} /> : null}

                <div id="M" data-tooltip={t('zonePair_couple_love')} className="title-style title_style_addon button_matrix_little mt-[210px] ml-[166px] tag_little_energy tag_border_energy text-[8px] text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, M: !prevState.M}) )}
                >{energies[12]}</div>
                {openEnergyPopup.M ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, M: false}) ) : null} additionInfo={[ 'zonePair_couple_love', energies[12], gender ]} /> : null}

                <div id="N" data-tooltip={t('zonePair_couple_money')} className="title-style title_style_addon button_matrix_little mt-[164px] ml-[212px] tag_little_energy tag_border_energy text-[8px] text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, N: !prevState.N}) )}
                >{energies[13]}</div>
                {openEnergyPopup.N ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, N: false}) ) : null} additionInfo={[ 'zonePair_couple_money', energies[13], gender ]} /> : null}

                <div id="O" data-tooltip={t('zonePair_start_meetings_left_A_little')} className="title-style title_style_addon button_matrix_little mt-[142px] ml-[48px] tag_little_energy bg-[#98FCFF] text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, O: !prevState.O}) )}
                >{energies[14]}</div>
                {openEnergyPopup.O ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, O: false}) ) : null} additionInfo={[ 'zonePair_start_meetings_left_A_little', energies[14], gender ]} /> : null}

                <div id="P" data-tooltip={t('zonePair_start_meetings_left_A_middle')} className="title-style title_style_addon button_matrix_little mt-[140px] ml-[28px] tag_middle_energy bg-[#98A4FD]"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, P: !prevState.P}) )}
                >{energies[15]}</div>
                {openEnergyPopup.P ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, P: false}) ) : null} additionInfo={[ 'zonePair_start_meetings_left_A_middle', energies[15], gender ]} /> : null}

                {/* <div id="Q" data-tooltip={t('zone_emotionalBottom')} className="title-style title_style_addon button_matrix_little mt-[142px] ml-[90px] tag_little_energy bg-[#E8FE9C]"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, Q: !prevState.Q}) )}
                >{energies[16]}</div>
                {openEnergyPopup.Q ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, Q: false}) ) : null} additionInfo={[ 'zone_emotionalBottom', energies[16], gender ]} /> : null} */}

                <div id="R" data-tooltip={t('zonePair_start_meetings_top_B_little')} className="title-style title_style_addon button_matrix_little mt-[48px] ml-[142px] tag_little_energy bg-[#98FCFF] text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, R: !prevState.R}) )}
                >{energies[17]}</div>
                {openEnergyPopup.R ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, R: false}) ) : null} additionInfo={[ 'zonePair_start_meetings_top_B_little', energies[17], gender ]} /> : null}

                <div id="S" data-tooltip={t('zonePair_start_meetings_top_B_middle')} className="title-style title_style_addon button_matrix_little mt-[28px] ml-[140px] tag_middle_energy bg-[#98A4FD]"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, S: !prevState.S}) )}
                >{energies[18]}</div>
                {openEnergyPopup.S ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, S: false}) ) : null} additionInfo={[ 'zonePair_start_meetings_top_B_middle', energies[18], gender ]} /> : null}

                {/* <div id="T" data-tooltip={t('zone_emotionalTop')} className="title-style title_style_addon button_matrix_little mt-[90px] ml-[142px] tag_little_energy bg-[#E8FE9C]"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, T: !prevState.T}) )}
                >{energies[19]}</div>
                {openEnergyPopup.T ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, T: false}) ) : null} additionInfo={[ 'zone_emotionalTop', energies[19], gender ]} /> : null} */}

                {/* Средняя энергия кармического хвоста */}
                <div id="U" data-tooltip={t('zonePair_couple_tasks_bottomCarma_D_middle')} className="title-style title_style_addon button_matrix_little mt-[252px] ml-[140px] tag_middle_energy tag_border_energy text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, U: !prevState.U}) )}
                >{energies[20]}</div>
                {openEnergyPopup.U ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, U: false}) ) : null} additionInfo={[ 'zonePair_couple_tasks_bottomCarma_D_middle', energies[20], gender ]} /> : null}

                <div id="V" data-tooltip={t('zonePair_couple_tasks_rightCarma_C_middle')} className="title-style title_style_addon button_matrix_little mt-[140px] ml-[252px] tag_middle_energy tag_border_energy text-black"
                onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, V: !prevState.V}) )}
                >{energies[21]}</div>
                {openEnergyPopup.V ? <PopupContainer t={t} choosePopup={'pair_energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({...prevState, V: false}) ) : null} additionInfo={[ 'zonePair_couple_tasks_rightCarma_C_middle', energies[21], gender ]} /> : null}
                
            </div>
            
        </div>

        {/* SVG задняя */}
        <div className="pt-[50px] pl-[51px]">
           <img className="touch_screen_non_selectable pointer-events-none w-[300px] h-[300px] select-none" src="images/background_matrix_years.svg" alt="matrixImage" /> 
        </div>

      </div>
      
    )
}

const MatrixVisualPair = ({ birthDateInner, genderInner, secondBirthDate}) => {
                          
    // eslint-disable-next-line
    let listOfEnergies, listOfYears;

    const listOfData1 = matrixCalculationFunc(birthDateInner, secondBirthDate)
    listOfEnergies = listOfData1[0]

    listOfYears = listOfYearsFunc(listOfEnergies)
    
    return (
        <div className="overflow-hidden relative touch_screen_non_selectable flex items-center justify-center">
            <div id="matrix_visual" className="w-[390px] h-[430px]">
                {CreateMatrixVisualComponentFunc(t, listOfEnergies, listOfYears, genderInner)}
            </div>
        </div>
    )
}

export default MatrixVisualPair;