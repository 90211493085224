import axios from "axios";
import { useState, useEffect } from "react";
import { baseBackendUrl } from "../websocket_comp";
import PopupContainer from "../popups_root";
import ExitCrossSVG from "../../../svg/exitCross_svg";
import Resizer from 'react-image-file-resizer';
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "../../store/profileUserInfoSlice";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const EditProfile = ({ close, additionInfo }) => {
    
    const [username, firstName, lastName, email, birthDate, profilePic, coverPic, telegram] = additionInfo[0];

    const [codePupupOpen, setCodePopupOpen] = useState(false);
    const [infoChanged, setInfoChanged] = useState(false);
    const [picturesChanged, setPicturesChanged] = useState(false);
    const [progress, setProgress] = useState(0);

    const [progressBar, setProgressBar] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setProgressBar(prevProgress => (prevProgress + 1) % 101);
        }, 20);
    
        return () => {
          clearInterval(interval);
        };
      }, []);

      const dispatch = useDispatch()

      useEffect(() => {
          dispatch(updateDisplayNonOverflow(true))
      // eslint-disable-next-line
      },[])
  
      const handleClose = () => {
          dispatch(updateDisplayNonOverflow(false))
          close()
      }
    
      const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)

    const [formData, setFormData] = useState({
        firstname: firstName,
        lastname: lastName,
        username: username.toLowerCase(),
        email: email,
        birthdate: birthDate,
        telegram_input: telegram,
        });

    const [formPicturesData, setFormPicturesData] = useState({
        profile: '',
        cover: '',
        });


    const sendCode = async (e) => {
        e?.preventDefault()
        setCodePopupOpen(true);
        const data = {username: formData.username}
        const url = `${baseBackendUrl}/register_code_send`

        await axios.post(url, data);
    }
    

    const submitAfterApproval = async () => {
        const data = formData
        const url = `${baseBackendUrl}/edit_profile_info`

        await axios.post(url, data);
        console.log('es')
        setCodePopupOpen(!codePupupOpen)
        dispatch(updateProfileAction(!updateProfile))
        close()
    };

    const handleInputChange = (e) => {
        const { name, value=null } = e.target;
        setInfoChanged(true);

        setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        }));
    };

    const handlePicturesChange = (e) => {
        const { name, files=null } = e.target;
        setPicturesChanged(true);
        if (files) {
            setFormPicturesData((prevState) => ({
                ...prevState,
                [name]: files[0],
                }));
                return;
        };
    };

    const submitPictures = async (e) => {
        e.preventDefault()

        const submitFormData = new FormData();

        submitFormData.append('username', formData.username);

        submitFormData.append('profile_value', profilePic.name)
        submitFormData.append('cover_value', coverPic.name )
        
        // submitFormData.append('profile', formPicturesData.profile);
        // submitFormData.append('cover', formPicturesData.cover);

        // console.log(formPicturesData.profile, '1')


        // console.log(formPicturesData.profile)

        // const maxWidth = 800; // Максимальная ширина изображения в пикселях
        // const maxHeight = 800; // Максимальная высота изображения в пикселях
        
        const profilePromise = new Promise((resolve, reject) => {
            console.log(formPicturesData.profile, formPicturesData.profile.type)
            if (formPicturesData.profile) {
              Resizer.imageFileResizer(
                formPicturesData.profile,
                300,
                300,
                'JPEG',
                90,
                0,
                (resizedProfileImage) => {
                    console.log(formPicturesData.profile)

                  submitFormData.append('profile', resizedProfileImage);
                  console.log(resizedProfileImage)

                  resolve();
                },
                'file'
              );
            } else {
                resolve();
              }
          });
        
          const coverPromise = new Promise((resolve, reject) => {
            if (formPicturesData.cover) {
              Resizer.imageFileResizer(
                formPicturesData.cover,
                800,
                800,
                'JPEG',
                90,
                0,
                (resizedCoverImage) => {
                  submitFormData.append('cover', resizedCoverImage);
                  resolve();
                },
                'file'
              );
            } else {
                resolve();
              }
          });


        try {
            await Promise.all([profilePromise, coverPromise]);

            const data = submitFormData;
            const url = `${baseBackendUrl}/edit_profile_pictures`;
            
            const response = await axios.post(url, data, {
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  setProgress(progress);
                },
              });
              console.log('Upload successful', response.data);
        } catch (error) {
              console.error('Error uploading file', error);
        }
        handleClose()
        dispatch(updateProfileAction(!updateProfile))
    };


    return (                    
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-4 w-fit h-fit sm:h-full sm:w-full">

                    {/* Обрабатываем закрытие окна */}

                    <div className="exit_cross_div">
                        <div onClick={handleClose}>
                            <ExitCrossSVG className="exit_cross_svg" fill="#28a745"/>
                        </div>
                    </div>

                    <form className="flex flex-col gap-2 w-full" action="/" method="post" id="edit-profile-form" encType="multipart/form-data">

                        {/* {% csrf_token %} */}
                        <p className="text-lg font-bold pl-3">{username}</p>
                        <input className="appearance-none shadow rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm inp fname" 
                            type="text" name="firstname" placeholder={t('reg-first-name')} autoComplete="off" value={formData.firstname} autoFocus onChange={handleInputChange} />
                        <input className="appearance-none shadow rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm inp lname" 
                            type="text" name="lastname" placeholder={t('reg-last-name')} value={formData.lastname} autoComplete="off" onChange={handleInputChange} />
                        <input className="appearance-none shadow rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm inp email" 
                            type="email" name="email" placeholder={t('reg-email')} minLength="3" maxLength="64" value={formData.email} autoComplete="off" onChange={handleInputChange} />
                        <input className="lang-reg-birthdate appearance-none shadow rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  placeholder:text-sm inp bdate" 
                            type="date" name="birthdate" placeholder={t('reg-birthdate')} value={formData.birthdate} autoComplete="off" onChange={handleInputChange} />

                        {/* telegram */}
                        {/* <p className="text-[10px]">{t('reg-telegram')}:</p> */}
                        <p className="">Telegram:</p>
                        <div className="flex items-center justify-center gap-2">
                            <p> @</p>
                            <input className="appearance-none shadow rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm inp telegram" 
                                type="text" name="telegram_input" placeholder={t('reg-telegram')} autoComplete="off" value={formData.telegram_input} autoFocus onChange={handleInputChange} />
                        </div>

                        {/* <!-- Кнопка отправки изменения основной инфы  --> */}
                        {/* <div className="flex"> */}
                            {/* <button id="cancel_delete_post" className="button_red" onClick={close}>{t('profile-cancelButton')}</button> */}
                            <button className={infoChanged ? 'button_green' : "button_grey_like_green pointer-events-none"} onClick={sendCode}>{t('profile-editProfileButton')}</button>
                        {/* </div> */}


                        
                        {/* <!-- Тут прикрепляем фотки --> */}
                        {/* <p className="">Изменение фотографий:</p> */}
                        <div className="flex flex-col gap-1">
                            <label className="button_blue_rounded w-full text-center" >{t('reg-profilePic')}

                                <input data-tooltip={t('reg-helpTitle-profilePic')} type="file" name="profile" className="hidden" id="registration_profile_pic" 
                                accept="image/jpeg, image/png, image/gif" data-focusable="true" aria-describedby="profile_pic_description" onChange={handlePicturesChange} value={profilePic ? profilePic.name : ''} />
                            </label>
                            {/* появляющаяся подпись файла прикреплённого */}
                            <p className="text-[8px]">{formPicturesData.profile?.name ? formPicturesData.profile.name : formPicturesData.profile}</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="button_blue_rounded w-full text-center"><span>{t('reg-coverPic')}</span>
                            
                                <input data-tooltip={t('reg-helpTitle-cover')}  type="file" name="cover" className="hidden" id="registration_cover_pic" 
                                accept="image/jpeg, image/png, image/gif" data-focusable="true" aria-describedby="cover_pic_description" onChange={handlePicturesChange} value={coverPic ? coverPic.name : ''} />
                                {/* <div className="tooltip">{t('your-tooltip-text')}</div> */}
                            </label>
                            <p className="text-[8px]">{formPicturesData.cover?.name ? formPicturesData.cover.name : formPicturesData.cover}</p>
                        </div>

                        {/* <div className="flex flex-col gap-1">
                            <label className="button_blue_rounded w-full text-center" >{t('reg-profilePic')}

                                <input data-tooltip={t('reg-helpTitle-profilePic')} type="file" name="profile" className="hidden" id="registration_profile_pic" 
                                accept="image/jpeg,image/gif" data-focusable="true" aria-describedby="profile_pic_description" onChange={handlePicturesChange} value={profilePic ? profilePic.name : ''} />
                            </label>
                            <p className="text-[8px]">{formPicturesData.profile?.name ? formPicturesData.profile.name : formPicturesData.profile}</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="button_blue_rounded w-full text-center"><span>{t('reg-coverPic')}</span>
                            
                                <input data-tooltip={t('reg-helpTitle-cover')}  type="file" name="cover" className="hidden" id="registration_cover_pic" 
                                accept="image/jpeg" data-focusable="true" aria-describedby="cover_pic_description" onChange={handlePicturesChange} value={coverPic ? coverPic.name : ''} />
                            </label>
                            <p className="text-[8px]">{formPicturesData.cover?.name ? formPicturesData.cover.name : formPicturesData.cover}</p>
                        </div> */}

                        {/* <!-- Тут прикрепляем фотки --> */}
                        {/* <div>
                            <label className="" htmlFor="profile">{t('reg-profilePic')}</label>
                            <input type="file" name="profile" className="lang-reg-profilePic custom-file-label file_browse_input" id="registration_profile_pic" onChange={handlePicturesChange}
                            accept="image/jpeg,image/gif" data-focusable="true" aria-describedby="inputGroupFileAddon01" value={profilePic ? profilePic.name : ''} />
                        </div>

                        <div>
                            <label className="" htmlFor="cover"><span>{t('reg-coverPic')}</span></label>
                            <input type="file" name="cover" className="lang-reg-coverPic custom-file-label file_browse_input" id="registration_cover_pic" onChange={handlePicturesChange}
                            accept="image/jpeg" data-focusable="true" aria-describedby="inputGroupFileAddon01" value={coverPic ? coverPic.name : ''} />
                        </div>  */}

                        {progress > 0 && progress < 100 ? 
                            <div className="w-full flex flex-col items-center justify-center gap-1 text-[12px]">
                                <p>{t('profile-photoWaiting')}</p>
                                <div className="h-2 bg-green-400 rounded-full" style={{ width: `${progressBar}%` }} />
                                <progress max="100" value={progress}></progress>
                                <p className="-mt-1">{progress}%</p>
                            </div>
                        : null}

                        {/* <!-- Кнопка отправки изменения фоток  --> */}
                        <button className={picturesChanged ? 'button_green' : "button_grey_like_green pointer-events-none"} onClick={submitPictures}>{t('profile-editProfileButton')}</button>

                        {/* <!-- Сообщение об ошибке --> */}
                        {/* <span className="star small">{{message}}</span> */}

                    </form>
                    {codePupupOpen ? <PopupContainer t={t} choosePopup={'code_approve'} setPopupOpen={setCodePopupOpen} additionInfo={[submitAfterApproval, username, sendCode]} /> : null}

                </div>
            </div>
    )
}

export default EditProfile;