import ExitCrossSVG from "../../../svg/exitCross_svg";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const MatrixProgrammsPopup = ({ close, additionInfo }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const matrixList = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    const listOfEnergies = matrixList[0]
    const listOfEnergiesHealth = matrixList[3]

    // const descriptionEnergyColors = matrixList[1]
    // const descriptionZones = matrixList[2]

    function generatePermutations(num1, num2, num3) {
        const permutations = [];

        // Генерация перестановок
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                if (j === i) continue;
                for (let k = 0; k < 3; k++) {
                    if (k === i || k === j) continue;

                    const zone = `carmicProgramTitle-${arguments[i]}-${arguments[j]}-${arguments[k]}`

                    if (t(zone) !== zone) {
                        if (!permutations.includes(zone)) {

                            permutations.push(`${t('matrixProgram-title_inside')} ${arguments[i]}-${arguments[j]}-${arguments[k]}`)

                            permutations.push(zone);

                            const temp1 = `carmicProgramLesson-${arguments[i]}-${arguments[j]}-${arguments[k]}`
                            if (t(temp1) !== temp1) {
                                permutations.push(temp1);
                            }

                            const temp2 = `carmicProgramPassed-${arguments[i]}-${arguments[j]}-${arguments[k]}`
                            if (t(temp2) !== temp2) {
                                permutations.push(temp2);
                            }
                            const temp3 = `carmicProgramFailure-${arguments[i]}-${arguments[j]}-${arguments[k]}`
                            if (t(temp3) !== temp3) {
                                permutations.push(temp3);
                            }
                        }
                    }

                    const zone2 = `matrixProgram-${arguments[i]}-${arguments[j]}-${arguments[k]}`

                    if (t(zone2) !== zone2) {
                        if (!permutations.includes(zone2)) {

                            permutations.push(`${t('matrixProgram-title_inside')} ${arguments[i]}-${arguments[j]}-${arguments[k]}`)

                            permutations.push(`matrixProgram-${arguments[i]}-${arguments[j]}-${arguments[k]}`);

                            const temp1 = `matrixProgram-${arguments[i]}-${arguments[j]}-${arguments[k]}-description`
                            if (t(temp1) !== temp1) {
                                permutations.push(temp1);
                            }
                            const temp2 = `matrixProgram-${arguments[i]}-${arguments[j]}-${arguments[k]}-negative`
                            if (t(temp2) !== temp2) {
                                permutations.push(temp2);
                            }
                            const temp3 = `matrixProgram-${arguments[i]}-${arguments[j]}-${arguments[k]}-practice`
                            if (t(temp3) !== temp3) {
                                permutations.push(temp3);
                            }
                        }
                    }


                }
            }
        }

        return permutations;
    }

    const zonePersonalEarth = generatePermutations(listOfEnergies[0], listOfEnergies[15], listOfEnergies[14])
    const zonePersonalHeaven = generatePermutations(listOfEnergies[1], listOfEnergies[18], listOfEnergies[17])
    const zoneMaterialKarma = generatePermutations(listOfEnergies[2], listOfEnergies[21], listOfEnergies[10])
    const zoneKarmicTale = generatePermutations(listOfEnergies[3], listOfEnergies[20], listOfEnergies[9])
    const zoneSoulComfort = generatePermutations(listOfEnergies[4], listOfEnergies[40], listOfEnergies[41])

    const zoneFatherTalent = generatePermutations(listOfEnergies[5], listOfEnergies[23], listOfEnergies[22])
    const zoneMotherTalent = generatePermutations(listOfEnergies[6], listOfEnergies[25], listOfEnergies[24])
    const zoneFatherTasks = generatePermutations(listOfEnergies[7], listOfEnergies[27], listOfEnergies[26])
    const zoneMotherTasks = generatePermutations(listOfEnergies[8], listOfEnergies[29], listOfEnergies[28])

    const zonePurposesFirst = generatePermutations(listOfEnergies[36], listOfEnergies[37], listOfEnergies[30])
    const zonePurposesSecond = generatePermutations(listOfEnergies[38], listOfEnergies[39], listOfEnergies[31])

    const zonePurposesThird = generatePermutations(listOfEnergies[30], listOfEnergies[31], listOfEnergies[32])
    const zonePurposesFourth = generatePermutations(listOfEnergies[31], listOfEnergies[32], listOfEnergies[42])

    const zoneMoney = generatePermutations(listOfEnergies[10], listOfEnergies[13], listOfEnergies[11])
    const zoneRelationship = generatePermutations(listOfEnergies[11], listOfEnergies[12], listOfEnergies[9])

    const zoneHealth_1 = generatePermutations(listOfEnergiesHealth[0], listOfEnergiesHealth[1], listOfEnergiesHealth[2])
    const zoneHealth_2 = generatePermutations(listOfEnergiesHealth[3], listOfEnergiesHealth[4], listOfEnergiesHealth[5])
    const zoneHealth_3 = generatePermutations(listOfEnergiesHealth[6], listOfEnergiesHealth[7], listOfEnergiesHealth[8])
    const zoneHealth_4 = generatePermutations(listOfEnergiesHealth[9], listOfEnergiesHealth[10], listOfEnergiesHealth[11])
    const zoneHealth_5 = generatePermutations(listOfEnergiesHealth[12], listOfEnergiesHealth[13], listOfEnergiesHealth[14])
    const zoneHealth_6 = generatePermutations(listOfEnergiesHealth[15], listOfEnergiesHealth[16], listOfEnergiesHealth[17])
    const zoneHealth_7 = generatePermutations(listOfEnergiesHealth[18], listOfEnergiesHealth[19], listOfEnergiesHealth[20])
    const zoneHealth_8 = generatePermutations(listOfEnergiesHealth[21], listOfEnergiesHealth[22], listOfEnergiesHealth[23])

    // console.log(listOfEnergies)
    // console.log(listOfEnergies[31], listOfEnergies[32], listOfEnergies[42])

    const visitCardZone = () => {
        return (
            <>
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_personal_earth')}</p>

                {
                    zonePersonalEarth.length > 0
                        ? zonePersonalEarth.map((item, index) => {
                            return (

                                <div key={index}>
                                    <p>{t(item)}</p>
                                </div>

                            )
                        })
                        : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_personal_heaven')}</p>

                {zonePersonalHeaven.length > 0
                    ? zonePersonalHeaven.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_material_karma')}</p>

                {zoneMaterialKarma.length > 0
                    ? zoneMaterialKarma.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_karmic_tale')}</p>

                {zoneKarmicTale.length > 0
                    ? zoneKarmicTale.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_soul_comfort')}</p>

                {zoneSoulComfort.length > 0
                    ? zoneSoulComfort.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }


                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_father_talent')}</p>

                {zoneFatherTalent.length > 0
                    ? zoneFatherTalent.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_mother_talent')}</p>

                {zoneMotherTalent.length > 0
                    ? zoneMotherTalent.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_father_tasks')}</p>

                {zoneFatherTasks.length > 0
                    ? zoneFatherTasks.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_mother_tasks')}</p>

                {zoneMotherTasks.length > 0
                    ? zoneMotherTasks.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_purpose_first')}</p>

                {zonePurposesFirst.length > 0
                    ? zonePurposesFirst.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }


                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_purpose_second')}</p>

                {zonePurposesSecond.length > 0
                    ? zonePurposesSecond.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_purpose_third')}</p>

                {zonePurposesThird.length > 0
                    ? zonePurposesThird.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }


                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_purpose_fourth')}</p>

                {zonePurposesFourth.length > 0
                    ? zonePurposesFourth.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }


                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_money')}</p>

                {zoneMoney.length > 0
                    ? zoneMoney.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_relationship')}</p>

                {zoneRelationship.length > 0
                    ? zoneRelationship.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }


                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_chakras_sahasrara')}</p>

                {zoneHealth_1.length > 0
                    ? zoneHealth_1.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }


                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_chakras_adzhna')}</p>

                {zoneHealth_2.length > 0
                    ? zoneHealth_2.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_chakras_vishudha')}</p>

                {zoneHealth_3.length > 0
                    ? zoneHealth_3.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_chakras_anahata')}</p>

                {zoneHealth_4.length > 0
                    ? zoneHealth_4.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_chakras_manipura')}</p>

                {zoneHealth_5.length > 0
                    ? zoneHealth_5.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_chakras_svadhistana')}</p>

                {zoneHealth_6.length > 0
                    ? zoneHealth_6.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_chakras_muladhara')}</p>

                {zoneHealth_7.length > 0
                    ? zoneHealth_7.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

                <div className="br_border_line_gray" />
                <p className="bg-slate-700 rounded-3xl p-3">{t('program_zone_chakras_result')}</p>

                {zoneHealth_8.length > 0
                    ? zoneHealth_8.map((item, index) => {
                        return (

                            <div key={index}>
                                <p>{t(item)}</p>
                            </div>

                        )
                    })
                    : t('program_zone_unknown')
                }

            </>


        )
    }

    return (
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <p className="popup_title">{t('matrixProgram-yourPrograms')}</p>

                    {/* Основа */}
                    <div className="flex w-full flex-col gap-5 p-5">
                        {visitCardZone()}
                    </div>
                </div>

                <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
            </div>
        </div>
    )
}

export default MatrixProgrammsPopup;
