const RoundedGradientAvatar = ({ border = 4 }) => {
    return (
        <div className="relative">
            <div className={`w-[150px] h-[150px] sm:w-[100px] sm:h-[100px] loader rotate-90 overflow-hidden border-${border} border_color_rounded_elements`}>
                <div className="w-full h-full rounded-full benzin">
                    <div className="w-full h-full rounded-full benzin">
                        <div className="w-full h-full rounded-full benzin">
                            <div className="w-full h-full rounded-full benzin">
                                <div className="w-full h-full rounded-full benzin">
                                    <div className="w-full h-full rounded-full benzin">
                                        <div className="w-full h-full rounded-full benzin">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoundedGradientAvatar