import ExitCrossSVG from "../../../svg/exitCross_svg";
import { t } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";


const PifagorWillGraphPopup = ({close, additionInfo}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    return (
            // Основа
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">
                
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <p className="popup_title">{t('pifagor-graph-destinyWill-title')}</p>

                    <div className="flex w-full flex-col gap-5 p-5">
                    {/* Основа */}
                    
                        {/* <div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{additionInfo}</div> */}
                        <p className="">{t(`pifagor-graph-destinyWill-description_1`)}</p>
                        <p className="">{t(`pifagor-graph-destinyWill-description_2`)}</p>
                        <p className="">{t(`pifagor-graph-destinyWill-description_3`)}</p>
                        <p className="">{t(`pifagor-graph-destinyWill-description_4`)}</p>

                {/* Основа */}
                </div>
            </div>
            <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
        </div>
    </div>
    )
}

export default PifagorWillGraphPopup;