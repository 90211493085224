import ExitCrossSVG from "../../../svg/exitCross_svg";
import { baseBackendUrl } from "../websocket_comp";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const FollowPopup = ({ close, additionInfo}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const info = additionInfo

    return (
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">                
                {/* Обрабатываем закрытие окна */}
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" fill="#28a745"/>
                </div>
                <div className="flex flex-col gap-2">
                    {info.map((followers, index) => {return (
                        <Link key={index} className="flex gap-1 items-center" to={`/${followers[0]}`} onClick={() => close()}>
                            <div className="small-profilepic" style={{ backgroundImage: `url(${baseBackendUrl}/media/${followers[1]})` }}></div>
                            <p>{followers[0]}</p>
                        </Link>
                    )})}
                </div>
                
            </div>
        </div>
    )
}

export default FollowPopup;