import ExitCrossSVG from "../../../svg/exitCross_svg";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const PurposesPopup = ({close, additionInfo}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const cardsUrl = useSelector(state => state.optionsSlice.cardStyleUrl)
    const matrixListOfEnergies = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    const profileData = useSelector(state => state.profileUserSlice.profileUser)

    const friendsListEnergies = additionInfo ? additionInfo[0] : null
    const friendsGender = additionInfo ? additionInfo[1] : null

    const listOfEnergies = friendsListEnergies ? friendsListEnergies : matrixListOfEnergies[0]
    const purposesList = [listOfEnergies[30], listOfEnergies[31], listOfEnergies[32], listOfEnergies[42]]

    const user = profileData.user
    const gender = friendsGender ? friendsGender : user.gender

    const firstPurpose = (gender === 'Мужской' || gender === 'Male') && (purposesList[0] === 3 || purposesList[0] === 4) ? `matrix-purposes-${purposesList[0]}_forMan` : `matrix-purposes-${purposesList[0]}`
    const secondPurpose = (gender === 'Мужской' || gender === 'Male') && (purposesList[1] === 3 || purposesList[1] === 4) ? `matrix-purposes-${purposesList[1]}_forMan` : `matrix-purposes-${purposesList[1]}`
    const thirdPurpose = (gender === 'Мужской' || gender === 'Male') && (purposesList[2] === 3 || purposesList[2] === 4) ? `matrix-purposes-${purposesList[2]}_forMan` : `matrix-purposes-${purposesList[2]}`
    const fourthPurpose = (gender === 'Мужской' || gender === 'Male') && (purposesList[3] === 3 || purposesList[3] === 4) ? `matrix-purposes-${purposesList[3]}_forMan` : `matrix-purposes-${purposesList[3]}`

    // const isMale = (gender === 'Мужской' || gender === 'Male') && (firstPurpose === 3 || secondPurpose === 3 || thirdPurpose === 3 || firstPurpose === 4 || secondPurpose === 4 || thirdPurpose === 4);
    // const energyShortDescriptionKey = isMale ? `energy-shortDescription-${energiesInner}_forMan` : `energy-shortDescription-${energiesInner}`;

    return (        
            // Основа
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">
                
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">
                {/* Основа */}


                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                        {t('profile-matrix-destinationsTitle')}
                    </div>

                    <div className="flex w-full flex-col gap-5 p-5">

                        {/* ОПИСАНИЕ */}
                        <p className="">{t('matrix-purposes-description')}</p>

                        <p className="flex bg-blue-300 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{t('matrix-purposes-first-title')}</p>
                        <p className="">{t('matrix-purposes-first')}</p>
                        <div className="flex items-center gap-2">
                            <p>{t('matrix-purposes-forMe')}</p><div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{purposesList[0]}</div>
                        </div>

                        <div className="flex sm:flex-col items-center gap-2">
                            <div className="taro_style_upper w-[200px]"><img className="taro_style dance_button" src={`${cardsUrl}/${purposesList[0]}.jpg`} alt="cards"/></div>
                            <p className="w-[90%]">{t(`${firstPurpose}`)}</p>
                        </div>
    


                        
                        <p className="flex w-ful bg-blue-300 items-cente justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{t('matrix-purposes-second-title')}</p>
                        <p className="">{t('matrix-purposes-second')}</p>
                        <div className="flex items-center gap-2">
                            <p>{t('matrix-purposes-forMyGenus')}</p><div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{purposesList[1]}</div>
                        </div>
                        <div className="flex sm:flex-col items-center gap-2">
                        <div className="taro_style_upper w-[200px]"><img className="taro_style dance_button" src={`${cardsUrl}/${purposesList[1]}.jpg`} alt="cards"/></div>
                            <p className="w-[90%]">{t(`${secondPurpose}`)}</p>
                        </div>


                        <p className="flex w-full bg-blue-300 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{t('matrix-purposes-third-title')}</p>
                            <p className="">{t('matrix-purposes-third')}</p>
                            <div className="flex items-center gap-2">
                                <p>{t('matrix-purposes-forWorld')}</p><div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{purposesList[2]}</div>
                            </div>
                        <div className="flex sm:flex-col items-center gap-2">
                            <div className="taro_style_upper w-[200px]"><img className="taro_style " src={`${cardsUrl}/${purposesList[2]}.jpg`} alt="cards"/></div>
                            <p className="w-[90%]">{t(`${thirdPurpose}`)}</p>
                        </div>
                    
                        <p className="flex w-full bg-blue-300 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{t('matrix-purposes-fourth-title')}</p>
                            <div className="flex items-center gap-2">
                                <p>{t('matrix-purposes-forPlanet')}</p><div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{purposesList[3]}</div>
                            </div>
                        <div className="flex sm:flex-col items-center gap-2">
                            <div className="taro_style_upper w-[200px]"><img className="taro_style " src={`${cardsUrl}/${purposesList[3]}.jpg`} alt="cards"/></div>
                            <p className="w-[90%]">{t(`${fourthPurpose}`)}</p>
                        </div>
                        
                    </div>

            {/* Основа */}
                </div>
                <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
            </div>
        </div>
    )
}

export default PurposesPopup;