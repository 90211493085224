import ExitCrossSVG from "../../../svg/exitCross_svg";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseBackendUrl } from "../websocket_comp";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "../../store/profileUserInfoSlice";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const AddTelegramPopup = ({ close, additionInfo}) => {
    const [formData, setFormData] = useState('')
    
    const [authorizedUser] = additionInfo

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }
        const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)

    const handleInputChange = (e) => {
        let inputValue = e.target.value;

        if (inputValue.includes('@')) {
            inputValue = inputValue.replace('@', '');
        }

        setFormData(inputValue);
    };
    

    const submitTelegram  = async () => {
        let url = `${baseBackendUrl}/add_telegram`;
        console.log(formData)
    
        const data = { username: authorizedUser, telegram: formData };
        await axios.post(url, data);

        close()
        dispatch(updateProfileAction(!updateProfile))
    };


    return (
            // Основа
<div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 lg:w-[700px] h-fit sm:h-full sm:w-full">                
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                        {t('profile-button_addTelegram')}
                    </div>

                    <div className="flex w-full flex-col gap-5 p-5">
                    {/* Основа */}

                    <div className="flex items-center justify-center gap-5">
                        <p>@</p>
                       <input className="appearance-none shadow rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm inp telegram" 
                                type="text" name="telegram_input" placeholder={t('reg-telegram')} autoComplete="off" value={formData} autoFocus onChange={handleInputChange} />
                        {/* <p id="username_error" className="lang-reg-error-username text-xs p-1 text-red-500" hidden></p> */}
                        <button className='button_green' onClick={submitTelegram}>{t('profile-button_save')}</button>
                    </div>
                    
                        {/* <div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{additionInfo}</div>
                        <p className="">{t(`matrix-year-description-${additionInfo}`)}</p> */}
                
                {/* Основа */}
                </div>
            </div>
            <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
        </div>
    </div>
    )
}

export default AddTelegramPopup;