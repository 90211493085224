import CommentFillSVG from "../../../svg/comment_fill_svg";
import CommentStrokeSVG from "../../../svg/comment_stroke_svg";
import PopupContainer from "../popups_root";
import { useState, useEffect } from 'react';
import { baseBackendUrl } from "../websocket_comp";
import axios from "axios";
import { formattedDate } from "../services";
import SendMessageSVG from "../../../svg/svg_send_message";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import Checkbox from "../checkbox";
import { Dropdown } from 'flowbite-react';
import DropDownSVG from "../../../svg/dropDown_svg";

const ShowComments = ({ authorizedUserInner, postIdInner, setCommentsLengthInner }) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [commentInput, setCommentInput] = useState();
    const [commentsFromServer, setCommentsFromServer] = useState([]);
    const [anonymousComment, setAnonymousComment] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (!authorizedUserInner) { setPopupOpen(true) }
        checkCommentsFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const checkCommentsFromServer = async () => {
        try {
            const url = `${baseBackendUrl}/post/${postIdInner}/comments`;
            const data = { post: postIdInner, user: authorizedUserInner, comment_text: commentInput, anonymous: anonymousComment};
            const response = await axios.post(url, data);
            setCommentsFromServer(response.data)
            setCommentsLengthInner(response.data.length)

        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmitButton = (e) => {
        e.preventDefault()
        checkCommentsFromServer()
        setCommentInput('');
    }

    const handleDeleteComment = async (commentIdInner) => {
        try {
            const url = `${baseBackendUrl}/post/${postIdInner}/comments_delete`;
            const data = { post: postIdInner, user: authorizedUserInner, commentId: commentIdInner};
            await axios.post(url, data);
            checkCommentsFromServer()


        } catch (error) {
            console.error(error);
        }
    }

    const everyComment = () => {
        if (commentsFromServer?.length === 0) {
            return null;
        }

        // каждый коммент
        return commentsFromServer?.map((comment, index) => (
            <div key={index} className='flex flex-col gap-2 p-3 pl-4 pr-5 rounded-3xl border break-words border_color'>

                <div className="flex gap-1 items-center text-xs">
                    {comment.anonymous 
                        ? <div className="flex justify-between w-full">
                            <p className="bg_bright_blue text_inverted_color pl-2 pr-2 rounded-full">{t('profile-anonymousPost')}</p> 
                            {comment.commenter.username === authorizedUserInner
                                ?
                                <div className="w-fit stroke_color flex items-center justify-center z-10">

                                    <Dropdown label="" dismissOnClick={true} className="border_color transpatent_bg" renderTrigger={() =>
                                        <div className="flex gap-2">
                                            <DropDownSVG className={' stroke-green-700 w-[14px] h-[14px]'} />
                                            {/* {editMessages ? <div className="text-red-500" onClick={deleteChatMessages}>{t('messages-delete')}</div> : null}
                                            {editMessages ? <div className="" onClick={() => { setEditMessages(!editMessages) }}>{t('messages-ready')}</div> : <ThreeDotsSVG className="fill_color w-7 h-7" />} */}
                                        </div>
                                    }>

                                        <Dropdown.Item
                                            className="flex gap-1 text_in_menu hover:text-red-500"
                                            onClick={() => {handleDeleteComment(comment.id)}}
                                            >
                                            <p>{t('messages-delete')}</p>
                                        </Dropdown.Item>
                                    </Dropdown>


                                </div>
                                : null
                            }
                        </div>
                        : 
                        <div className="flex items-center w-full">

                            <div className="flex items-center w-full" onClick={() => { navigate(`/${comment.commenter.username}`) }}>
                                {/* ава */}
                                <div id="Profile Picture" className="comments-profilepic shadow-md duration-300 hover:rotate-12 cursor-pointer" style={{ backgroundImage: `url(${baseBackendUrl + comment.commenter.profile_pic})` }}></div>

                                {/* имя */}
                                <div className="flex gap-1 duration-300 hover:text-blue-500 cursor-pointer">
                                    <h1 className="font-bold">{comment.commenter.first_name}</h1>
                                    <h1 className="font-bold">{comment.commenter.last_name}</h1>
                                    {/* <p>@{comment.commenter.username}</p> */}
                                </div>
                            </div>

                            {comment.commenter.username === authorizedUserInner
                                ?
                                <div className="w-fit stroke_color flex items-center justify-center z-10">

                                    <Dropdown label="" dismissOnClick={true} className="border_color transpatent_bg" renderTrigger={() =>
                                        <div className="flex gap-2">
                                            <DropDownSVG className={' stroke-green-700 w-[14px] h-[14px]'} />
                                            {/* {editMessages ? <div className="text-red-500" onClick={deleteChatMessages}>{t('messages-delete')}</div> : null}
                                            {editMessages ? <div className="" onClick={() => { setEditMessages(!editMessages) }}>{t('messages-ready')}</div> : <ThreeDotsSVG className="fill_color w-7 h-7" />} */}
                                        </div>
                                    }>

                                        <Dropdown.Item
                                            className="flex gap-1 text_in_menu hover:text-red-500"
                                            onClick={() => {handleDeleteComment(comment.id)}}
                                            >
                                            <p>{t('messages-delete')}</p>
                                        </Dropdown.Item>
                                    </Dropdown>


                                </div>
                                : null
                            }
                            
                        </div>
                    }

                    
                    

                </div>

                {/* коммент */}
                <p className="pl-8 text-xs">{comment.body}</p>

                {/* дата */}
                <p className=" text-right text-[9px] text-gray-400">{formattedDate(comment.timestamp)}</p>

            </div>
        ));
    }

    const commentsContainerClass = commentsFromServer?.length > 5 ? 'flex flex-col gap-1 h-[400px] overflow-auto' : 'flex flex-col gap-1';

    // ИНПУТ и все комменты ниже
    return (
        <>
            {authorizedUserInner ? (
                <div className="w-full" data-post_id={postIdInner}>
                    {/* <div className="spinner-div"><img src="/images/spinner.gif" alt="spinner" id="spinner" height="65px" /></div> */}

                    <div className='flex flex-col w-full gap-2' data-post_id={postIdInner}>

                        {/* ИНПУТ */}
                        <form className="flex p-2 items-center " onSubmit={handleSubmitButton}>
                            <div className="w-full">
                                <div className="flex items-center gap-2">
                                    <input type="text" name="comment" autoFocus placeholder="Write a comment..." value={commentInput || ''} onChange={(e) => setCommentInput(e.target.value)} />
                                    {/* <button className="button_blue_rounded !w-8 !h-8"></button> */}
                                    <div onClick={handleSubmitButton}>
                                        {commentInput ? <SendMessageSVG className='fill-[#1da1f2] hover:w-5 ease-in-out duration-500 hover:-rotate-180' /> : null}
                                    </div>
                                </div>


                                <div className="flex justify-end"><Checkbox checkedInner={anonymousComment} handleCheckboxInner={() => setAnonymousComment(!anonymousComment)} text={t('profile-anonymousCreatePost')} /></div>
                            </div>

                        </form>

                        {/* ВСЕ КОММЕНТЫ */}
                        <div className={commentsContainerClass}>
                            {/* <p>Comments:</p> */}
                            {everyComment()}
                        </div>

                    </div>

                </div>

            ) : null}

            {popupOpen ? <PopupContainer t={t} choosePopup={'login_warning'} setPopupOpen={setPopupOpen} additionInfo="comment" /> : null}
        </>
    )
};


const Comments = ({ comment_count, showCommentsBool }) => {
    const commentsSvgButtonClass = 
        comment_count > 0 
            ? <CommentFillSVG className='w-[18px] fill-[#1da1f2] hover:w-5 hover:-rotate-45 ease-in-out duration-500' />
            : <CommentStrokeSVG className='w-[18px] hover:w-5 hover:-rotate-45 ease-in-out duration-500' />

    // Кнопка
    return (
        <>
            <div className="actions_with_posts select-none">

                <div className="svg-span cursor-pointer w-[20px]" onClick={showCommentsBool}>
                    {commentsSvgButtonClass}
                </div>

                <div className="cmt-count">{comment_count}</div>
            </div>
        </>
    )
}

export { Comments, ShowComments };