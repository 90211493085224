const OrbitYearSVG = ({className}) => {
    return (
        <svg 
            className={className} 
            // fill="currentColor"
            // stroke="currentColor"

            // width="3em" height="3em"
            version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.000000 60.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
            >
            <path d="M248 589 c-138 -21 -248 -150 -248 -290 0 -63 17 -53 22 14 13 155
            122 257 272 257 113 -1 198 -51 246 -147 35 -69 35 -169 0 -237 -32 -62 -50
            -70 -50 -22 0 19 -4 38 -10 41 -11 7 -13 -36 -4 -84 7 -33 25 -38 93 -25 46 9
            39 28 -8 20 l-39 -6 30 56 c116 213 -61 459 -304 423z"/>
            <path d="M233 390 c-63 -38 -63 -142 0 -180 64 -39 145 -13 167 54 33 101 -75
            183 -167 126z"/>
            <path d="M20 197 c0 -7 7 -22 15 -33 12 -16 14 -17 15 -2 0 23 -11 48 -21 48
            -5 0 -9 -6 -9 -13z"/>
            <path d="M70 112 c0 -13 23 -32 39 -32 11 0 10 4 -4 20 -19 21 -35 26 -35 12z"/>
            <path d="M150 52 c0 -13 48 -33 54 -23 3 4 -6 13 -20 20 -30 13 -34 14 -34 3z"/>
            <path d="M250 21 c0 -10 47 -21 54 -13 3 3 3 9 0 13 -7 12 -54 12 -54 0z"/>
            </g>
        </svg>
    )
}

export default OrbitYearSVG;
