import DropDownSVG from "../../../svg/dropDown_svg";
import { useDispatch } from "react-redux";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import throttle from 'lodash/throttle';
import { moonPosition } from "../calculations/zodiac_calculations";

export const MoonRelativeEarth = ({ moonInfo, setMoonInfo, cityLatLong, prevDeltaX, setPrevDeltaX, moonDate, setMoonDate, setTodayDate }) => {
    const dispatch = useDispatch()

    const handleThrottledSwipeMove = throttle((event) => {
        setTodayDate(false)

        let moveX
        let deltaX
        let finalDeltaX

        dispatch(updateDisplayNonOverflow(true))



        if (event.type === "touchmove") {
            moveX = event.touches[0].clientX;

            finalDeltaX = Math.floor((moveX) / 5);

            if (prevDeltaX > finalDeltaX) {
                deltaX = -3
            } else if (prevDeltaX < finalDeltaX) {
                deltaX = 3
            } else if (prevDeltaX === finalDeltaX) {
                deltaX = 0
            }

            setPrevDeltaX(finalDeltaX)
        }

        let currentDate = new Date(moonDate);
        setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX));
        setMoonInfo(moonPosition(currentDate, cityLatLong));


    }, 2000);


    const handleSwipeStart = (event) => {

        setTodayDate(false)

        const startX = event.clientX

        const handleSwipeMove = (event) => {
            let moveX
            let deltaX

            if (event.type === "mousemove") {
                moveX = event.clientX;
                deltaX = Math.floor((moveX - startX));

                let currentDate = new Date(moonDate);
                setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX))
                setMoonInfo(moonPosition(currentDate, cityLatLong))
            }
        };

        const handleSwipeEnd = () => {
            // Очищаем обработчики событий после окончания перетаскивания
            window.removeEventListener('mousemove', handleSwipeMove);
            window.removeEventListener('mouseup', handleSwipeEnd);
        };

        // Добавляем обработчики событий для перемещения мыши и окончания перетаскивания
        window.addEventListener('mousemove', handleSwipeMove);
        window.addEventListener('mouseup', handleSwipeEnd);
    };


    return (
        <div className="flex items-center justify-center">

            <div className="flex items-center justify-center rotate-90 z-0 p-4"
                onMouseDown={(e) => handleSwipeStart(e)}
                onTouchMove={handleThrottledSwipeMove}
                onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
            >
                <div className="flex items-center justify-center flex-col cursor-pointer">
                    <DropDownSVG className='animate-pulse' />
                    <DropDownSVG className='w-4 h-4 animate-pulse' />
                    <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-[100px] z-[1] w-[350px] h-[300px] pt-10 pl-12 pr-12">


                <div className="flex items-center justify-center m-[100px] relative cursor-pointer h-[250px]"
                    onMouseDown={(e) => handleSwipeStart(e)}
                    onTouchMove={handleThrottledSwipeMove}
                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                >
                    {/* Орбита луны */}
                    <div className="absolute outline-dashed rounded-full w-[325px] h-[325px] outline-slate-700 outline-[1px] earth_moon_center_rotate"></div>

                    {/* Центр масс кружок */}
                    <div className="absolute outline-dashed rounded-full w-[50px] h-[50px] outline-slate-600 outline-[1px] earth_moon_center_rotate"></div>

                    {/* Земля */}
                    <div style={{ 'transform': `rotate(${moonInfo[11]}deg)` }} className="absolute flex items-center justify-center ">
                        <div style={{ 'transform': `translateX(${Math.floor(-moonInfo[4] * 1) / 12000}px)` }} className="flex items-center justify-center">
                            <div style={{ 'transform': `rotate(${90 - moonInfo[10]}deg)` }} className='flex items-center justify-center w-[100px] h-[100px] rounded-full'>

                                {/* Орбита земли показательная */}
                                <div className="absolute outline-dashed rounded-full w-[100px] h-[100px] outline-slate-700 outline-[1px] earth_moon_center_rotate"></div>

                                {/* Линия Видимости */}
                                <div className="absolute top-6 left-12 w-[100px] -rotate-12 outline-dashed outline-[1px] outline-slate-500 bg-black"></div>
                                <div className="absolute top-6 -left-12 w-[100px] rotate-12 outline-dashed outline-[1px] outline-slate-500 bg-black"></div>

                                {/* Точка на Земле (город) */}
                                <div className="absolute top-6 w-[5px] h-[5px] bg-slate-300 z-[2] rounded-full"></div>

                                {/* EarthShadow */}
                                <div style={{ 'boxShadow': `inset 0px 50px 20px 2px rgba(0, 0, 0, 0.9)`, 'transform': `rotate(${moonInfo[10] - moonInfo[11] - 90}deg)` }} className="  w-[100px] h-[100px] absolute rounded-full z-[1]"></div>
                                <img src="/images/planets/earth/earth_from_top.png" alt='earth' className="pointer-events-none rotate-[-30deg]"></img>

                            </div>
                        </div>
                    </div>

                    {/* Луна */}
                    <div style={{ 'transform': `rotate(${moonInfo[11]}deg)` }} className="absolute w-[250px] h-[250px]">

                        {/* <div className="absolute top-[-100px] left-[50px]"> */}
                        <div style={{ 'transform': `translateX(${Math.floor(moonInfo[4] * 0.4) / 1000}px) rotate(90deg) scale(${90 - moonInfo[5] * 600}%)` }} className="absolute top-0 left-0 h-[250px] w-[250px]" >

                            {/* MoonShadow */}
                            <div style={{ 'boxShadow': `inset 0px 90px 40px 10px rgba(0, 0, 0, 0.9)`, 'transform': `rotate(${-90 - moonInfo[11]}deg)` }} className="justify-center backdrop-saturate-[0.3] bg-white/10 w-[250px] h-[250px] absolute rounded-full"></div>
                            <img src="/images/planets/moon/moon.png" alt='moon_near_earth' className="rounded-full "></img>
                        </div>
                        {/* </div> */}

                    </div>

                </div>


            </div>

            <div className="flex items-center justify-center -rotate-90 z-0 p-4"
                onMouseDown={(e) => handleSwipeStart(e)}
                onTouchMove={handleThrottledSwipeMove}
                onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
            >
                <div className="flex items-center justify-center flex-col cursor-pointer">
                    <DropDownSVG className='animate-pulse' />
                    <DropDownSVG className='w-4 h-4 animate-pulse' />
                    <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                </div>
            </div>

        </div>
    )
}

export const SunWithEarth = ({ moonInfo, setMoonInfo, cityLatLong, prevDeltaX, setPrevDeltaX, moonDate, setMoonDate, setTodayDate }) => {
    const dispatch = useDispatch()

    const handleThrottledSwipeMove = throttle((event) => {
        setTodayDate(false)

        let moveX
        let deltaX
        let finalDeltaX

        dispatch(updateDisplayNonOverflow(true))



        if (event.type === "touchmove") {
            moveX = event.touches[0].clientX;

            finalDeltaX = Math.floor((moveX) / 5);

            if (prevDeltaX > finalDeltaX) {
                deltaX = -5
            } else if (prevDeltaX < finalDeltaX) {
                deltaX = 5
            } else if (prevDeltaX === finalDeltaX) {
                deltaX = 0
            }

            setPrevDeltaX(finalDeltaX)
        }

        let currentDate = new Date(moonDate);
        setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX));
        setMoonInfo(moonPosition(currentDate, cityLatLong));


    }, 2000);


    const handleSwipeStart = (event) => {

        setTodayDate(false)

        const startX = event.clientX

        const handleSwipeMove = (event) => {
            let moveX
            let deltaX

            if (event.type === "mousemove") {
                moveX = event.clientX;
                deltaX = Math.floor((moveX - startX));

                let currentDate = new Date(moonDate);
                setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX))
                setMoonInfo(moonPosition(currentDate, cityLatLong))
            }
        };

        const handleSwipeEnd = () => {
            // Очищаем обработчики событий после окончания перетаскивания
            window.removeEventListener('mousemove', handleSwipeMove);
            window.removeEventListener('mouseup', handleSwipeEnd);
        };

        // Добавляем обработчики событий для перемещения мыши и окончания перетаскивания
        window.addEventListener('mousemove', handleSwipeMove);
        window.addEventListener('mouseup', handleSwipeEnd);
    };

    return (
        <div className="flex justify-center items-center">
            <div className="w-[500px] h-[550px] relative flex items-center justify-center cursor-pointer"

            >
                <div className="flex items-center justify-center rotate-90 z-0 p-4"
                    onMouseDown={(e) => handleSwipeStart(e)}
                    onTouchMove={handleThrottledSwipeMove}
                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                >
                    <div className="flex items-center justify-center flex-col cursor-pointer">
                        <DropDownSVG className='animate-pulse' />
                        <DropDownSVG className='w-4 h-4 animate-pulse' />
                        <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                    </div>
                </div>

                <div className="w-full h-full flex items-center justify-center z-[1]">

                    {/* Луна */}
                    <div className="w-[30px] h-[30px] absolute z-[2]"
                        style={{ 'transform': `rotate(${moonInfo[11] - 163 + moonInfo[12]}deg` }}
                        onMouseDown={(e) => handleSwipeStart(e)}
                        onTouchMove={handleThrottledSwipeMove}
                        onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                    >
                        <div
                            style={{ 'transform': `translateX(${60 + Math.floor((moonInfo[4] * 1000) / 1000 - 356400) / 1000}px)` }}
                            className="absolute top-0 left-0 h-[30px] w-[30px]">

                            {/* MoonShadow */}
                            <div style={{ 'boxShadow': `inset 0px 10px 5px 2px rgba(0, 0, 0, 0.9)`, 'transform': `rotate(${-(moonInfo[12] + moonInfo[11]) + moonInfo[12]}deg)` }} className="  w-[30px] h-[30px] absolute rounded-full z-[1]"></div>
                            <img src="/images/planets/moon/moon.png" alt='moon_near_earth' className="rounded-full pointer-events-none"></img>

                        </div>
                    </div>

                    {/* Земля */}
                    <div className="relative rotate-[213deg] h-[80px] w-[80px] z-[1]"
                        onMouseDown={(e) => handleSwipeStart(e)}
                        onTouchMove={handleThrottledSwipeMove}
                        onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                    >


                        {/* Точка город */}
                        <div className="absolute top-[50px] left-[40px] -rotate-[30deg] w-[5px] h-[5px] bg-slate-300 z-[2] rounded-full"></div>

                        {/* Линия Видимости */}
                        <div className="z-0 absolute top-[80px] -left-[50px] -rotate-[40deg] w-[100px] outline-dashed outline-[1px] outline-slate-500 bg-black"></div>
                        <div className="z-0 absolute top-[15px] left-[50px] -rotate-[25deg] w-[100px] outline-dashed outline-[1px] outline-slate-500 bg-black"></div>

                        {/* EarthShadow */}
                        <div style={{ 'boxShadow': `inset 0px 50px 20px 2px rgba(0, 0, 0, 0.9)`, 'transform': `rotate(${-30 + moonInfo[12]}deg)` }} className="  w-[80px] h-[80px] absolute rounded-full z-[1]"></div>
                        <img src="/images/planets/earth/earth_from_top.png" alt='earth' className="pointer-events-none rotate-[120deg] border-slate-500 border-[1px] rounded-full"></img>
                    </div>

                    {/* Солнечная ось */}
                    <div className="absolute outline-dashed rounded-full w-[380px] h-[380px] outline-slate-700 outline-[1px] earth_moon_center_rotate_reversed"></div>

                    {/* Лунная ось */}
                    <div className="absolute outline-dashed rounded-full w-[210px] h-[210px] outline-slate-700 outline-[1px] earth_moon_center_rotate"></div>
                    <div className="absolute outline-dashed rounded-full w-[135px] h-[135px] outline-slate-700 outline-[1px] earth_moon_center_rotate"></div>

                    {/* Солнце вокруг оси */}
                    <div
                        style={{ 'transform': `rotate(${-90 + 10 + moonInfo[12]}deg` }}
                        className="h-[150px] w-[150px] absolute"
                        onMouseDown={(e) => handleSwipeStart(e)}
                        onTouchMove={handleThrottledSwipeMove}
                        onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                    >

                        {/* Солнце выдвинуто от центра */}
                        <div
                            style={{ 'transform': `translateX(200px)` }}
                            className="absolute top-0 left-0 h-[150px] w-[150px]">
                            <img className="pointer-events-none" src="/images/planets/sun/sun.png" alt='sun'></img>
                        </div>
                    </div>


                </div>

            </div>

            <div className="flex items-center justify-center -rotate-90 z-0 p-4"
                onMouseDown={(e) => handleSwipeStart(e)}
                onTouchMove={handleThrottledSwipeMove}
                onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
            >
                <div className="flex items-center justify-center flex-col cursor-pointer">
                    <DropDownSVG className='animate-pulse' />
                    <DropDownSVG className='w-4 h-4 animate-pulse' />
                    <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                </div>
            </div>

        </div>
    )
}