import ExitCrossSVG from "../../../svg/exitCross_svg";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

import React, { useState, useEffect } from "react"
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom';
import EyeSVG from "../../../svg/eye_svg";
import Resizer from 'react-image-file-resizer';
import { baseBackendUrl } from "../websocket_comp";

import PopupContainer from "../popups_root";
import { useDispatch } from "react-redux";
import { updateAuthorizedUser } from "../../store/authorizedUserSlice";



const RegisterPopupJoin = ({ close, additionInfo }) => {
    const [progressBar, setProgressBar] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            setProgressBar(prevProgress => (prevProgress + 1) % 101);
        }, 20);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const [popupOpen, setPopupOpen] = useState(false);
    const [progress, setProgress] = useState(0);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        birthdate: "2000-01-01",
        gender: '',
        password: '',
        confirmation: '',
        profile: '',
        cover: '',
    });

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?]{8,20}$/;

    const [usernameProblem, setUsernameProblem] = useState()
    const [passwordPatternProblem, setPasswordPatternProblem] = useState(false)
    const [passwordConfirmProblem, setPasswordConfirmProblem] = useState(true)
    const [errorUsername, setErrorUsername] = useState(false)

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value = null, files = null } = e.target;
        if (files) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
            }));
            return;
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // console.log(formData)

    };

    const handleInputUsernameChange = (e) => {
        const { name, value = null, files = null } = e.target;

        const regex = /^[a-z0-9]+$/; // Регулярное выражение для проверки на латиницу
        const isSingleWord = !/\s/.test(value); // Проверка на отсутствие пробелов

        if (files) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
            }));
            return;
        }

        if (regex.test(value) && isSingleWord) {
            setErrorUsername(false)
            setFormData((prevState) => ({
                ...prevState,
                [name]: value.toLowerCase(),
            }));
        } else if (value === '') {
            setErrorUsername(false)
        } else {
            setErrorUsername(true)
        }
    };

    // console.log(formData)

    const submitButton = () => {
        if (formData.firstname && formData.lastname && formData.username && formData.email && formData.birthdate && formData.gender && formData.password && formData.confirmation && !passwordConfirmProblem && !errorUsername) {
            return (
                <input className="button_blue bg-red-500"
                    type="submit" id="input_submit" value={t('reg-signup-button')} />
            )
        } else {
            return (
                <div>
                    <input className="button_gley_like_blue"
                        type="submit" id="input_submit" value={t('reg-signup-button')} onClick={() => alert(`${t('reg-error-emptyInputs')}`)} />
                    <div className="tooltip">{t('reg-helpTitle-button')}</div>

                </div>

            )
        }

    }


    // Проверяем занят ли username
    useEffect(() => {
        const checkUsername = async () => {
            const data = { username: formData.username }
            const url = `${baseBackendUrl}/check_username`

            const response = await axios.post(url, data);
            response.data.approve === 'no' ? setUsernameProblem(true) : setUsernameProblem(false)
        }

        checkUsername()
    }, [formData.username]);

    useEffect(() => {
        // Проверка на соответствие паттерну
        if (formData.password.length > 0) {
            !passwordPattern.test(formData.password) ? setPasswordPatternProblem(true) : setPasswordPatternProblem(false)
        }

        // Проверка на соответствие паролей
        formData.password === formData.confirmation ? setPasswordConfirmProblem(false) : setPasswordConfirmProblem(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.password, formData.confirmation])

    // Ошибка если username занят
    const usernameError = () => usernameProblem ? <p className="text-xs p-1 text-red-500">{t('reg-error-username')}</p> : null
    const usernameError2 = () => errorUsername ? <p className="text-xs p-1 text-red-500">{t('reg-error-username-space')}</p> : null

    // Ошибка если паттерн не соответствует в Пароле
    const passwordPatternError = () => {
        if (passwordPatternProblem) {
            return (
                <p id="password_error" className="text-xs p-1 text-red-500">
                    {t('reg-error-password')}
                </p>
            )
        }
    }

    // Ошибка если пароли не сходятся
    const passwordConfirmError = () => {
        if (passwordConfirmProblem) {
            return (
                <p id="password_confirm_error" className="text-xs p-1 text-red-500">{t('reg-error-confirm-password')}</p>
            )
        }
    }

    // Высылаем код
    const sendCode = async () => {
        const data = { email: formData.email, username: formData.username }
        const url = `${baseBackendUrl}/register_code_send`

        try {
            const response = await axios.post(url, data, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    setProgress(progress);
                },
            });
            console.log('Upload successful', response.data);
            if (response.data.message === "unable send code") {
                setPopupOpen(false)
                alert(t('reg-error-cantSendCode'))
            }
        } catch (error) {
            console.error('Error uploading file', error);

        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Высылаем код на проверку
        if (!passwordPatternProblem && !passwordConfirmProblem && !usernameProblem) {
            sendCode()
            setPopupOpen(true);
        }
    };

    // Финальная регистрация
    const submitAfterApproval = async () => {
        const submitFormData = new FormData();

        submitFormData.append('firstname', formData.firstname);
        submitFormData.append('lastname', formData.lastname);
        submitFormData.append('username', formData.username.toLowerCase());
        submitFormData.append('email', formData.email);
        submitFormData.append('birthdate', formData.birthdate);
        submitFormData.append('gender', formData.gender);
        submitFormData.append('password', formData.password);
        submitFormData.append('confirmation', formData.confirmation);

        const profilePromise = new Promise((resolve, reject) => {
            if (formData.profile) {
                Resizer.imageFileResizer(
                    formData.profile,
                    300,
                    300,
                    'JPEG',
                    90,
                    0,
                    (resizedProfileImage) => {
                        submitFormData.append('profile', resizedProfileImage);
                        console.log(resizedProfileImage)

                        resolve();
                    },
                    'file'
                );
            } else {
                resolve();
            }
        });

        const coverPromise = new Promise((resolve, reject) => {
            if (formData.cover) {
                Resizer.imageFileResizer(
                    formData.cover,
                    800,
                    800,
                    'JPEG',
                    90,
                    0,
                    (resizedCoverImage) => {
                        submitFormData.append('cover', resizedCoverImage);
                        resolve();
                    },
                    'file'
                );
            } else {
                resolve();
            }
        });


        try {
            await Promise.all([profilePromise, coverPromise]);

            // submitFormData.append('profile', formData.profile);
            // submitFormData.append('cover', formData.cover);

            const data = submitFormData
            const url = `${baseBackendUrl}/register`

            const response = await axios.post(url, data, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    setProgress(progress);
                },
            });
            console.log('Upload successful', response.data);

            if (response.data.approve === 'yes') {
                const user = response.data.user
                dispatch(updateAuthorizedUser(user))

                setTimeout(() => {
                    navigate(`/${formData.username}`);
                    window.location.reload();
                }, 2000);

            } else {
                console.log(response.data.message)
                alert(t('reg-error-failed'))
            }

        } catch (error) {
            console.error('Error uploading file', error);
        }


    };


    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    return (
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="bg-white flex flex-col items-center p-8 sm:p-5 w-[400px] h-fit sm:h-full sm:w-full overflow-auto">

                <img className="w-[300px]" alt="index_matrix" src="/images/logo.png" />

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                {/* <div className="w-[400px] sm:w-full h-full overflow-auto flex flex-col items-center justify-center"> */}


                <h3 className=" w-full bg-blue-300 text-white text-center">{t('reg-head')}</h3>

                <form id="registration-form" className="flex flex-col gap-2 w-full" action="/" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
                    {/* {% csrf_token %} */}
                    {/* <!-- <input className="input_style" type="text" name="step" value="first" readonly="readonly" hidden> --> */}

                    <input className="input_style inp fname"
                        type="text" name="firstname" placeholder={t('reg-first-name')} autoComplete="off" autoFocus required onChange={handleInputChange} />

                    <input className="input_style inp lname"
                        type="text" name="lastname" placeholder={t('reg-last-name')} autoComplete="off" required onChange={handleInputChange} />

                    <div>
                        <input className="input_style inp usrname"
                            type="text" name="username" placeholder={t('reg-username')} autoComplete="off" required onChange={handleInputUsernameChange} />
                        {usernameError()}
                        {usernameError2()}
                        {/* <p id="username_error" className="lang-reg-error-username text-xs p-1 text-red-500" hidden></p> */}
                    </div>

                    <input className="input_style inp email"
                        type="email" name="email" placeholder={t('reg-email')} minLength="3" maxLength="64" autoComplete="off" required onChange={handleInputChange} />

                    <div className="flex shadow rounded bg-blue-300 h-10">
                        <label htmlFor="cover" className="flex text-white items-center justify-center text-center text-[14px]">{t('reg-helpTitle-birthDate')}:</label>
                        <input className="input_style  inp bdate"
                            type="date" name="birthdate" min="1500-01-01" max="3000-01-01" value={formData.birthdate}
                            placeholder={t('reg-birthdate')} autoComplete="off" required onChange={handleInputChange} />
                    </div>


                    <select className="input_style inp gender"
                        defaultValue="" name="gender" size="1" required onChange={handleInputChange}>
                        <option value="" disabled>{t('reg-choose-gender')}</option>
                        <option value="Male">{t('reg-Male')}</option>
                        <option value="Female">{t('reg-Female')}</option>
                    </select>

                    {/* <!-- password --> */}
                    <div className='flex flex-col gap-1 relative'>
                        <input className="input_style inp pswd"
                            type="password" name="password" placeholder={t('reg-password')} autoComplete="off" minLength="8" maxLength="20" onChange={handleInputChange} required />
                        {passwordPatternError()}
                        <div className="absolute right-2 top-1 w-8 h-8 cursor-pointer fill-blue-300 hover:fill-blue-400" onClick={(e) => e.target.previousElementSibling.type === "password" ? e.target.previousElementSibling.type = "text" : e.target.previousElementSibling.type = "password"}>
                            <EyeSVG className="pointer-events-none animate-pulse" />
                        </div>

                    </div>

                    {/* <!-- password confirm --> */}
                    <div className='flex flex-col gap-1 relative'>
                        <input className="input_style inp cpswd"
                            type="password" name="confirmation" placeholder={t('reg-confirm')} autoComplete="off" onChange={handleInputChange} />
                        <div className="absolute z-[1] right-2 top-1 w-8 h-8 cursor-pointer fill-blue-300 hover:fill-blue-400" onClick={(e) => e.target.previousElementSibling.type === "password" ? e.target.previousElementSibling.type = "text" : e.target.previousElementSibling.type = "password"}>
                            <EyeSVG className="pointer-events-none animate-pulse" />
                        </div>
                        {passwordConfirmError()}

                    </div>

                    {/* <!-- Тут прикрепляем фотки --> */}
                    <div className="flex flex-col gap-1">
                        <label className="button_base w-full text-center" >{t('reg-profilePic')}

                            <input data-tooltip={t('reg-helpTitle-profilePic')} type="file" name="profile" className="hidden" id="registration_profile_pic"
                                accept="image/jpeg, image/png, image/gif" data-focusable="true" aria-describedby="profile_pic_description" onChange={handleInputChange} />
                        </label>
                        <p className="text-[8px]">{formData.profile.name}</p>
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className="button_base w-full text-center"><span>{t('reg-coverPic')}</span>

                            <input data-tooltip={t('reg-helpTitle-cover')} type="file" name="cover" className="hidden" id="registration_cover_pic"
                                accept="image/jpeg, image/png, image/gif" data-focusable="true" aria-describedby="cover_pic_description" onChange={handleInputChange} />
                        </label>
                        <p className="text-[8px]">{formData.cover.name}</p>
                    </div>

                    {progress > 0 ?
                        <div className="h-2 bg-green-400 rounded-full" style={{ width: `${progressBar}%` }} />
                        : null}

                    {progress > 0 && progress < 100 ?
                        <div className="w-full flex flex-col items-center justify-center gap-1 text-[12px]">
                            <p>{t('profile-photoWaiting')}</p>
                            <progress max="100" value={progress}></progress>
                            <p className="-mt-1">{progress}%</p>
                        </div>
                        : null}

                    {/* <!-- Кнопка --> */}
                    <div>
                        {submitButton()}
                        {popupOpen ? <PopupContainer t={t} choosePopup={'code_approve'} setPopupOpen={setPopupOpen} additionInfo={[submitAfterApproval, formData.username, sendCode]} /> : null}
                    </div>
                    {/* <div className="bg-red-300 sticky top-0">К сожалению, на данный момент регистрация на почты mail.ru недоступна. Можно зарегистрироваться на почты с другими доменными именами, либо же попробовать позже. Возможно завтра эта проблема с mail.ru решится. Приносим свои извинения.</div> */}


                    {/* <!-- Если есть уже акаунт --> */}
                    <div className="flex justify-between text-sm">
                        <p>{t("reg-alreadyHaveAccount")}</p>
                        <Link to="/login" id="already_have_account_button" className="link_blue">{t('reg-loginLink')}</Link>
                    </div>

                </form>

                {/* </div> */}

            </div>
        </div>
    )
}

export default RegisterPopupJoin;