

const UniverseSVG = ({className}) => {
    return (
<svg width="1.5em" height="1.5em" fill="currentColor"  strokeWidth={10} stroke="currentColor" className={className} version="1.0" xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 512.000000 512.000000"
preserveAspectRatio="xMidYMid meet">
    
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
<path d="M2313 5096 c-70 -33 -128 -95 -154 -166 l-21 -57 -116 -22 c-355 -66
-682 -208 -964 -419 -138 -102 -272 -226 -368 -339 l-85 -100 -71 -4 c-56 -3
-79 -10 -112 -31 -90 -60 -128 -196 -80 -288 l23 -45 -37 -82 c-95 -211 -162
-440 -194 -668 -21 -146 -24 -456 -5 -595 56 -415 203 -784 442 -1110 18 -25
18 -26 -7 -89 -50 -124 -18 -262 81 -349 87 -76 220 -95 324 -46 l55 25 30
-23 c279 -210 660 -371 1012 -428 l72 -11 21 -59 c43 -117 149 -190 277 -190
89 0 151 21 208 71 43 38 96 126 96 160 0 14 11 19 48 24 97 14 281 58 397 96
152 50 409 176 545 267 529 353 903 924 1006 1533 l18 107 52 16 c29 9 71 34
97 56 138 121 138 339 0 460 -26 22 -68 47 -97 56 l-52 16 -18 107 c-103 612
-476 1177 -1011 1536 -131 88 -389 214 -540 264 -116 38 -300 82 -397 96 -37
5 -48 10 -48 24 0 34 -53 122 -96 160 -57 50 -119 71 -208 71 -54 0 -84 -6
-123 -24z m197 -151 c45 -23 74 -71 74 -125 0 -83 -60 -140 -148 -140 -50 0
-95 30 -121 81 -45 86 24 199 121 199 25 0 58 -7 74 -15z m-340 -265 c19 -46
86 -109 143 -136 62 -29 168 -33 239 -8 57 19 120 72 154 130 14 24 30 44 36
44 5 0 56 -9 111 -21 438 -89 802 -283 1113 -593 134 -134 201 -216 294 -357
74 -112 186 -338 230 -464 36 -104 85 -298 95 -376 l6 -54 -46 -24 c-86 -45
-136 -128 -143 -236 -2 -38 1 -89 7 -112 18 -67 70 -134 129 -168 l53 -30 -7
-54 c-8 -76 -57 -270 -94 -376 -41 -119 -155 -350 -220 -450 -297 -452 -730
-776 -1228 -919 -80 -23 -276 -66 -300 -66 -6 0 -22 20 -36 44 -34 58 -97 111
-154 130 -71 25 -177 21 -239 -8 -57 -27 -124 -90 -143 -136 -7 -16 -17 -30
-22 -30 -6 0 -66 11 -134 25 -287 58 -552 171 -797 338 -81 55 -88 63 -83 86
4 14 9 51 12 82 22 199 -189 375 -379 316 l-47 -15 -53 76 c-196 281 -319 594
-373 945 -24 152 -23 451 0 602 34 216 107 457 187 616 l28 57 64 4 c51 3 74
10 107 33 83 57 120 172 85 265 -9 25 -17 50 -18 57 -3 21 165 203 274 297
289 249 625 416 984 490 153 31 150 32 165 -4z m2600 -1995 c45 -23 74 -71 74
-125 0 -83 -60 -140 -148 -140 -50 0 -95 30 -121 81 -45 86 24 199 121 199 25
0 58 -7 74 -15z m-3851 -1603 c90 -67 91 -180 2 -239 -121 -81 -272 59 -200
186 42 75 135 100 198 53z m1591 -657 c45 -23 74 -71 74 -125 0 -83 -60 -140
-148 -140 -50 0 -95 30 -121 81 -45 86 24 199 121 199 25 0 58 -7 74 -15z"/>
<path d="M2314 4236 c-66 -31 -121 -86 -150 -153 -16 -36 -30 -53 -42 -53 -27
0 -208 -58 -285 -92 -419 -182 -761 -590 -857 -1020 -13 -56 -15 -59 -65 -82
-61 -27 -116 -79 -147 -139 -19 -35 -23 -58 -23 -137 0 -79 4 -102 23 -137 31
-60 86 -112 147 -139 50 -23 52 -26 65 -82 57 -256 220 -536 426 -731 507
-480 1271 -552 1851 -175 82 53 83 53 117 39 109 -46 264 30 296 145 13 48 12
119 -3 154 -12 28 -10 34 32 95 56 84 142 263 175 364 207 638 -44 1358 -599
1722 -148 97 -367 191 -492 211 -38 6 -43 10 -59 53 -24 63 -106 143 -168 164
-74 25 -179 22 -242 -7z m196 -151 c45 -23 74 -71 74 -125 0 -83 -60 -140
-148 -140 -50 0 -95 30 -121 81 -45 86 24 199 121 199 25 0 58 -7 74 -15z
m-342 -258 c26 -58 83 -114 146 -143 63 -29 168 -32 242 -7 59 20 145 101 165
156 15 43 18 43 149 0 179 -59 347 -162 495 -304 250 -242 394 -551 420 -904
19 -260 -71 -583 -226 -811 l-27 -40 -60 4 c-74 5 -117 -8 -169 -53 -58 -49
-87 -122 -79 -197 l6 -59 -63 -40 c-157 -101 -347 -171 -549 -204 -72 -11
-298 -6 -390 10 -120 21 -272 72 -389 131 -316 157 -566 446 -672 775 -42 129
-42 126 -3 139 52 17 122 84 153 148 40 81 40 183 0 264 -31 64 -101 131 -153
148 -39 13 -39 11 7 152 104 319 358 611 662 765 81 40 275 112 304 113 7 0
21 -20 31 -43z m-1058 -1142 c98 -51 98 -199 0 -250 -16 -8 -49 -15 -74 -15
-50 0 -95 30 -121 81 -45 86 24 199 121 199 25 0 58 -7 74 -15z"/>
<path d="M2360 3296 l0 -134 -66 -16 c-64 -16 -158 -59 -199 -90 -19 -14 -25
-10 -118 82 l-97 97 -57 -58 -58 -57 96 -97 96 -98 -33 -54 c-31 -49 -63 -134
-78 -203 l-6 -28 -135 0 -135 0 0 -80 0 -80 135 0 135 0 6 -27 c15 -70 47
-155 78 -204 l33 -54 -96 -98 -96 -97 58 -57 57 -58 97 97 c93 92 99 96 118
82 41 -31 135 -74 199 -90 l66 -16 0 -134 0 -134 80 0 80 0 0 134 0 133 67 17
c66 16 144 49 195 83 l28 18 95 -95 95 -95 57 58 58 57 -95 95 -95 95 18 28
c35 52 78 154 89 208 l11 54 133 0 134 0 0 80 0 80 -134 0 -133 0 -11 54 c-11
54 -54 156 -89 208 l-18 28 95 95 95 95 -58 57 -57 58 -95 -95 -95 -95 -28 18
c-51 34 -129 67 -195 83 l-67 17 0 133 0 134 -80 0 -80 0 0 -134z m272 -339
c133 -65 223 -186 248 -330 32 -184 -70 -376 -247 -464 -67 -33 -86 -38 -168
-41 -114 -5 -181 13 -270 73 -256 171 -259 554 -5 727 91 62 149 79 266 75 93
-2 106 -5 176 -40z"/>
<path d="M3700 4025 l-54 -55 54 -55 c30 -30 59 -55 65 -55 13 0 105 92 105
106 0 10 -101 114 -111 114 -2 0 -29 -25 -59 -55z"/>
<path d="M3910 3815 l-55 -55 58 -58 57 -57 57 57 58 58 -55 55 c-30 30 -57
55 -60 55 -3 0 -30 -25 -60 -55z"/>
</g>
</svg>

    )
}
// fill="#17bf63"
export default UniverseSVG;

