import ExitCrossSVG from "../../../svg/exitCross_svg"
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import { useEffect } from "react";


const createPartOfDescription = (cardsUrl, energiesInner, descriptionZonesInner, gender) => {
  // const cardsUrl = ''

  const isMale = (gender === 'Мужской' || gender === 'Male') && (energiesInner === 3 || energiesInner === 4);
    
    const energyShortDescriptionKey = isMale ? `energy-shortDescription-${energiesInner}_forMan` : `energy-shortDescription-${energiesInner}`;
    const energyShortDescriptionWordsPlusKey = isMale ? `energy-shortDescriptionWordsPlus-${energiesInner}_forMan` : `energy-shortDescriptionWordsPlus-${energiesInner}`;
    const energyShortDescriptionWordsMinusKey = isMale ? `energy-shortDescriptionWordsMinus-${energiesInner}_forMan` : `energy-shortDescriptionWordsMinus-${energiesInner}`;
  
    const descriptionZoneText = t(descriptionZonesInner);
    const descriptionZonePlusMinus = t(descriptionZonesInner + '_plus-minus');

    // const descriptionZoneTextLong = t(`${descriptionZonesInner}-long`);

    const energyShortDescription = t(energyShortDescriptionKey);
    const energyShortDescriptionWordsPlus = t(energyShortDescriptionWordsPlusKey);
    const energyShortDescriptionWordsMinus = t(energyShortDescriptionWordsMinusKey);

    const energyLongDescriptionPlus = t(`energy-longDescriptionPlus-${energiesInner}${isMale ? '_forMan' : ''}`);
    const energyLongDescriptionMinus = t(`energy-longDescriptionMinus-${energiesInner}${isMale ? '_forMan' : ''}`);
    const energyDescriptionAction = t(`energy-Description-${energiesInner}_mustDo${isMale ? '_forMan' : ''}`);
  
    return (
      // каждый контейнер описания
      // <div id={descriptionZonesInner} className="rounded-lg border p-2 flex flex-col gap-3 shadow-inner bg-black">
      <div id={descriptionZonesInner} className="popup_relative_container">
        
        {/* <div className={`w-10 h-10 ${bgColor}`}></div> */}

        <p className="p-3 text-[12px]">{t('zone_plus-minus-instructions')}</p>


        {/* Базовый заголовок - определение что за ЗОНА */}
        <div className={`bg-blue-400 font-extrabold text-white rounded p-4 flex justify-center flex-col relative`}>
          <p className="text-center underline underline-offset-4">{t('zone_title')}</p>
          <p className="text-center">{descriptionZoneText}</p>
          
          {descriptionZonePlusMinus === '+' ? 
                    <div className="flex justify-end">                    
                      <p className="absolute sm:relative w-fit bg-green-600 -bottom-3 right-0 rounded-full shadow border-green-300 border-2 p-1 pl-2 pr-2 text-[10px]">{t('zone_in_plus')}</p>
                    </div>
                    :
                    <div className="flex justify-end">                    
                      <p className="absolute sm:relative w-fit bg-red-600 -bottom-3 right-0 rounded-full shadow border-re-300 border-2 p-1 pl-2 pr-2 text-[10px]">{t('zone_in_minus')}</p>
                    </div>
          }
        </div>

       
        <div className="flex flex-col">
          
          <div className="flex items-center justify-center relative gap-2 overflow-hidden">
            {/* Анимационный квадрат круг */}
            <div className="w-[250px] h-[250px] max-w-[300px] max-h-[300px] absolute  bg-gradient-to-r from-cyan-300 to-blue-300 shadow-inside animation_bounce_ball"></div>

            <div className="flex flex-col">
              {/* Ваша энергия (Аркан) */}
              <p className="text-[18px] sm:text-[14px] z-[1] mix-blend-multiply drop-shadow-sm text-blue-400 pl-2">{t('energy-yours')}</p>
              {/* Название */}
              <p className='text-[18px] sm:text-[14px] z-10  pl-2'>{energyShortDescription}</p>
              {/* цифра */}
              <p className="absolute sm:-top-[20px] sm:left-0 left-[30px] -top-12 sm:text-[200px] text-[300px] text-orange-500 stoke-2 opacity-50 font-extrabold pl-2">{energiesInner}</p>
            </div>
            
            {/* Карта картинка */}
            <div className="flex justify-center  z-10">
              <div className="taro_style_upper w-[200px] sm:w-[150px] "><img className="taro_style dance_button" src={`${cardsUrl}/${energiesInner}.jpg`} alt="cards"/></div>
            </div>
            
          </div>


          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-5 bg-gradient-to-tr from-[#a1ffce] to-[#f9ffd1] rounded-lg justify-center text-black">
              <p className="text-[50px] ">+</p><p>{t('energy-in-positive')}</p>
            </div>
            <p className="bg-gradient-to-tr from-[#a1ffce] to-[#f9ffd1] rounded w-fit text-black p-1">{t('energy-short-title')}:</p>
            <p id={`shortDescriptionWordsPlus-${energiesInner}`} className="paragraph_tag_description p-2 pl-2">
                  {energyShortDescriptionWordsPlus}
            </p>
            <p className="bg-gradient-to-tr from-[#a1ffce] to-[#f9ffd1] rounded w-fit text-black p-1">{t('energy-long-title')}:</p>
            <div id={`longDescriptionPlus-${energiesInner}`} className="p-2">
                    {energyLongDescriptionPlus}
            </div>
          </div>
          
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-5 bg-gradient-to-tr from-[#d299c2] to-[#fef9d7] rounded-lg justify-center text-black">
              <p className="text-[50px] pb-1">-</p><p>{t('energy-in-negative')}</p> 
            </div>

              <p className="bg-gradient-to-tr from-[#d299c2] to-[#fef9d7] rounded w-fit text-black p-1">{t('energy-short-title')}:</p>
              <p id={`shortDescriptionWordsMinus-${energiesInner}`} className=" paragraph_tag_description p-2 pl-2 rounded-none">
                {energyShortDescriptionWordsMinus}
              </p>
              {/* <div className="flex items-center gap-5 bg-gradient-to-tr from-[#00ffb3] to-[#00f2ff] rounded-lg justify-center"> */}

              <p className="bg-gradient-to-tr from-[#d299c2] to-[#fef9d7] rounded w-fit text-black p-1">{t('energy-long-title')}:</p>
              <div id={`longDescriptionMinus-${energiesInner}`} className="p-2">
                      {energyLongDescriptionMinus}
              </div>
           </div>
          

          {/* практическая проработка заголовок  */}
          <div className="flex items-center gap-5 bg-gradient-to-tr from-[#00ffb3] to-[#00f2ff] rounded-lg justify-center text-black">
              <p className="p-1">{t('energy-goToPlus')}:</p> 
          </div>
          {/* сам текст практической проработки */}
          <p className="paragraph_tag_description p-2 pl-2">{energyDescriptionAction}</p>

        </div>
       
      </div>
    );
  };

const MatrixDescriptionEveryEnergyPopup = ({close, additionInfo}) => {

    // эти три входящие обязательны!!!!!
    const [zoneName, energy, gender] = additionInfo;

    const cardsUrl = useSelector(state => state.optionsSlice.cardStyleUrl)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    return (    
            // Основа
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">
                {/* Основа */}

                  {/* ЗАГОЛОВОК */}
                  <p className="popup_title">{t('profile-bigDescriptionBorder')}</p>

                  <div className="flex w-full flex-col gap-5">
                    {createPartOfDescription(cardsUrl, energy, zoneName, gender)}
                  </div>
                

                {/* Основа */}
                </div>
                <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
            </div>
          </div>
)}

export default MatrixDescriptionEveryEnergyPopup;