const SendMessageSVG = ({className}) => {
    return (
        <svg
        className={className}   
        width="1.5em" height="1.5em"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0}

        version="1.0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        // fill="#000000" stroke="none"
        >
        <path d="M167 5106 c-20 -8 -43 -19 -51 -25 -26 -22 -46 -79 -46 -130 0 -42
        421 -1956 432 -1966 3 -3 2043 -357 2291 -398 70 -12 127 -24 127 -27 0 -3
        -57 -15 -127 -27 -249 -41 -2288 -395 -2291 -398 -11 -10 -432 -1924 -432
        -1966 0 -118 88 -189 199 -159 51 14 4698 2395 4729 2424 37 33 52 70 52 126
        0 56 -15 93 -52 126 -29 27 -4675 2410 -4726 2424 -47 13 -58 12 -105 -4z"/>
        </g>
        </svg>

    )
}

export default SendMessageSVG;
