import { useDispatch } from "react-redux";
import { updateProfileUserInfo } from "../../store/profileUserInfoSlice"
import ProfileBaseDetails from "../profile_components/profile_base_details";
import { updateProfileAction } from "../../store/profileUserInfoSlice";
import ProfileMatrix from "../profile_components/profile_matrix";
// import ProfileRelationship from "../profile_components/profile_relationship_matr";
// import ProfileAboutMe from "../profile_components/profile_about_me";
import ProfileMoodStatus from "../../pages/page_mood"
import { t } from "i18next";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseBackendUrl } from "../websocket_comp";
import { useSelector } from "react-redux";
import Spinner from "../spinner_loading";
import PifagorSquare from "./profile_pifagor_square";
import Zodiac from "../../pages/page_zodiac";
import { matrixCalculationFunc } from "../calculations/profile_matrix_calculation";
import { updateListOfEnergiesAction } from "../../store/profileUserInfoSlice";
import { EveryDayInfluence } from "./profile_influence";

const Profile = ({ userLink }) => {

    const dispatch = useDispatch();

    const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    const [selectedComponent, setSelectedComponent] = useState(localStorage.getItem('selectedProfileComponent') || '');

    const [profileData, setProfileData] = useState(null);
    const [moodNotification, setMoodNotification] = useState(false);


    // POPUPS
    // const [createPostPopupOpen, setCreatePostPopupOpen] = useState(false);

    const todayDate = new Date()
    const todayOnlyDate = `${todayDate.getFullYear()}-${(todayDate.getMonth() + 1).toString().padStart(2, '0')}-${todayDate.getDate().toString().padStart(2, '0')}`

    const getProfileInfo = async () => {
        const data = { authorizedUser: authorizedUser.username };
        const url = `${baseBackendUrl}/${userLink}`;
        const response = await axios.post(url, data);

        // Здесь запросили инфо о профиле и записали его в стор
        setProfileData(response.data);
        dispatch(updateProfileUserInfo(response.data))

        // Получаем статус календаря настроения
        setMoodNotification(response.data.mood_notification.date === todayOnlyDate ? false : true)

        // калькулируем матрицу для пользователя к которому зашли на страницу
        const birthDay = response.data.user.birth_date

        // записываем матрицу для всех профилей тут
        const listOfMatrixEnergies = matrixCalculationFunc(birthDay)
        dispatch(updateListOfEnergiesAction(listOfMatrixEnergies))
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getProfileInfo(); }, [updateProfile, userLink])

    const plusVisit = async () => {
        dispatch(updateProfileAction(!updateProfile))
        const url = `${baseBackendUrl}/${userLink}/plus_visit`;
        const data = { authorizedUser: authorizedUser.username };
        await axios.post(url, data);
    }

    useEffect(() => {
        if (userLink !== authorizedUser.username) {

            if (localStorage.getItem('nowProfile') !== userLink) {
                plusVisit()
                localStorage.setItem('nowProfile', userLink)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (!profileData || !profileData.user) { return <Spinner /> }

    const handleComponentChange = (component) => {
        if (selectedComponent === component) {
            setSelectedComponent('')
            localStorage.setItem('selectedProfileComponent', '')
        } else {
            setSelectedComponent(component);
            localStorage.setItem('selectedProfileComponent', component)
        }
        // selectedComponent === component ?  setSelectedComponent('') :  setSelectedComponent(component);
    };


    // const profileMenuButtons = () => {
    //     return (
    //         <div className="flex flex-col gap-2">

    //             <div className="br_border_line_gray" />

    {/* <div className="flex justify-center gap-1 pl-2 pr-2"> */ }
    {/* <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] ${selectedComponent === 'matrix' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('matrix')}>{t('profile-matrixButton')}</button> */ }
    {/* <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] ${selectedComponent === 'aboutMe' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('aboutMe')}>{t('profile-aboutMeButton')}</button> */ }

    {/* <div className="relative flex-1">
                        {moodNotification ? <div className="absolute -top-1 right-0 w-[10px] h-[10px] rounded-full bg-red-500 border-[1px] border_color_rounded_elements z-[1]" /> : null}
                        <button className={`button_blue  !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] ${selectedComponent === 'moodStatus' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('moodStatus')}>{t('profile-moodStatusButton')}</button>
                    </div> */}

    {/* <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] !leading-[14px] ${selectedComponent === 'pifagor' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('pifagor')}>{t('profile-pifagorButton')}</button> */ }
    {/* <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] ${selectedComponent === 'zodiac' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('zodiac')}>{t('profile-zodiacButton')}</button> */ }
    {/* </div> */ }

    //             <div className="br_border_line_gray" />

    //         </div>
    //     )
    // }

    // const profileMenu = () => {
    //     return (
    //         <div id="profile_description_block" className="flex flex-col sm:items-center gap-5">

    {/* Отделяющая линия от верхнего блока */ }
    {/* <div className="br_border_line_gray" /> */ }

    {/* {selectedComponent === 'aboutMe' &&
                    <>
                        <ProfileAboutMe />
                        {userBio ? <div className="br_border_line_gray" /> : null}
                        <ProfileRelationship />
                    </>
                } */}

    {/* {selectedComponent === 'matrix' && <ProfileMatrix />} */ }
    {/* {selectedComponent === 'relationship' && <ProfileRelationship />} */ }
    {/* {selectedComponent === 'moodStatus' && <ProfileMoodStatus setMoodNotificationInner={setMoodNotification} />} */ }
    {/* {selectedComponent === 'pifagor' && <PifagorSquare />} */ }
    {/* {selectedComponent === 'zodiac' && <Zodiac />} */ }
    //         </div>
    //     )
    // }

    return (
        <div className="flex flex-col gap-2">

            {/* Кнопка выхода
            <div className="flex justify-end">
                {username === authorizedUser.username ? <ButtonPilotRed checked={logoutButtonChecked} setChecket={setLogoutButtonChecked} /> : null}
            </div> */}

            <div className="main_white_container w-[800px] sm:w-[100dvw] !p-0">

                <div className="flex flex-col">
                    <ProfileBaseDetails />


                    {/* {profileMenuButtons()} */}
                    {/* {profileMenu()} */}
                </div>
            </div>

            <div className="main_white_container w-[800px] sm:w-[100dvw] !p-0">
                <EveryDayInfluence />
            </div>
        </div>

    )
}

export default Profile;