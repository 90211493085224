import { useState, useEffect } from "react"
import { useSelector } from "react-redux";

const RatingSmiles = ({ value = 0, setSelectedSmileOutside, textInner = '', blockContent=false }) => {

    const profileUser = useSelector(state => state.profileUserSlice.profileUser);
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser);

    const user = profileUser.user
    const username = user.username

    const [selectedSmile, setSelectedSmile] = useState(value)

    useEffect(() => {
        setSelectedSmile(value);
    }, [value]);

    const handleClickOnSmile = (e) => {
        if (authorizedUser.username === username) {

            const number = Number(e.target.alt)
            setSelectedSmile(number)

            try {
                setSelectedSmileOutside(number)
            } catch {
                console.log("can't return selected smile")
            }
        }
    }

    return (
        <>
            <div className="p-2 select-none touch_screen_non_selectable">
                <p className="flex">{textInner}:</p>

                <ul className="flex p-1 gap-1"
                    onClick={(e) => handleClickOnSmile(e)}
                    onTouchStart={(e) => handleClickOnSmile(e)}
                >

                    <li className={`w-10 h-10  ${selectedSmile === 1 ? 'opacity-100' : 'opacity-30'} duration-300 ${authorizedUser.username === username && !blockContent ? 'hover:opacity-90 cursor-pointer' : null}`}>
                        <img src="/images/rating_smiles/1.png" alt="1"></img>
                    </li>

                    <li className={`w-10 h-10  ${selectedSmile === 2 ? 'opacity-100' : 'opacity-30'} duration-300 ${authorizedUser.username === username && !blockContent  ? 'hover:opacity-90 cursor-pointer' : null}`}>
                        <img src="/images/rating_smiles/2.png" alt="2"></img>
                    </li>

                    <li className={`w-10 h-10  ${selectedSmile === 3 ? 'opacity-100' : 'opacity-30'} duration-300 ${authorizedUser.username === username && !blockContent  ? 'hover:opacity-90 cursor-pointer' : null}`}>
                        <img src="/images/rating_smiles/3.png" alt="3"></img>
                    </li>

                    <li className={`w-10 h-10  ${selectedSmile === 4 ? 'opacity-100' : 'opacity-30'} duration-300 ${authorizedUser.username === username && !blockContent  ? 'hover:opacity-90 cursor-pointer' : null}`}>
                        <img src="/images/rating_smiles/4.png" alt="4"></img>
                    </li>

                    <li className={`w-10 h-10  ${selectedSmile === 5 ? 'opacity-100' : 'opacity-30'} duration-300 ${authorizedUser.username === username && !blockContent  ? 'hover:opacity-90 cursor-pointer' : null}`}>
                        <img src="/images/rating_smiles/5.png" alt="5"></img>
                    </li>

                </ul>
            </div>
        </>
    )
}

export default RatingSmiles