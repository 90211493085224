import ExitCrossSVG from "../../../svg/exitCross_svg"
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const createPartOfDescription = (cardsUrl, energiesInner, bgColor, descriptionZonesInner, index, gender) => {
  // const cardsUrl = ''
  const isMale = (gender === 'Мужской' || gender === 'Male') && (energiesInner === 3 || energiesInner === 4);

  const energyShortDescriptionKey = isMale ? `energy-shortDescription-${energiesInner}_forMan` : `energy-shortDescription-${energiesInner}`;
  const energyShortDescriptionWordsPlusKey = isMale ? `energy-shortDescriptionWordsPlus-${energiesInner}_forMan` : `energy-shortDescriptionWordsPlus-${energiesInner}`;
  const energyShortDescriptionWordsMinusKey = isMale ? `energy-shortDescriptionWordsMinus-${energiesInner}_forMan` : `energy-shortDescriptionWordsMinus-${energiesInner}`;

  const descriptionZoneText = t(descriptionZonesInner);
  const descriptionZonePlusMinus = t(descriptionZonesInner + '_plus-minus');
  // const descriptionZoneTextLong = t(`${descriptionZonesInner}-long`);

  const energyShortDescription = t(energyShortDescriptionKey);
  const energyShortDescriptionWordsPlus = t(energyShortDescriptionWordsPlusKey);
  const energyShortDescriptionWordsMinus = t(energyShortDescriptionWordsMinusKey);

  const energyLongDescriptionPlus = t(`energy-longDescriptionPlus-${energiesInner}${isMale ? '_forMan' : ''}`);
  const energyLongDescriptionMinus = t(`energy-longDescriptionMinus-${energiesInner}${isMale ? '_forMan' : ''}`);
  const energyDescriptionAction = t(`energy-Description-${energiesInner}_mustDo${isMale ? '_forMan' : ''}`);

  return (
    // каждый контейнер описания
    <div key={index} id={descriptionZonesInner} className="popup_relative_container">

      {/* <div className={`w-10 h-10 ${bgColor}`}></div> */}
      {/* Базовый заголовок - определение что за ЗОНА */}
      <div className={`bg-blue-400 font-extrabold text-white rounded p-4 flex justify-center flex-col relative w-full`}>
        <p className="text-center underline underline-offset-4">{t('zone_title')}</p>
        <p className="text-center">{descriptionZoneText}</p>

        {descriptionZonePlusMinus === '+' ?
          <div className="flex justify-end">
            <p className="z-[20] absolute sm:relative w-fit bg-green-600 -bottom-3 right-0 rounded-full shadow border-green-300 border-2 p-1 pl-2 pr-2 text-[10px]">{t('zone_in_plus')}</p>
          </div>
          :
          <div className="flex justify-end">
            <p className="z-[20] absolute sm:relative w-fit bg-red-600 -bottom-3 right-0 rounded-full shadow border-re-300 border-2 p-1 pl-2 pr-2 text-[10px]">{t('zone_in_minus')}</p>
          </div>
        }
      </div>

      <div className="flex flex-col">

        <div className="flex items-center justify-center relative gap-2 overflow-hidden">
          {/* круг-квадрат анимационный? */}
          <div className="w-[250px] h-[250px] max-w-[300px] max-h-[300px] absolute  bg-gradient-to-r from-cyan-300 to-blue-300 shadow-inside animation_bounce_ball"></div>

          <div className="flex flex-col">
            <p className="text-[18px] sm:text-[14px] z-[1] mix-blend-multiply drop-shadow-sm text-blue-400">{t('energy-yours')}</p>
            <p className='text-[18px] sm:text-[14px] z-10'>{energyShortDescription}</p>
            {/* цифра */}
            <p className="absolute sm:-top-[60px] sm:left-[50px] left-[30px] -top-4 text-[250px] text-orange-500 stoke-2 opacity-50 font-extrabold">{energiesInner}</p>
          </div>

          <div className="flex justify-center  z-10">
            <div className="taro_style_upper w-[200px] sm:w-[150px] "><img className="taro_style dance_button" src={`${cardsUrl}/${energiesInner}.jpg`} alt="cards" /></div>
          </div>

        </div>


        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-5 bg-gradient-to-tr from-[#a1ffce] to-[#f9ffd1] rounded-lg justify-center text-black">
            <p className="text-[50px]">+</p><p>{t('energy-in-positive')}</p>
          </div>
          <p className="bg-gradient-to-tr from-[#a1ffce] to-[#f9ffd1] rounded w-fit text-black">{t('energy-short-title')}:</p>
          <p id={`shortDescriptionWordsPlus-${energiesInner}`} className="paragraph_tag_description p-2 pl-2">
            {energyShortDescriptionWordsPlus}
          </p>
          <p className="bg-gradient-to-tr from-[#a1ffce] to-[#f9ffd1] rounded w-fit text-black">{t('energy-long-title')}:</p>
          <div id={`longDescriptionPlus-${energiesInner}`} className="p-2">
            {energyLongDescriptionPlus}
          </div>
        </div>

        <div className="flex flex-col  gap-2">
          <div className="flex items-center gap-5 bg-gradient-to-tr from-[#d299c2] to-[#fef9d7] rounded-lg justify-center text-black">
            <p className="text-[50px] pb-1">-</p><p>{t('energy-in-negative')}</p>
          </div>

          <p className="bg-gradient-to-tr from-[#d299c2] to-[#fef9d7] rounded w-fit text-black">{t('energy-short-title')}:</p>
          <p id={`shortDescriptionWordsMinus-${energiesInner}`} className=" paragraph_tag_description p-2 pl-2 rounded-none">
            {energyShortDescriptionWordsMinus}
          </p>
          {/* <div className="flex items-center gap-5 bg-gradient-to-tr from-[#00ffb3] to-[#00f2ff] rounded-lg justify-center"> */}

          <p className="bg-gradient-to-tr from-[#d299c2] to-[#fef9d7] rounded w-fit text-black">{t('energy-long-title')}:</p>
          <div id={`longDescriptionMinus-${energiesInner}`} className="p-2">
            {energyLongDescriptionMinus}
          </div>
        </div>


        {/* практическая проработка заголовок  */}
        <div className="flex items-center gap-5 bg-gradient-to-tr from-[#00ffb3] to-[#00f2ff] rounded-lg justify-center text-black">
          <p>{t('energy-goToPlus')}:</p>
        </div>
        {/* сам текст практической проработки */}
        <p className="paragraph_tag_description p-2 pl-2">{energyDescriptionAction}</p>

      </div>

    </div>
  );
};

const MatrixDescriptionPopup = ({ close, additionInfo }) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
  }, [])

  const handleClose = () => {
    dispatch(updateDisplayNonOverflow(false))
    close()
  }

  const cardsUrl = useSelector(state => state.optionsSlice.cardStyleUrl)

  const profileData = useSelector(state => state.profileUserSlice.profileUser)

  const user = profileData.user
  const gender = user.gender

  const matrixList = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
  const listOfEnergies = matrixList[0]
  const descriptionEnergyColors = matrixList[1]
  const descriptionZones = matrixList[2]


  const matrixDescription = () => {
    const descriptionElement = []
    for (let i = 0; i < 22; i++) {
      descriptionElement.push(createPartOfDescription(cardsUrl, listOfEnergies[i], descriptionEnergyColors[i], descriptionZones[i], i, gender))
    }
    return descriptionElement;
  }

  return (
    // Основа
    <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
      <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">
        
        <div className="exit_cross_div" onClick={handleClose}>
          <ExitCrossSVG className="exit_cross_svg" />
        </div>

        <div className="flex w-full flex-col items-center overflow-auto">
          {/* Основа */}

          {/* ЗАГОЛОВОК */}
          <div className="popup_title">
            {t('profile-bigDescriptionBorder')}
          </div>

          <div className="flex w-full flex-col gap-5">

            <p className="p-3 text-[12px]">{t('zone_plus-minus-instructions')}</p>
            {matrixDescription()}

          </div>


          {/* Основа */}
        </div>
        <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
      </div>
    </div>
  )
}

export default MatrixDescriptionPopup;