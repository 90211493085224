import axios from 'axios'
import { baseBackendUrl } from '../little_components/websocket_comp';
import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { formattedDate } from "../little_components/services";
// import { useSelector } from 'react-redux';
import { formattedDate } from '../little_components/services';
import PopupContainer from '../little_components/popups_root';
import { useSelector } from "react-redux";
import { t } from "i18next";
import CommentFillSVG from '../../svg/comment_fill_svg';
// import { updateChatColorStatus } from '../store/wsChatSlice';
// import { firebaseRequestPermission, sendPushNotification } from '../little_components/firebase_settings';

const userCard = ({ message_date, username, profile_pic, first_name, last_name, birth_date, is_online, last_time_online, moodStatusBase, nonReadMessages, authorizedUser, setCurrentChatUser, currentChatUser, onlineUsers, setMessagesPopupOpen, messagesPopupOpen }) => {
    const moodStatusTemp = moodStatusBase.find(item => item.username === username)
    const moodStatus = moodStatusTemp?.mood_status
    const moodClarification = moodStatusTemp?.mood_status_clarification
    const moodOwnOption = moodStatusTemp?.mood_status_own_option

    const isOnline = Array.isArray(onlineUsers?.usersOnline) ? onlineUsers.usersOnline.includes(username) : null;

    const unreadMessagesToMe = nonReadMessages?.find(message => {
        if (message.username === username && message.authorized_user === authorizedUser.username) {
            return message.authorized_user_message_been_read
        } else if (message.username === authorizedUser.username && message.authorized_user === username) {
            return message.user_message_been_read
        }
        // Вернуть значение по умолчанию, если ни одно из условий не выполняется
        return false;
    })

    const mineUnreadMessages = nonReadMessages?.find(message => {
        if (message.username === username && message.authorized_user === authorizedUser.username) {
            return message.user_message_been_read
        } else if (message.username === authorizedUser.username && message.authorized_user === username) {
            return message.authorized_user_message_been_read
        }
        // Вернуть значение по умолчанию, если ни одно из условий не выполняется
        return false;
    })

    const lastTimeOnlineSec = (new Date() - new Date(last_time_online)) / 1000
    const lastTimeOnlineMin = lastTimeOnlineSec / 60
    const lastTimeOnlineHours = lastTimeOnlineMin / 60
    const lastTimeOnlineDay = lastTimeOnlineHours / 24
    const lastTimeOnlineWeek = lastTimeOnlineDay / 7
    const lastTimeOnlineMonth = lastTimeOnlineWeek / 4
    const lastTimeOnlineYear = lastTimeOnlineMonth / 12

    let lastTimeOnline = 0

    if (lastTimeOnlineSec < 60) {
        lastTimeOnline = Math.floor(lastTimeOnlineSec) + `${t('lastTimeOnline-sec')}`
    } else {
        if (lastTimeOnlineMin < 60) {
            lastTimeOnline = Math.floor(lastTimeOnlineMin) + `${t('lastTimeOnline-min')}`
        } else {
            if (lastTimeOnlineHours < 24) {
                lastTimeOnline = Math.floor(lastTimeOnlineHours) + `${t('lastTimeOnline-hour')}`
            } else {
                if (lastTimeOnlineDay < 7) {
                    lastTimeOnline = Math.floor(lastTimeOnlineDay) + `${t('lastTimeOnline-day')}`
                } else {
                    if (lastTimeOnlineWeek < 4) {
                        lastTimeOnline = Math.floor(lastTimeOnlineWeek) + `${t('lastTimeOnline-week')}`
                    } else {
                        if (lastTimeOnlineMonth < 12) {
                            lastTimeOnline = Math.floor(lastTimeOnlineMonth) + `${t('lastTimeOnline-month')}`
                        } else {
                            lastTimeOnline = Math.floor(lastTimeOnlineYear) + `${t('lastTimeOnline-year')}`
                        }
                    }
                }
            }
        }
    }

    const handleChatUser = (username) => {
        setCurrentChatUser(username)
        setMessagesPopupOpen(!messagesPopupOpen)
    }

    return (
        <div className={`navigation-bg p-3 sm:p-2 border-[1px] rounded-xl border_color shadow-md`}>

            <div className='flex flex-col gap-2 cursor-pointer' onClick={() => setCurrentChatUser(username)}>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center gap-2'>

                        {/* аватарка */}
                        <div className='relative hover:cursor-pointer'>
                            <div className="button_ava_posts border-2 border_color_rounded_elements bg-cover bg-no-repeat bg-center w-[50px] h-[50px] rounded-full" style={{ backgroundImage: `url(${baseBackendUrl + profile_pic})` }}></div>
                            {isOnline
                                ? <div className='absolute top-[38px] left-[2px] h-[12px] w-[12px] rounded-full bg-green-500 border-[2px] border_color_rounded_elements' />
                                :
                                <div className="z-10 absolute sm:top-[-15px] sm:-left-2 sm:h-[14px] top-[-22px] -left-2 h-5 pl-2 pr-2  rounded-full backdrop-blur-sm backdrop-brightness-[0.6] border-2 border_color_rounded_elements">
                                    <p className="flex w-full whitespace-nowrap sm:text-[8px] text-[12px] -m-[1px] text-white">{lastTimeOnline}</p>
                                </div>
                            }

                        </div>

                        {/* имя */}
                        <div className='flex gap-1'>
                            <p>{first_name}</p>
                            <p>{last_name}</p>

                            {/* статус прочтения сообщения */}
                            <div className='w-full h-full relative'>
                                {unreadMessagesToMe
                                    ? <div className='absolute top-[3px] w-2 h-2 bg-blue-500 rounded-full border border_color_rounded_elements'></div>
                                    : <div className='absolute top-[3px] w-2 h-2 bg-red-500 rounded-full border border_color_rounded_elements'></div>
                                }
                                {mineUnreadMessages
                                    ? <div className='absolute left-1 w-2 h-2 bg-blue-500 rounded-full border border_color_rounded_elements'></div>
                                    : <div className='absolute left-1 w-2 h-2 bg-orange-500 rounded-full border border_color_rounded_elements'></div>
                                }
                            </div>

                        </div>
                    </div>

                    {/* КНОПКА СООБЩЕНИЯ */}
                    <div className='w-fitflex justify-end p-1'>

                        <button className="button_blue_rounded !w-fit !h-7 !pt-0 pb-0"
                            onClick={() => handleChatUser(username)}
                        >
                            <CommentFillSVG className={'lg:hidden w-4 fill-slate-200'} />
                            <p className='sm:hidden'>{t('profile-messages-sendMessage')}</p>
                        </button>
                    </div>
                </div>






                {/* НАСТРОЕНИЕ */}
                <div className="w-fit flex flex-col gap-1">
                    {!moodStatus || moodStatus === 'status_empty'
                        ? null
                        : <p className={`rounded-full bg_bright_blue text_on_bright_plate w-[200px] h-fit pl-2 pr-2 text-[10px] text-center ${moodStatus === 'status_need_help' ? '!bg-red-500' : null}`}>{t(`profile-${moodStatus}`)}</p>
                    }

                    {/* Поясняющий статус настроения */}
                    {
                        !moodClarification || moodClarification === 'profile-clarification_my_option'
                            ? null
                            : <p className='rounded-full bg_bright_blue text_on_bright_plate w-full h-fit  pl-2 pr-2 text-[10px] text-center'>{t(`${moodClarification}`)}</p>
                    }

                    {
                        moodClarification === 'profile-clarification_my_option' && moodOwnOption
                            ? <p className='rounded-full bg_bright_blue text_on_bright_plate w-full h-fit  pl-2 pr-2 text-[10px] text-center'>{t(`${moodOwnOption}`)}</p>
                            : null
                    }

                </div>

            </div>

            {currentChatUser === username ? (
                <PopupContainer choosePopup={'messages_popup'} setPopupOpen={setCurrentChatUser}
                    additionInfo={
                        [username, authorizedUser, first_name, last_name, profile_pic, isOnline, currentChatUser ? true : false, mineUnreadMessages, unreadMessagesToMe]
                    }
                />
            ) : null}


            <div className='w-full h-full flex justify-end items-end text-[11px] gap-2 text-gray-400'>
                <p>{t('friends-last_time_message')}</p>
                <p>{formattedDate(message_date.date)}</p>
                {/* <p className=''>{last_time_online === null ? 'online' : formattedDate(last_time_online)}</p> */}
            </div>


        </div>
    )
}

const MessagesPage = () => {
    const [usersInHistory, setUsersInHistory] = useState([]);
    const [moodStatusBase, setMoodStatusBase] = useState([]);
    const [lastMessagesHistory, setLastMessagesHistory] = useState([]);
    const [currentChatUser, setCurrentChatUser] = useState(null);
    const [nonReadMessages, setNonReadMessages] = useState([]);

    const [messagesPopupOpen, setMessagesPopupOpen] = useState(false)


    const onlineUsers = useSelector(state => state.wsOnlineStatus.onlineUsers)
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    // const chatColorStatusInner = useSelector(state => state.wsChatStatus.chatColorStatus)

    const getMessagesHistory = async () => {

        const data = { authorizedUser: authorizedUser.username };
        const url = `${baseBackendUrl}/get_messages_history`;
        const response = await axios.post(url, data);

        setUsersInHistory(response.data.user)
        setMoodStatusBase(response.data.mood_status_data)
        setLastMessagesHistory(response.data.history)
        fetchData()
    };

    const fetchData = async () => {
        const url = `${baseBackendUrl}/check_navigation`;
        const data = { 'username': authorizedUser.username }
        const response = await axios.post(url, data);
        setNonReadMessages(response.data.non_read_history_messages);
    };

    useEffect(() => {
        // console.log(currentChatUser, messagesPopupOpen)
        getMessagesHistory()
        
        window.scrollTo({
                top: 0,
                behavior: 'smooth', // Добавляет плавное прокручивание (поддерживается не во всех браузерах)
            });

        // eslint-disable-next-line
    }, [currentChatUser, messagesPopupOpen,
        // chatColorStatusInner
    ])

    // FIREBASE
    // useEffect(() => {
    //     firebaseRequestPermission()

    //     sendPushNotification()
    // }, [])


    // Функция сравнения для сортировки по убыванию даты
    const compareDatesDescending = (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        // Сравнение дат в обратном порядке
        if (dateA > dateB) {
            return -1;
        } else if (dateA < dateB) {
            return 1;
        } else {
            return 0;
        }
    };

    // Сортировка списка объектов по дате
    lastMessagesHistory.sort(compareDatesDescending);

    return (
        <div className=' sm:touch-pan-y flex flex-col sm:gap-2 gap-3 !p-2 w-[800px] sm:w-screen sm:pb-20 h-[100%] sm:h-[100dvh] overflow-auto'>
            {lastMessagesHistory?.length ? (
                lastMessagesHistory.map((message, index) => {
                    const user = usersInHistory.find(user => user.username === message.username || user.username === message.authorized_user);

                    if (user) {
                        return (
                            <div key={index}>
                                {userCard({
                                    message_date: message, username: user.username, profile_pic: user.profile_pic,
                                    first_name: user.first_name, last_name: user.last_name, birth_date: user.birth_date,
                                    is_online: user.is_online, last_time_online: user.last_time_online, mood_status: user.mood_status,
                                    moodStatusBase, nonReadMessages, authorizedUser, setCurrentChatUser, currentChatUser, onlineUsers,
                                    setMessagesPopupOpen, messagesPopupOpen

                                })}
                            </div>
                        )
                    }
                    return null; // Если не найден соответствующий пользователь, пропускаем
                }

                ))
                :
                <div className='flex items-center justify-center h-full'>
                    <p>{t('message_page-zeroMessages')}</p>
                </div>
            }

        </div>
    )
}

export default MessagesPage;