const PostsSVG = ({className}) => {
    return (
        <svg
        className={className} 
        width="1.5em" height="1.5em"
        fill="currentColor"
        // stroke="currentColor"
        version="1.0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        >
        <path d="M2440 5101 c-213 -57 -358 -233 -377 -457 l-6 -71 -51 -13 c-161 -41
        -362 -122 -407 -164 -77 -73 -52 -201 48 -242 49 -21 62 -18 211 40 75 29 178
        64 228 77 l92 23 53 -48 c167 -150 417 -170 605 -48 32 22 68 49 78 60 11 12
        25 22 31 22 33 0 203 -57 318 -106 70 -29 141 -54 158 -54 50 0 106 38 130 87
        35 72 13 140 -59 189 -45 31 -231 107 -338 139 l-90 27 -1 68 c-3 222 -157
        414 -376 471 -87 23 -162 23 -247 0z"/>
        <path d="M2450 3834 c-573 -54 -1036 -475 -1147 -1043 -22 -116 -22 -346 0
        -462 102 -525 501 -924 1026 -1026 116 -22 346 -22 462 0 470 92 839 419 988
        876 74 228 74 534 0 762 -148 453 -521 787 -979 873 -82 16 -275 27 -350 20z"/>
        <path d="M800 3559 c-37 -15 -69 -49 -96 -101 -36 -72 -100 -235 -124 -319
        l-22 -75 -68 -1 c-222 -3 -414 -157 -471 -376 -23 -87 -24 -151 -3 -236 54
        -218 232 -368 460 -388 l71 -6 13 -51 c43 -167 120 -357 164 -404 34 -36 93
        -56 137 -47 59 13 119 86 119 145 0 11 -24 82 -54 158 -29 75 -64 178 -77 228
        l-23 92 48 53 c150 167 170 417 48 605 -22 32 -49 68 -60 78 -12 11 -22 25
        -22 31 0 33 57 203 106 318 29 70 54 141 54 157 0 98 -111 174 -200 139z"/>
        <path d="M4235 3556 c-52 -23 -95 -85 -95 -137 0 -11 24 -81 54 -157 29 -75
        64 -178 77 -228 l23 -92 -48 -53 c-150 -167 -170 -417 -48 -605 22 -32 49 -68
        60 -78 12 -11 22 -25 22 -31 0 -33 -57 -203 -106 -318 -29 -70 -54 -141 -54
        -157 0 -85 87 -159 170 -145 52 9 94 45 125 105 38 74 101 237 125 321 l22 75
        68 1 c225 4 417 160 475 388 19 76 19 142 -1 224 -54 218 -232 368 -460 388
        l-71 6 -13 51 c-34 135 -113 341 -151 394 -21 29 -85 62 -119 62 -14 -1 -38
        -7 -55 -14z"/>
        <path d="M1629 980 c-48 -25 -84 -96 -75 -148 7 -43 31 -79 74 -108 45 -31
        231 -107 337 -139 l90 -27 1 -67 c4 -222 158 -415 377 -472 87 -23 151 -24
        236 -3 218 54 368 232 388 460 l6 71 51 13 c161 41 362 122 407 164 77 73 52
        201 -48 242 -49 21 -62 18 -211 -40 -75 -29 -178 -64 -228 -77 l-92 -23 -53
        48 c-167 150 -417 170 -605 48 -32 -22 -68 -49 -78 -60 -11 -12 -25 -22 -31
        -22 -33 0 -203 57 -318 106 -137 58 -172 64 -228 34z"/>
        </g>
        </svg>


    )
}

export default PostsSVG;
