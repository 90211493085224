import ExitCrossSVG from "../../../svg/exitCross_svg";
import { baseBackendUrl } from "../websocket_comp";
import axios from "axios";
import MatrixVisual from "../profile_components/matrix_visual_comp";
import { useState } from "react";
import { useEffect } from "react";
import UserCard from "../search_components/user_card_comp";
import { formattedDateWithoutClock } from "../services";
import MatrixVisualPair from "../profile_components/matrix_visual_pair";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "../../store/profileUserInfoSlice";
import { t } from "i18next";
import { matrixCalculationFunc } from "../calculations/profile_matrix_calculation"
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const RelationshipPairPopup = ({ close, additionInfo }) => {
    const [allUsers, setAllUsers] = useState([])

    // const [authorizedUser, havePair, username, birthDate, profilePic, firstName, gender] = additionInfo

    const [searchData, setSearchData] = useState('');
    const [formNotReady, setFormNotReady] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
    const profileData = useSelector(state => state.profileUserSlice.profileUser)
    const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)

    const user = profileData.user
    const username = user.username
    const gender = user.gender
    const birthDate = user.birth_date
    const firstName = user.first_name
    const profilePic = user.profile_pic

    const havePair = profileData.have_pair

    // console.log(baseBackendUrl+'/media/'+havePair?.family_profile_pic,baseBackendUrl+authorizedUser.profile_pic)
    // const [pairMatrix, getPairMatrix] = useState('')

    // useEffect(() => {
    // const [year1, month1, day1] = authorizedUser.birth_date.split('-')
    // const [year2, month2, day2] = havePair?.family_date.split('-')
    // const pairMatrix = `${Number(year1) + Number(year2)}-${Number(month1) + Number(month2)}-${Number(day1) + Number(day2)}`
    // }, [])

    // console.log(havePair?.family_date, pairMatrix)

    // const [birthDay1, birthMonth1, birthYear1] = formattedDateWithoutClock(birthDate).split("-");
    // const birthMonth = Number(birthMonth1)
    // const birthDay = Number(birthDay1)

    const [formData, setFormData] = useState({
        authorized_user: authorizedUser.username,
        firstname: '',
        status: 'relationship',
        birthdate: "2000-01-01",
        gender: '',
        username: '',
    });

    const fetchAllUsersInfo = async () => {
        const url = `${baseBackendUrl}/all_users_list`;
        const data = { authorizedUser: authorizedUser?.username };

        try {
            const response = await axios.post(url, data);

            setAllUsers(response.data.all_users);

        } catch (error) {
            console.log(error);
        }
    };

    const deletePair = async () => {
        const url = `${baseBackendUrl}/delete_relationship`;
        const data = { authorizedUser: authorizedUser?.username };
        try {
            await axios.post(url, data);

        } catch (error) {
            console.log(error);
        }
        dispatch(updateProfileAction(!updateProfile))
    }

    useEffect(() => {
        fetchAllUsersInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendUserToBase = async () => {
        const url = `${baseBackendUrl}/add_relationship`;
        const data = { data: formData };
        await axios.post(url, data);

        dispatch(updateProfileAction(!updateProfile))
    }

    const addUserToForm = (user) => {
        setFormData((prevState) => ({
            ...prevState,
            firstname: user.first_name,
            username: user.username,
            birthdate: user.birth_date,
            gender: user.gender,
        }));
        setSearchData('')
    }

    useEffect(() => {
        if (formData.birthdate === "2000-01-01" && !formData.firstname && !formData.gender) {
            setFormNotReady(false)
        } else {
            setFormNotReady(true)
        }
    }, [formData]);

    const renderSearchUsers = () => {
        let searchUsersCount = 0; // Инициализируем счетчик

        const searchResults = allUsers.map(user => {
            if (
                user.username.toLowerCase().includes(searchData) ||
                user.first_name.toLowerCase().includes(searchData) ||
                user.last_name.toLowerCase().includes(searchData) ||
                formattedDateWithoutClock(user.birth_date).includes(searchData)
            ) {
                searchUsersCount++; // Увеличиваем счетчик при совпадении

                return (
                    <div className="flex flex-col pb-2 w-full" key={user.id}>
                        <div className="">
                            <UserCard
                                username={user.username}
                                profile_pic={user.profile_pic}
                                first_name={user.first_name}
                                last_name={user.last_name}
                                birth_date={user.birth_date}
                                is_online={user.is_online}
                                last_time_online={user.last_time_online}
                            />

                        </div>
                        <div className="w-full flex justify-center">
                            <button className="title_style_addon z-[1] button_blue_rounded !overflow-visible !w-fit !h-6 !p-0 !pl-5 !pr-5"
                                onClick={() => addUserToForm(user)}
                            >
                                {t('profile-addButton')}
                            </button>
                        </div>
                    </div>

                );
            }

            return null; // Если пользователь не соответствует условиям, возвращаем null
        });

        if (searchUsersCount === 0) {
            return (
                <div>
                    <p>{t('friends-empty-search')}</p>
                </div>
            );
        }

        return searchResults; // Возвращаем результаты поиска
    };

    const handleInputInfoChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        console.log(e)
    }

    const handleInputChange = (e) => {
        setSearchData(e.target.value.toLowerCase());
    };

    // Искать друга в зарегестрированных пользователях
    const searchRegisteredUsers = () => {
        return (
            <div className="w-full flex flex-col gap-2 p-2">
                <p>{t('profile-relationshipPair-findPair')}:</p>
                <input type="text" placeholder={t('friends-search')} className='input_style' onChange={handleInputChange}
                    autoFocus
                    value={searchData}
                />

                {
                    searchData
                        ? (renderSearchUsers())
                        : null
                }
            </div>
        )
    }

    const searchRegisteredUsersStatic = searchRegisteredUsers()


    return (
        // Основа
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2  w-fit h-fit sm:h-full sm:w-full">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                <div className="relative flex w-full flex-col items-center overflow-x-hidden">

                    {/* ЗАГОЛОВОК */}
                    <p className="popup_title">{t('profile-relationshipPair-addPartner')}</p>

                    {/* Основа */}
                    <div className="w-full flex flex-col gap-5">

                        {/* const listOfMatrixEnergies =  matrixCalculationFunc(birthDate) */}


                        {
                            havePair?.family_name
                                ?
                                (
                                    <div className="w-full overflow-hidden">
                                        <div className="flex sm:flex-col items-center justify-center">
                                            <div className="flex flex-col items-center justify-center">
                                                <div className="flex items-center justify-center gap-2 border rounded-full p-1 pr-4">
                                                    <div className="absoulte border-2 border-white bg-cover bg-no-repeat bg-center w-[40px] h-[40px] rounded-full" style={{ backgroundImage: `url(${baseBackendUrl + profilePic})` }}></div>
                                                    <p>{firstName}</p>
                                                </div>

                                                <MatrixVisual
                                                    matrixListOfEnergiesInner={matrixCalculationFunc(birthDate)}
                                                    birthDateInner={birthDate}
                                                    genderInner={gender}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center justify-center gap-2">
                                                <div className="flex items-center justify-center gap-2 border rounded-full p-1 pr-4">

                                                    <Link to={`/${havePair?.family_username}`} onClick={handleClose} className="flex items-center justify-center gap-2">
                                                        {
                                                            havePair?.family_profile_pic
                                                                ? <div className="absoulte border-2 border-white bg-cover bg-no-repeat bg-center w-[40px] h-[40px] rounded-full" style={{ backgroundImage: `url(${baseBackendUrl + '/media/' + havePair?.family_profile_pic})` }}></div>
                                                                : <div className="absoulte border-2 border-white bg-cover bg-no-repeat bg-center w-[40px] h-[40px] rounded-full" style={{ backgroundImage: `url(${baseBackendUrl + '/media/no_pic.png'})` }}></div>
                                                        }
                                                        <p>{havePair?.family_name}</p>
                                                    </Link>

                                                    <p>{havePair?.family_date}</p>

                                                </div>



                                                <MatrixVisual
                                                    matrixListOfEnergiesInner={matrixCalculationFunc(havePair?.family_date)}
                                                    birthDateInner={havePair?.family_date}
                                                    genderInner={havePair?.family_gender}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-5">

                                            <div className="flex flex-col items-center justify-center">
                                                <p className="popup_title">{t('profile-relationshipPair-pairMatrix')}</p>

                                                {
                                                    havePair?.family_date
                                                        ?
                                                        <MatrixVisualPair
                                                            birthDateInner={birthDate}
                                                            secondBirthDate={havePair?.family_date}
                                                        />

                                                        : null
                                                }
                                            </div>

                                        </div>

                                    </div>
                                )
                                :
                                (
                                    <>
                                        {searchRegisteredUsersStatic}

                                        {/* <div className="w-full flex flex-col gap-2 p-2">
                                            <p>{t('profile-relationshipPair-findPair')}:</p>
                                            <input type="text" placeholder={t('friends-search')} className='input_style' onChange={handleInputChange}
                                                autoFocus
                                                value={searchData}
                                            />

                                            {
                                                searchData
                                                    ? (renderSearchUsers())
                                                    : null
                                            }
                                        </div> */}

                                        <div className="flex flex-col gap-3 border border_color rounded-3xl p-3">
                                            <p>{t('profile-relationshipPair-findPairUnputs')}:</p>

                                            <input className="input_style inp fname"
                                                type="text" name="firstname" placeholder={t('reg-first-name')} autoComplete="off" required
                                                onChange={handleInputInfoChange}
                                                value={formData.firstname}
                                            />

                                            <div className="flex shadow rounded-3xl bg-blue-300 h-10">
                                                <label htmlFor="cover" className=" pl-4 pr-4 flex text-white items-center justify-center text-center text-[14px]">{t('reg-helpTitle-birthDate')}:</label>
                                                <input className="input_style  inp bdate"
                                                    type="date" name="birthdate" min="1500-01-01" max="3000-01-01"
                                                    value={formData.birthdate}
                                                    placeholder={t('reg-birthdate')} autoComplete="off" required
                                                    onChange={handleInputInfoChange}
                                                />
                                            </div>

                                            <select className="input_style inp gender"
                                                // defaultValue='' 
                                                name="gender" size="1" required
                                                onChange={handleInputInfoChange}
                                                value={formData.gender}
                                            >
                                                <option value="" disabled>{t('reg-choose-gender')}</option>
                                                <option value="Male">{t('reg-Male')}</option>
                                                <option value="Female">{t('reg-Female')}</option>
                                            </select>

                                            <button className={` ${formNotReady ? 'button_blue_rounded' : 'button_grey_like_green'}`} disabled={!formNotReady}
                                                onClick={sendUserToBase}
                                            >
                                                {t('profile-addButton')}
                                            </button>
                                        </div>
                                    </>
                                )
                        }




                        {/* Основа */}
                    </div>
                </div>
                <div className="flex w-full">

                    {havePair?.family_name && authorizedUser.username === username
                        ?
                        (
                            <button className="button_blue_rounded"
                                onClick={deletePair}
                            >
                                {t('profile-relationshipPair-delete')}
                            </button>
                        )
                        : null
                    }

                    <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
                </div>
            </div>
        </div>
    )
}

export default RelationshipPairPopup;