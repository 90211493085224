import {createSlice} from '@reduxjs/toolkit';

const authorizedUserSlice = createSlice ({
    name: 'authorizedUser',
    initialState: {
        authorizedUser: {},
    },
    reducers: {
        updateAuthorizedUser(state, action) {
            // console.log(state,action)
            state.authorizedUser = action.payload
        }
    }
})

export const {updateAuthorizedUser} = authorizedUserSlice.actions; 

export default authorizedUserSlice.reducer;