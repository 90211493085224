import { formattedDateWithoutClock } from "../services"
import moment from "moment"

const getRealNumbers = (baseBirthNumber) => {
        baseBirthNumber = String(baseBirthNumber)
        let finalBirthNumber = 0

        if (baseBirthNumber < 23) {
                return Number(baseBirthNumber)
        } else {
                for (let i = 0; i < baseBirthNumber.length; i++) {
                        finalBirthNumber += Number(baseBirthNumber[i])
                }
                return getRealNumbers(finalBirthNumber)
        }
}

const getInterimYears = (yearStart, yearEnd) => {
        const middle = getRealNumbers(yearStart + yearEnd)
        const middle_left_center = getRealNumbers(yearStart + middle)
        const middle_left_center_left = getRealNumbers(yearStart + middle_left_center)
        const middle_left_center_right = getRealNumbers(middle_left_center + middle)

        const middle_right_center = getRealNumbers(middle + yearEnd)
        const middle_right_center_left = getRealNumbers(middle + middle_right_center)
        const middle_right_center_right = getRealNumbers(middle_right_center + yearEnd)

        return [middle_left_center_left, middle_left_center, middle_left_center_right, middle, middle_right_center_left, middle_right_center, middle_right_center_right]
}

const listOfYearsFunc = (energyList) => {
        // eslint-disable-next-line
        const [A_birthDay, B_birthMonth, C_birthYear, D_karma, E_comfort, F_father_talent, G_mother_talent, H_father_karma, I_mother_karma] = energyList

        // ЭНЕРГИЯ ГОДОВ
        const year_0 = A_birthDay

        const year_10 = F_father_talent
        const [year_1__2_5, year_2_5__3_5, year_3_5__4, year_5, year_6__7_5, year_7_5__8_5, year_8_5__9] = getInterimYears(year_0, year_10)
        // 0 до 10 /\

        const year_20 = B_birthMonth
        const [year_11__12_5, year_12_5__13_5, year_13_5__14, year_15, year_16__17_5, year_17_5__18_5, year_18_5__19] = getInterimYears(year_10, year_20)
        // 10 до 20 /\

        const year_30 = G_mother_talent
        const [year_21__22_5, year_22_5__23_5, year_23_5__24, year_25, year_26__27_5, year_27_5__28_5, year_28_5__29] = getInterimYears(year_20, year_30)
        // 20 до 30 /\

        const year_40 = C_birthYear
        const [year_31__32_5, year_32_5__33_5, year_33_5__34, year_35, year_36__37_5, year_37_5__38_5, year_38_5__39] = getInterimYears(year_30, year_40)
        // 30 до 40 /\

        const year_50 = H_father_karma
        const [year_41__42_5, year_42_5__43_5, year_43_5__44, year_45, year_46__47_5, year_47_5__48_5, year_48_5__49] = getInterimYears(year_40, year_50)
        // 40 до 50 /\

        const year_60 = D_karma
        const [year_51__52_5, year_52_5__53_5, year_53_5__54, year_55, year_56__57_5, year_57_5__58_5, year_58_5__59] = getInterimYears(year_50, year_60)
        // 50 до 60 /\

        const year_70 = I_mother_karma
        const [year_61__62_5, year_62_5__63_5, year_63_5__64, year_65, year_66__67_5, year_67_5__68_5, year_68_5__69] = getInterimYears(year_60, year_70)
        // 60 до 70 /\

        const [year_71__72_5, year_72_5__73_5, year_73_5__74, year_75, year_76__77_5, year_77_5__78_5, year_78_5__79] = getInterimYears(year_70, year_0)
        // 70 до 80 /\

        const listOfYears = [year_0, year_1__2_5, year_2_5__3_5, year_3_5__4, year_5, year_6__7_5, year_7_5__8_5, year_8_5__9,
                // 7
                year_10, year_11__12_5, year_12_5__13_5, year_13_5__14, year_15, year_16__17_5, year_17_5__18_5, year_18_5__19, year_20,
                //   16
                year_21__22_5, year_22_5__23_5, year_23_5__24, year_25, year_26__27_5, year_27_5__28_5, year_28_5__29, year_30,
                //   24
                year_31__32_5, year_32_5__33_5, year_33_5__34, year_35, year_36__37_5, year_37_5__38_5, year_38_5__39, year_40,
                //   32
                year_41__42_5, year_42_5__43_5, year_43_5__44, year_45, year_46__47_5, year_47_5__48_5, year_48_5__49, year_50,
                //   40
                year_51__52_5, year_52_5__53_5, year_53_5__54, year_55, year_56__57_5, year_57_5__58_5, year_58_5__59, year_60,
                //    48
                year_61__62_5, year_62_5__63_5, year_63_5__64, year_65, year_66__67_5, year_67_5__68_5, year_68_5__69, year_70,
                //   56
                year_71__72_5, year_72_5__73_5, year_73_5__74, year_75, year_76__77_5, year_77_5__78_5, year_78_5__79]
        //   63

        return listOfYears;
}

const matrixCalculationFunc = (birthDateInner) => {
        // let birthDayBefore, birthMonthBefore, birthYearBefore;

        const [birthDayBefore, birthMonthBefore, birthYearBefore] = formattedDateWithoutClock(birthDateInner).split("-");

        // ОСНОВНЫЕ ЭНЕРГИИ
        const A_birthDay = getRealNumbers(birthDayBefore)
        // earth /\

        const B_birthMonth = getRealNumbers(birthMonthBefore)
        // heaven /\

        const C_birthYear = getRealNumbers(birthYearBefore)
        const D_karma = getRealNumbers(A_birthDay + B_birthMonth + C_birthYear)
        const E_comfort = getRealNumbers(A_birthDay + B_birthMonth + C_birthYear + D_karma)
        // middle /\

        const O_child_parent_1 = getRealNumbers(A_birthDay + E_comfort)
        const P_child_parent_2 = getRealNumbers(A_birthDay + O_child_parent_1)
        const Q_emotion_heart_3 = getRealNumbers(O_child_parent_1 + E_comfort)
        // A_addition /\

        const R_familyGift_1 = getRealNumbers(B_birthMonth + E_comfort)
        const S_godTalent_2 = getRealNumbers(B_birthMonth + R_familyGift_1)
        const T_emotion_heart_3 = getRealNumbers(R_familyGift_1 + E_comfort)
        // B_addition /\

        const F_father_talent = getRealNumbers(A_birthDay + B_birthMonth)
        const W_father_1 = getRealNumbers(O_child_parent_1 + R_familyGift_1)
        const X_father_2 = getRealNumbers(P_child_parent_2 + S_godTalent_2)

        const G_mother_talent = getRealNumbers(B_birthMonth + C_birthYear)

        const H_father_karma = getRealNumbers(C_birthYear + D_karma)
        const I_mother_karma = getRealNumbers(D_karma + A_birthDay)
        // parents /\

        const J_relationship = getRealNumbers(E_comfort + D_karma)
        const U_relationship_middle = getRealNumbers(J_relationship + D_karma)

        const K_money_block = getRealNumbers(E_comfort + C_birthYear)
        const V_money_block_middle = getRealNumbers(K_money_block + C_birthYear)

        const L_balance_money_rel = getRealNumbers(J_relationship + K_money_block)
        const M_ideal_partner = getRealNumbers(J_relationship + L_balance_money_rel)
        const N_implementation_scope = getRealNumbers(L_balance_money_rel + K_money_block)
        // money and relatioship /\

        const earthSumm = getRealNumbers(A_birthDay + C_birthYear)
        const heavenSumm = getRealNumbers(B_birthMonth + D_karma)
        const firstPurpose_20_40 = getRealNumbers(earthSumm + heavenSumm)

        const fatherSumm = getRealNumbers(F_father_talent + H_father_karma)
        const motherSumm = getRealNumbers(G_mother_talent + I_mother_karma)
        const secondPurpose_40_60 = getRealNumbers(fatherSumm + motherSumm)

        const thirdPurpose_60 = getRealNumbers(firstPurpose_20_40 + secondPurpose_40_60)
        // purposes /\

        const Y_mother_1 = getRealNumbers(R_familyGift_1 + K_money_block)
        const Z_mother_2 = getRealNumbers(S_godTalent_2 + V_money_block_middle)
        // mother middle  /\

        const AA_father_karma_1 = getRealNumbers(K_money_block + J_relationship)
        const BB_father_karma_2 = getRealNumbers(H_father_karma + AA_father_karma_1)
        const CC_mother_karma_1 = getRealNumbers(J_relationship + O_child_parent_1)
        const DD_mother_karma_2 = getRealNumbers(CC_mother_karma_1 + I_mother_karma)
        // parents_karma_addition  /\

        const EE_familyStrength = getRealNumbers(F_father_talent + H_father_karma + G_mother_talent + I_mother_karma)
        const FF_sexProgram = getRealNumbers(EE_familyStrength + E_comfort)

        // parents father program
        const parents_father_programm_33 = getRealNumbers(AA_father_karma_1 + BB_father_karma_2)
        const parents_mother_programm_34 = getRealNumbers(CC_mother_karma_1 + DD_mother_karma_2)

        const parents_carma_35 = getRealNumbers(H_father_karma + I_mother_karma)

        const fourthPurpose_42 = getRealNumbers(secondPurpose_40_60 + thirdPurpose_60)


        const listOfEnergies = [
                A_birthDay, B_birthMonth, C_birthYear, D_karma, E_comfort, // 5
                F_father_talent, G_mother_talent, H_father_karma, I_mother_karma, // 9
                J_relationship, K_money_block, L_balance_money_rel, M_ideal_partner, N_implementation_scope, // 14
                O_child_parent_1, P_child_parent_2, Q_emotion_heart_3, // 17
                R_familyGift_1, S_godTalent_2, T_emotion_heart_3, // 20
                U_relationship_middle, V_money_block_middle, W_father_1, X_father_2, // 23
                Y_mother_1, Z_mother_2, AA_father_karma_1, BB_father_karma_2, CC_mother_karma_1, DD_mother_karma_2, // 29
                firstPurpose_20_40, secondPurpose_40_60, thirdPurpose_60, parents_father_programm_33, parents_mother_programm_34, // 34
                parents_carma_35, earthSumm, heavenSumm, fatherSumm, motherSumm, EE_familyStrength, FF_sexProgram, fourthPurpose_42] // 42

        const healthResult_1 = getRealNumbers(A_birthDay + B_birthMonth)
        const healthResult_2 = getRealNumbers(P_child_parent_2 + S_godTalent_2)
        const healthResult_3 = getRealNumbers(O_child_parent_1 + R_familyGift_1)
        const healthResult_4 = getRealNumbers(Q_emotion_heart_3 + T_emotion_heart_3)
        const healthResult_5 = getRealNumbers(E_comfort + E_comfort)
        const healthResult_6 = getRealNumbers(K_money_block + J_relationship)
        const healthResult_7 = getRealNumbers(C_birthYear + D_karma)

        const listOfHealthEnergies = [
                A_birthDay, B_birthMonth, healthResult_1,
                P_child_parent_2, S_godTalent_2, healthResult_2,
                O_child_parent_1, R_familyGift_1, healthResult_3,
                Q_emotion_heart_3, T_emotion_heart_3, healthResult_4,
                E_comfort, E_comfort, healthResult_5,
                K_money_block, J_relationship, healthResult_6,
                C_birthYear, D_karma, healthResult_7,

                getRealNumbers(A_birthDay + P_child_parent_2 + O_child_parent_1 + Q_emotion_heart_3 + E_comfort + K_money_block + C_birthYear),
                getRealNumbers(B_birthMonth + S_godTalent_2 + R_familyGift_1 + T_emotion_heart_3 + E_comfort + J_relationship + D_karma),
                getRealNumbers(healthResult_1 + healthResult_2 + healthResult_3 + healthResult_4 + healthResult_5 + healthResult_6 + healthResult_7)
        ]

        // фиолетовый фиолетовый красный красный жёлтый рыжий голубой синий зелёный
        const descriptionEnergyColors = ['bg-[#B977E2]', 'bg-[#B977E2]', 'bg-[#FA615B]', 'bg-[#FA615B]', 'bg-[#FFFE7A]',
                'border border-black', 'border border-black', 'border border-black', 'border border-black', 'bg-[#FDD843]', 'bg-[#FDD843]', 'border border-black',
                'border border-black', 'border border-black', 'bg-[#97FDFF]', 'bg-[#98A4FD]', 'bg-[#E8FE9C]', 'bg-[#97FDFF]', 'bg-[#98A4FD]', 'bg-[#E8FE9C]',
                'border border-black', 'border border-black']

        const descriptionZones = [
                'zone_birthDay_resource',
                'zone_month_bigTalant',
                'zone_year_soulTask',
                'zone_bd_m_y_karmicTail',
                'zone_year_soulComfort',
                'zone_fatherTopBig',
                'zone_motherTopBig',
                'zone_fatherBottomBig',
                'zone_motherBottomBig',
                'zone_relationshipsKarma',
                'zone_financeKarma',
                'zone_balance',
                'zone_partner',
                'zone_financial_strength',
                'zone_parentsRelationship',
                'zone_childrensRelationship',
                'zone_emotionalBottom',
                'zone_bonuses',
                'zone_smallTalent',
                'zone_emotionalTop',
                'zone_personalityKarma',
                'zone_financeCloseOrOpen'
        ]

        const listOfYears = listOfYearsFunc(listOfEnergies)

        const actualYearsOldInner = (Math.floor((moment(moment().format('YYYY-MM-DD')).diff(moment(birthDateInner), 'months') / 12) * 10) / 10)
        const roundedYearNumber = Math.floor(actualYearsOldInner * 2) / 2;

        let yearEnergy = 0;

        if (roundedYearNumber >= 0 && roundedYearNumber < 1) {
                yearEnergy = listOfYears[0]
        } else if (roundedYearNumber >= 1 && roundedYearNumber < 2.5) {
                yearEnergy = listOfYears[1]
        } else if (roundedYearNumber >= 2.5 && roundedYearNumber < 3.5) {
                yearEnergy = listOfYears[2]
        } else if (roundedYearNumber >= 3.5 && roundedYearNumber < 4) {
                yearEnergy = listOfYears[3]
        } else if (roundedYearNumber >= 5 && roundedYearNumber < 6) {
                yearEnergy = listOfYears[4]
        } else if (roundedYearNumber >= 6 && roundedYearNumber < 7.5) {
                yearEnergy = listOfYears[5]
        } else if (roundedYearNumber >= 7.5 && roundedYearNumber < 8.5) {
                yearEnergy = listOfYears[6]
        } else if (roundedYearNumber >= 8.5 && roundedYearNumber < 10) {
                yearEnergy = listOfYears[7]
        } else if (roundedYearNumber >= 10 && roundedYearNumber < 11) {
                yearEnergy = listOfYears[8]
        } else if (roundedYearNumber >= 11 && roundedYearNumber < 12.5) {
                yearEnergy = listOfYears[9]
        } else if (roundedYearNumber >= 12.5 && roundedYearNumber < 13.5) {
                yearEnergy = listOfYears[10]
        } else if (roundedYearNumber >= 13.5 && roundedYearNumber < 15) {
                yearEnergy = listOfYears[11]
        } else if (roundedYearNumber >= 15 && roundedYearNumber < 16) {
                yearEnergy = listOfYears[12]
        } else if (roundedYearNumber >= 16 && roundedYearNumber < 17.5) {
                yearEnergy = listOfYears[13]
        } else if (roundedYearNumber >= 17.5 && roundedYearNumber < 18.5) {
                yearEnergy = listOfYears[14]
        } else if (roundedYearNumber >= 18.5 && roundedYearNumber <= 20) {
                yearEnergy = listOfYears[15]
        } else if (roundedYearNumber >= 20 && roundedYearNumber < 21) {
                yearEnergy = listOfYears[16]
        } else if (roundedYearNumber >= 21 && roundedYearNumber < 22.5) {
                yearEnergy = listOfYears[17]
        } else if (roundedYearNumber >= 22.5 && roundedYearNumber < 23.5) {
                yearEnergy = listOfYears[18]
        } else if (roundedYearNumber >= 23.5 && roundedYearNumber < 25) {
                yearEnergy = listOfYears[19]
        } else if (roundedYearNumber >= 25 && roundedYearNumber < 26) {
                yearEnergy = listOfYears[20]
        } else if (roundedYearNumber >= 26 && roundedYearNumber < 27.5) {
                yearEnergy = listOfYears[21]
        } else if (roundedYearNumber >= 27.5 && roundedYearNumber < 28.5) {
                yearEnergy = listOfYears[22]
        } else if (roundedYearNumber >= 28.5 && roundedYearNumber < 30) {
                yearEnergy = listOfYears[23]
        } else if (roundedYearNumber >= 30 && roundedYearNumber < 31) {
                yearEnergy = listOfYears[24]
        } else if (roundedYearNumber >= 31 && roundedYearNumber < 32.5) {
                yearEnergy = listOfYears[25]
        } else if (roundedYearNumber >= 32.5 && roundedYearNumber < 33.5) {
                yearEnergy = listOfYears[26]
        } else if (roundedYearNumber >= 33.5 && roundedYearNumber < 35) {
                yearEnergy = listOfYears[27]
        } else if (roundedYearNumber >= 35 && roundedYearNumber < 36) {
                yearEnergy = listOfYears[28]
        } else if (roundedYearNumber >= 36 && roundedYearNumber < 37.5) {
                yearEnergy = listOfYears[29]
        } else if (roundedYearNumber >= 37.5 && roundedYearNumber < 38.5) {
                yearEnergy = listOfYears[30]
        } else if (roundedYearNumber >= 38.5 && roundedYearNumber < 40) {
                yearEnergy = listOfYears[31]
        } else if (roundedYearNumber >= 40 && roundedYearNumber < 41) {
                yearEnergy = listOfYears[32]
        } else if (roundedYearNumber >= 41 && roundedYearNumber < 42.5) {
                yearEnergy = listOfYears[33]
        } else if (roundedYearNumber >= 42.5 && roundedYearNumber < 43.5) {
                yearEnergy = listOfYears[34]
        } else if (roundedYearNumber >= 43.5 && roundedYearNumber < 45) {
                yearEnergy = listOfYears[35]
        } else if (roundedYearNumber >= 45 && roundedYearNumber < 46) {
                yearEnergy = listOfYears[36]
        } else if (roundedYearNumber >= 46 && roundedYearNumber < 47.5) {
                yearEnergy = listOfYears[37]
        } else if (roundedYearNumber >= 47.5 && roundedYearNumber < 48.5) {
                yearEnergy = listOfYears[38]
        } else if (roundedYearNumber >= 48.5 && roundedYearNumber < 50) {
                yearEnergy = listOfYears[39]
        } else if (roundedYearNumber >= 50 && roundedYearNumber < 51) {
                yearEnergy = listOfYears[40]
        } else if (roundedYearNumber >= 51 && roundedYearNumber < 52.5) {
                yearEnergy = listOfYears[41]
        } else if (roundedYearNumber >= 52.5 && roundedYearNumber < 53.5) {
                yearEnergy = listOfYears[42]
        } else if (roundedYearNumber >= 53.5 && roundedYearNumber < 55) {
                yearEnergy = listOfYears[43]
        } else if (roundedYearNumber >= 55 && roundedYearNumber < 56) {
                yearEnergy = listOfYears[44]
        } else if (roundedYearNumber >= 56 && roundedYearNumber < 57.5) {
                yearEnergy = listOfYears[45]
        } else if (roundedYearNumber >= 57.5 && roundedYearNumber < 58.5) {
                yearEnergy = listOfYears[46]
        } else if (roundedYearNumber >= 58.5 && roundedYearNumber < 60) {
                yearEnergy = listOfYears[47]
        } else if (roundedYearNumber >= 60 && roundedYearNumber < 61) {
                yearEnergy = listOfYears[48]
        } else if (roundedYearNumber >= 61 && roundedYearNumber < 62.5) {
                yearEnergy = listOfYears[49]
        } else if (roundedYearNumber >= 62.5 && roundedYearNumber < 63.5) {
                yearEnergy = listOfYears[50]
        } else if (roundedYearNumber >= 63.5 && roundedYearNumber < 65) {
                yearEnergy = listOfYears[51]
        } else if (roundedYearNumber >= 65 && roundedYearNumber < 66) {
                yearEnergy = listOfYears[52]
        } else if (roundedYearNumber >= 66 && roundedYearNumber < 67.5) {
                yearEnergy = listOfYears[53]
        } else if (roundedYearNumber >= 67.5 && roundedYearNumber < 68.5) {
                yearEnergy = listOfYears[54]
        } else if (roundedYearNumber >= 68.5 && roundedYearNumber < 70) {
                yearEnergy = listOfYears[55]
        } else if (roundedYearNumber >= 70 && roundedYearNumber < 71) {
                yearEnergy = listOfYears[56]
        } else if (roundedYearNumber >= 71 && roundedYearNumber < 72.5) {
                yearEnergy = listOfYears[57]
        } else if (roundedYearNumber >= 72.5 && roundedYearNumber < 73.5) {
                yearEnergy = listOfYears[58]
        } else if (roundedYearNumber >= 73.5 && roundedYearNumber < 75) {
                yearEnergy = listOfYears[59]
        } else if (roundedYearNumber >= 75 && roundedYearNumber < 76) {
                yearEnergy = listOfYears[60]
        } else if (roundedYearNumber >= 76 && roundedYearNumber < 77.5) {
                yearEnergy = listOfYears[61]
        } else if (roundedYearNumber >= 77.5 && roundedYearNumber < 78.5) {
                yearEnergy = listOfYears[62]
        } else if (roundedYearNumber >= 78.5 && roundedYearNumber < 80) {
                yearEnergy = listOfYears[63]
        }
        // else {
        //     console.log('Таких годов нет:', roundedYearNumber)
        // }

        return [listOfEnergies, descriptionEnergyColors, descriptionZones, listOfHealthEnergies, listOfYears, yearEnergy]
}


export { getRealNumbers, matrixCalculationFunc };