import InfoSVG from "../../../svg/svg_info";
import HandEyeSVG from "../../../svg/svg_hand_eye";
import SnakeTaleSVG from "../../../svg/svg_snake_tale";
import OrbitYearSVG from "../../../svg/svg_orbit_year";
import MatrixSVG from "../../../svg/svg_matrix";
import Matrixes from "../../../svg/svg_matrixes";
import PinkHeartSVG from "../../../svg/pink_heart";
import WomanSignSVG from "../../../svg/svg_woman_sign";
import ManSignSVG from "../../../svg/svg_man_sign";
import MindSVG from "../../../svg/svg_mind";

import MatrixVisual from "./matrix_visual_comp";
import moment from "moment";
import Checkbox from "../checkbox";
import { useState, useEffect } from "react";
import { t } from "i18next";
import PopupContainer from "../popups_root";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseBackendUrl } from "../websocket_comp";
import { updateProfileUserInfo } from "../../store/profileUserInfoSlice";
import { matrixCalculationFunc } from "../calculations/profile_matrix_calculation";
import { updateListOfEnergiesAction } from "../../store/profileUserInfoSlice";

const ProfileMatrix = () => {
    const dispatch = useDispatch()
    const { username } = useParams();
    
    const getProfileInfo = async () => {
      const data = { authorizedUser: authorizedUser.username };
      const url = `${baseBackendUrl}/${username}`;
      const response = await axios.post(url, data);
  
      // Здесь запросили инфо о профиле и записали его в стор
      dispatch(updateProfileUserInfo(response.data))
  
      // Получаем статус календаря настроения
      // setMoodNotification(response.data.mood_notification.date === todayOnlyDate ? false : true)
  
      // калькулируем матрицу для пользователя к которому зашли на страницу
      const birthDay = response.data.user.birth_date
  
      // записываем матрицу для всех профилей тут
      const listOfMatrixEnergies = matrixCalculationFunc(birthDay)
      dispatch(updateListOfEnergiesAction(listOfMatrixEnergies))
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if (username) {getProfileInfo()} }, [username])


    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    
    const user = profileUser?.user || authorizedUser
    const birthDate = user?.birth_date
    const gender = user?.gender

    const matrixList = matrixCalculationFunc(birthDate)
    const matrixListOfEnergies = matrixList[0]

    // Matrix Checkboxes
    const [matrixYearsCheckbox, setMatrixYearsCheckbox] = useState(JSON.parse(localStorage.getItem('matrixYearsCheckbox')) || false)
    const [matrixPurposesCheckbox, setMatrixPurposesCheckbox] = useState(JSON.parse(localStorage.getItem('matrixPurposesCheckbox')) || false)
    const [matrixMoneyCheckbox, setMatrixMoneysCheckbox] = useState(JSON.parse(localStorage.getItem('matrixMoneyCheckbox')) || false)
    const [matrixRelationshipCheckbox, setMatrixRelationshipCheckbox] = useState(JSON.parse(localStorage.getItem('matrixRelationshipCheckbox')) || false)
    const [matrixParentssCheckbox, setMatrixParentsCheckbox] = useState(JSON.parse(localStorage.getItem('matrixParentssCheckbox')) || false)
    const [matrixLittleEnergiesCheckbox, setMatrixLittleEnergiesCheckbox] = useState(JSON.parse(localStorage.getItem('matrixLittleEnergiesCheckbox')) || false)
    const [matrixClose, setMatrixClose] = useState(false)

    useEffect(() => {
        localStorage.setItem('matrixYearsCheckbox', matrixYearsCheckbox)
        localStorage.setItem('matrixPurposesCheckbox', matrixPurposesCheckbox)
        localStorage.setItem('matrixMoneyCheckbox', matrixMoneyCheckbox)
        localStorage.setItem('matrixRelationshipCheckbox', matrixRelationshipCheckbox)
        localStorage.setItem('matrixParentssCheckbox', matrixParentssCheckbox)
        localStorage.setItem('matrixLittleEnergiesCheckbox', matrixLittleEnergiesCheckbox)
    }, [matrixYearsCheckbox, matrixPurposesCheckbox, matrixMoneyCheckbox,
        matrixRelationshipCheckbox, matrixParentssCheckbox, matrixLittleEnergiesCheckbox])

    // POPUPS
    const [healthMapPopupOpen, setHealthMapPopupOpen] = useState(false);
    const [carmicProgramPopupOpen, setCarmicProgramPopupOpen] = useState(false);
    const [purposesPopupOpen, setPurposesPopupOpen] = useState(false);
    const [yearEnergyPopupOpen, setYearEnergyPopupOpen] = useState(false);
    const [matrixInfoPopupOpen, setMatrixInfoPopupOpen] = useState(false);
    const [myMatricesPopupOpen, setMyMatricesPopupOpen] = useState(false);
    const [matrixDescriptionMenuOpen, setMatrixDescriptionMenuOpen] = useState(false);
    const [materialKarmaPopupOpen, setMaterialKarmaPopupOpen] = useState(false);
    const [parentsKarmaPopupOpen, setParentsKarmaPopupOpen] = useState(false);
    const [matrixProgrammsPopupOpen, setMatrixProgrammsPopupOpen] = useState(false)


    const checkboxes = () => {
        return (

            <div className="grid sm:grid-cols-2">
                <Checkbox checkedInner={matrixYearsCheckbox} handleCheckboxInner={(e) => { setMatrixYearsCheckbox(e.target.checked) }} text={t('profile-checkbox-years')} />
                <Checkbox checkedInner={matrixPurposesCheckbox} handleCheckboxInner={(e) => { setMatrixPurposesCheckbox(e.target.checked) }} text={t('profile-checkbox-purposes')} />
                <Checkbox checkedInner={matrixMoneyCheckbox} handleCheckboxInner={(e) => { setMatrixMoneysCheckbox(e.target.checked) }} text={t('profile-checkbox-money')} />
                <Checkbox checkedInner={matrixRelationshipCheckbox} handleCheckboxInner={(e) => { setMatrixRelationshipCheckbox(e.target.checked) }} text={t('profile-checkbox-relationships')} />
                <Checkbox checkedInner={matrixParentssCheckbox} handleCheckboxInner={(e) => { setMatrixParentsCheckbox(e.target.checked) }} text={t('profile-checkbox-parents')} />
                <Checkbox checkedInner={matrixLittleEnergiesCheckbox} handleCheckboxInner={(e) => { setMatrixLittleEnergiesCheckbox(e.target.checked) }} text={t('profile-checkbox-little_energies')} />
            </div>

        )
    }

    useEffect(() => {

        // Визуальное отображение года в матрице
        if (matrixYearsCheckbox) {
            const yearClasses = ['bg-blue-200', 'animate-bounce', 'rounded-full', 'p-[6px]']

            const actualYearsOldInner = (Math.floor((moment(moment().format('YYYY-MM-DD')).diff(moment(birthDate), 'months') / 12) * 10) / 10)
            const roundedYearNumber = Math.floor(actualYearsOldInner * 2) / 2;

            let yearElement;

            if (document.getElementById(roundedYearNumber)) {
                yearElement = document.getElementById(roundedYearNumber)
            } else if (document.getElementById(roundedYearNumber - 0.5)) {
                yearElement = document.getElementById(roundedYearNumber - 0.5)
            } else if (document.getElementById(roundedYearNumber - 1)) {
                yearElement = document.getElementById(roundedYearNumber - 1)
            }
            yearElement?.classList.remove('animate-pulse')
            yearElement?.classList.add(...yearClasses);
            yearElement?.classList.add('text-black');
        }

        // eslint-disable-next-line
    }, [matrixYearsCheckbox])


    return (
        <>
            <section name="matrix_visual" className="profile_part_container">

                <div className="w-full flex sm:flex-col items-center justify-center gap-2 sm:gap-2">

                    {checkboxes()}

                    {matrixClose
                        ? null
                        :
                        <MatrixVisual
                            matrixListOfEnergiesInner={matrixList}
                            gender={gender}
                            checkboxes={[matrixYearsCheckbox, matrixPurposesCheckbox, matrixMoneyCheckbox, matrixRelationshipCheckbox, matrixParentssCheckbox, matrixLittleEnergiesCheckbox]}
                        />
                    }


                </div>

                <div className="br_border_line_gray" />

                <div className="grid grid-cols-2 sm:grid-cols-1 items-center h-fit gap-2">

                    <p className="popup_title">{t('profile-matrixGeneralInformation_title')}</p>

                    <ul className="flex justify-center items-center sm:items-start gap-3 sm:gap-1 text-[9px] ">
                        <li className="flex flex-col gap-3 items-center w-[90px]">
                            <div className="button_near_matrix w-10 h-10" onClick={() => setMatrixInfoPopupOpen(!matrixInfoPopupOpen)}><InfoSVG className='w-10 h-10 fill-blue-300 stroke-blue-300 hover:fill-white hover:stroke-white' /></div>
                            <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-info')}</p>
                        </li>

                        <li className="flex flex-col gap-3 items-center w-[90px]">
                            <div className="button_near_matrix w-10 h-10" onClick={() => setMatrixDescriptionMenuOpen(!matrixDescriptionMenuOpen)}><MatrixSVG className='w-9 h-9 fill-blue-300 stroke-blue-300 hover:fill-white hover:stroke-white' /></div>
                            <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-matrixDescription')}</p>
                        </li>

                        {
                            authorizedUser.username === username
                                ?
                                (
                                    <li className="flex flex-col gap-3 items-center w-[90px]">
                                        <div className="button_near_matrix w-10 h-10" onClick={() => setMyMatricesPopupOpen(!myMatricesPopupOpen)}><Matrixes className='w-10 h-10 fill-blue-300 stroke-blue-300 hover:fill-white hover:stroke-white' /></div>
                                        <p className="leading-3 -mt-2 text-center">{t('profile-myMatrix')}</p>
                                    </li>
                                )
                                : null
                        }
                    </ul>

                    {/* ------- */}
                    <div className="br_border_line_gray" />
                    <div className="br_border_line_gray" />


                    <p className="popup_title !text-red-400">{t('profile-currentTasks_title')}</p>

                    <ul className="flex flex-col justify-center items-center sm:items-start gap-3 sm:gap-1 text-[9px] ">

                        <div className="w-full flex gap-6 items-center justify-center">
                            <li className="flex flex-col gap-3 items-center w-[75px]">
                                <div className="button_near_matrix w-10 h-10" onClick={() => setPurposesPopupOpen(!purposesPopupOpen)}><HandEyeSVG className='w-8 h-8 fill-red-300 hover:fill-white ' /></div>
                                <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-destiny')}</p>
                            </li>

                            <li className="flex flex-col gap-3 items-center w-[75px]">
                                <div className="button_near_matrix w-10 h-10" onClick={() => setCarmicProgramPopupOpen(!carmicProgramPopupOpen)}><SnakeTaleSVG className='w-8 h-8 fill-red-300 hover:fill-white' /></div>
                                <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-carma')}</p>
                            </li>

                            <li className="flex flex-col gap-3 items-center w-[75px]">
                                <div className="button_near_matrix w-10 h-10" onClick={() => setYearEnergyPopupOpen(!yearEnergyPopupOpen)}><OrbitYearSVG className='w-8 h-8 fill-red-300 hover:fill-white' /></div>
                                <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-year')}</p>
                            </li>
                        </div>

                        <div className="w-full flex gap-6  items-center justify-center">
                            <li className="flex flex-col gap-3 items-center w-[75px]">
                                <div className="button_near_matrix w-10 h-10 overflow-hidden" onClick={() => setMaterialKarmaPopupOpen(!materialKarmaPopupOpen)}><SnakeTaleSVG className='w-10 h-10 translate-y-4 fill-red-300 hover:fill-white' /></div>
                                <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-materialKarma')}</p>
                            </li>

                            <li className="flex flex-col gap-3 items-center w-[75px]">
                                <div className="button_near_matrix w-10 h-10" onClick={() => setParentsKarmaPopupOpen(!parentsKarmaPopupOpen)}>
                                    <WomanSignSVG className='w-10 h-10 fill-red-300 hover:fill-white' />
                                    <ManSignSVG className='w-10 h-10 fill-red-300 hover:fill-white' />
                                </div>
                                <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-parentsKarma')}</p>
                            </li>

                            <li className="flex flex-col gap-3 items-center w-[75px]">
                                <div className="button_near_matrix w-10 h-10 overflow-hidden" onClick={() => setMatrixProgrammsPopupOpen(!matrixProgrammsPopupOpen)}><MindSVG className='w-10 h-10 fill-red-300 hover:fill-white' /></div>
                                <p className="leading-3 -mt-2 text-center">{t('matrixProgram-title')}</p>
                            </li>
                        </div>



                    </ul>

                    {/* ------- */}
                    <div className="br_border_line_gray" />
                    <div className="br_border_line_gray" />


                    <p className="popup_title !text-green-400">{t('profile-healthMap_title')}</p>

                    <div className="flex flex-col gap-3 items-center justify-center text-[9px]">
                        <div className="button_near_matrix w-10 h-10" onClick={() => setHealthMapPopupOpen(!healthMapPopupOpen)}><PinkHeartSVG className='w-6 h-6 fill-green-300 hover:fill-white' /></div>
                        <p className="leading-3 -mt-2 text-center">{t('profile-matrix-instructions-health')}</p>
                    </div>

                </div>


                {/* <div className="grid grid-cols-2 items-center h-12">

                            </div> */}

                {parentsKarmaPopupOpen ? <PopupContainer choosePopup={'parents_karma'} setPopupOpen={setParentsKarmaPopupOpen} additionInfo={''} /> : null}
                {materialKarmaPopupOpen ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setMaterialKarmaPopupOpen} additionInfo={['zone_year_soulTask', matrixListOfEnergies[2], gender]} /> : null}

                {purposesPopupOpen ? <PopupContainer choosePopup={'purposes'} setPopupOpen={setPurposesPopupOpen} additionInfo={''} /> : null}
                {carmicProgramPopupOpen ? <PopupContainer choosePopup={'carmic_program'} setPopupOpen={setCarmicProgramPopupOpen} additionInfo={''} /> : null}
                {healthMapPopupOpen ? <PopupContainer choosePopup={'health_map'} setPopupOpen={setHealthMapPopupOpen} additionInfo={''} /> : null}
                {yearEnergyPopupOpen ? <PopupContainer choosePopup={'year_energy_description'} setPopupOpen={setYearEnergyPopupOpen} additionInfo={null} /> : null}
                {matrixInfoPopupOpen ? <PopupContainer choosePopup={'matrix_information'} setPopupOpen={setMatrixInfoPopupOpen} additionInfo={''} /> : null}
                {matrixDescriptionMenuOpen ? <PopupContainer choosePopup={'matrix_description_menu'} setPopupOpen={() => setMatrixDescriptionMenuOpen(!matrixDescriptionMenuOpen)} additionInfo={''} /> : null}

                {myMatricesPopupOpen ? <PopupContainer choosePopup={'my_matrices'} setPopupOpen={setMyMatricesPopupOpen} additionInfo={setMatrixClose} /> : null}

                {matrixProgrammsPopupOpen ? <PopupContainer choosePopup={'matrix_programms'} setPopupOpen={setMatrixProgrammsPopupOpen} additionInfo={''} /> : null}

                
            </section>

        </>
    )
}

export default ProfileMatrix;
