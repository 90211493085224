import axios from 'axios'
import { baseBackendUrl } from '../little_components/websocket_comp';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formattedDateWithoutClock } from '../little_components/services';
import UserCard from '../little_components/search_components/user_card_comp';
import { t } from "i18next";
import Paginator from '../little_components/posts_components/paginator';


const FriendsPage = () => {
    const [allUsers, setAllUsers] = useState([])
    const [followers, setFollowers] = useState([])
    const [followings, setFollowings] = useState([])
    const [moodStatusBase, setMoodStatusBase] = useState([])
    const [allUsersPaginator, setAllUsersPaginator] = useState([])
    const [followingsPaginator, setFollowingsPaginator] = useState([])
    // const [followersPaginator, setFollowersPaginator] = useState([])
    

    const [searchData, setSearchData] = useState('');
    const [selectedComponent, setSelectedComponent] = useState('allUsers');
    const [allUsersPage, setAllUsersPage] = useState(1)
    const [followingsPage, setFollowingsPage] = useState(1)
    const [followersPage, setFollowersPage] = useState(1)
    

    // const { username } = useParams();

    const onlineUsers = useSelector(state => state.wsOnlineStatus.onlineUsers)
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    const fetchAllUsersInfo = async (allUsersPageInner, followingsPageInner, followersPageInner) => {
        const url = `${baseBackendUrl}/all_users_list`;
        const data = { authorizedUser: authorizedUser?.username, 
            allUsersPage: allUsersPageInner, 
            followingsPage: followingsPageInner,
            followersPage: followersPageInner
        
        };

        try {
            const response = await axios.post(url, data);

            setAllUsers(response.data.all_users);
            setFollowers(response.data.followers)
            setFollowings(response.data.followings)
            setMoodStatusBase(response.data.mood_status)
            setAllUsersPaginator(response.data.all_users_paginator)
            setFollowingsPaginator(response.data.followings_paginator)
            // setFollowersPaginator(response.data.followings_paginator)


        } catch (error) {
            console.log(error);
        }
    };

    // if (onlineUsers.includes(authorizedUser)) {
    //     const 
    // }

    useEffect(() => {
        fetchAllUsersInfo(allUsersPage, followingsPage, followersPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineUsers, allUsersPage, followingsPage, followersPage]); // Пустой массив зависимостей, чтобы выполнить эффект только один раз при монтировании компонента

    const sortByLastConnectedUser = (users) => {
        const sortedUsers = [...users].sort((a, b) => {
            if (a.last_time_online === '0' && b.last_time_online !== '0') {
                return -1; // Первый пользователь онлайн, а второй нет
            } else if (a.last_time_online !== '0' && b.last_time_online === '0') {
                return 1; // Первый пользователь не онлайн, а второй онлайн
            } else {
                // Оба пользователи либо онлайн, либо не онлайн, сортировка по last_time_online
                return new Date(b.last_time_online) - new Date(a.last_time_online);
            }
        });

        return sortedUsers;
    }


    //   ВСЕ ПОЛЬЗОВАТЕЛИ
    const renderAllUsers = () => {
        const sortedUsers = sortByLastConnectedUser(allUsers)

        // console.log(allUsers, sortedUsers)
        return sortedUsers?.map((user) =>
            <div key={user.id} className="">
                <UserCard
                    username={user.username}
                    profile_pic={user.profile_pic}
                    first_name={user.first_name}
                    last_name={user.last_name}
                    birth_date={user.birth_date}
                    is_online={user.is_online}
                    last_time_online={user.last_time_online}
                    moodStatusId={user.mood_statuses}
                    moodStatusBase={moodStatusBase}
                />

                <div className='br_border_line_gray' />

            </div>
        );
    };

    //   НА МЕНЯ ПОДПИСАНЫ
    const renderFollowers = () => {

        if (followers && followers.length > 0) {
            return followers.map((follower) =>
                <div key={`follower-${follower.id}`} className="">

                    <UserCard
                        username={follower.username}
                        profile_pic={follower.profile_pic}
                        first_name={follower.first_name}
                        last_name={follower.last_name}
                        birth_date={follower.birth_date}
                        is_online={follower.is_online}
                        last_time_online={follower.last_time_online}
                        moodStatusId={follower.mood_statuses}
                        moodStatusBase={moodStatusBase}
                    />

                    <div className='br_border_line_gray' />
                </div>
            );
        } else {
            return (
                <div>
                    {t('friends-nobody-subscribed')}
                </div>
            )
        }
    };


    const renderFollowings = () => {
        if (followings && followings.length > 0) {
            return followings?.map((following) =>
                <div key={`fol-${following.id}`} className="">

                    <UserCard
                        username={following.username}
                        profile_pic={following.profile_pic}
                        first_name={following.first_name}
                        last_name={following.last_name}
                        birth_date={following.birth_date}
                        is_online={following.is_online}
                        last_time_online={following.last_time_online}
                        moodStatusId={following.mood_statuses}
                        moodStatusBase={moodStatusBase}

                    />

                    <div className='br_border_line_gray' />
                </div>
            );
        } else {
            return (
                <div>
                    {t('friends-you-dont-subscribed-anyone')}
                </div>
            )
        }
    };

    //   Показываем пользователй в поиске
    const renderSearchUsers = () => {
        let searchUsersCount = 0; // Инициализируем счетчик

        const searchResults = allUsers.map(user => {
            if (
                user.username.toLowerCase().includes(searchData) ||
                user.first_name.toLowerCase().includes(searchData) ||
                user.last_name.toLowerCase().includes(searchData) ||
                formattedDateWithoutClock(user.birth_date).includes(searchData)
            ) {
                searchUsersCount++; // Увеличиваем счетчик при совпадении
                return (
                    <div key={user.id} className="">

                        <UserCard
                            username={user.username}
                            profile_pic={user.profile_pic}
                            first_name={user.first_name}
                            last_name={user.last_name}
                            birth_date={user.birth_date}
                            is_online={user.is_online}
                            last_time_online={user.last_time_online}
                            moodStatusId={user.mood_statuses}
                            moodStatusBase={moodStatusBase}
                        />

                    </div>
                );
            }
            return null; // Если пользователь не соответствует условиям, возвращаем null
        });

        if (searchUsersCount === 0) {
            return (
                <div>
                    <p>{t('friends-empty-search')}</p>
                </div>
            );
        }

        return searchResults; // Возвращаем результаты поиска
    };

    const handleInputChange = (e) => {
        setSearchData(e.target.value.toLowerCase());
    };

    const handleComponentChange = (component) => {
        setSelectedComponent(component);
    };

    return (
        <div className="main_white_container w-[800px] sm:w-screen sm:pb-10">

            <div className='flex flex-col gap-2'>
                <input type="text" placeholder={t('friends-search')} className='input_style' onChange={handleInputChange}></input>

                {searchData ?
                    (renderSearchUsers())
                    : (
                        <>
                            {/* Меню для переключения между компонентами */}
                            <div className="flex flex-col gap-2 pb-">
                                <div className="br_border_line_gray" />
                                <div className="flex justify-center gap-1">
                                    <button className="button_blue sm:!rounded-full sm:!text-[10px]" onClick={() => handleComponentChange('allUsers')}>{t('friends-all-people')}</button>
                                    <button className="button_blue sm:!rounded-full sm:!text-[10px]" onClick={() => handleComponentChange('followers')}>{t('friends-followMe-people')}</button>
                                    <button className="button_blue sm:!rounded-full sm:!text-[9px]" onClick={() => handleComponentChange('followings')}>{t('friends-iFollow-people')}</button>
                                </div>
                                <div className="br_border_line_gray" />
                            </div>

                            {selectedComponent === 'allUsers' &&
                                <>
                                    <p className=' rounded w-full pl-2 pr-2  '>{t('friends-all-people')}:</p>
                                    <div className="br_border_line_gray" />

                                    {renderAllUsers()}
                                    
                                    <Paginator paginatorData={allUsersPaginator} fetchDataPosts={fetchAllUsersInfo} changePage={setAllUsersPage} />


                                </>}
                            {selectedComponent === 'followers' &&
                                <>
                                    <p className=' rounded w-full pl-2 pr-2 '>{t('friends-followMe-people')}:</p>
                                    <div className="br_border_line_gray" />

                                    {renderFollowers()}
                                    
                                    {/* <Paginator paginatorData={followersPaginator} fetchDataPosts={fetchAllUsersInfo} changePage={setFollowersPage} /> */}

                                </>}
                            {selectedComponent === 'followings' &&
                                <>
                                    <p className=' rounded w-full pl-2 pr-2 '>{t('friends-iFollow-people')}:</p>
                                    <div className="br_border_line_gray" />

                                    {renderFollowings()}
                                    <Paginator paginatorData={followingsPaginator} fetchDataPosts={fetchAllUsersInfo} changePage={setFollowingsPage} />

                                    
                                </>}

                        </>

                    )
                }

            </div>

        </div>
    )
}

export default FriendsPage;