import moment from 'moment';

export const formattedDate = (date) => { return moment(date).format('DD-MM-YYYY HH:mm') }
export const formattedDateWithoutClock = (date) => { return moment(date).format('DD-MM-YYYY') }
export const formattedDateWithoutClockReversed = (date) => { return moment(date).format('YYYY-MM-DD') }

export const formattedTime = (date) => { return moment(date).format('HH:mm:ss') }
export const formattedTimeMin = (date) => { return moment(date).format('HH:mm') }

export const getChatRoomNumber = (authorizedUser_username, username) => {
    
    const getCharCode = (name) => {
        let nameCharCode = '';

        for (let i = 0; i < (name)?.length; i++) {
            const char = name[i].charCodeAt();
            nameCharCode += String(char)
          }

        return nameCharCode
    }

    // eslint-disable-next-line no-undef
    return BigInt(getCharCode(authorizedUser_username)) + BigInt(getCharCode(username))
}


// Вспомогательная функция в числе судьбы
function sumDigits(number) {
    // Преобразуем число в строку
    var strNumber = number.toString();
    
    // Суммируем цифры
    var sum = 0;
    for (var i = 0; i < strNumber.length; i++) {
        sum += parseInt(strNumber.charAt(i), 10);
    }
    
    // Если сумма больше 9, продолжаем складывать цифры
    while (sum > 9) {
        sum = sumDigits(sum);
    }
    
    return sum;
}

// Считаем число судьбы
export function sumDigitsOfBirthdate(birthdate) {
    // Удаляем символы '-' из даты рождения
    birthdate = birthdate.replace(/-/g, '');
    
    // Преобразуем строку в число
    var number = parseInt(birthdate, 10);
    
    // Считаем сумму цифр
    var sum = sumDigits(number);
    
    return sum;
}