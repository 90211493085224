import {createSlice} from '@reduxjs/toolkit';

const profileUserSlice = createSlice ({
    name: 'profileUser',
    initialState: {
        profileUser: {},
        updateProfile: false,
        matrixListOfEnergies: []
    },
    reducers: {
        updateProfileUserInfo(state, action) {
            state.profileUser = action.payload
        },
        updateProfileAction(state, action) {
            state.updateProfile = action.payload
        },
        updateListOfEnergiesAction(state, action) {
            state.matrixListOfEnergies = action.payload
        },
    }
})

export const {updateProfileUserInfo, updateProfileAction, updateListOfEnergiesAction} = profileUserSlice.actions; 

export default profileUserSlice.reducer;