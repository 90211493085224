import { t } from "i18next"
import { useSelector } from "react-redux"
import axios from "axios"
import { baseBackendUrl } from "../websocket_comp"
import SendMessageSVG from "../../../svg/svg_send_message"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateProfileAction } from "../../store/profileUserInfoSlice"

const ProfileAboutMe = () => {
    const dispatch = useDispatch();
    const [textareaVisible, setTextareaVisible] = useState(true);

    const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)
    const profileUser = useSelector(state => state.profileUserSlice.profileUser);
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser);

    const user = profileUser.user;
    const username = user.username;
    const [userBio, setUserBio] = useState(user.bio);

    const bioInBase = user.bio

    const handleTextareaKeyPress = (e) => {
        // Если нажата клавиша "Ctrl" (или "Command" на Mac) + "Enter",
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
            saveBioInBase();
            setTextareaVisible(!textareaVisible)
        }
    };

    const handleTextareaChange = (e) => {
        const element = e.target;
        element.style.height = 'auto'; // Сначала сбросим высоту
        // Установим высоту textarea так, чтобы она соответствовала высоте содержимого
        element.style.height = element.scrollHeight + 'px';
        setUserBio(e.target.value);
    };

    const saveBioInBase = async () => {
        dispatch(updateProfileAction(!updateProfile))
        const url = `${baseBackendUrl}/${username}/add_bio`;
        const data = { bio: userBio };
        await axios.post(url, data);
    }

    useEffect(() => {
        bioInBase?.length ? setTextareaVisible(false) : setTextareaVisible(true)
    }, [bioInBase])

    if (authorizedUser.username === username) {
        return (
            <>
                <section name="profile_bio" className="profile_part_container">


                    {
                        textareaVisible
                            ?
                            <div className="flex flex-col items-center justify-center gap-5 w-full">

                                <p className="popup_title">{t('profile-bio-title')}:</p>

                                <div className="flex w-full">
                                    <textarea className={`input_style min-h-fit h-fit max-h-[300px] border-[1px] !rounded-3xl resize-none`}
                                        type="text" name="bio" placeholder={t('profile-bio')} autoComplete="off" maxLength={500}
                                        value={userBio ? userBio : ''}
                                        onChange={handleTextareaChange}
                                        onKeyDown={handleTextareaKeyPress}
                                    />
                                    {
                                        userBio === bioInBase
                                            ? null
                                            :
                                            <button className="pl-2" onClick={saveBioInBase}>
                                                <SendMessageSVG className='fill-[#1da1f2] hover:w-5 ease-in-out duration-500 hover:-rotate-180' />
                                            </button>
                                    }
                                </div>

                            </div>

                            :
                            <div className="w-full flex flex-col items-center justify-center gap-5">
                                <p className="popup_title w-fit p-1 !m-0">{t('profile-bio-title')}:</p>
                                <div className="w-full flex cursor-pointer" onClick={() => { setTextareaVisible(true) }}>
                                    <p className="w-full textarea_div_bio flex items-center justify-center whitespace-pre-line">{userBio}</p>
                                </div>

                            </div>

                    }



                </section>

            </>
        )
    } else {
        if (userBio) {
            return (
                <>
                    <section name="profile_bio" className="profile_part_container">
                        <p className="popup_title">{t('profile-bio-title')}:</p>

                        <div className="w-full flex">
                            <p className="w-full textarea_div_bio flex items-center justify-center whitespace-pre-line">
                                {userBio}
                            </p>
                        </div>
                    </section>

                </>
            )
        }
    }
}


export default ProfileAboutMe;
