import ReactDOM from 'react-dom';
import React, { useRef, useEffect } from 'react';
import LoginPopup from './popups_folder/popup_login';
import CodeApprove from './popups_folder/popup_code_approve';
import DeletePost from './popups_folder/popup_delete_post';
import MatrixDescriptionPopup from './popups_folder/popup_matrix_description';
import HealthMapPopup from './popups_folder/popup_matrix_healthMap';
import EditProfile from './popups_folder/popup_edit_profile';
import ForgetPassword from './popups_folder/popup_forget_password';
import CreatePost from './popups_folder/popup_create_post';
import FollowPopup from './popups_folder/popup_follow';
import CarmicProgramPopup from './popups_folder/popup_matrix_carmicProgram';
import PurposesPopup from './popups_folder/popup_matrix_purposes';
import YearEnergyDesctiptionPopup from './popups_folder/popup_matrix_year_energy_description';
import DeleteAccountPopup from './popups_folder/popup_delete_account';
import MatrixDescriptionMenuPopup from './popups_folder/popup_matrix_menu_description';
import MatrixDescriptionEveryEnergyPopup from './popups_folder/popup_matrix_energy_description';
import ZodiacDescription from './popups_folder/popup_zodiac_desc';
import MatrixInformation from './popups_folder/popup_matrix_info';
// import CreditsPopup from './popups_folder/popup_credits';
import AddTelegramPopup from './popups_folder/popup_add_telegram';
import MessagesPopup from './popups_folder/popup_messages';
import RelationshipPairPopup from './popups_folder/popup_relationship_pair';
import MatrixPairDescriptionEveryEnergyPopup from './popups_folder/popup_matrix_pair_energy_description';
import MyMatricesPopup from './popups_folder/popup_my_matrices';
import ProfileLikePopup from './popups_folder/popup_profile_like';
import PifagorDescriptionPopup from './popups_folder/popup_pifagor';
import ParentsKarmaPopup from './popups_folder/popup_parents_karma';
import PifagorWillGraphPopup from './popups_folder/popup_pifagor_will_graph';
import ProfileInfoPopup from './popups_folder/popup_profile_info';
import GradientWallpaperPopup from './popups_folder/popup_gradient';
import FriendMatrixPopup from './popups_folder/popup_matrix_friends';
import DestinyNumberPopup from './popups_folder/popup_destiny_number';
import MatrixProgrammsPopup from './popups_folder/popup_matrix_programms';
import LoginPopupJoin from './popups_folder/popup_login_join';
import RegisterPopupJoin from './popups_folder/popup_register_join';


// Сами попап окна
const Popups = ({ choosePopup, setPopupOpen, additionInfo=null }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeAllPopups();
      }
    };
    

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        closeAllPopups();
        // document.querySelector('#popup_open_from_right').classList.remove('right_to_zero');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  });

  const closeAllPopups = () => {
    setPopupOpen(false);
  };

  let popupComponent = null;

  const popupComponents = {
    login_popup: <LoginPopupJoin close={closeAllPopups} type={additionInfo} />,
    register_popup: <RegisterPopupJoin close={closeAllPopups} type={additionInfo} />,

    // profile
    login_warning: <LoginPopup close={closeAllPopups} type={additionInfo} />,
    code_approve: <CodeApprove close={closeAllPopups} additionInfo={additionInfo} />,
    edit_profile: <EditProfile close={closeAllPopups} additionInfo={additionInfo} />,
    forget_password: <ForgetPassword close={closeAllPopups} additionInfo={additionInfo} />,
    delete_account_popup: <DeleteAccountPopup close={closeAllPopups} additionInfo={additionInfo} />,
    // credits_popup:  <CreditsPopup close={closeAllPopups} additionInfo={additionInfo} />,
    add_telegram_popup:  <AddTelegramPopup close={closeAllPopups} additionInfo={additionInfo} />,
    messages_popup:  <MessagesPopup close={closeAllPopups} additionInfo={additionInfo} />,
    profile_like_popup: <ProfileLikePopup close={closeAllPopups} additionInfo={additionInfo} />,
    profile_info: <ProfileInfoPopup close={closeAllPopups} additionInfo={additionInfo} />,

    gradient_wallpaper_popup: <GradientWallpaperPopup close={closeAllPopups} additionInfo={additionInfo} />,

    following: <FollowPopup close={closeAllPopups} additionInfo={additionInfo} />,
    follower: <FollowPopup close={closeAllPopups} additionInfo={additionInfo} />,

    zodiac_description: <ZodiacDescription close={closeAllPopups} additionInfo={additionInfo} />,

    // Posts
    create_post: <CreatePost close={closeAllPopups} additionInfo={additionInfo} />,
    edit_post: <CreatePost close={closeAllPopups} additionInfo={additionInfo} />,
    delete_post: <DeletePost close={closeAllPopups} additionInfo={additionInfo} />,

    // Matrix
    matrix_description: <MatrixDescriptionPopup close={closeAllPopups} additionInfo={additionInfo} />,
    health_map: <HealthMapPopup close={closeAllPopups} additionInfo={additionInfo} />,
    carmic_program: <CarmicProgramPopup close={closeAllPopups} additionInfo={additionInfo} />,
    purposes: <PurposesPopup close={closeAllPopups} additionInfo={additionInfo} />,
    year_energy_description: <YearEnergyDesctiptionPopup close={closeAllPopups} additionInfo={additionInfo} />,
    matrix_description_menu: <MatrixDescriptionMenuPopup close={closeAllPopups} additionInfo={additionInfo} />,
    energys_description: <MatrixDescriptionEveryEnergyPopup close={closeAllPopups} additionInfo={additionInfo} />,
    pair_energys_description: <MatrixPairDescriptionEveryEnergyPopup close={closeAllPopups} additionInfo={additionInfo} />,
    matrix_information: <MatrixInformation close={closeAllPopups} additionInfo={additionInfo} />,
    relationship_pair: <RelationshipPairPopup close={closeAllPopups} additionInfo={additionInfo} />,
    my_matrices: <MyMatricesPopup close={closeAllPopups} additionInfo={additionInfo} />,
    parents_karma: <ParentsKarmaPopup close={closeAllPopups} additionInfo={additionInfo} />,
    friend_matrix_popup: <FriendMatrixPopup close={closeAllPopups} additionInfo={additionInfo} />,
    matrix_programms:  <MatrixProgrammsPopup close={closeAllPopups} additionInfo={additionInfo} />,
    
    // Pifagor
    pifagor_popup: <PifagorDescriptionPopup close={closeAllPopups} additionInfo={additionInfo} />,
    pifagorWillGraph_popup: <PifagorWillGraphPopup close={closeAllPopups} additionInfo={additionInfo} />,

    destiny_number_popup: <DestinyNumberPopup close={closeAllPopups} additionInfo={additionInfo} />,
  };
  
  popupComponent = popupComponents[choosePopup];

  return (
    <>
      {popupComponent && (<div>
        <div id="popup_open_from_right" 
            className={`popup_window__animation
                       flex flex-col items-center justify-center overflow-x-hidden
                       fixed w-[100dvw] h-[100dvh]
                       backdrop-blur-sm bg-gray-950/[.7] hover:bg-gray-950/[.8] ease-in-out duration-700 z-20
                       `}>
          
          <div className='h-[90dvh] w-[90dvw] sm:h-[100dvh] sm:w-[100dvw] rounded-3xl sm:rounded-none flex justify-center overflow-hidden'
          // ref={popupRef}
          >
              {popupComponent}
          </div>
        </div>
        </div>
      )}
    </>
  );
};

// Контейнер для вставки в главное приложение
const PopupContainer = ({ choosePopup, setPopupOpen=null, additionInfo=null }) => {
  const popupRoot = document.querySelector('#popups');

  return ReactDOM.createPortal(
    <Popups choosePopup={choosePopup} setPopupOpen={setPopupOpen} additionInfo={additionInfo} />,
    popupRoot
  );
};

export default PopupContainer;
