import { useSelector } from "react-redux";
import { formattedDateWithoutClock } from "../services";


const everyNumberPlus = (inputNumber) => {
    let outputNumber = 0;
    for (let i = 0; i < inputNumber.length; i++) {
        const digit = parseInt(inputNumber[i], 10);
        outputNumber += digit;
    }
    return outputNumber;
}

// Функция для подсчета количества определенной цифры в числе
const countDigits = (number, digit) => {
    const digitString = digit.toString();
    const numberString = number.toString();
    let count = 0;
  
    for (let i = 0; i < numberString.length; i++) {
      if (numberString[i] === digitString) {
        count++;
      }
    }
  
    return count;
  };

// Функция для подсчета цифр в массиве чисел
const countDigitsInNumbers = (numbers, digit) => {
    let totalCount = 0;
  
    numbers.forEach((number) => {
      totalCount += countDigits(number, digit);
    });
  
    return totalCount;
  };

function removeZeros(number) {
    // Преобразовать число в строку и удалить все нули
    const stringWithoutZeros = number.toString().replace(/0+/g, '');
  
    // Преобразовать обратно в число
    const numberWithoutZeros = parseFloat(stringWithoutZeros);
  
    return numberWithoutZeros;
}

export const PifagorCalculationFunc = () => {
    const profileUser = useSelector(state => state.profileUserSlice.profileUser);
    const user = profileUser.user;
    // const username = user.username;
    const birthDate = user.birth_date

    let birthDayBefore, birthMonthBefore, birthYearBefore;
    [birthDayBefore, birthMonthBefore, birthYearBefore] = formattedDateWithoutClock(birthDate).split("-");

    const birthDay = Number(birthDayBefore)
    const birthMounth = Number(birthMonthBefore)
    const birthYear = Number(birthYearBefore)

    const birthDateString = birthDayBefore + birthMonthBefore + birthYearBefore;

    let firstPifagorNumber = everyNumberPlus(birthDateString);
    let secondPifagorNumber = everyNumberPlus(String(firstPifagorNumber));
    let thirdPifagorNumber = removeZeros(firstPifagorNumber - String(birthDay)[0] * 2);
    let fourthPifagorNumber = everyNumberPlus(String(thirdPifagorNumber));

    const allNumbers = [birthDay, birthMounth, birthYear, firstPifagorNumber, secondPifagorNumber, thirdPifagorNumber, fourthPifagorNumber];
    
    // console.log(allNumbers)

    const count1 = countDigitsInNumbers(allNumbers, 1) === 0 ? '-' : '1'.repeat(countDigitsInNumbers(allNumbers, 1));
    const count2 = countDigitsInNumbers(allNumbers, 2) === 0 ? '-' : '2'.repeat(countDigitsInNumbers(allNumbers, 2));
    const count3 = countDigitsInNumbers(allNumbers, 3) === 0 ? '-' : '3'.repeat(countDigitsInNumbers(allNumbers, 3));
    const count4 = countDigitsInNumbers(allNumbers, 4) === 0 ? '-' : '4'.repeat(countDigitsInNumbers(allNumbers, 4));
    const count5 = countDigitsInNumbers(allNumbers, 5) === 0 ? '-' : '5'.repeat(countDigitsInNumbers(allNumbers, 5));
    const count6 = countDigitsInNumbers(allNumbers, 6) === 0 ? '-' : '6'.repeat(countDigitsInNumbers(allNumbers, 6));
    const count7 = countDigitsInNumbers(allNumbers, 7) === 0 ? '-' : '7'.repeat(countDigitsInNumbers(allNumbers, 7));
    const count8 = countDigitsInNumbers(allNumbers, 8) === 0 ? '-' : '8'.repeat(countDigitsInNumbers(allNumbers, 8));
    const count9 = countDigitsInNumbers(allNumbers, 9) === 0 ? '-' : '9'.repeat(countDigitsInNumbers(allNumbers, 9));

    // Строки
    const count147 = (count1 + count4 + count7).replace(/-/g, '').length
    const count258 = (count2 + count5 + count8).replace(/-/g, '').length
    const count369 = (count3 + count6 + count9).replace(/-/g, '').length

    // Столбцы
    const count123 = (count1 + count2 + count3).replace(/-/g, '').length
    const count456 = (count4 + count5 + count6).replace(/-/g, '').length
    const count789 = (count7 + count8 + count9).replace(/-/g, '').length

    // Восходящая диагональ
    const count357 = (count3 + count5 + count7).replace(/-/g, '').length
    // Сподающая диагональ
    const count159 = (count1 + count5 + count9).replace(/-/g, '').length

        // СЧИТАЕМ ЧАРТЫ 
    // Данные для графика Пифагора  
    const yearList = Array.from({ length: 10 }, (_, index) => {
        const year = birthYear + index * 12;
        const month = 1; // Например, январь
        const day = 1; // Например, 1-е число
        return new Date(year, month - 1, day); // Месяцы в JavaScript начинаются с 0
      });

    const numbersInTimeList = [];
    const numberAsString = String(birthDay * birthMounth * birthYear);
    
    // Добавляем каждую цифру в список
    for (const digitChar of numberAsString) {numbersInTimeList.push(Number(digitChar));}

    // Добавляем все цифры еще раз в список
    numbersInTimeList.push(...numbersInTimeList);

    // Строим График СУДЬБЫ И ВОЛИ
    const pifagorDestinyBefore = Number(birthDayBefore + birthMonthBefore) * birthYear
    const pifagorDestiny = String(pifagorDestinyBefore).length > 6 ? pifagorDestinyBefore : Number("0" + String(pifagorDestinyBefore))

    const pifagorWillBefore = Number((birthDayBefore + birthMonthBefore).replace(/0/g, '1')) * Number(birthYearBefore.replace(/0/g, '1'))
    const pifagorWill = String(pifagorWillBefore).length > 6 ? pifagorWillBefore : Number("0" + String(pifagorWillBefore))

    let pifagorDestinyList = [];
    let pifagorWillList = [];

    for (const digitChar of String(pifagorDestiny)) {pifagorDestinyList.push(Number(digitChar));}
    pifagorDestinyList.push(...pifagorDestinyList)
    for (const digitChar of String(pifagorWill)) {pifagorWillList.push(Number(digitChar));}
    pifagorWillList.push(...pifagorWillList)

    return [count1, count2, count3, count4, count5, count6, count7, count8, count9, 
            count147, count258, count369, count123, count456, count789, count159, 
            count357, yearList, numbersInTimeList, pifagorDestinyList, pifagorWillList]
}
    
