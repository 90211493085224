import { Line, getElementAtEvent } from 'react-chartjs-2';
import { CategoryScale, TimeScale } from 'chart.js'; 
import Chart from 'chart.js/auto';
import { useRef, useState } from 'react';
import { t } from 'i18next';
import { format } from 'date-fns';
import { useEffect } from 'react';
import 'chartjs-adapter-date-fns';

Chart.register(CategoryScale, TimeScale);


const ChartComponent = ({yearListInner, numbersInTimeListInner}) => {
    const chartRef = useRef();

    const data = {
        labels: yearListInner,
        datasets: [
            {
            label: t('pifagor-graph-title_1'),
            fill: false,
            lineTension: 0,
            backgroundColor: 'white',
            borderColor: '#9DDDFC',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#A9F8B7',
            pointBackgroundColor: 'black',
            pointBorderWidth: 5,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointHitRadius: 10,
            data: numbersInTimeListInner,
            tension: 0.4,
            },
            {
                label: t('pifagor-graph-title_2'),
                fill: false,
                lineTension: 0.4, // Значение, определяющее, насколько сглаженной будет линия
                backgroundColor: 'white',
                borderColor: 'gray',
                borderCapStyle: 'butt',
                borderDash: [6],
                borderWidth: 2,
                borderJoinStyle: 'miter',
                pointBorderColor: 'blue',
                pointBackgroundColor: 'black',
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                data: [
                    {
                      x: yearListInner[numbersInTimeListInner.length/2],
                      y: 0// Ваше значение для 2020 года,
                    },
                    {
                        x: yearListInner[numbersInTimeListInner.length/2],
                        y: 10// Ваше значение для 2020 года,
                      },
                  ],
                tension: 0.4,
                },
                {
                    label: t('pifagor-graph-title_3'),
                    fill: false,
                    lineTension: 0.4, // Значение, определяющее, насколько сглаженной будет линия
                    backgroundColor: 'white',
                    borderColor: 'orange',
                    borderCapStyle: 'butt',
                    borderDash: [6],
                    borderWidth: 2,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'blue',
                    pointBackgroundColor: 'black',
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    data: [
                        {
                          x: new Date(),
                          y: 0// Ваше значение для 2020 года,
                        },
                        {
                            x: new Date(),
                            y: 10// Ваше значение для 2020 года,
                          },
                      ],
                    tension: 0.4,
                    }
        ]
    };

    const options = {
        scales: {
            y: {
              beginAtZero: true, // Ось Y начинается с нуля
              max: 9, // Максимальное значение на оси Y
            },
            x: {
                type: 'time',
                time: {
                    unit: 'year'
                },
                ticks: {
                    maxTicksLimit: 20,
                    callback: function (value, index, values) {
                      // Форматируем текст для отображения
                      return `(${index} ${t('years')}) ${format(value, 'yyyy')}`;
                    },
                  },
                },

          },
    }

      
    const onClick = (event) => {
        if (getElementAtEvent(chartRef.current, event).length > 0){
            const dataPoint = getElementAtEvent(chartRef.current, event)[0].element.$context.raw;
            alert(t(`pifagor-graph-${dataPoint}`))
        }
    }


    return (
      <div className='w-full p-1 h-full'>
        <Line 
            data={data} 
            options={options} 
            height={window.outerWidth < 600 ? 300 : 150}
            onClick={onClick}
            ref = {chartRef}
            // plugins={[arbitraryLine]}
        />
      </div>
    );
  };
  
  const ChartDestinyWill = ({yearListInner, pifagorDestinyInner, pifagorWillInner}) => {
    const [mainDates, setMainDates] = useState([]);

    const data = {
        labels: yearListInner,
        datasets: [
            {
            label: t('pifagor-graph-destiny'),
            fill: false,
            lineTension: 0,
            backgroundColor: 'white',
            borderColor: 'red',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#9AA2F8',
            pointBackgroundColor: 'black',
            pointBorderWidth: 5,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointHitRadius: 10,
            data: pifagorDestinyInner,
            tension: 0.4,
            },
            {
                label: t('pifagor-graph-will'),
                fill: false,
                lineTension: 0,
                backgroundColor: 'white',
                borderColor: '#A9F8B7',
                borderCapStyle: 'butt',
                borderWidth: 7,
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#9AA2F8',
                pointBackgroundColor: 'black',
                pointBorderWidth: 5,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: pifagorWillInner,
                tension: 0.4,
                },
            {
                label: t('pifagor-graph-title_2'),
                fill: false,
                lineTension: 0.4, // Значение, определяющее, насколько сглаженной будет линия
                backgroundColor: 'white',
                borderColor: 'gray',
                borderCapStyle: 'butt',
                borderDash: [6],
                borderWidth: 2,
                borderJoinStyle: 'miter',
                pointBorderColor: 'blue',
                pointBackgroundColor: 'black',
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                data: [
                    {
                      x: yearListInner[pifagorDestinyInner.length/2],
                      y: 0// Ваше значение для 2020 года,
                    },
                    {
                        x: yearListInner[pifagorDestinyInner.length/2],
                        y: 10// Ваше значение для 2020 года,
                      },
                  ],
                tension: 0.4,
                },
                {
                    label: t('pifagor-graph-title_3'),
                    fill: false,
                    lineTension: 0.4, // Значение, определяющее, насколько сглаженной будет линия
                    backgroundColor: 'white',
                    borderColor: 'orange',
                    borderCapStyle: 'butt',
                    borderDash: [6],
                    borderWidth: 2,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'blue',
                    pointBackgroundColor: 'black',
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    data: [
                        {
                          x: new Date(),
                          y: 0// Ваше значение для 2020 года,
                        },
                        {
                            x: new Date(),
                            y: 10// Ваше значение для 2020 года,
                          },
                      ],
                    tension: 0.4,
                    }
        ]
    };

    const options = {
        scales: {
            y: {
              beginAtZero: true, // Ось Y начинается с нуля
              max: 9, // Максимальное значение на оси Y
            },
            x: {
                type: 'time',
                time: {
                    unit: 'year'
                },
                ticks: {
                    maxTicksLimit: 20,
                    callback: function (value, index, values) {
                      // Форматируем текст для отображения
                      return `(${index} ${t('years')}) ${format(value, 'yyyy')}`;
                    },
                  },
                },

          },
    }

    const chartRef = useRef();


    useEffect(() => {
        const datesList = [];

        // Проверка на совпадение точек при обновлении данных
        const checkIntersection = () => {
          if (chartRef.current && chartRef.current.data.datasets) {
            const datasets = chartRef.current.data.datasets;
    
            // Перебираем все точки
            for (let i = 0; i < (datasets[0].data.length / 2); i++) {
              const yValue1 = datasets[0].data[i];
              const yValue2 = datasets[1].data[i];

              if (yValue1 === yValue2) {
                // console.log(datasets[0], )
                // datasets[0].pointBorderColor = 'red'
                datesList.push(`${format(chartRef.current.data.labels[i], 'yyyy')}`)

                // Если значения совпадают, то точки пересекаются
                // chartRef.current.chart.setDatasetVisibility(0, i, true);
                // chartRef.current.chart.setDatasetVisibility(1, i, true);
              }
            }
          }
        };
    
        checkIntersection();
        setMainDates(datesList)

      }, [yearListInner, pifagorDestinyInner, pifagorWillInner]);


    return (
      <div className='w-full p-1 h-full'>
        <Line 
            data={data} 
            options={options} 
            height={window.outerWidth < 600 ? 300 : 150}
            // onClick={onClick}
            ref = {chartRef}
            // plugins={[arbitraryLine]}
        />
        <div className='flex gap-2 p-2'>
            <p>{t('pifagor-important_dates')}</p>
            <p>{mainDates.join(', ')}</p>  
        </div>
    
    </div>
    );
  };

  export { ChartComponent, ChartDestinyWill };