import ExitCrossSVG from "../../../svg/exitCross_svg";
import { t } from "i18next";

import { useDispatch } from "react-redux";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import { useEffect } from "react";

const ZodiacDescription = ({ close, additionInfo}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    return (
            // Основа
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2  w-fit h-fit sm:h-full sm:w-full">                
                
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                        {t('profile-zodiac-description-title')}
                    </div>

                    <div className="flex w-full flex-col gap-5 p-5">
                    {/* Основа */}
                    
                        <div className="flex items-center justify-center gap-10">
                            <img className="w-10" src={`/images/zodiac/zodiac/${additionInfo}.png`} alt="zodiac" />
                            <div className="flex w-fit bg_bright_blue items-center justify-center text-center rounded-full text-white pl-2 pr-2 m-1 gap-10">
                                {t(`profile-zodiac-${additionInfo}`)}
                            </div>
                            <img className="w-10" src={`/images/planets/zodiac/${additionInfo}.png`} alt="planets" />
                        </div>
                       
                        <strong>{t('profile-zodiac-description-element')}:</strong>
                        <p className="">{t(`profile-zodiac-description-element-${additionInfo}`)}</p>

                        <strong className="">{t('profile-zodiac-description-character')}:</strong>
                        <p className="">{t(`profile-zodiac-description-character-${additionInfo}`)}</p>


                {/* Основа */}
                </div>
            </div>
            <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
        </div>
    </div>    
)
}

export default ZodiacDescription;