import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Language = ({className}) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      setSelectedLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <header className={`${className} language_pannel`}>
      <button 
        className={`button_blue !w-14 hover:bg-blue-400 text-white py-2 px-4 ease-in-out duration-300 rounded ${selectedLanguage === 'en' ? 'opacity-50' : ''}`}
        onClick={() => changeLanguage('en')}>EN</button>
      <button 
        className={`button_blue !w-14 hover:bg-blue-400 text-white py-2 px-4 ease-in-out duration-300 rounded ${selectedLanguage === 'ru' ? 'opacity-50' : ''}`}
        onClick={() => changeLanguage('ru')}>RU</button>
      {/* <div>{t('text')}</div> */}
    </header>
  )
};

export default Language;
