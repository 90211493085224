const Main = () => {
  
    window.addEventListener('load', async () => {

        if (navigator) {

            if ('serviceWorker' in navigator) {
                try {
                    await navigator.serviceWorker.register('/service-worker.js')
                    // console.log("Service worker register success", reg)
                } catch (e) {
                    console.log('Service worker register fail:', e)
                }
            }
            
        }

    })
}

export default Main