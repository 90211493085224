const TitleChange = ({
    rusTitle = "Социальная сеть Матрицы Судьбы",
    engTitle = "Matrix Destiny Net",
    rusDescription = "Социальная сеть, которая внедряет духовное в Вашу жизнь уже сегодня. Матрица судьбы, астрология, психология, квадрат пифагора, календарь настроения и знакомства.",
    engDescription = "Social net Destiny Matrix Net",
    ruskeyWords = 'Матрица, Матрица Судьбы, Социальная сеть, Destiny Matrix, Destiny Matrix Net',
    engkeyWords = 'Матрица, Матрица Судьбы, Социальная сеть, Destiny Matrix, Destiny Matrix Net'
}) => {
    const language = localStorage.getItem('language');

    const metaTitle = document.querySelector('title');
    const title_str = language === 'ru' ? rusTitle : engTitle
    metaTitle.textContent = title_str

    const google_content = language === 'ru'
        ? rusDescription
        : engDescription
    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.setAttribute('content', google_content ) 

    const keyWords = language === 'ru' ? ruskeyWords : engkeyWords
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    metaKeywords.setAttribute('content', keyWords ) 
}

export default TitleChange