import ExitCrossSVG from "../../../svg/exitCross_svg";
import PopupContainer from "../popups_root";
import { useState, useEffect } from "react";
import { baseBackendUrl } from "../websocket_comp";
import axios from "axios";
import { t } from "i18next";

const ForgetPassword = ({close, additionInfo}) => {
    const [codePupupOpen, setCodePopupOpen] = useState(false);
    const [infoChanged, setInfoChanged] = useState(false);

    const [passwordPatternProblem, setPasswordPatternProblem] = useState()
    const [passwordConfirmProblem, setPasswordConfirmProblem] = useState()

    const [formData, setFormData] = useState({
        password: '',
        confirmation: '',
        username: ''
        });
        
    const handleInputChange = (e) => {
        const { name, value=null } = e.target;
        setInfoChanged(true);

        setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        }));
    };
    
    const sendCode = async (e) => {
        setCodePopupOpen(true);
        // console.log(codePupupOpen)
        const data = {username: formData.username}
        const url = `${baseBackendUrl}/register_code_send`

        await axios.post(url, data);
    }

    // Ошибка если паттерн не соответствует в Пароле
    const passwordPatternError = () => { 
        if (passwordPatternProblem) {
            return (
            <p id="password_error" className="text-xs p-1 text-red-500">
            {t('reg-error-password')}
            </p>
            )
        }
    }

    // Ошибка если пароли не сходятся
    const passwordConfirmError = () => {
        if (passwordConfirmProblem) {
            return (
                <p id="password_confirm_error" className="text-xs p-1 text-red-500">{t('reg-error-confirm-password')}</p>
            )
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Проверка на соответствие паттерну
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?]{8,20}$/;
        !passwordPattern.test(formData.password) ? setPasswordPatternProblem(true) : setPasswordPatternProblem(false)
    
        // Проверка на соответствие паролей
        formData.password === formData.confirmation ? setPasswordConfirmProblem(false) : setPasswordConfirmProblem(true)
    };
    
    useEffect(() => {
        if (passwordPatternProblem === false && passwordConfirmProblem === false) {
            sendCode();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordPatternProblem, passwordConfirmProblem]);

    const submitAfterApproval = async () => {
        const data = formData
        const url = `${baseBackendUrl}/forgot_password`

        await axios.post(url, data);
        close()
    }

    return (                    
<div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 lg:w-[700px] h-fit sm:h-full sm:w-full">
                {/* Обрабатываем закрытие окна */}

                <div className="exit_cross_div" onClick={close}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                <form className="flex flex-col gap-2 w-full" action="/" method="post" id="edit-profile-form" encType="multipart/form-data">

                    <input className="appearance-none shadow rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm inp usrname" 
                    type="text" name="username" placeholder={t('reg-username')} autoComplete="off" required onChange={handleInputChange}/>

                    {/* <!-- password --> */}
                    <div>
                        <input className="appearance-none shadow rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm inp pswd" 
                        type="password" name="password" placeholder={t('recovery-password')} value={formData.password} autoComplete="off" minLength="8" maxLength="20" onChange={handleInputChange} required />
                        {passwordPatternError()}
                    </div>

                    {/* <!-- password confirm --> */}
                    <div>
                        <input className="appearance-none shadow rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm inp cpswd" 
                        type="password" name="confirmation" placeholder={t('recovery-password-confirm')} value={formData.confirmation} autoComplete="off" onChange={handleInputChange} />

                        {passwordConfirmError()}
                    </div>

                    <button className={infoChanged ? 'button_green' : "button_grey_like_green pointer-events-none"} onClick={handleSubmit}>{t('profile-editProfileButton')}</button>

                </form>
                {codePupupOpen ? <PopupContainer t={t} choosePopup={'code_approve'} setPopupOpen={setCodePopupOpen} additionInfo={[submitAfterApproval, formData.username]} /> : null}

            </div>
            </div>
    )
}

export default ForgetPassword;