const PlanetSVG = ({className}) => {
    return (
        <svg version="1.0"
                width="1.4em" height="1.4em" fill="currentColor"  strokeWidth={10} stroke="currentColor" className={`${className}`} viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
 stroke="none">


<path d="M2255 5106 c-310 -41 -551 -113 -830 -251 -505 -248 -896 -636 -1154
-1143 -39 -77 -78 -142 -86 -146 -11 -4 -14 -13 -10 -25 3 -11 -16 -83 -44
-168 -104 -309 -135 -522 -128 -868 5 -254 19 -368 73 -580 197 -783 765
-1435 1514 -1738 319 -130 615 -187 965 -187 691 0 1300 245 1789 719 403 392
651 866 747 1425 27 156 37 511 19 682 -82 812 -548 1533 -1265 1955 -255 150
-584 266 -895 315 -140 22 -556 28 -695 10z m690 -196 c170 -30 437 -105 488
-137 5 -3 -19 -21 -54 -39 l-64 -33 -176 -1 c-164 0 -178 -1 -203 -21 -38 -30
-206 -367 -206 -413 0 -59 29 -79 190 -132 l145 -47 172 42 172 43 123 -40
c121 -40 502 -122 566 -122 24 0 60 19 135 68 l102 69 66 -81 c65 -80 179
-239 179 -249 0 -3 -91 -36 -202 -73 l-203 -68 -225 38 c-124 21 -225 39 -226
39 -1 1 -38 57 -82 124 -75 112 -85 123 -123 133 -26 7 -134 10 -305 8 l-264
-3 -150 -150 c-83 -82 -153 -159 -156 -171 -5 -21 79 -634 92 -667 9 -23 19
-28 356 -173 165 -71 303 -124 321 -124 18 0 97 16 177 37 l145 37 93 -62 92
-63 0 -154 0 -154 170 -341 170 -340 0 -171 c0 -105 4 -179 11 -192 6 -12 51
-63 100 -112 l89 -90 -47 -60 c-71 -91 -247 -268 -348 -350 -321 -261 -698
-432 -1116 -505 -159 -28 -469 -38 -631 -20 -342 37 -663 141 -946 306 -539
315 -934 823 -1105 1419 -111 387 -116 861 -13 1236 l23 84 33 -100 c18 -55
40 -108 49 -119 9 -10 110 -81 224 -158 l208 -139 -55 -60 -56 -60 0 -160 0
-159 94 -188 c52 -103 101 -194 110 -201 8 -7 100 -30 203 -50 l188 -37 5
-414 5 -415 83 -205 c46 -113 90 -215 97 -227 10 -15 58 -36 169 -73 164 -54
181 -56 224 -17 21 20 22 26 22 228 l0 208 143 107 c216 162 197 135 197 283
l0 126 161 160 c88 88 165 168 170 178 11 20 99 449 99 485 0 13 -13 37 -29
53 l-29 29 -161 0 -160 0 -112 188 c-61 103 -122 198 -136 212 l-23 25 -290 3
-290 3 -74 147 c-72 144 -96 178 -126 182 -49 7 -72 -3 -129 -56 l-61 -56 0
66 c0 83 -10 103 -99 191 l-71 69 0 68 c0 38 3 68 8 68 4 0 125 18 270 39
l264 39 228 137 228 137 122 204 c68 112 128 208 134 213 6 4 62 25 124 46 62
20 121 45 130 55 29 31 115 305 107 341 -11 51 -45 62 -316 108 -205 34 -257
46 -286 65 l-35 24 73 22 c99 30 241 59 369 76 159 20 510 12 665 -16z m-1213
-231 l108 -71 212 -36 c117 -19 214 -36 215 -37 2 -1 -5 -26 -15 -56 l-18 -54
-117 -40 c-64 -22 -124 -47 -132 -56 -9 -9 -71 -108 -138 -220 l-123 -203
-195 -117 -195 -118 -299 -42 c-287 -41 -301 -44 -327 -70 l-28 -27 0 -163 0
-162 85 -86 85 -87 0 -67 0 -67 -173 115 -172 115 -64 191 -63 191 23 52 c221
479 617 884 1104 1130 61 30 112 56 115 56 2 0 53 -32 112 -71z m1997 -46 c36
-20 100 -59 141 -86 127 -85 341 -260 330 -269 -3 -3 -32 -23 -65 -45 l-61
-41 -184 37 c-102 21 -249 59 -328 85 l-144 47 -170 -42 -170 -41 -60 17 c-32
10 -61 22 -64 26 -2 4 17 51 43 103 l48 96 156 0 157 0 148 74 c82 41 151 75
153 75 2 1 34 -15 70 -36z m-202 -913 c40 -60 83 -116 95 -124 13 -8 145 -35
294 -60 l271 -46 239 80 c131 44 241 80 245 80 7 0 76 -146 114 -240 64 -159
122 -403 146 -610 17 -150 7 -472 -20 -623 -53 -301 -150 -569 -298 -820 l-46
-79 -63 63 -64 63 0 168 0 167 -170 341 -170 340 0 165 c0 94 -4 174 -11 187
-16 35 -298 218 -334 218 -17 0 -99 -18 -183 -39 l-152 -39 -258 110 c-141 61
-259 112 -260 113 -2 2 -20 114 -39 250 l-36 247 99 99 99 99 215 0 215 0 72
-110z m-2301 -1002 c87 -174 46 -158 389 -158 l292 0 117 -195 c64 -107 127
-204 138 -215 20 -18 36 -20 155 -20 133 0 133 0 128 -22 -3 -13 -17 -79 -30
-148 l-25 -125 -173 -173 -172 -172 -4 -126 -3 -125 -160 -120 c-95 -72 -163
-130 -168 -145 -6 -14 -10 -95 -10 -180 0 -85 -1 -154 -3 -154 -2 0 -23 7 -48
15 l-44 15 -72 183 -73 182 0 423 c0 463 -2 478 -56 500 -16 6 -113 28 -215
48 l-185 36 -72 144 -72 144 0 97 0 98 152 152 c84 84 154 153 155 153 2 0 28
-51 59 -112z"/>
</g>
</svg>

    )
}

export default PlanetSVG;
