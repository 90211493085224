import { useState, useEffect } from "react";
import PictureAddSVG from "../../../svg/picture_add_svg";
import { baseBackendUrl } from "../websocket_comp";
import CancelCrossSVG from "../../../svg/cancel_cross_svg";
import axios from "axios";
import ExitCrossSVG from "../../../svg/exitCross_svg";
import Resizer from 'react-image-file-resizer';
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "../../store/profileUserInfoSlice";
import { updatePostsAction } from "../../store/postsSlice";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import Checkbox from "../checkbox";


const CreatePost = ({ close, additionInfo }) => {

    // const user = additionInfo[0]

    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    // const profileData = useSelector(state => state.profileUserSlice.profileUser)
    
    // const user = profileData.user
    const createPostType = additionInfo[0];

    const innerContent = additionInfo[1] || null
    const post_id = additionInfo[2] || null

    const profilePic = authorizedUser.profile_pic
    const username = authorizedUser.username

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)
    const updatePosts = useSelector(state => state.postsSlice.updatePosts)

    const [infoChanged, setInfoChanged] = useState(false)
    const [showPicture, setShowPicture] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [progress, setProgress] = useState(0);

    const [progressBar, setProgressBar] = useState(0);

    useEffect(() => {
      dispatch(updateDisplayNonOverflow(true))

        const interval = setInterval(() => {
          setProgressBar(prevProgress => (prevProgress + 1) % 101);
        }, 20);
    
        return () => {
          clearInterval(interval);
        };
        // eslint-disable-next-line 
      }, []);

    const [formData, setFormData] = useState({
        username: username,
        text: createPostType === 'edit' ? innerContent[0] : '',
        picture: createPostType === 'edit' ? innerContent[1] : '',
        post_id: post_id ? post_id : null,
        anonymous: false,
        });

    // if (createPostType === 'edit') {

    //     formData.text = innerContent[0]
    //     formData.picture = innerContent[1]
    // }

    const handleInputChange = (e) => {
        const element = e.target;
        element.style.height = 'auto'; // Сначала сбросим высоту
    
        // Установим высоту textarea так, чтобы она соответствовала высоте содержимого
        element.style.height = element.scrollHeight + 'px';

        const { name, value=null, files=null } = e.target;

        if (files) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
                }));
                return;
        };

        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
          }));
    
    };
    
    const deletePicture = () => {
        setFormData((prevState) => ({
            ...prevState,
            picture: ''
          }));
    }

    useEffect(() => { 
        formData.picture ? setShowPicture(true) : setShowPicture(false);
        if (formData.picture) {
          if (typeof formData.picture !== 'string') {
            setImageURL(URL.createObjectURL(formData.picture));
          } else {
            setImageURL(`${baseBackendUrl}/${formData.picture}`);
          }
        }
      }, [formData.picture]);

    useEffect(() => { 
        if (formData.text.length > 0 || formData.picture) {
            setInfoChanged(true);
        } else {
            setInfoChanged(false);
        }
     }, [formData])


    const showPictureFunc = () => {
        // делаем ссылку из прикреплённого изображения
        // console.log(imageURL)


        return (
            <div className="w-full h-[300px] bg-center mt-2.5 mb-2.5 bg-contain bg-no-repeat" style={{ backgroundImage: `url(${imageURL})` }}>
    
                <button className="hover:fill-red-500 hover:rotate-90 ease-in-out duration-300" type='button' onClick={deletePicture}><CancelCrossSVG /></button>
                <img src="images/spinner.gif" id='spinner' height="70px" alt="spinner" />

            </div>
        )
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const submitFormData = new FormData();

        submitFormData.append('username', formData.username)
        submitFormData.append('text', formData.text)
        submitFormData.append('post_id', formData.post_id)
        submitFormData.append('anonymous', formData.anonymous)

        const picturePromise = new Promise((resolve, reject) => {
          if (formData.picture) {
            Resizer.imageFileResizer(
              formData.picture,
              800,
              800,
              'JPEG',
              90,
              0,
              (resizedPictureImage) => {
                submitFormData.append('picture', resizedPictureImage);

                resolve();
              },
              'file'
            );
          } else {
              resolve();
            }
        });


      try {
          await Promise.all([picturePromise]);
          handleClose()

          const data = submitFormData
          const url = `${baseBackendUrl}/createpost`
          const response = await axios.post(url, data, {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setProgress(progress);
            },
          });
          console.log('Upload successful', response.data);

          dispatch(updateProfileAction(!updateProfile))

        } catch (error) {
          console.error('Error uploading file', error);
        }
      


        // updatePosts()
        dispatch(updatePostsAction(!updatePosts))
        
        handleClose()
    }

    return (
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 lg:w-[700px] h-fit sm:h-full sm:w-full">

            <div className="exit_cross_div" onClick={handleClose}>
                <ExitCrossSVG className="exit_cross_svg" />
            </div>

            <form id="createPostForm" action="/" method="POST" 
                  className="flex flex-col items-center justify-center gap-2 w-full h-full pt-8" 
                  encType="multipart/form-data" onSubmit={handleSubmit}>

                <div className="flex w-full h-fit">
                    {/* фотка */}
                    <div className="small-profilepic !w-12 !h-11 border-2 border_color" style={{ backgroundImage: `url(${baseBackendUrl}${profilePic})` }}></div>
                    
                    {/* текстареа */}
                    <textarea className="w-full border-2 rounded-lg p-1 min-h-[10em] max-h-[35em] overflow-hidden focus:outline-slate-500 outline-1 border_color" 
                       name="text" autoFocus placeholder={t('profile-postPlaceholder')} 
                      //  rows="5"
                       id="post-text"
                        maxLength={500} 
                       onChange={handleInputChange} 
                       value={formData.text}>
                    </textarea>
                </div>
    
                <input type="hidden" id="img-change" value="false"></input>

    
                {/* <!-- тут появляется картинка, когда загружена --> */}
                {showPicture ? showPictureFunc() : null}
    
    
                <div className="w-full">
    
                    {/* <!-- тут прикрепляется картинка --> */}
                    <div className="flex justify-between p-1">
                        <div className="flex items-center justify-center">
                            <input
                                    key={formData.picture ? formData.picture.name : 'empty'}
                                    name='picture'
                                    accept="image/jpeg, image/png, image/gif"
                                    type="file"
                                    id="insert-img"
                                    data-focusable="true"
                                    onChange={handleInputChange}
                                    hidden
                                    />

                            <label htmlFor="insert-img" className="flex items-center justify-center gap-2 text-[#28a745] p-1 w-fit hover:text-blue-400 hover:cursor-pointer fill-[#28a745] hover:fill-blue-400">
            
                                <PictureAddSVG className='w-4' />
                                <span>{t('profile-attachPhoto')}</span>
                                
                            </label>

                        </div>
                        {/* {console.log(formData.anonymous)} */}
                        <Checkbox checkedInner={formData.anonymous} handleCheckboxInner={() => formData.anonymous = !formData.anonymous} text={t('profile-anonymousCreatePost')} textLeft={true} />

                        {/* АНОНИМ ный чекбокс */}
                        {/* <div className="flex gap-2">
                            <p>{t('profile-anonymousCreatePost')}</p>

                            <label className="checkbox style-e flex items-center justify-center">

                                <input type='checkbox'
                                    id="anonymous" name="anonymous" value={formData.anonymous} 
                                    onChange={handleInputChange} 
                                />

                                <div className="checkbox__checkmark"></div>
                                

                            </label>
                        </div> */}
                      
                    </div>
                    

                    {progress > 0 && progress < 100 ? 
                    <div className="w-full flex flex-col items-center justify-center gap-1 text-[12px]">
                        <p>{t('profile-photoWaiting')}</p>
                        <div className="h-2 bg-green-400 rounded-full" style={{ width: `${progressBar}%` }} />
                        <progress max="100" value={progress}></progress>
                        <p className="-mt-1">{progress}%</p>
                    </div>
                    : null}

                    {/* <!-- Кнопки --> */}
                    <div className="flex gap-2 p-2">
                        <button id="cancel_post_createEdit" className="button_red" type="button" onClick={handleClose}>{t('profile-cancelButton')}</button>
                        <input id="create_post_submit_btn" type={infoChanged ? 'submit' : 'button'} className={infoChanged ? "button_green" : "button_grey_like_green"} value={t('profile-createPost')} />
                    </div>
    
                </div>
            </form>
        </div>
      </div>
    )
}

export default CreatePost;