import ExitCrossSVG from "../../../svg/exitCross_svg";
import PopupContainer from "../popups_root";
import { t } from "i18next";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const ParentsKarmaPopup = ({close, additionInfo}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }
    
    const friendsListEnergies = additionInfo ? additionInfo[0] : null
    const friendsGender = additionInfo ? additionInfo[1] : null

    const profileData = useSelector(state => state.profileUserSlice.profileUser)
    const user = profileData.user
    const gender = friendsGender ? friendsGender : user.gender

    const matrixListOfEnergies = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    const listOfEnergies = matrixListOfEnergies[0]

    const fatherKarma = friendsListEnergies ? friendsListEnergies[7] : listOfEnergies[7]
    const motherKarma = friendsListEnergies ? friendsListEnergies[8] : listOfEnergies[8]

    const [openEnergyPopup_fatherKarma, setOpenEnergyPopup_fatherKarma] = useState(false)
    const [openEnergyPopup_motherKarma, setOpenEnergyPopup_motherKarma] = useState(false)

    return (
            // Основа
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">
                
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                        {t('profile-matrix-instructions-parentsKarma-father')}
                    </div>

                    <div className="flex w-full flex-col gap-5 p-5">
                        {/* Основа */}
                        
                        <div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{fatherKarma}</div>
                        <p className="">{t(`zone_fatherBottomBig`)}</p>     
                        <button className="button_blue !w-fit" onClick={() => {setOpenEnergyPopup_fatherKarma(!openEnergyPopup_fatherKarma)}}>{t('button-open')}</button>         
           
                    </div>
                </div>

                <div className="flex w-full flex-col items-center overflow-auto">
                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                        {t('profile-matrix-instructions-parentsKarma-mother')}
                    </div>

                    <div className="flex w-full flex-col gap-5 p-5">
                        {/* Основа */}
                        
                        <div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{motherKarma}</div>
                        <p className="">{t(`zone_motherBottomBig`)}</p>       
                        <button className="button_blue !w-fit" onClick={() => {setOpenEnergyPopup_motherKarma(!openEnergyPopup_motherKarma)}}>{t('button-open')}</button>         
                    </div>
                </div>

            <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>

            {openEnergyPopup_fatherKarma ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setOpenEnergyPopup_fatherKarma} additionInfo={['zone_fatherBottomBig', fatherKarma, gender ]} /> : null}
            {openEnergyPopup_motherKarma ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setOpenEnergyPopup_motherKarma} additionInfo={['zone_motherBottomBig', motherKarma, gender ]} /> : null}
                
        </div>
    </div>
    )
}

export default ParentsKarmaPopup;