import ExitCrossSVG from "../../../svg/exitCross_svg";
import axios from "axios"
import { baseBackendUrl } from "../websocket_comp";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const DeleteAccountPopup = ({close, additionInfo}) => {
    const navigate = useNavigate();

    const deleteAccount = async () => {
        const data = {username: additionInfo.username}
        const url = `${baseBackendUrl}/delete_account`
        
        const response = await axios.post(url, data);
        if (response.data.approve === 'yes') {
            close()
            navigate('/logout')
        } else {
            alert('Error')
        }
    }

    return (
        // Основа
<div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
        <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">
            <div className="exit_cross_div" onClick={close}>
                <ExitCrossSVG className="exit_cross_svg" />
            </div>
            
            <div className="flex w-full flex-col items-center gap-5">

                {/* ЗАГОЛОВОК */}
                <strong>{t("options-delete-approve")}</strong>

                {/* <div className="flex w-full flex-col gap-5 p-5"> */}
                {/* Основа */}
                
                <div className="flex w-full">
                        <button id="approve_code_button_cancel" className="button_red" type="button" onClick={close}>{t('profile-cancelButton')}</button>
                        <button id="approve_code_button" className="button_green" type="button" onClick={deleteAccount}>{t('reg-codeApproveAccept')}</button>
                </div>
            
                {/* Основа */}
                {/* </div> */}
        </div>
        {/* <button className="button_red sticky bottom-0" type="button" onClick={close}>{t('profile-closePopup')}</button> */}
    </div>
</div>
)
}

export default DeleteAccountPopup