import axios from "axios"
import { baseBackendUrl } from "../websocket_comp";
import { useSelector, useDispatch } from "react-redux";
import { updatePostsAction } from "../../store/postsSlice";
import { t } from "i18next";
import { useEffect } from "react";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import ExitCrossSVG from "../../../svg/exitCross_svg";

const DeletePost = ({ close, additionInfo }) => {

    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
    const updatePosts = useSelector(state => state.postsSlice.updatePosts)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }


    const deletePost = async () => {
        const [postId] = additionInfo

        try {
            const url = `${baseBackendUrl}/post/${postId}/delete`;
            const data = { post: postId, user: authorizedUser.username};

            await axios.post(url, data);
        } catch (error) {
            console.error(error);
            }
        

        dispatch(updatePostsAction(!updatePosts))
        handleClose()
    }

    return (   
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-4 w-fit h-fit sm:h-full sm:w-full">
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                <div className="popup_window p-8 sm:p-5 w-fit h-fit sm:h-full sm:w-full">            
                    
                    <p className="popup_title">{t('profile-deletePostQuestion')}</p>
                    
                    <div>{t('profile-deletePostWarning')}</div>

                    {/* <!-- Кнопки --> */}
                    <div className="flex w-full gap-3">
                        <button id="cancel_delete_post" className="button_green" onClick = {handleClose}>{t('profile-cancelButton')}</button>
                        <button id="delete_post_btn" className="button_red" onClick={deletePost}>{t('profile-deletePost')}</button>
                    </div>

                </div>
            </div>
        </div>
    )
    }

export default DeletePost;