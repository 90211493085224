const LoginSVG = () => {
    return (
        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-door-closed" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2zm1 0v13h8V2H4z"/>
            <path d="M7 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            <path fillRule="evenodd" d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
        </svg>
    )
}

export default LoginSVG;
