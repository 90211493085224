import ExitCrossSVG from "../../../svg/exitCross_svg";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseBackendUrl } from "../websocket_comp";
import { formattedDate } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "../../store/profileUserInfoSlice";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const ProfileLikePopup = ({ close, additionInfo}) => {
    const [authorizedUser, user, profileLikes] = additionInfo

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)

    const [formNotReady, setFormNotReady] = useState(false)
    const [updatePopup, setUpdatePopup] = useState(false)

    const [formData, setFormData] = useState({
        authorized_user: authorizedUser.username,
        // username: user.username,
        comment: '',
        });

    const handleInputInfoChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
            }));
    }

    const sendProfileLike = async () => {
        const url = `${baseBackendUrl}/${user.username}/add_profile_like`;
        const data = { data: formData, date: new Date() };
        await axios.post(url, data);
        setUpdatePopup(!updatePopup)
        dispatch(updateProfileAction(!updateProfile))

        setFormData((prevState) => ({
            ...prevState,
            comment: '',
            }));
      }

    useEffect(() => {
        if (formData.comment?.length > 2) {
            setFormNotReady(true)
        } else {
            setFormNotReady(false)
        }
    }, [formData]);

    return (
            // Основа
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-0 w-fit h-fit sm:h-full sm:w-full">
                
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                        {t('profile-profileLike-title')}
                    </div>

                    <div className="flex w-full flex-col gap-5 p-5">
                    {/* Основа */}
                    
                        {
                            authorizedUser.username !== user.username
                                ?
                                profileLikes.find(item => item.creater === authorizedUser.username)
                                    ? null
                                    : 
                                    <div className="flex flex-col gap-2">
                                        <div className="flex flex-col gap-2">
                                            <p>{t('profile-profileLike-explainer')} </p>
                                            <p>{t('profile-profileLike-explainer-2')}</p>
                                        </div>

                                        <input className="input_style inp fname" 
                                            type="text" 
                                            name="comment" 
                                            placeholder={t('profile-profileLike-placeholder')} 
                                            autoComplete="off" 
                                            required 
                                            maxLength={500}
                                            onChange={handleInputInfoChange} 
                                            value={formData.comment}
                                            onKeyDown={(e) => e.key === 'Enter' ? sendProfileLike() : null}
                                            />

                                        <button className={` ${formNotReady ? 'button_blue_rounded' : 'button_grey_like_green'}`} disabled={!formNotReady}
                                            onClick={sendProfileLike}
                                        >
                                            {t('profile-addButton')}
                                        </button>
                                    </div>
                                

                                : 
                                    profileLikes.length
                                    ? null
                                    : <p className="flex items-start justify-center text-center">{t('profile-profileLike-empty')}</p>
                                }
                        
                        

                        <div className="flex flex-col gap-2">
                            {profileLikes
                                ? 
                                    profileLikes.map((oneLike) => {
                                        return(
                                            <div className=" p-2 flex flex-col" key={oneLike.id}>
                                                <div className="flex items-center gap-1">
                                                    <div className="small-profilepic !w-7 !h-7 button_ava_posts" style={{ backgroundImage: `url(${baseBackendUrl + oneLike.profile_pic})` }}></div>
                                                    <p>{oneLike.first_name}</p>
                                                    <p>{oneLike.last_name}</p>
                                                </div>
                                                {/* <p>{oneLike.creater}</p> */}
                        
                                                <p className="pl-14">{oneLike.comment}</p>
                                                <p className="text-gray-400 text-xs text-right">{formattedDate(oneLike.date)}</p>
                                                <hr />
                                            </div>
                                        )
                                    })
                                : <p className="flex items-start justify-center text-center">{t('profile-profileLike-empty')}</p>
                            }
                        </div>

                    {/* Основа */}
                    </div>
                </div>
            {/* <button className="button_red sticky bottom-0" type="button" onClick={close}>{t('profile-closePopup')}</button> */}
        </div>
    </div>
)
}

export default ProfileLikePopup;