import React from "react";
import { t } from "i18next";

const Paginator = ({ paginatorData, fetchDataPosts, changePage = 1 }) => {
  if (!paginatorData) return null;
  // console.log(paginatorData)

  const { num_pages, number, has_previous, previous_page_number, has_next, next_page_number } = paginatorData;

  const handleClick = (pageNumber) => {
    changePage(pageNumber)
  };

  const tags = [];

  for (let each = 1; each <= num_pages; each++) {
    if (num_pages > 1) {
      const isActive = each === number;

      tags.push(
        <li
          key={each}
          id={isActive ? "paginator_now_page" : "paginator_other_page"}
          className={`paginator-item ${isActive ? "active" : ""} sm:shadow-md`}
          onClick={() => handleClick(each)}
        >
          {each}
        </li>
      );
    }

  }

  return (
    <div className="w-full flex items-center justify-center overflow-auto sm:z-[1]">
      <ul className="w-full sm:z-[1] sm:sticky sm:bottom-10 sm:p-0 flex gap-1 p-2 m-2">

        {has_previous && (
          <li
            id="paginator_previous"
            className="paginator-item sm:shadow-md "
            onClick={() => handleClick(previous_page_number)}
          >
            {t('posts-previous')}
          </li>
        )}

        {tags}

        {has_next && (
          <li
            id="paginator_next"
            className="paginator-item sm:shadow-md"
            onClick={() => handleClick(next_page_number)}
          >
            {t('posts-next')}
          </li>
        )}

      </ul>
    </div>
  );
};

export default Paginator;