

const SavePostSVG = ({className}) => {
    return (
        <svg 
        className={className} 
        fill="none"
        stroke="currentColor"
        viewBox="0.5 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3 3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12l-5-3-5 3V3z" />
        </svg>
    )
}
// fill="#17bf63"
export default SavePostSVG;
