import {createSlice} from '@reduxjs/toolkit';

const wsSlice = createSlice ({
    name: 'online_status',
    initialState: {
        onlineUsers: [],
    },
    reducers: {
        updateUserOnlineStatus(state, action) {
            // console.log(state,action)
            state.onlineUsers = action.payload
        }
    }
})

export const {updateUserOnlineStatus} = wsSlice.actions; 

export default wsSlice.reducer;