const SnakeTaleSVG = ({className}) => {
    return (
        <svg 
            className={className} 
            width="3em" height="3em"
            // fill="currentColor"
            // stroke="currentColor"
            version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100.000000 100.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
            >
            <path d="M340 953 c-168 -76 -264 -183 -305 -338 -89 -343 237 -669 580 -580
            293 77 450 406 317 670 -66 133 -122 167 -286 172 -123 5 -124 4 -16 -51 78
            -40 142 -106 178 -184 23 -48 27 -71 27 -142 0 -73 -4 -93 -28 -142 -35 -72
            -93 -130 -165 -165 -49 -24 -69 -28 -142 -28 -71 0 -94 4 -140 26 -239 113
            -267 440 -50 588 63 43 101 47 194 22 43 -12 82 -19 87 -15 5 3 -16 22 -48 43
            -67 43 -90 68 -77 81 5 5 54 11 109 12 81 2 100 6 100 18 0 16 -143 58 -200
            59 -16 0 -77 -21 -135 -46z m170 17 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3
            6 1 10 9 10 9 0 16 -4 16 -10z"/>
            </g>
        </svg>
    )
}

export default SnakeTaleSVG;
