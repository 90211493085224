import {createSlice} from '@reduxjs/toolkit';

const postsSlice = createSlice ({
    name: 'posts',
    initialState: {
        updatePosts: false
    },
    reducers: {
        updatePostsAction(state, action) {
            state.updatePosts = action.payload
        },
    }
})

export const {updatePostsAction} = postsSlice.actions; 

export default postsSlice.reducer;