const MindSVG = ({ className }) => {
    
    return (

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="3em" height="3em"
            fill="currentColor"
            stroke="currentColor"

            viewBox="0 0 100.000000 100.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"

            >
                <path d="M404 889 c-18 -5 -53 -24 -80 -41 -67 -45 -101 -115 -113 -228 -5
-47 -18 -110 -30 -140 -11 -30 -21 -56 -21 -57 0 -10 36 -33 53 -33 19 0 22
-6 24 -42 3 -73 15 -142 25 -155 6 -8 34 -13 69 -13 l59 0 0 -34 c0 -34 12
-52 28 -42 4 2 5 28 2 58 l-5 53 -67 -3 -66 -4 -6 59 c-3 32 -6 79 -5 104 0
46 -1 47 -36 53 -19 4 -35 9 -35 11 0 2 9 28 21 57 11 29 23 84 25 123 9 134
72 218 185 245 64 16 150 7 214 -20 35 -15 51 -18 59 -10 13 13 0 23 -72 50
-57 21 -170 26 -228 9z"/>
                <path d="M730 795 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
                <path d="M500 773 c-14 -18 -19 -35 -15 -50 7 -29 -26 -48 -78 -44 -52 4 -87
-28 -87 -79 0 -55 36 -85 92 -77 47 6 80 -6 72 -27 -3 -8 2 -30 10 -50 18 -43
70 -72 111 -62 76 19 103 116 47 169 -36 35 -97 37 -131 6 -30 -28 -44 -14
-44 46 0 57 10 68 64 69 33 1 46 7 61 27 51 68 -49 139 -102 72z m64 -9 c9 -3
16 -16 16 -29 0 -25 -21 -38 -45 -29 -18 7 -20 50 -2 57 6 3 13 6 14 6 1 1 8
-2 17 -5z m-130 -130 c9 -8 16 -23 16 -31 0 -23 -29 -53 -51 -53 -23 0 -49 26
-49 50 0 10 7 26 16 34 8 9 24 16 34 16 10 0 26 -7 34 -16z m196 -104 c60 -60
-29 -150 -92 -93 -40 36 -8 113 47 113 14 0 34 -9 45 -20z"/>
                <path d="M776 751 c-4 -6 1 -33 10 -61 12 -37 15 -74 12 -138 -3 -74 -9 -96
-33 -137 -71 -124 -70 -119 -56 -214 12 -86 21 -108 39 -97 6 3 4 35 -4 82
-16 89 -12 113 28 171 16 24 36 62 44 85 19 51 22 194 5 254 -12 45 -35 72
-45 55z"/>
            </g>
        </svg>

    )
}

export default MindSVG;
