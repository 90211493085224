const Following2SVG = ({className}) => {
    return (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            
            className={className}                 
            width="1.5em" height="1.5em"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={0}

            viewBox="0 0 48.000000 48.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
            // fill="#000000" stroke="none"
            >
            <path d="M101 419 c-26 -25 -31 -39 -31 -76 0 -29 7 -55 20 -72 l19 -28 -34
            -33 c-44 -43 -67 -132 -41 -159 24 -24 268 -24 292 0 26 27 3 116 -41 159
            l-34 33 19 28 c13 17 20 43 20 72 0 37 -5 51 -31 76 -26 26 -38 31 -79 31 -41
            0 -53 -5 -79 -31z m136 -21 c51 -48 15 -138 -56 -138 -47 0 -81 32 -81 79 0
            72 84 109 137 59z m29 -212 c27 -27 34 -42 34 -74 0 -49 -6 -52 -120 -52 -114
            0 -120 3 -120 52 0 32 7 47 34 74 30 30 40 34 86 34 46 0 56 -4 86 -34z"/>
            <path d="M395 285 c-26 -21 -31 -23 -45 -10 -21 19 -40 19 -40 2 0 -17 32 -47
            49 -47 16 0 91 58 91 71 0 16 -24 9 -55 -16z"/>
            </g>
            </svg>
    )
}

export default Following2SVG;
