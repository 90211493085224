import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import { baseBackendUrl } from "../little_components/websocket_comp";
import Posts from '../little_components/posts'
import { useSelector } from "react-redux";
import Spinner from "../little_components/spinner_loading";
import Profile from "../little_components/profile_components/profile";
import { t } from "i18next";
import Checkbox from "../little_components/checkbox";
import TitleChange from "../little_components/title_change";
import { useMemo } from "react";

export const ProfilePageContainer = () => {
    const navigate = useNavigate();

    const [userExistsData, setUserExistsData] = useState(null);
    const [postsOn, setPostsOn] = useState(JSON.parse(localStorage.getItem('postsOpen')) || false);


    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    const user = profileUser?.user
    const firstName = user?.first_name

    const { username } = useParams();

    const checkExistionUser = async () => {
        let url = `${baseBackendUrl}/check_username`;

        const data = { username: username };
        const response = await axios.post(url, data);

        if (response.data.exist_user === 'user_not_exist') {
            navigate('/page_not_exists');
        } else if (!authorizedUser) {
            navigate('/');
        } else {
            setUserExistsData(response.data); // Сохранение данных профиля в состоянии
        }
    };

    useEffect(() => {
        checkExistionUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);

    const handlePostsCheckbox = (e) => {
        setPostsOn(e.target.checked);
        localStorage.setItem('postsOpen', e.target.checked)
    }

        // Оборачиваем компонент Posts в useMemo
        const postsComponent = useMemo(() => {
            return postsOn ? <Posts pageName="profile_messages" userLink={username} /> : null;
        }, [postsOn, username]);


    if (!userExistsData) {
        return <Spinner /> // Пока данные профиля не загружены, ничего не отображаем
    }

    return (
        <div className={`flex flex-col gap-2 sm:pb-10`}>

            <TitleChange
                rusTitle={"Ваш Профиль Судьбы"}
                engTitle={"Your Destiny Profile"}
                rusDescription={"Смотрите Ваше предназначение по Матрице Судьбы, Ваши сильные стороны, Ваш характер по Квадрату Пифагора. Ведите график своего настроения каждый день! Смотрите причины болезней по Вашим чакрам. Прогнозируйте события по положениям планет и луны."}
                engDescription={"See your purpose according to the Matrix of Destiny, your strengths, your character according to the Pythagorean Square. Keep a chart of your mood every day! See the causes of diseases according to your chakras. Predict events based on the positions of the planets and the moon."}
                ruskeyWords={'Профиль Матрицы Судьбы, Квадрат Пифагора, Нумерология, Зодиак, положение планет, Нотальная карта, Предназначение, Чакры, Здоровье, Анонимность'}
                engkeyWords={'Fate Matrix Profile, Pythagorean Square, Numerology, Zodiac, planetary positions, Notal chart, Purpose, Chakras, Health, Anonymity'}
            />


            {/* Фото - Портрет - Кнопки профиля */}
            <Profile userLink={username} />

            {/* Чекбокс на включение постов */}
            <Checkbox checkedInner={postsOn} handleCheckboxInner={handlePostsCheckbox} text={authorizedUser.username === username ? t('profileReadPostsButton') : (t('profileReadPostsButton') + ` ${firstName}`)} />

            {/* Раскрываем все посты если чекбокс открыт */}
            {postsComponent}

        </div>
    );
};

