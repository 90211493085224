
const ButtonPilotRed = ({checked, setChecket}) => {
    return (
            <div className="flex flex-col gap-2 justify-center items-center mr-3">

                <label className="switch">
                    <input defaultChecked={checked} type="checkbox" onClick={() => setChecket(!checked)}/>
                    <div className="button">
                        <div className="light"></div>
                        <div className="dots"></div>
                        <div className="characters"></div>
                        <div className="shine"></div>
                        <div className="shadow"></div>
                    </div>
                </label>

            </div>
    )
}

export default ButtonPilotRed;
