const InfoSVG = ({className}) => {
    return (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            className={className} 
            width="3em" height="3em"
            fill="currentColor"
            stroke="currentColor"
             viewBox="0 0 455.000000 497.000000"
             preserveAspectRatio="xMidYMid meet">
            
            <g transform="translate(0.000000,497.000000) scale(0.100000,-0.100000)"
            // fill="#000000" stroke="none"
            >
            <path d="M2225 4848 c-26 -39 -49 -78 -52 -85 -4 -11 81 -158 99 -169 6 -4 93
            122 105 153 4 11 -81 158 -99 169 -4 2 -28 -28 -53 -68z"/>
            <path d="M1342 4078 c-21 -21 -14 -66 13 -84 23 -15 27 -15 50 0 17 11 25 26
            25 44 0 35 -16 52 -50 52 -14 0 -31 -5 -38 -12z"/>
            <path d="M3132 4078 c-21 -21 -14 -66 13 -84 23 -15 27 -15 50 0 17 11 25 26
            25 44 0 35 -16 52 -50 52 -14 0 -31 -5 -38 -12z"/>
            <path d="M2192 3939 c-146 -28 -273 -142 -322 -290 -49 -143 -9 -307 101 -424
            206 -218 560 -155 692 122 30 63 32 76 32 173 0 97 -2 110 -32 173 -87 184
            -279 284 -471 246z m213 -188 c114 -64 163 -195 120 -322 -21 -62 -63 -109
            -126 -144 -45 -24 -65 -29 -124 -29 -59 0 -79 5 -124 29 -92 51 -141 134 -141
            239 1 122 84 226 203 257 54 14 137 1 192 -30z"/>

            {/* <path d="M2196 3744 c-95 -34 -157 -122 -155 -223 0 -31 4 -65 8 -75 7 -17 10
            -15 28 15 74 122 163 283 161 289 -2 4 -21 1 -42 -6z"/>
            <path d="M2310 3750 c0 -8 99 -184 163 -289 18 -30 21 -32 28 -15 4 10 8 44 8
            75 1 67 -35 147 -83 184 -37 28 -116 59 -116 45z"/>
            <path d="M2247 3693 c-51 -81 -51 -89 1 -39 29 28 48 54 45 62 -9 23 -21 16
            -46 -23z"/>
            <path d="M2224 3607 c-43 -46 -90 -103 -103 -128 -21 -39 -11 -32 89 67 91 90
            111 115 103 128 -8 14 -22 4 -89 -67z"/>
            <path d="M2220 3525 c-99 -99 -111 -115 -90 -115 36 0 226 191 213 214 -7 13
            -30 -5 -123 -99z"/>
            <path d="M2265 3500 c-78 -78 -86 -90 -65 -90 30 0 181 145 173 166 -3 8 -8
            14 -12 14 -3 0 -47 -40 -96 -90z"/>
            <path d="M2320 3475 c-58 -59 -62 -65 -39 -65 29 0 119 85 119 113 0 28 -12
            20 -80 -48z"/>
            <path d="M2370 3455 c-42 -43 -43 -45 -19 -45 34 0 84 52 72 74 -7 13 -16 9
            -53 -29z"/>
            <path d="M2425 3430 c-18 -20 -18 -20 8 -20 28 0 32 6 19 28 -7 9 -13 8 -27
            -8z"/>
            <path d="M2090 3371 c0 -19 83 -70 132 -82 39 -9 63 -9 103 0 50 11 135 63
            135 82 0 5 -80 9 -185 9 -105 0 -185 -4 -185 -9z"/> */}


            <path d="M170 3699 c0 -6 19 -46 43 -90 l42 -79 103 0 102 0 -45 90 -45 90
            -100 0 c-65 0 -100 -4 -100 -11z"/>
            <path d="M4135 3620 l-45 -90 102 0 103 0 43 79 c23 44 42 84 42 90 0 7 -35
            11 -100 11 l-100 0 -45 -90z"/>
            {/* <path d="M2170 2705 c-94 -94 -101 -104 -70 -94 45 15 215 180 197 191 -6 4
            -14 8 -17 8 -3 0 -53 -47 -110 -105z"/> */}
            {/* <path d="M2076 2681 c-82 -82 -117 -124 -114 -137 2 -14 35 13 143 118 119
            117 136 137 115 138 -19 0 -54 -29 -144 -119z"/> */}
            {/* <path d="M2265 2720 c-70 -71 -77 -80 -55 -80 35 0 180 144 155 154 -9 3 -17
            6 -19 6 -1 0 -37 -36 -81 -80z"/> */}
            {/* <path d="M2325 2710 c-62 -63 -67 -70 -44 -70 34 0 159 124 134 134 -9 3 -18
            6 -19 6 -1 0 -33 -31 -71 -70z"/> */}
            {/* <path d="M2089 2746 c-32 -22 -73 -68 -94 -106 -4 -8 24 16 64 55 87 85 92 93
            30 51z"/>
            <path d="M2380 2695 l-54 -55 30 0 c22 0 41 11 73 44 40 41 47 66 18 66 -7 0
            -37 -25 -67 -55z"/>
            <path d="M2440 2675 c-37 -38 -41 -45 -26 -51 21 -8 103 67 92 85 -11 18 -19
            14 -66 -34z"/>
            <path d="M2485 2650 c-23 -24 -26 -33 -17 -42 10 -10 19 -6 43 19 18 17 28 36
            25 42 -11 17 -20 14 -51 -19z"/>
            <path d="M2530 2615 c-21 -22 -22 -28 -10 -35 21 -13 56 22 43 44 -8 15 -12
            13 -33 -9z"/> */}
            {/* <path d="M2123 2587 c-71 -25 -153 -79 -153 -101 0 -23 89 -82 157 -105 83
            -28 207 -28 294 -1 69 22 159 81 159 105 0 24 -90 83 -159 105 -89 28 -215 27
            -298 -3z m211 -43 c53 -53 16 -144 -59 -144 -44 0 -85 41 -85 85 0 76 90 113
            144 59z"/> */}
            {/* <path d="M2227 2532 c-22 -25 -21 -75 1 -95 25 -22 75 -21 95 1 22 25 21 75
            -1 95 -25 22 -75 21 -95 -1z"/>
            <path d="M2560 2575 c-12 -14 -12 -18 1 -25 19 -12 33 4 22 25 -9 15 -11 15
            -23 0z"/>
            <path d="M447 2522 c-10 -10 -17 -28 -17 -39 0 -23 30 -53 53 -53 21 0 57 35
            57 55 0 21 -36 55 -58 55 -11 0 -26 -8 -35 -18z"/>
            <path d="M4024 2515 c-21 -33 -6 -72 31 -81 52 -13 86 53 47 89 -27 24 -59 21
            -78 -8z"/>
            <path d="M2445 2308 c-119 -117 -136 -137 -115 -138 34 0 264 228 258 256 -2
            14 -35 -13 -143 -118z"/>
            <path d="M1965 2420 c-3 -4 -2 -15 1 -24 5 -13 9 -14 20 -5 8 6 14 15 14 19 0
            12 -29 20 -35 10z"/> */}
            <path d="M1315 2388 c-226 -30 -396 -263 -356 -485 17 -95 55 -168 122 -236
            168 -168 430 -168 598 0 132 133 164 322 82 488 -83 168 -256 259 -446 233z
            m175 -180 c165 -71 208 -298 80 -426 -56 -57 -105 -77 -190 -77 -65 0 -82 4
            -129 30 -30 17 -67 48 -83 69 -30 41 -58 122 -58 166 0 50 30 126 66 168 79
            93 199 120 314 70z"/>
            {/* <path d="M1269 2177 c-53 -28 -104 -92 -120 -150 -11 -42 -6 -124 9 -139 5 -5
            44 54 87 129 43 76 85 148 93 161 13 20 13 22 -5 22 -11 0 -40 -10 -64 -23z"/>
            <path d="M1427 2168 c11 -18 52 -90 92 -160 41 -70 77 -125 82 -123 4 3 11 25
            14 49 9 58 -15 140 -55 188 -30 37 -101 78 -134 78 -17 0 -17 -2 1 -32z"/>
            <path d="M1364 2163 c-5 -10 -21 -36 -34 -58 l-23 -40 46 44 c26 25 43 49 40
            57 -7 19 -16 18 -29 -3z"/>
            <path d="M1324 2047 c-44 -46 -90 -103 -103 -128 -21 -39 -10 -31 92 70 69 68
            113 119 110 127 -3 8 -9 14 -13 14 -4 0 -43 -37 -86 -83z"/>
            <path d="M1319 1974 c-98 -98 -110 -114 -89 -114 19 0 51 26 123 98 92 93 108
            114 89 126 -5 3 -60 -47 -123 -110z"/>
            <path d="M1374 1949 c-77 -77 -85 -89 -64 -89 17 0 47 22 97 72 70 69 83 91
            65 102 -5 3 -49 -35 -98 -85z"/> */}
            {/* <path d="M1420 1925 c-57 -58 -62 -65 -40 -65 30 0 131 96 123 116 -3 8 -8 14
            -12 14 -4 0 -36 -29 -71 -65z"/>
            <path d="M1475 1900 c-37 -38 -38 -40 -15 -40 29 0 81 47 73 66 -8 21 -15 18
            -58 -26z"/>
            <path d="M1525 1880 c-18 -19 -17 -20 8 -20 17 0 27 5 27 14 0 24 -16 27 -35
            6z"/>
            <path d="M1226 1791 c87 -82 232 -80 314 4 l34 35 -195 0 -194 -1 41 -38z"/>
            <path d="M1996 2381 c-10 -11 -14 -25 -10 -35 6 -15 9 -15 30 5 28 26 30 35
            10 42 -8 3 -21 -2 -30 -12z"/> */}
            <path d="M3105 2388 c-224 -30 -394 -262 -355 -485 16 -94 54 -169 119 -234
            240 -240 643 -122 717 209 65 290 -181 550 -481 510z m177 -180 c197 -83 206
            -368 16 -474 -46 -25 -63 -29 -128 -29 -58 0 -85 6 -116 22 -97 52 -149 137
            -148 243 2 103 52 188 136 231 80 41 155 43 240 7z"/>
            {/* <path d="M3080 2189 c-44 -18 -89 -59 -117 -106 -22 -38 -27 -59 -27 -114 -1
            -111 12 -105 94 38 41 70 82 143 93 161 18 31 18 32 0 31 -10 0 -29 -5 -43
            -10z"/>
            <path d="M3211 2179 c8 -13 51 -86 94 -162 88 -153 100 -159 100 -48 0 58 -4
            75 -30 116 -35 56 -94 102 -145 112 l-35 6 16 -24z"/>
            <path d="M3158 2162 c-20 -23 -58 -87 -58 -98 0 -4 20 12 45 36 25 24 45 50
            45 56 0 21 -17 24 -32 6z"/>
            <path d="M3123 2058 c-39 -40 -86 -98 -103 -128 -31 -54 -30 -53 83 59 69 68
            113 119 110 127 -3 8 -8 14 -12 14 -3 0 -39 -33 -78 -72z"/> */}
            {/* <path d="M3109 1974 c-108 -108 -112 -114 -84 -114 25 0 47 17 123 93 85 85
            105 118 84 131 -5 3 -60 -47 -123 -110z"/>
            <path d="M3164 1949 c-77 -77 -85 -89 -64 -89 17 0 47 22 97 72 70 69 83 91
            65 102 -5 3 -49 -35 -98 -85z"/>
            <path d="M3210 1925 c-58 -59 -62 -65 -39 -65 29 0 135 101 125 119 -12 18
            -19 14 -86 -54z"/>
            <path d="M3265 1900 c-37 -38 -38 -40 -15 -40 29 0 81 47 73 66 -8 21 -15 18
            -58 -26z"/>
            <path d="M3315 1880 c-18 -19 -17 -20 8 -20 17 0 27 5 27 14 0 24 -16 27 -35
            6z"/>
            <path d="M3019 1790 c89 -81 219 -81 305 1 l41 38 -195 0 -195 0 44 -39z"/> */}
            {/* <path d="M2035 2340 c-22 -23 -26 -33 -17 -42 9 -9 20 -4 47 22 25 24 31 36
            22 42 -18 12 -21 11 -52 -22z"/>
            <path d="M2445 2358 c-16 -6 -69 -49 -117 -96 -72 -71 -85 -88 -72 -95 13 -7
            39 13 123 97 58 58 104 106 101 105 -3 0 -18 -5 -35 -11z"/>
            <path d="M2075 2310 c-30 -31 -36 -43 -27 -52 9 -9 22 -2 57 32 42 41 49 60
            21 60 -6 0 -30 -18 -51 -40z"/>
            <path d="M2127 2291 c-30 -28 -47 -53 -43 -59 13 -22 31 -13 85 42 49 50 51
            54 30 59 -17 4 -33 -5 -72 -42z"/> */}
            
            {/* <path d="M2340 2333 c-14 -3 -58 -37 -97 -76 -57 -56 -70 -73 -58 -80 8 -4 21
            -4 29 0 12 7 163 165 154 162 -1 -1 -14 -4 -28 -6z"/>
            <path d="M2485 2269 c-55 -57 -63 -68 -36 -53 18 11 48 37 66 59 70 87 59 85
            -30 -6z"/>
            <path d="M2181 2266 c-53 -53 -60 -64 -45 -70 13 -5 36 11 88 64 64 63 68 70
            46 70 -18 0 -44 -19 -89 -64z"/> */}
            
            <path d="M213 1361 c-24 -44 -43 -84 -43 -90 0 -7 35 -11 100 -11 l100 0 45
            90 45 90 -102 0 -103 0 -42 -79z"/>
            <path d="M4135 1350 l45 -90 100 0 c65 0 100 4 100 11 0 6 -19 46 -42 90 l-43
            79 -103 0 -102 0 45 -90z"/>
            <path d="M1352 974 c-17 -12 -22 -24 -20 -46 4 -40 28 -55 65 -42 38 13 45 65
            11 88 -12 9 -25 16 -28 16 -3 0 -16 -7 -28 -16z"/>
            <path d="M3142 974 c-24 -17 -30 -62 -10 -82 7 -7 24 -12 38 -12 33 0 50 17
            50 51 0 24 -30 59 -50 59 -3 0 -16 -7 -28 -16z"/>
            <path d="M2225 308 c-26 -39 -49 -78 -52 -85 -4 -11 81 -158 99 -169 6 -4 93
            122 105 153 4 11 -81 158 -99 169 -4 2 -28 -28 -53 -68z"/>
            </g>
            </svg>
            
    )
}

export default InfoSVG;
