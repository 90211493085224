export const PythagorSquareSVG = ({ className }) => {

    // className={className}
    // width="1.5em" height="1.5em"
    // fill="currentColor"
    // stroke="currentColor"

    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"

            className={className}
            width="1.5em" height="1.5em"
            fill="currentColor"
            stroke="currentColor"

            viewBox="0 0 100.000000 100.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                >
                <path d="M92 908 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16 200
0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80 0 80
0 0 -80z"/>
                <path d="M392 908 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16
200 0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80
0 80 0 0 -80z"/>
                <path d="M692 908 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16
200 0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80
0 80 0 0 -80z"/>
                <path d="M92 608 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16 200
0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80 0 80
0 0 -80z"/>
                <path d="M392 608 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16
200 0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80
0 80 0 0 -80z"/>
                <path d="M692 608 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16
200 0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80
0 80 0 0 -80z"/>
                <path d="M92 308 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16 200
0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80 0 80
0 0 -80z"/>
                <path d="M392 308 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16
200 0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80
0 80 0 0 -80z"/>
                <path d="M692 308 c-16 -16 -16 -200 0 -216 16 -16 200 -16 216 0 16 16 16
200 0 216 -16 16 -200 16 -216 0z m188 -108 l0 -80 -80 0 -80 0 0 80 0 80 80
0 80 0 0 -80z"/>
            </g>
        </svg>
    )
}