import { initializeApp } from "firebase/app";
import 'firebase/messaging';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD7ZeNtiVIHfr5xB5RRHRc3b_jd4YJtlvU",
    authDomain: "push-notifications-destiny.firebaseapp.com",
    projectId: "push-notifications-destiny",
    storageBucket: "push-notifications-destiny.appspot.com",
    messagingSenderId: "254959418124",
    appId: "1:254959418124:web:76d43237b21bac15e3a729",
    measurementId: "G-H1J56QZGTD"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const messaging = getMessaging(app)


export const firebaseRequestPermission = () => {
  console.log('Request permission...')

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.')
      
      
      getToken(messaging, { vapidKey: 'BC8ZzuZ5tTCbdchoa9Ac1LGD1QMzwfh0kcEhPCceQ1LHs1wH0U2TfdjKMx27YEyHRCMxQBeWrvxEqAbd_SJCW-4' })
        .then((currentToken) => {
            if (currentToken) {
                localStorage.setItem('pushToken', currentToken)
                console.log('Token:', currentToken);
                // Отправьте токен на ваш сервер для последующей отправки уведомлений.
            } else {
                console.log('Не удалось получить токен.');
            }})
            
        .catch((err) => {
            console.error('Ошибка при получении токена:', err);
            });

    } else {
      console.log('Do not have permission!')
    }
  })
    
}

// firebaseRequestPermission()

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, payload => {

            // console.log('OnMessage payload', payload)
            resolve(payload)
        })
    })
}



export const sendPushNotification = () => {
  
    getToken(messaging).then(token => {
      const payload = {
        notification: {
          title: 'asdasdd',
          options: firebaseConfig,
        },
        to: token,
      };

      fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'key=BC8ZzuZ5tTCbdchoa9Ac1LGD1QMzwfh0kcEhPCceQ1LHs1wH0U2TfdjKMx27YEyHRCMxQBeWrvxEqAbd_SJCW-4', // Замените YOUR_SERVER_KEY на свой серверный ключ Firebase
        },
        body: JSON.stringify(payload),
      })
      .then(response => console.log('Push notification sent successfully:', response))
      .catch(error => console.error('Error sending push notification:', error));
    });
  };



  
  const message = {
    notification: {
      title: 'Заголовок уведомления',
      body: 'Текст уведомления',
    },
    token: 'cSG1VIe4UZOvrXuaIPoj9T:APA91bFdmRPFfWAC20om9Wd-c5AFhfEjgUs_wz0wM9grR0gAOApD_5gaomyFKi0X9fY3od7-Rh3Ij8A5gYRS0DnQrKvNP7TlGulV70rwaqVfjfr-ninf21LiRmUyumzZy3LR-MVlcfuB', // Токен устройства, полученный на клиентской стороне
  };
  



// messaging.requestPermission().then(() => {
//     return messaging.getTocken()
// }).then(token => {
//     console.log('Token:', token)
// }).catch ((err) => {
//     console.log('Ошибка при получении токена:', err)
// })

