import PinkHeartSVG from "../../../svg/pink_heart";
import CommentFillSVG from "../../../svg/comment_fill_svg";
import SavePostSVG from "../../../svg/savePost_svg";
import ExitCrossSVG from "../../../svg/exitCross_svg";
import { Link } from 'react-router-dom';
import { t } from "i18next";

const LoginPopup = ({close, type}) => {

    return (
<div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 lg:w-[700px] h-fit sm:h-full sm:w-full">                
                {/* Обрабатываем закрытие окна */}
                <div className="exit_cross_div" onClick={close}>
                    <ExitCrossSVG className="exit_cross_svg" fill="#28a745"/>
                </div>

                {/* Обрабатываем разные страницы, если не залогинился */}
                <div className="flex flex-col items-center justify-center p-10">
                
                    {type === 'like' && (
                        <>
                            <PinkHeartSVG className="w-20 animate-bounce" />
                            <h2 className="text-xl">{t('login-join-like')}</h2>
                            <p className="grey">{t('login-join')}</p>
                        </>
                    )}

                    {type === 'comment' && (
                        <>
                            <CommentFillSVG className="w-20 animate-bounce fill-blue-500" />
                            <h2 className="text-xl">{t('login-join-comment')}</h2>
                            <p className="grey">{t('login-join')}</p>
                        </>
                    )}

                    {type === 'save' && (
                        <>
                            <SavePostSVG className="w-20 animate-bounce fill-green-500" />
                            <h2 className="text-xl">{t('login-join-save')}</h2>
                            <p className="grey">{t('login-join')}</p>
                        </>
                    )}

                    {/* {type === 'follow' && (
                        <>
                            <FollowSVG className="w-20 animate-bounce fill-blue-500" />
                            <h2 className="text-xl">{t('login-join-comment')}</h2>
                            <p className="grey">{t('login-join')}</p>
                        </>
                    )} */}

                </div>
                
                {/* Ссылки на логин и регистрацию */}
                <div className="flex gap-1 w-full">
                    <Link className="button_green" to='register'>{t('login-signup-link')}</Link>
                    <Link className="button_green" to='login'>{t('login-button')}</Link>
                </div>
        </div>
    </div>
    )
}

export default LoginPopup;
