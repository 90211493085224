import { useState } from "react"
import PopupContainer from "../popups_root"
import { t } from "i18next"
import PinkHeartSVG from "../../../svg/pink_heart"
import DollarSVG from "../../../svg/svg_dollar"
import ManSignSVG from "../../../svg/svg_man_sign"
import WomanSignSVG from "../../../svg/svg_woman_sign"
import MatrixYearsBackgroundSVG from "../../../svg/svg_matrix_background_years"

const CreateMatrixVisualComponentFunc = ({energies, years, gender, checkboxes=null}) => {
    let matrixYearsCheckbox = true;
    let matrixPurposesCheckbox = true;
    let matrixMoneyCheckbox = true;
    let matrixRelationshipCheckbox = true;
    let matrixParentsCheckbox = true;
    let matrixLittleEnergiesCheckbox = true;

    if (checkboxes) {
        [matrixYearsCheckbox, matrixPurposesCheckbox, matrixMoneyCheckbox, matrixRelationshipCheckbox, matrixParentsCheckbox, matrixLittleEnergiesCheckbox] = checkboxes;
    }
    const [purposesPopupOpen, setPurposesPopupOpen] = useState(false);

    const [openEnergyPopup, setOpenEnergyPopup] = useState({
        A: false,
        B: false,
        C: false,
        D: false,
        E: false,
        F_10: false,
        F_30: false,
        F_50: false,
        F_70: false,
        J: false,
        K: false,
        L: false,
        M: false,
        N: false,
        O: false,
        P: false,
        Q: false,
        R: false,
        S: false,
        T: false,
        U: false,
        V: false,
        W: false,
        X: false,
        Y: false,
        Z: false,
        AA: false,
        BB: false,
        CC: false,
        DD: false,
    })

    const baseInfo = () => {
        return (
            <>
                {/* Портрет / Ресурс */}
                <div id="0" data-tooltip={t('zone_birthDay_resource')}
                    className="title-style title_style_addon button_matrix tag_big_energy mt-[136px] text-white tag_big_energy bg-[#B977E2] "
                    onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, A: !prevState.A }))}
                >{energies[0]}</div>

                {openEnergyPopup.A ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, A: false })) : null} additionInfo={['zone_birthDay_resource', energies[0], gender]} /> : null}


                {/* Талант Свыше / Вдохновенье */}
                <div id="20" data-tooltip={t('zone_month_bigTalant')} className="title-style title_style_addon button_matrix tag_big_energy border-gray-300  text-white bg-[#B977E2] ml-[136px]"
                    onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, B: !prevState.B }))}
                >{energies[1]}</div>

                {openEnergyPopup.B ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, B: false })) : null} additionInfo={['zone_month_bigTalant', energies[1], gender]} /> : null}


                {/* Задача Души / Карма материального */}
                <div id="40" data-tooltip={t('zone_year_soulTask')} className="title-style title_style_addon button_matrix tag_big_energy border-gray-300  mt-[136px] ml-[272px] text-white tag_big_energy bg-[#F84842]"
                    onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, C: !prevState.C }))}
                >{energies[2]}</div>

                {openEnergyPopup.C ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, C: false })) : null} additionInfo={['zone_year_soulTask', energies[2], gender]} /> : null}

                {/* Кармический хвост */}
                <div id="60" data-tooltip={t('zone_bd_m_y_karmicTail')} className="title-style title_style_addon button_matrix tag_big_energy border-gray-300  mt-[272px] ml-[136px] text-white tag_big_energy bg-[#F84842]"
                    onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, D: !prevState.D }))}
                >{energies[3]}</div>

                {openEnergyPopup.D ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, D: false })) : null} additionInfo={['zone_bd_m_y_karmicTail', energies[3], gender]} /> : null}


                {/* Зона комфорта души */}
                <div id="E" data-tooltip={t('zone_year_soulComfort')} className="title-style title_style_addon button_matrix tag_big_energy border-gray-300  mt-[136px] ml-[136px] tag_big_energy bg-[#FFFE7A] text-black"
                    onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, E: !prevState.E }))}
                >{energies[4]}</div>

                {openEnergyPopup.E ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, E: false })) : null} additionInfo={['zone_year_soulComfort', energies[4], gender]} /> : null}
            </>
        )
    }

    const dots = () => {
        return (
            <div className="">

                <div id="dots_1" className="dots_color_style matrix_dots mt-[126px] ml-[15px]"></div>
                <div id="dots_2.5" className="dots_color_style matrix_dots mt-[115px] ml-[17px]"></div>
                <div id="dots_3.5" className="dots_color_style matrix_dots mt-[106px] ml-[20px]"></div>
                <div id="dots_5" className="dots_color_style matrix_dots mt-[97px] ml-[23px]"></div>
                <div id="dots_6" className="dots_color_style matrix_dots mt-[88px] ml-[27px]"></div>
                <div id="dots_7.5" className="dots_color_style matrix_dots mt-[79px] ml-[32px]"></div>
                <div id="dots_8.5" className="dots_color_style matrix_dots mt-[70px] ml-[38px]"></div>

                <div id="dots_11" className="dots_color_style matrix_dots mt-[40px] ml-[68px]"></div>
                <div id="dots_12.5" className="dots_color_style matrix_dots mt-[34px] ml-[77px]"></div>
                <div id="dots_13.5" className="dots_color_style matrix_dots mt-[29px] ml-[85px]"></div>
                <div id="dots_15" className="dots_color_style matrix_dots mt-[25px] ml-[94px]"></div>
                <div id="dots_16" className="dots_color_style matrix_dots mt-[21px] ml-[104px]"></div>
                <div id="dots_17.5" className="dots_color_style matrix_dots mt-[18px] ml-[114px]"></div>
                <div id="dots_18.5" className="dots_color_style matrix_dots mt-[15px] ml-[126px]"></div>

                <div id="dots_21" className="dots_color_style matrix_dots mt-[15px] ml-[168px]"></div>
                <div id="dots_22.5" className="dots_color_style matrix_dots mt-[17px] ml-[178px]"></div>
                <div id="dots_23.5" className="dots_color_style matrix_dots mt-[20px] ml-[188px]"></div>
                <div id="dots_25" className="dots_color_style matrix_dots mt-[24px] ml-[198px]"></div>
                <div id="dots_26" className="dots_color_style matrix_dots mt-[28px] ml-[208px]"></div>
                <div id="dots_27.5" className="dots_color_style matrix_dots mt-[33px] ml-[218px]"></div>
                <div id="dots_28.5" className="dots_color_style matrix_dots mt-[40px] ml-[228px]"></div>

                <div id="dots_31" className="dots_color_style matrix_dots mt-[70px] ml-[258px]"></div>
                <div id="dots_32.5" className="dots_color_style matrix_dots mt-[79px] ml-[264px]"></div>
                <div id="dots_33.5" className="dots_color_style matrix_dots mt-[88px] ml-[269px]"></div>
                <div id="dots_35" className="dots_color_style matrix_dots mt-[97px] ml-[273px]"></div>
                <div id="dots_36" className="dots_color_style matrix_dots mt-[106px] ml-[276px]"></div>
                <div id="dots_37.5" className="dots_color_style matrix_dots mt-[115px] ml-[278px]"></div>
                <div id="dots_38.5" className="dots_color_style matrix_dots mt-[126px] ml-[281px]"></div>

                <div id="dots_41" className="dots_color_style matrix_dots mt-[168px] ml-[281px]"></div>
                <div id="dots_42.5" className="dots_color_style matrix_dots mt-[178px] ml-[278px]"></div>
                <div id="dots_43.5" className="dots_color_style matrix_dots mt-[188px] ml-[276px]"></div>
                <div id="dots_45" className="dots_color_style matrix_dots mt-[198px] ml-[273px]"></div>
                <div id="dots_46" className="dots_color_style matrix_dots mt-[208px] ml-[269px]"></div>
                <div id="dots_47.5" className="dots_color_style matrix_dots mt-[218px] ml-[264px]"></div>
                <div id="dots_48.5" className="dots_color_style matrix_dots mt-[228px] ml-[257px]"></div>

                <div id="dots_51" className="dots_color_style matrix_dots mt-[257px] ml-[227px]"></div>
                <div id="dots_52.5" className="dots_color_style matrix_dots mt-[263px] ml-[219px]"></div>
                <div id="dots_53.5" className="dots_color_style matrix_dots mt-[267px] ml-[210px]"></div>
                <div id="dots_55" className="dots_color_style matrix_dots mt-[272px] ml-[200px]"></div>
                <div id="dots_56" className="dots_color_style matrix_dots mt-[276px] ml-[190px]"></div>
                <div id="dots_57.5" className="dots_color_style matrix_dots mt-[279px] ml-[180px]"></div>
                <div id="dots_58.5" className="dots_color_style matrix_dots mt-[281px] ml-[170px]"></div>

                <div id="dots_61" className="dots_color_style matrix_dots mt-[280px] ml-[124px]"></div>
                <div id="dots_62.5" className="dots_color_style matrix_dots mt-[278px] ml-[114px]"></div>
                <div id="dots_63.5" className="dots_color_style matrix_dots mt-[275px] ml-[104px]"></div>
                <div id="dots_65" className="dots_color_style matrix_dots mt-[271px] ml-[94px]"></div>
                <div id="dots_66" className="dots_color_style matrix_dots mt-[266px] ml-[84px]"></div>
                <div id="dots_67.5" className="dots_color_style matrix_dots mt-[261px] ml-[75px]"></div>
                <div id="dots_68.5" className="dots_color_style matrix_dots mt-[255px] ml-[67px]"></div>

                <div id="dots_71" className="dots_color_style matrix_dots mt-[228px] ml-[38px]"></div>
                <div id="dots_72.5" className="dots_color_style matrix_dots mt-[218px] ml-[32px]"></div>
                <div id="dots_73.5" className="dots_color_style matrix_dots mt-[208px] ml-[27px]"></div>
                <div id="dots_75" className="dots_color_style matrix_dots mt-[198px] ml-[23px]"></div>
                <div id="dots_76" className="dots_color_style matrix_dots mt-[188px] ml-[20px]"></div>
                <div id="dots_77.5" className="dots_color_style matrix_dots mt-[178px] ml-[17px]"></div>
                <div id="dots_78.5" className="dots_color_style matrix_dots mt-[168px] ml-[15px]"></div>
            </div>
        )
    }

    const matrixYears = () => {
        if (matrixYearsCheckbox) {
            return (
                <div>
                    <p id="1" data-energy={years[1]} className="energy_visual_matrix mt-[120px] ml-[0px]">{years[1]}</p>
                    <p id="2.5" data-energy={years[2]} className="energy_visual_matrix mt-[110px] ml-[2px]">{years[2]}</p>
                    <p id="3.5" data-energy={years[3]} className="energy_visual_matrix mt-[100px] ml-[5px]">{years[3]}</p>
                    <p id="5" data-energy={years[4]} className="energy_visual_matrix mt-[90px] ml-[9px]">{years[4]}</p>
                    <p id="6" data-energy={years[5]} className="energy_visual_matrix mt-[80px] ml-[14px]">{years[5]}</p>
                    <p id="7.5" data-energy={years[6]} className="energy_visual_matrix mt-[70px] ml-[19px]">{years[6]}</p>
                    <p id="8.5" data-energy={years[7]} className="energy_visual_matrix mt-[60px] ml-[25px]">{years[7]}</p>

                    <p id="11" data-energy={years[9]} className="energy_visual_matrix mt-[28px] ml-[62px]">{years[9]}</p>
                    <p id="12.5" data-energy={years[10]} className="energy_visual_matrix mt-[20px] ml-[72px]">{years[10]}</p>
                    <p id="13.5" data-energy={years[11]} className="energy_visual_matrix mt-[14px] ml-[82px]">{years[11]}</p>
                    <p id="15" data-energy={years[12]} className="energy_visual_matrix mt-[9px] ml-[92px]">{years[12]}</p>
                    <p id="16" data-energy={years[13]} className="energy_visual_matrix mt-[6px] ml-[102px]">{years[13]}</p>
                    <p id="17.5" data-energy={years[14]} className="energy_visual_matrix mt-[3px] ml-[112px]">{years[14]}</p>
                    <p id="18.5" data-energy={years[15]} className="energy_visual_matrix mt-[1px] ml-[122px]">{years[15]}</p>

                    <p id="21" data-energy={years[17]} className="energy_visual_matrix mt-[1px] ml-[170px]">{years[17]}</p>
                    <p id="22.5" data-energy={years[18]} className="energy_visual_matrix mt-[3px] ml-[180px]">{years[18]}</p>
                    <p id="23.5" data-energy={years[19]} className="energy_visual_matrix mt-[6px] ml-[190px]">{years[19]}</p>
                    <p id="25" data-energy={years[20]} className="energy_visual_matrix mt-[9px] ml-[200px]">{years[20]}</p>
                    <p id="26" data-energy={years[21]} className="energy_visual_matrix mt-[15px] ml-[210px]">{years[21]}</p>
                    <p id="27.5" data-energy={years[22]} className="energy_visual_matrix mt-[20px] ml-[220px]">{years[22]}</p>
                    <p id="28.5" data-energy={years[23]} className="energy_visual_matrix mt-[27px] ml-[230px]">{years[23]}</p>

                    <p id="31" data-energy={years[25]} className="energy_visual_matrix mt-[60px] ml-[267px]">{years[25]}</p>
                    <p id="32.5" data-energy={years[26]} className="energy_visual_matrix mt-[70px] ml-[275px]">{years[26]}</p>
                    <p id="33.5" data-energy={years[27]} className="energy_visual_matrix mt-[80px] ml-[279px]">{years[27]}</p>
                    <p id="35" data-energy={years[28]} className="energy_visual_matrix mt-[90px] ml-[284px]">{years[28]}</p>
                    <p id="36" data-energy={years[29]} className="energy_visual_matrix mt-[100px] ml-[287px]">{years[29]}</p>
                    <p id="37.5" data-energy={years[30]} className="energy_visual_matrix mt-[110px] ml-[290px]">{years[30]}</p>
                    <p id="38.5" data-energy={years[31]} className="energy_visual_matrix mt-[120px] ml-[290px]">{years[31]}</p>

                    <p id="41" data-energy={years[33]} className="energy_visual_matrix mt-[168px] ml-[290px]">{years[33]}</p>
                    <p id="42.5" data-energy={years[34]} className="energy_visual_matrix mt-[178px] ml-[290px]">{years[34]}</p>
                    <p id="43.5" data-energy={years[35]} className="energy_visual_matrix mt-[188px] ml-[287px]">{years[35]}</p>
                    <p id="45" data-energy={years[36]} className="energy_visual_matrix mt-[198px] ml-[284px]">{years[36]}</p>
                    <p id="46" data-energy={years[37]} className="energy_visual_matrix mt-[208px] ml-[279px]">{years[37]}</p>
                    <p id="47.5" data-energy={years[38]} className="energy_visual_matrix mt-[218px] ml-[275px]">{years[38]}</p>
                    <p id="48.5" data-energy={years[39]} className="energy_visual_matrix mt-[228px] ml-[267px]">{years[39]}</p>

                    <p id="51" data-energy={years[41]} className="energy_visual_matrix mt-[266px] ml-[230px]">{years[41]}</p>
                    <p id="52.5" data-energy={years[42]} className="energy_visual_matrix mt-[273px] ml-[220px]">{years[42]}</p>
                    <p id="53.5" data-energy={years[43]} className="energy_visual_matrix mt-[279px] ml-[210px]">{years[43]}</p>
                    <p id="55" data-energy={years[44]} className="energy_visual_matrix mt-[283px] ml-[200px]">{years[44]}</p>
                    <p id="56" data-energy={years[45]} className="energy_visual_matrix mt-[286px] ml-[190px]">{years[45]}</p>
                    <p id="57.5" data-energy={years[46]} className="energy_visual_matrix mt-[289px] ml-[180px]">{years[46]}</p>
                    <p id="58.5" data-energy={years[47]} className="energy_visual_matrix mt-[291px] ml-[170px]">{years[47]}</p>

                    <p id="61" data-energy={years[49]} className="energy_visual_matrix mt-[291px] ml-[122px]">{years[49]}</p>
                    <p id="62.5" data-energy={years[50]} className="energy_visual_matrix mt-[289px] ml-[112px]">{years[50]}</p>
                    <p id="63.5" data-energy={years[51]} className="energy_visual_matrix mt-[286px] ml-[102px]">{years[51]}</p>
                    <p id="65" data-energy={years[52]} className="energy_visual_matrix mt-[283px] ml-[92px]">{years[52]}</p>
                    <p id="66" data-energy={years[53]} className="energy_visual_matrix mt-[279px] ml-[82px]">{years[53]}</p>
                    <p id="67.5" data-energy={years[54]} className="energy_visual_matrix mt-[273px] ml-[72px]">{years[54]}</p>
                    <p id="68.5" data-energy={years[55]} className="energy_visual_matrix mt-[266px] ml-[62px]">{years[55]}</p>

                    <p id="71" data-energy={years[57]} className="energy_visual_matrix mt-[228px] ml-[25px]">{years[57]}</p>
                    <p id="72.5" data-energy={years[58]} className="energy_visual_matrix mt-[218px] ml-[19px]">{years[58]}</p>
                    <p id="73.5" data-energy={years[59]} className="energy_visual_matrix mt-[208px] ml-[14px]">{years[59]}</p>
                    <p id="75" data-energy={years[60]} className="energy_visual_matrix mt-[198px] ml-[9px]">{years[60]}</p>
                    <p id="76" data-energy={years[61]} className="energy_visual_matrix mt-[188px] ml-[5px]">{years[61]}</p>
                    <p id="77.5" data-energy={years[62]} className="energy_visual_matrix mt-[178px] ml-[2px]">{years[62]}</p>
                    <p id="78.5" data-energy={years[63]} className="energy_visual_matrix mt-[168px] ml-[0px]">{years[63]}</p>
                </div>
            )
        }
    }

    const relationship = () => {
        return (
            <>
                {
                    matrixRelationshipCheckbox
                        ?
                        <div>
                            {/* relationship */}
                            {/* Мелкая энергия кармического хвоста */}
                            <div id="J" data-tooltip={t('zone_relationshipsKarma')} className="title-style title_style_addon button_matrix_little tag_big_energy mt-[236px] ml-[142px] tag_little_energy bg-[#FDD843] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, J: !prevState.J }))}
                            >{energies[9]}</div>
                            {openEnergyPopup.J ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, J: false })) : null} additionInfo={['zone_relationshipsKarma', energies[9], gender]} /> : null}

                            <div id="L" data-tooltip={t('zone_balance')} className="title-style title_style_addon button_matrix_little mt-[188px] ml-[188px] tag_little_energy tag_border_energy text-[8px] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, L: !prevState.L }))}
                            >{energies[11]}</div>
                            {openEnergyPopup.L ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, L: false })) : null} additionInfo={['zone_balance', energies[11], gender]} /> : null}

                            <div id="M" data-tooltip={t('zone_partner')} className="title-style title_style_addon button_matrix_little mt-[210px] ml-[166px] tag_little_energy tag_border_energy text-[8px] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, M: !prevState.M }))}
                            >{energies[12]}</div>
                            {openEnergyPopup.M ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, M: false })) : null} additionInfo={['zone_partner', energies[12], gender]} /> : null}

                            <div id="EE" data-tooltip={t('zone_familyStrength')} className="title-style title_style_addon button_matrix_little mt-[140px] ml-[165px] tag_middle_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, EE: !prevState.EE }))}
                            >{energies[40]}</div>
                            {openEnergyPopup.EE ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, EE: false })) : null} additionInfo={['zone_familyStrength', energies[40], gender]} /> : null}

                            <div id="FF" data-tooltip={t('zone_sexProgram')} className="title-style title_style_addon button_matrix_little mt-[142px] ml-[185px] tag_little_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, FF: !prevState.FF }))}
                            >{energies[41]}</div>
                            {openEnergyPopup.FF ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, FF: false })) : null} additionInfo={['zone_sexProgram', energies[41], gender]} /> : null}

                            <PinkHeartSVG className='absolute w-[14px] top-[192px] left-[164px] -rotate-45 fill-red-200' />
                        </div>
                        : null
                }
            </>
        )
    }


    const money = () => {
        return (
            <>
                {
                    matrixMoneyCheckbox
                        ?
                        <div>
                            {/* Finance */}

                            {/* Вход в денежный канал рыжая */}
                            <div id="K" data-tooltip={t('zone_financeKarma')} className="title-style title_style_addon button_matrix_little mt-[142px] ml-[236px] tag_little_energy bg-[#FDD843] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, K: !prevState.K }))}
                            >{energies[10]}</div>

                            {openEnergyPopup.K ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, K: false })) : null} additionInfo={['zone_financeKarma', energies[10], gender]} /> : null}


                            <div id="N" data-tooltip={t('zone_financial_strength')} className="title-style title_style_addon button_matrix_little mt-[164px] ml-[212px] tag_little_energy tag_border_energy text-[8px] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, N: !prevState.N }))}
                            >{energies[13]}</div>

                            {openEnergyPopup.N ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, N: false })) : null} additionInfo={['zone_financial_strength', energies[13], gender]} /> : null}

                            <div id="V" data-tooltip={t('zone_financeCloseOrOpen')} className="title-style title_style_addon button_matrix_little mt-[140px] ml-[252px] tag_middle_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, V: !prevState.V }))}
                            >{energies[21]}</div>

                            {openEnergyPopup.V ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, V: false })) : null} additionInfo={['zone_financeCloseOrOpen', energies[21], gender]} /> : null}

                            <DollarSVG className="absolute w-[16px] fill-green-200 top-[160px] left-[195px]" />
                        </div>
                        : null
                }
            </>
        )
    }

    const parents = () => {
        return (
            <>
                {
                    matrixParentsCheckbox
                        ?
                        <div>

                            {/* PARENTS */}

                            {/* Верхняя слева по мужской */}
                            <div id="10" data-tooltip={t('zone_fatherTopBig')} className="title-style title_style_addon button_matrix tag_big_energy mt-[38px] ml-[38px] tag_big_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, F_10: !prevState.F_10 }))}
                            >{energies[5]}</div>
                            {openEnergyPopup.F_10 ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, F_10: false })) : null} additionInfo={['zone_fatherTopBig', energies[5], gender]} /> : null}

                            {/* Верхняя справа по Женской */}
                            <div id="30" data-tooltip={t('zone_motherTopBig')} className="title-style title_style_addon button_matrix tag_big_energy mt-[38px] ml-[232px] tag_big_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, F_30: !prevState.F_30 }))}
                            >{energies[6]}</div>
                            {openEnergyPopup.F_30 ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, F_30: false })) : null} additionInfo={['zone_motherTopBig', energies[6], gender]} /> : null}

                            {/* Нижняя справа по мужской */}
                            <div id="50" data-tooltip={t('zone_fatherBottomBig')} className="title-style title_style_addon button_matrix tag_big_energy mt-[232px] ml-[232px] tag_big_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, F_50: !prevState.F_50 }))}
                            >{energies[7]}</div>
                            {openEnergyPopup.F_50 ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, F_50: false })) : null} additionInfo={['zone_fatherBottomBig', energies[7], gender]} /> : null}

                            {/* Нижняя слева по женской */}
                            <div id="70" data-tooltip={t('zone_motherBottomBig')} className="title-style title_style_addon button_matrix tag_big_energy mt-[232px] ml-[38px] tag_big_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, F_70: !prevState.F_70 }))}
                            >{energies[8]}</div>
                            {openEnergyPopup.F_70 ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, F_70: false })) : null} additionInfo={['zone_motherBottomBig', energies[8], gender]} /> : null}



                            <div id="W" data-tooltip={t('zone_man_talant_little')} className="title-style title_style_addon button_matrix_little select-none mt-[76px] ml-[76px] tag_little_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, W: !prevState.W }))}
                            >{energies[22]}</div>
                            {openEnergyPopup.W ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, W: false })) : null} additionInfo={['zone_man_talant_little', energies[22], gender]} /> : null}

                            <div id="X" data-tooltip={t('zone_man_talant_little')} className="title-style title_style_addon button_matrix_little select-none mt-[60px] ml-[60px] tag_middle_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, X: !prevState.X }))}
                            >{energies[23]}</div>
                            {openEnergyPopup.X ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, X: false })) : null} additionInfo={['zone_man_talant_little', energies[23], gender]} /> : null}

                            <div id="Y" data-tooltip={t('zone_woman_talant_little')} className="title-style title_style_addon button_matrix_little select-none mt-[76px] ml-[206px] tag_little_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, Y: !prevState.Y }))}
                            >{energies[24]}</div>
                            {openEnergyPopup.Y ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, Y: false })) : null} additionInfo={['zone_woman_talant_little', energies[24], gender]} /> : null}

                            <div id="Z" data-tooltip={t('zone_woman_talant_little')} className="title-style title_style_addon button_matrix_little select-none mt-[60px] ml-[218px] tag_middle_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, Z: !prevState.Z }))}
                            >{energies[25]}</div>
                            {openEnergyPopup.Z ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, Z: false })) : null} additionInfo={['zone_woman_talant_little', energies[25], gender]} /> : null}


                            <div id="AA" data-tooltip={t('zone_man_karma_little')} className="title-style title_style_addon button_matrix_little select-none mt-[208px] ml-[208px] tag_little_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, AA: !prevState.AA }))}
                            >{energies[26]}</div>
                            {openEnergyPopup.AA ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, AA: false })) : null} additionInfo={['zone_man_karma_little', energies[26], gender]} /> : null}

                            <div id="BB" data-tooltip={t('zone_man_karma_little')} className="title-style title_style_addon button_matrix_little select-none mt-[219px] ml-[219px] tag_middle_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, BB: !prevState.BB }))}
                            >{energies[27]}</div>
                            {openEnergyPopup.BB ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, BB: false })) : null} additionInfo={['zone_man_karma_little', energies[27], gender]} /> : null}

                            <div id="CC" data-tooltip={t('zone_woman_karma_little')} className="title-style title_style_addon button_matrix_little select-none mt-[208px] ml-[74px] tag_little_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, CC: !prevState.CC }))}>{energies[28]}</div>
                            {openEnergyPopup.CC ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, CC: false })) : null} additionInfo={['zone_woman_karma_little', energies[28], gender]} /> : null}

                            <div id="DD" data-tooltip={t('zone_woman_karma_little')} className="title-style title_style_addon button_matrix_little select-none mt-[220px] ml-[60px] tag_middle_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, DD: !prevState.DD }))}>{energies[29]}</div>
                            {openEnergyPopup.DD ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, DD: false })) : null} additionInfo={['zone_woman_karma_little', energies[29], gender]} /> : null}

                        </div>
                        : null
                }
            </>
        )
    }

    const littleEnergies = () => {
        return (
            <>
                {
                    matrixLittleEnergiesCheckbox
                        ?
                        <div>
                            {/* Little energies */}
                            <div id="O" data-tooltip={t('zone_parentsRelationship')} className="title-style title_style_addon button_matrix_little mt-[142px] ml-[48px] tag_little_energy bg-[#98FCFF] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, O: !prevState.O }))}
                            >{energies[14]}</div>
                            {openEnergyPopup.O ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, O: false })) : null} additionInfo={['zone_parentsRelationship', energies[14], gender]} /> : null}

                            <div id="P" data-tooltip={t('zone_childrensRelationship')} className="title-style title_style_addon button_matrix_little mt-[140px] ml-[28px] tag_middle_energy bg-[#98A4FD]"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, P: !prevState.P }))}
                            >{energies[15]}</div>
                            {openEnergyPopup.P ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, P: false })) : null} additionInfo={['zone_childrensRelationship', energies[15], gender]} /> : null}

                            <div id="Q" data-tooltip={t('zone_emotionalBottom')} className="title-style title_style_addon button_matrix_little mt-[142px] ml-[90px] tag_little_energy bg-[#E8FE9C] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, Q: !prevState.Q }))}
                            >{energies[16]}</div>
                            {openEnergyPopup.Q ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, Q: false })) : null} additionInfo={['zone_emotionalBottom', energies[16], gender]} /> : null}

                            <div id="R" data-tooltip={t('zone_smallTalent')} className="title-style title_style_addon button_matrix_little mt-[48px] ml-[142px] tag_little_energy bg-[#98FCFF] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, R: !prevState.R }))}
                            >{energies[17]}</div>
                            {openEnergyPopup.R ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, R: false })) : null} additionInfo={['zone_smallTalent', energies[17], gender]} /> : null}

                            <div id="S" data-tooltip={t('zone_bonuses')} className="title-style title_style_addon button_matrix_little mt-[28px] ml-[140px] tag_middle_energy bg-[#98A4FD]"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, S: !prevState.S }))}
                            >{energies[18]}</div>
                            {openEnergyPopup.S ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, S: false })) : null} additionInfo={['zone_bonuses', energies[18], gender]} /> : null}

                            <div id="T" data-tooltip={t('zone_emotionalTop')} className="title-style title_style_addon button_matrix_little mt-[90px] ml-[142px] tag_little_energy bg-[#E8FE9C] text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, T: !prevState.T }))}
                            >{energies[19]}</div>
                            {openEnergyPopup.T ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, T: false })) : null} additionInfo={['zone_emotionalTop', energies[19], gender]} /> : null}

                            {/* Средняя энергия кармического хвоста */}
                            <div id="U" data-tooltip={t('zone_personalityKarma')} className="title-style title_style_addon button_matrix_little mt-[252px] ml-[140px] tag_middle_energy tag_border_energy text-black"
                                onClick={() => setOpenEnergyPopup((prevState) => ({ ...prevState, U: !prevState.U }))}
                            >{energies[20]}</div>
                            {openEnergyPopup.U ? <PopupContainer t={t} choosePopup={'energys_description'} setPopupOpen={(answer) => answer === false ? setOpenEnergyPopup((prevState) => ({ ...prevState, U: false })) : null} additionInfo={['zone_personalityKarma', energies[20], gender]} /> : null}

                        </div>
                        : null
                }
            </>
        )
    }

    const backgroundImages = () => {
        return (
            <>
                <div className="pt-[51px] pl-[50px]">
                    {
                        matrixLittleEnergiesCheckbox
                            ?
                            <div className="-mb-[300px]">
                                <img className="block touch_screen_non_selectable pointer-events-none w-[300px] h-[300px] select-none" src="/images/background_matrix_1.svg" alt="matrixImage" />
                            </div>
                            : null
                    }
                </div>

                <div className="pl-[56px] pt-[6px] text_in_matrix">
                    {
                        matrixYearsCheckbox
                            ? <MatrixYearsBackgroundSVG />
                            : null
                    }
                </div>
            </>
        )
    }

    const rombusAndCross = () => {
        return (
            <>
                {/* ромб */}
                <div className="relative left-[150px] -top-[63px] rotate-[45deg]">
                    {/* сам ромб */}
                    <div className="absolute w-[1px] h-[190px] bg-gray-200 left-[55px] top-[55px]"></div>
                    <div className="absolute w-[190px] h-[1px] bg-gray-200 left-[55px] top-[55px]"></div>
                    <div className="absolute w-[1px] h-[190px] bg-gray-200 left-[245px] top-[55px]"></div>
                    <div className="absolute w-[190px] h-[1px] bg-gray-200 left-[55px] top-[245px]"></div>

                    {
                        matrixParentsCheckbox
                            ?
                            <div>
                                {/* родительские палки */}
                                <div className="absolute w-[1px] h-[300px] left-[149px] border-[1px] border-dashed border-gray-200"></div>
                                <div className="absolute w-[300px] h-[1px] top-[149px] border-[1px] border-dashed border-gray-200"></div>
                            </div>
                            : null
                    }

                    {
                        matrixMoneyCheckbox || matrixRelationshipCheckbox
                            ? <div className="absolute w-[1px] h-[135px] bg-gray-200 left-[217px] top-[85px]"></div>
                            : null
                    }
                </div>
            </>
        )
    }

    const squareAndCross = () => {
        return (
            <>
                <div className="relative">
                    {/* круг */}
                    {/* <div className="absolute w-[270px] h-[270px] top-[15px] left-[15px] border border-gray-300 rounded-full"></div> */}

                    {/* центральные палки */}
                    <div className="absolute w-[1px] h-[300px] bg-gray-200 left-[149px]"></div>
                    <div className="absolute w-[300px] h-[1px] bg-gray-200 top-[149px]"></div>

                    {/* анимационные палки */}
                    <div className="absolute w-[1px] bg-blue-300 left-[149px] matrix_visual_animation_vertical_stick"></div>
                    <div className="absolute h-[1px] bg-blue-300 top-[149px] matrix_visual_animation_horisontal_stick"></div>


                    {
                        matrixParentsCheckbox
                            ?
                            <div>
                                {/* родовые палки */}
                                <div className="absolute w-[1px] h-[190px] bg-gray-200 left-[55px] top-[55px]"></div>
                                <div className="absolute w-[190px] h-[1px] bg-gray-200 left-[55px] top-[55px]"></div>
                                <div className="absolute w-[1px] h-[190px] bg-gray-200 left-[245px] top-[55px]"></div>
                                <div className="absolute w-[190px] h-[1px] bg-gray-200 left-[55px] top-[245px]"></div>
                                <ManSignSVG className="absolute w-4 fill-gray-200 top-[108px] left-[105px] stroke-none" />
                                <WomanSignSVG className="absolute w-4 fill-gray-200 top-[105px] left-[181px] stroke-none" />
                            </div>
                            : null
                    }

                </div>
            </>
        )
    }

    const purposes = () => {
        return (
            <>
                {
                    matrixPurposesCheckbox
                        ?
                        <div className="relative">
                            {/* дополнения сверху снизу (предназначения) */}

                            {/* верх - лево \/\/ */}
                            {/* вертикальная палка для дуги */}
                            <div className="absolute w-[1px] h-[20px] border-gray-200 top-9 left-[199px] border-[1px] border-dashed"></div>

                            {/* горизонтальная палка для дуги */}
                            <div className="absolute w-[20px] h-[1px] border-gray-200 top-[199px] left-9 border-[1px] border-dashed"></div>

                            {/* ДУГА Первого предназначения */}
                            <div className="absolute w-[180px] h-[180px] border-gray-200 border-t-[1px] border-l-[1px] rounded-tl-[100px] left-[25px] top-[25px] rotate-[0deg]"></div>

                            {/* Вертикаль */}
                            <div id="heavenSumm" data-tooltip={t('matrix-heaven-summ')} className="title-style title_style_addon !border-gray-300 select-none mt-[20px] ml-[190px] tag_middle_energy tag_border_energy text-black"
                            // onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, DD: !prevState.DD}))}
                            >{energies[37]}</div>

                            {/* горизонталь */}
                            <div id="earthSumm" data-tooltip={t('matrix-earth-summ')} className="title-style title_style_addon !border-gray-300 select-none mt-[190px] ml-[20px] tag_middle_energy tag_border_energy text-black z-[1]"
                            // onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, DD: !prevState.DD}))}
                            >{energies[36]}</div>

                            {/* первое предназначение */}
                            <div id="firstPurpose" data-tooltip={t('matrix-purposes-first-title')} className="title-style title_style_addon button_matrix tag_big_energy mt-[38px] ml-[38px] tag_big_energy tag_border_energy !bg-gray-200 text-black"
                                onClick={() => setPurposesPopupOpen(!purposesPopupOpen)}
                            >{energies[30]}</div>
                            {/* верх - лево /\/\ */}


                            

                            {/* низ \/\/ */}
                            {/* поперечные палки соединяющие нижнюю дугу */}
                            <div className="absolute w-[1px] h-[20px] border-gray-200 top-[300px] left-[89px] border-[1px] border-dashed rotate-[45deg]"></div>
                            <div className="absolute w-[1px] h-[20px] border-gray-200 top-[300px] left-[310px] border-[1px] border-dashed rotate-[-45deg]"></div>

                            {/* ДУГА Второго предназначения */}
                            <div className="absolute w-[180px] h-[180px] border-gray-200 border-b-[1px] border-l-[1px] rounded-bl-[100px] left-[110px] top-[235px] rotate-[-43deg]"></div>
                            
                            <div id="fatherSumm" data-tooltip={t('profile-matrix-parentsFatherProgrammPurpose')} className="title-style title_style_addon !border-gray-300 select-none mt-[315px] ml-[315px] tag_middle_energy tag_border_energy text-black"
                            // onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, DD: !prevState.DD}))}
                            >{energies[38]}</div>

                            <div id="motherSumm" data-tooltip={t('profile-matrix-parentsMotherProgrammPurpose')} className="title-style title_style_addon !border-gray-300 select-none mt-[310px] ml-[70px] tag_middle_energy tag_border_energy text-black z-[1]"
                            // onClick={() => setOpenEnergyPopup((prevState) => ({...prevState, DD: !prevState.DD}))}
                            >{energies[39]}</div>

                            {/* второе предназначение */}
                            <div id="secondPurpose" data-tooltip={t('matrix-purposes-second-title')} className="title-style title_style_addon button_matrix tag_big_energy mt-[395px] ml-[185px] tag_big_energy tag_border_energy !bg-gray-300 text-black"
                                onClick={() => setPurposesPopupOpen(!purposesPopupOpen)}
                            >{energies[31]}</div>
                            {/* низ /\/\ */}


                            {/* ДУГА Третьего предназначения */}
                            <div className="absolute w-[285px] h-[285px] border-gray-200 border-0 border-t-[1px] border-l-[1px]  rounded-tl-[250px] rounded-tr-[20px] rounded-bl-[20px] -left-[25px] top-[95px] rotate-[-68deg] border-dashed"></div>
                            
                            {/* третье предназначение */}
                            <div id="thirdPurpose" data-tooltip={t('matrix-purposes-third-title')} className="title-style title_style_addon button_matrix tag_big_energy mt-[265px] ml-[15px] tag_big_energy tag_border_energy !bg-gray-400 text-white "
                                onClick={() => setPurposesPopupOpen(!purposesPopupOpen)}
                            >{energies[32]}</div>


                            {/* ДУГА Четвёртого предназначения */}
                            <div className="absolute w-[160px] h-[160px] border-dotted border-gray-200 border-b-[1px] border-l-[1px] rounded-bl-[100px] left-[30px] top-[270px] rotate-[-5deg]"></div>

                            {/* четвёртое предназначение */}
                            <div id="fourthPurpose" data-tooltip={t('matrix-purposes-fourth-title')} className="title-style title_style_addon button_matrix tag_big_energy mt-[390px] ml-[50px] tag_big_energy tag_border_energy !bg-gray-500 text-white"
                                onClick={() => setPurposesPopupOpen(!purposesPopupOpen)}
                            >{energies[42]}</div>

                            {purposesPopupOpen ? <PopupContainer t={t} choosePopup={'purposes'} setPopupOpen={setPurposesPopupOpen} additionInfo={[energies, gender]} /> : null}

                        </div>
                        : null
                }
            </>
        )
    }


    return (
        <div className="absolute">

            {purposes()}

            <div className="absolute top-[50px] left-[50px]">

                <div className="relative">
                    {squareAndCross()}
                    {rombusAndCross()}
                </div>

                {dots()}
                {matrixYears()}

                <div>
                    {baseInfo()}
                    {relationship()}
                    {money()}
                    {parents()}
                    {littleEnergies()}
                </div>

            </div>

            {backgroundImages()}
        </div>

    )
}

const MatrixVisual = ({ matrixListOfEnergiesInner, gender, checkboxes = null }) => {
    // Гендер и ДатуРождения оставляем т.к. входящие разные

    const listOfEnergies = matrixListOfEnergiesInner[0]
    const listOfYears = matrixListOfEnergiesInner[4]

    const MatrixMasterComponent = CreateMatrixVisualComponentFunc({energies: listOfEnergies, years: listOfYears, gender: gender, checkboxes: checkboxes})

    return (
        // НЕ ДЕЛАТЬ RELATIVE - не будут работать подсказки
        <div className="overflow-hidden touch_screen_non_selectable flex items-center justify-center">
            <div id="matrix_visual" className="w-[390px] h-[430px]">
                {MatrixMasterComponent}
            </div>
        </div>
    )
}

export default MatrixVisual;