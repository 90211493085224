import ExitCrossSVG from "../../../svg/exitCross_svg";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const YearEnergyDesctiptionPopup = ({close, additionInfo}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const yearEnergyFriend = additionInfo ? additionInfo : null

    const matrixList = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    const cardEnergy = yearEnergyFriend ? yearEnergyFriend : matrixList[5]

    const cardsUrl = useSelector(state => state.optionsSlice.cardStyleUrl)

    return (
        // Основа
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <div className="popup_title">
                        {t('profile-matrix-yearEnergyTitle')}
                    </div>

                    <div className="flex w-full flex-col gap-5 p-5">
                    {/* Основа */}
                        <div className="flex items-center justify-center">
                            <div className="taro_style_upper w-[200px]"><img className=" taro_style dance_button" src={`${cardsUrl}/${cardEnergy}.jpg`} alt="cards"/></div>
                        </div>

                        <div className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1 gap-10">{cardEnergy}</div>
                        <p className="">{t(`matrix-year-description-${cardEnergy}`)}</p>
                
                {/* Основа */}
                </div>
                </div>
                <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
            </div>
        </div>
    )
}

export default YearEnergyDesctiptionPopup;