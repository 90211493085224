const ShareSVG = ({ className }) => {
  return (

    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="1.5em" height="1.5em"
      fill="currentColor"
      stroke="currentColor"

      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        >
        <path d="M3299 4653 l-22 -28 7 -532 6 -532 -57 -6 c-32 -4 -112 -13 -178 -21
-735 -90 -1371 -353 -1875 -775 -359 -301 -667 -711 -871 -1161 -146 -320
-250 -665 -296 -980 -17 -116 -17 -118 3 -145 16 -21 31 -29 61 -31 40 -3 42
-1 122 93 223 260 541 539 851 747 435 290 933 515 1415 637 238 60 517 109
733 127 l84 7 -8 -516 c-6 -476 -6 -518 10 -542 22 -33 58 -47 90 -35 27 10
1721 1801 1738 1837 6 12 8 33 4 47 -4 16 -350 390 -862 931 -847 896 -856
905 -894 905 -30 0 -43 -6 -61 -27z"/>
      </g>
    </svg>
  )

}

export default ShareSVG;