import { t } from "i18next";
import { ChartComponent, ChartDestinyWill } from "./profile_pifagor_chart";
import { useState } from "react";
import PopupContainer from "../popups_root";
import { PifagorCalculationFunc } from "../calculations/profile_pifagor_calculations";

const PifagorSquare = () => {
    const [count1, count2, count3, count4, count5, count6, count7, count8, count9, 
        count147, count258, count369, count123, count456, count789, count159, 
        count357, yearList, numbersInTimeList, pifagorDestinyList, pifagorWillList] = PifagorCalculationFunc()

    // ПОПАПЫ
    const [pifagorCount1PopupOpen, setPifagorCount1PopupOpen] = useState(false);
    const [pifagorCount2PopupOpen, setPifagorCount2PopupOpen] = useState(false);
    const [pifagorCount3PopupOpen, setPifagorCount3PopupOpen] = useState(false);
    const [pifagorCount4PopupOpen, setPifagorCount4PopupOpen] = useState(false);
    const [pifagorCount5PopupOpen, setPifagorCount5PopupOpen] = useState(false);
    const [pifagorCount6PopupOpen, setPifagorCount6PopupOpen] = useState(false);
    const [pifagorCount7PopupOpen, setPifagorCount7PopupOpen] = useState(false);
    const [pifagorCount8PopupOpen, setPifagorCount8PopupOpen] = useState(false);
    const [pifagorCount9PopupOpen, setPifagorCount9PopupOpen] = useState(false);

    const [pifagorColumn1PopupOpen, setPifagorColumn1PopupOpen] = useState(false);
    const [pifagorColumn2PopupOpen, setPifagorColumn2PopupOpen] = useState(false);
    const [pifagorColumn3PopupOpen, setPifagorColumn3PopupOpen] = useState(false);

    const [pifagorLine1PopupOpen, setPifagorLine1PopupOpen] = useState(false);
    const [pifagorLine2PopupOpen, setPifagorLine2PopupOpen] = useState(false);
    const [pifagorLine3PopupOpen, setPifagorLine3PopupOpen] = useState(false);

    const [pifagorDiagonal1PopupOpen, setPifagorDiagonal1PopupOpen] = useState(false);
    const [pifagorDiagonal2PopupOpen, setPifagorDiagonal2PopupOpen] = useState(false);

    const [pifagorWillGraphPopupOpen, setPifagorWillGraphPopupOpen] = useState(false);

    // PifagorDescriptionPopup
    return (
    <>
        <div className="profile_part_container">
            <p className="popup_title">{t('pifagor-title')}</p>

            <div className="flex justify-center gap-2 sm:gap-1 border-[1px] p-5 sm:p-2 rounded-2xl border_color">

                <div className="flex flex-col gap-2 sm:gap-1">

                    <div className="pifagor" onClick={() => setPifagorCount1PopupOpen(!pifagorCount1PopupOpen)}>
                    {/* // {alert(t(`pifagor-character-${count1}`))}}> */}
                        <h1>{count1}</h1>
                        <p>{t('pifagor-character')}</p>

                    </div>
                    {pifagorCount1PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount1PopupOpen} additionInfo={[t(`pifagor-character-${count1}`), count1, 1]} /> : null}


                    <div className="pifagor" onClick={() => setPifagorCount2PopupOpen(!pifagorCount2PopupOpen)}>
                        <h1>{count2}</h1>
                        <p>{t('pifagor-energy')}</p>

                    </div>
                    {pifagorCount2PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount2PopupOpen} additionInfo={[t(`pifagor-energy-${count2}`), count2, 2]} /> : null}


                    <div className="pifagor" onClick={() => setPifagorCount3PopupOpen(!pifagorCount3PopupOpen)}>
                        <h1>{count3}</h1>
                        <p>{t('pifagor-interest')}</p>

                    </div>
                    {pifagorCount3PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount3PopupOpen} additionInfo={[t(`pifagor-interest-${count3}`), count3, 3]} /> : null}


                    <div className="pifagor pifagor_color_add " onClick={() => setPifagorColumn1PopupOpen(!pifagorColumn1PopupOpen)}>
                        <h1>{count123}</h1>
                        <p>{t('pifagor-self-rating')}</p>

                    </div>
                    {pifagorColumn1PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorColumn1PopupOpen} additionInfo={[t(`pifagor-column-1`), count123, 'column']} /> : null}


                </div>

                <div className="flex flex-col gap-2 sm:gap-1">

                    <div className="pifagor" onClick={() => setPifagorCount4PopupOpen(!pifagorCount4PopupOpen)}>
                        <h1>{count4}</h1>
                        <p>{t('pifagor-health')}</p>

                    </div>
                    {pifagorCount4PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount4PopupOpen} additionInfo={[t(`pifagor-health-${count4}`), count4, 4]} /> : null}


                    <div className="pifagor" onClick={() => setPifagorCount5PopupOpen(!pifagorCount5PopupOpen)}>
                        <h1>{count5}</h1>
                        <p>{t('pifagor-logic')}</p>

                    </div>
                    {pifagorCount5PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount5PopupOpen} additionInfo={[t(`pifagor-logic-${count5}`), count5, 5]} /> : null}


                    <div className="pifagor" onClick={() => setPifagorCount6PopupOpen(!pifagorCount6PopupOpen)}>
                        <h1>{count6}</h1>
                        <p>{t('pifagor-work')}</p>

                    </div>
                    {pifagorCount6PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount6PopupOpen} additionInfo={[t(`pifagor-work-${count6}`), count6, 6]} /> : null}


                    <div className="pifagor pifagor_color_add " onClick={() => setPifagorColumn2PopupOpen(!pifagorColumn2PopupOpen)}>
                        <h1>{count456}</h1>
                        <p>{t('pifagor-money')}</p>

                    </div>
                    {pifagorColumn2PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorColumn2PopupOpen} additionInfo={[t(`pifagor-column-2`), count456, 'column']} /> : null}


                </div>

                <div className="flex flex-col gap-2 sm:gap-1">

                    <div className="pifagor" onClick={() => setPifagorCount7PopupOpen(!pifagorCount7PopupOpen)}>
                        <h1>{count7}</h1>
                        <p>{t('pifagor-luck')}</p>

                    </div>
                    {pifagorCount7PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount7PopupOpen} additionInfo={[t(`pifagor-luck-${count7}`), count7, 7]} /> : null}


                    <div className="pifagor" onClick={() => setPifagorCount8PopupOpen(!pifagorCount8PopupOpen)}>
                        <h1>{count8}</h1>
                        <p>{t('pifagor-call_of_duty')}</p>

                    </div>
                    {pifagorCount8PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount8PopupOpen} additionInfo={[t(`pifagor-call_of_duty-${count8}`), count8, 8]} /> : null}


                    <div className="pifagor" onClick={() => setPifagorCount9PopupOpen(!pifagorCount9PopupOpen)}>
                        <h1>{count9}</h1>
                        <p>{t('pifagor-mind')}</p>

                    </div>
                    {pifagorCount9PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount9PopupOpen} additionInfo={[t(`pifagor-mind-${count9}`), count9, 9]} /> : null}


                    <div className="pifagor pifagor_color_add " onClick={() => setPifagorColumn3PopupOpen(!pifagorColumn3PopupOpen)}>
                        <h1>{count789}</h1>
                        <p>{t('pifagor-talant')}</p>

                    </div>
                    {pifagorColumn3PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorColumn3PopupOpen} additionInfo={[t(`pifagor-column-3`), count789, 'column']} /> : null}


                </div>

                <div className="flex flex-col gap-2 sm:gap-1">

                    <div className="pifagor pifagor_color_add " onClick={() => setPifagorLine1PopupOpen(!pifagorLine1PopupOpen)}>
                        <h1>{count147}</h1>
                        <p>{t('pifagor-purpose')}</p>

                    </div>
                    {pifagorLine1PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorLine1PopupOpen} additionInfo={[t(`pifagor-line-1`), count147, 'line']} /> : null}


                    <div className="pifagor pifagor_color_add " onClick={() => setPifagorLine2PopupOpen(!pifagorLine2PopupOpen)}>
                        <h1>{count258}</h1>
                        <p>{t('pifagor-family')}</p>

                    </div>
                    {pifagorLine2PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorLine2PopupOpen} additionInfo={[t(`pifagor-line-2`), count258, 'line']} /> : null}


                    <div className="pifagor pifagor_color_add " onClick={() => setPifagorLine3PopupOpen(!pifagorLine3PopupOpen)}>
                        <h1>{count369}</h1>
                        <p>{t('pifagor-habits')}</p>

                    </div>
                    {pifagorLine3PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorLine3PopupOpen} additionInfo={[t(`pifagor-line-3`), count369, 'line']} /> : null}


                    <div className="pifagor pifagor_color_add !cursor-auto">
                        <div className="flex gap-3">
                            <h1 className="hover:text-green-500 cursor-pointer" onClick={() => setPifagorDiagonal1PopupOpen(!pifagorDiagonal1PopupOpen)}>{count159}</h1>
                            /
                            <h1 className="hover:text-green-500 cursor-pointer" onClick={() => setPifagorDiagonal2PopupOpen(!pifagorDiagonal2PopupOpen)}>{count357}</h1>
                        </div>
                        {pifagorDiagonal1PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorDiagonal1PopupOpen} additionInfo={[t(`pifagor-diagonal-159`), count159, 'diagonal']} /> : null}
                        {pifagorDiagonal2PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorDiagonal2PopupOpen} additionInfo={[t(`pifagor-diagonal-357`), count357, 'diagonal']} /> : null}

                        <p>{t('pifagor-spirit_body')}</p>
                    </div>

                </div>

            </div>
        </div>

        {/* ЧАРТЫ */}
        <div className="w-full h-full p-5 sm:p-2">

            {/* График жизни */}
            <div>
                <div className="br_border_line_gray" />

                <p className="popup_title">{t('pifagor-graph-title_1')}</p>
                <ChartComponent yearListInner={yearList} numbersInTimeListInner={numbersInTimeList} />
                <p>{t('pifagor-graph-addonInfo')}</p>
            </div>

            {/* График судьбы и воли */}
            <div className="flex flex-col items-center justify-center">
                <div className="br_border_line_gray" />

                <p className="popup_title">{t('pifagor-graph-destinyWill-title')}</p>
                <ChartDestinyWill yearListInner={yearList} pifagorDestinyInner={pifagorDestinyList} pifagorWillInner={pifagorWillList} />
                <button className="button_blue_rounded !w-fit" onClick={() => setPifagorWillGraphPopupOpen(!pifagorWillGraphPopupOpen)}>{t('description')}</button>
                
        
            </div>
            {pifagorWillGraphPopupOpen ? <PopupContainer choosePopup={'pifagorWillGraph_popup'} setPopupOpen={setPifagorWillGraphPopupOpen} additionInfo={null} /> : null}

        </div>
            
    </>
    )
}

export default PifagorSquare;