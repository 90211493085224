import { Link } from "react-router-dom"
import { t } from "i18next"
import { formattedDate, formattedDateWithoutClock } from "../services"
import { baseBackendUrl } from "../websocket_comp";

const UserCard = ( {username, profile_pic, first_name, last_name, birth_date, is_online=null, last_time_online=null, moodStatusId=null, moodStatusBase=null }) => {
    const moodStatusTemp = moodStatusBase?.find(item => item.username === username)
    const moodStatus = moodStatusTemp?.mood_status
    const moodClarification = moodStatusTemp?.mood_status_clarification
    const moodOwnOption = moodStatusTemp?.mood_status_own_option

    return (
        <div className=''>
                <Link className=' hover:text-blue-300 flex items-center  gap-3' to={`/${username}`}>
                    {/* фотка */}
                    <div className='relative'>
                        <div className=" button_ava_posts border-2 border_color_rounded_elements bg-cover bg-no-repeat bg-center w-[50px] h-[50px] rounded-full" style={{ backgroundImage: `url(${baseBackendUrl+profile_pic})` }}></div>
                        {is_online ? <div className={`absolute right-1 top-[38px] w-2 h-2 bg-green-500 border-[1px] border_color_rounded_elements rounded-full`}></div> : null}
                    </div>
                    
                    {/* имя */}
                    <div className='flex flex-col leading-4 w-full'>
                        <div className='flex sm:flex-col gap-4 sm:gap-1'>
                            <div className='flex gap-1'>
                                <p>{first_name}</p>
                                <p>{last_name}</p>
                            </div>

                            <div className="w-full flex flex-col gap-1">
                                
                                {/* Основной статус настроения */}
                                {moodStatus === 'status_empty' || !moodStatus
                                ? null 
                                : <p className={`rounded-full bg_bright_blue text_on_bright_plate w-fit pl-2 pr-2 text-[10px] text-center ${moodStatus === 'status_need_help' ? '!bg-red-500' : null}`}>{t(`profile-${moodStatus}`)}</p>
                                }

                                {/* Поясняющий статус настроения */}
                                {
                                    !moodClarification || moodClarification === 'profile-clarification_my_option' 
                                        ? null 
                                        : <p className='rounded-full bg_bright_blue text_on_bright_plate w-fit pl-2 pr-2 text-[10px] text-center'>{t(`${moodClarification}`)}</p>
                                }

                                {
                                     moodClarification === 'profile-clarification_my_option' && moodOwnOption 
                                        ? <p className='rounded-full bg_bright_blue text_on_bright_plate w-fit pl-2 pr-2 text-[10px] text-center'>{t(`${moodOwnOption}`)}</p>
                                        : null
                                }
                                
                            </div>

                            <div>
                            </div>
                        </div>
                        <p className='text-[11px] text-gray-500'>@{username}</p>
                        <div className='flex gap-1 text-[12px] text-gray-500'>
                            <p>{t('profile-matrix-birthDate')}</p>
                            <p>{formattedDateWithoutClock(birth_date)}</p>
                        </div>
                    </div>
                </Link>


                {is_online ? 
                    <div className='w-full h-full flex justify-end items-end text-[11px] gap-2 text-gray-400'>
                        <p>{t('friends-online')}</p>
                    </div>
                    :
                    <div className='w-full h-full flex justify-end items-end text-[11px] gap-2 text-gray-400'>
                        <p>{t('friends-last_time_was_online')}:</p>
                        <p className=''>{last_time_online === null ? 'online' : formattedDate(last_time_online)}</p>
                    </div>
                }
            </div>
    )
  }

  export default UserCard;