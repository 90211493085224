import React, { useState } from 'react';
import SavePostSVG from '../../../svg/savePost_svg';
import PopupContainer from '../popups_root';
import axios from 'axios';
import { baseBackendUrl } from "../websocket_comp";
// import { t } from "i18next";


const SavePost = ({ authorizedUserInner, postSaversInner, postIdInner }) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [saved, setSaved] = useState(postSaversInner.includes(authorizedUserInner));

    const savePostFunc = async (postIdInner) => {
        if (!authorizedUserInner) {
            setPopupOpen(true);
            return;
        }

        try {
        const url = `${baseBackendUrl}/post/${postIdInner}/save`;
        const data = { post: postIdInner, user: authorizedUserInner };

        await axios.post(url, data);
        setSaved(true); // Установить флаг "лайкнуто"

        // Обновить состояние компонента или выполнить другие необходимые действия
        } catch (error) {
        console.error(error);
        }
    };

    const unsavePostFunc = async (postIdInner) => {
        if (!authorizedUserInner) {
            setPopupOpen(true);
            return;
        }

        try {
        const url = `${baseBackendUrl}/post/${postIdInner}/unsave`;
        const data = { post: postIdInner, user: authorizedUserInner };

        await axios.post(url, data);
        setSaved(false); // Установить флаг "лайкнуто"

        // Обновить состояние компонента или выполнить другие необходимые действия
        } catch (error) {
        console.error(error);
        }
    };

    const handleSaveClick = (postIdInner) => {
        if (saved) {
        unsavePostFunc(postIdInner);
        } else {
        savePostFunc(postIdInner);
        }
    };

    return (
        <>
            <div className="actions_with_posts" data-post_id={postIdInner}>
            {(saved) ? (
                <div className="svg-span cursor-pointer" onClick={() => handleSaveClick(postIdInner)}>
                     {/* className="w-[18px] fill-[#e0245e] hover:fill-red-500 hover:w-5 hover:rotate-45  ease-in-out duration-500" */}
                <SavePostSVG className='w-[18px] stroke-none fill-[#17bf63] hover:w-5  ease-in-out duration-500' />
                </div>
            ) : (
                <div className="svg-span cursor-pointer" onClick={() => handleSaveClick(postIdInner)}>
                <SavePostSVG className="w-[18px] hover:w-5 ease-in-out duration-500" />
                </div>
            )}
            </div>
            {popupOpen ? <PopupContainer choosePopup={'login_warning'} setPopupOpen={setPopupOpen} additionInfo="save" /> : null}
        </>
    );
};

export default SavePost;