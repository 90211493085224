export const MoodSmile = ({ className }) => {
    return (

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="1.5em" height="1.5em"
            fill="currentColor"
            stroke="currentColor"


            viewBox="0 0 96.000000 96.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
            >
                <path d="M386 869 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111 -29
-61 -32 -76 -32 -163 0 -90 2 -99 37 -171 41 -81 107 -152 133 -142 25 10 17
43 -21 82 -20 20 -49 62 -63 92 -22 46 -26 69 -26 140 0 71 4 94 26 140 51
108 159 185 274 197 61 6 85 24 65 48 -14 17 -63 18 -124 4z"/>
                <path d="M633 833 c-23 -9 -14 -42 15 -57 47 -24 114 -98 141 -156 22 -46 26
-69 26 -140 0 -103 -27 -170 -96 -239 -67 -67 -136 -95 -239 -97 -65 -1 -87
-6 -95 -18 -18 -30 10 -41 103 -41 76 1 95 4 154 32 93 43 151 100 196 191 35
72 37 81 37 171 0 87 -3 102 -31 161 -37 78 -83 133 -143 172 -47 29 -48 30
-68 21z"/>
                <path d="M326 594 c-9 -8 -16 -24 -16 -34 0 -43 54 -65 84 -34 31 30 9 84 -34
84 -10 0 -26 -7 -34 -16z"/>
                <path d="M566 594 c-9 -8 -16 -24 -16 -34 0 -43 54 -65 84 -34 31 30 9 84 -34
84 -10 0 -26 -7 -34 -16z"/>
                <path d="M324 365 c-4 -8 -4 -22 0 -30 5 -13 29 -15 158 -13 l153 3 0 25 0 25
-153 3 c-129 2 -153 0 -158 -13z"/>
            </g>
        </svg>
    )
}