import ExitCrossSVG from "../../../svg/exitCross_svg";
import { useSelector, useDispatch } from "react-redux";
import PopupContainer from "../popups_root";
import { useState, useEffect } from "react";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const CarmicProgramPopup = ({ close, additionInfo }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const friendsListEnergies = additionInfo ? additionInfo[0] : null
    const friendsGender = additionInfo ? additionInfo[1] : null

    const matrixListOfEnergies = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    const cardsUrl = useSelector(state => state.optionsSlice.cardStyleUrl)
    const profileData = useSelector(state => state.profileUserSlice.profileUser)

    const listOfEnergies = matrixListOfEnergies[0]
    const carmikEnergies = friendsListEnergies ? [friendsListEnergies[9], friendsListEnergies[20], friendsListEnergies[3]] : [listOfEnergies[9], listOfEnergies[20], listOfEnergies[3]]

    const user = profileData.user
    const gender = friendsGender ? friendsGender : user.gender

    const [openEnergyPopup_1, setOpenEnergyPopup_1] = useState(false)
    const [openEnergyPopup_2, setOpenEnergyPopup_2] = useState(false)
    const [openEnergyPopup_3, setOpenEnergyPopup_3] = useState(false)

    // const [first, second, third] = carmikEnergies

    return (
        // Основа
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">
                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>

                <div className="flex w-full flex-col items-center overflow-auto">

                    {/* ЗАГОЛОВОК */}
                    <p className="popup_title">{t('profile-matrix-carmicProgramTitle')}</p>

                    <div className="flex w-full flex-col gap-5 p-5">
                        {/* Основа */}

                        <p className="">{t('carmicProgram-description')}</p>
                        <p className="flex bg-red-500 items-center justify-center rounded-full text-white pl-2 pr-2 m-1">
                            {carmikEnergies[0]}-{carmikEnergies[1]}-{carmikEnergies[2]}
                        </p>

                        <div className="flex sm:flex-col gap-2 items-center justify-center ">
                            <div className="taro_style_upper w-[150px] hover:border-blue-500 cursor-pointer" onClick={() => setOpenEnergyPopup_1(!openEnergyPopup_1)}><img className="taro_style dance_button" src={`${cardsUrl}/${carmikEnergies[0]}.jpg`} alt="cards" /></div>
                            <div className="taro_style_upper w-[150px] hover:border-blue-500 cursor-pointer" onClick={() => setOpenEnergyPopup_2(!openEnergyPopup_2)}><img className="taro_style dance_button" src={`${cardsUrl}/${carmikEnergies[1]}.jpg`} alt="cards" /></div>
                            <div className="taro_style_upper w-[150px] hover:border-blue-500 cursor-pointer" onClick={() => setOpenEnergyPopup_3(!openEnergyPopup_3)}><img className="taro_style dance_button" src={`${cardsUrl}/${carmikEnergies[2]}.jpg`} alt="cards" /></div>
                        </div>

                        <p className="flex w-fit bg-blue-500 items-center justify-center rounded-full text-white pl-10 pr-10 m-1">{t(`carmicProgramTitle-${carmikEnergies[0]}-${carmikEnergies[1]}-${carmikEnergies[2]}`)}</p>
                        <p>{t(`carmicProgramLesson-${carmikEnergies[0]}-${carmikEnergies[1]}-${carmikEnergies[2]}`)}</p>
                        <p className="flex w-fit bg-green-500 items-center justify-center rounded-3xl text-white p-1 pl-10 pr-10 m-1">{t(`carmicProgramPassed-${carmikEnergies[0]}-${carmikEnergies[1]}-${carmikEnergies[2]}`)}</p>
                        <p className="flex w-fit bg-red-500 items-center justify-center rounded-3xl text-white p-1 pl-10 pr-10 m-1">{t(`carmicProgramFailure-${carmikEnergies[0]}-${carmikEnergies[1]}-${carmikEnergies[2]}`)}</p>


                        {/* Основа */}
                    </div>

                    {openEnergyPopup_1 ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setOpenEnergyPopup_1} additionInfo={['zone_relationshipsKarma', carmikEnergies[0], gender]} /> : null}
                    {openEnergyPopup_2 ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setOpenEnergyPopup_2} additionInfo={['zone_personalityKarma', carmikEnergies[1], gender]} /> : null}
                    {openEnergyPopup_3 ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setOpenEnergyPopup_3} additionInfo={['zone_bd_m_y_karmicTail', carmikEnergies[2], gender]} /> : null}

                </div>
                <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
            </div>
        </div>
    )
}

export default CarmicProgramPopup;