const ManSignSVG = ({className}) => {
    return (
    <svg 
    className={className} 

    viewBox="19.5133 57.6121 26.4567 26.3892" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(0.007536000572144984, 0, 0, -0.007536000572144984, -2.787872314453182, 88.25349426269526)">
      <path d="M5400 3898 l0 -168 247 0 248 0 -345 -345 c-190 -190 -348 -345 -351 -345 -3 0 -43 25 -88 55 -235 157 -477 229 -771 229 -185 0 -297 -19 -457 -75 -258 -91 -505 -276 -663 -496 -77 -108 -170 -298 -205 -418 -142 -490 -8 -1011 350 -1370 197 -196 433 -323 715 -382 120 -25 411 -25 530 0 271 58 494 175 689 362 211 203 340 437 403 728 31 146 31 397 -1 547 -40 186 -131 396 -230 527 l-39 50 352 352 351 351 -3 -250 -3 -250 171 0 170 0 -2 533 -3 532 -532 1 -533 0 0 -168z m-906 -913 c124 -19 270 -68 372 -126 93 -52 293 -240 355 -333 347 -520 157 -1242 -401 -1521 -174 -87 -330 -120 -520 -112 -276 12 -507 113 -700 307 -191 191 -294 422 -307 691 -23 474 268 902 717 1051 160 54 320 68 484 43z" 
      />
    </g>
  </svg>
    )
}

export default ManSignSVG;
