import React, { useState } from 'react';
import LikePostSVG from '../../../svg/likePost_svg';
import UnLikePostSVG from '../../../svg/unLikePost_svg';
import PopupContainer from '../popups_root';
import axios from 'axios';
import { baseBackendUrl } from "../websocket_comp";
import { Link } from 'react-router-dom';
// import { t } from "i18next";

const Likes = ({ postIdInner, postLikersInner, authorizedUserInner, postLikersInnerCount }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [likesCount, setLikesCount] = useState(postLikersInnerCount);
  const [liked, setLiked] = useState(postLikersInner.includes(authorizedUserInner));

  const [focusOnLikers, setFocusOnLikers] = useState(false);

  const [postLikers, setPostLikers] = useState(postLikersInner)

  const likePost = async (postIdInner, user) => {
    if (!user) {
        setPopupOpen(true);
    } else {
        try {
            const url = `${baseBackendUrl}/post/${postIdInner}/like`;
            const data = { post: postIdInner, user: user };
      
            await axios.post(url, data);
      
            setLikesCount(prevCount => prevCount + 1);
            setLiked(true); // Установить флаг "лайкнуто"
        } catch (error) {
            console.error(error);
        }
    }
  };

  const unlikePost = async (postIdInner, user) => {
    if (!user) {
        setPopupOpen(true);
    } else {
        try {
            const url = `${baseBackendUrl}/post/${postIdInner}/unlike`;
            const data = { post: postIdInner, user: user };

            await axios.post(url, data);

            setLikesCount(prevCount => prevCount - 1);
            setLiked(false); // Установить флаг "не лайкнуто"
        } catch (error) {
            console.error(error);
            }
        }
  };

  const handleLikeClick = (postIdInner, user) => {
    if (liked) {
      unlikePost(postIdInner, user);
      setPostLikers(postLikers.filter((item) => item !== authorizedUserInner))
    } else {
      likePost(postIdInner, user);
      setPostLikers([...postLikers, authorizedUserInner])
    }
  };

  return (
    <>
      <div className="actions_with_posts" data-post_id={postIdInner}  onMouseEnter={() => setFocusOnLikers(true)} onMouseLeave={() => setFocusOnLikers(false)}>
        {liked ? (
          <div className="svg-span cursor-pointer" onClick={() => handleLikeClick(postIdInner, authorizedUserInner)}>
            <UnLikePostSVG className="w-[18px] fill-[#e0245e] hover:fill-red-500 hover:w-5 hover:rotate-45  ease-in-out duration-500" />
          </div>
        ) : (
          <div className="svg-span cursor-pointer" onClick={() => handleLikeClick(postIdInner, authorizedUserInner)}>
            <LikePostSVG className="w-[18px] hover:fill-red-500 hover:w-5 hover:rotate-45 ease-in-out duration-500" />
          </div>
        )}
        <div className="likes_count">{likesCount}</div>

        {focusOnLikers && likesCount > 0 ? 
          <div className='relative'>
            <div className='absolute flex flex-col gap-1 -top-10 left-0 p-2 max-w-[200px] max-h-[100px] border-[1px] rounded-xl border_color navigation-bg cursor-pointer overflow-auto'>
              {postLikers?.map((user, id) => <Link to={`/${user}`} key={id}>{user}</Link>)}
            </div> 
          </div>
        : null}

      </div>

      {popupOpen ? <PopupContainer choosePopup={'login_warning'} setPopupOpen={setPopupOpen} additionInfo="like" /> : null}

    </>
  );
};

export default Likes;