import {createSlice} from '@reduxjs/toolkit';

const wsChatSlice = createSlice ({
    name: 'chat',
    initialState: {
        nonReadMessages: [],
        chatColorStatus: false,
    },
    reducers: {
        updateNonReadMessages(state, action) {
            state.nonReadMessages = action.payload
        },
        updateChatColorStatus(state, action) {
            state.chatColorStatus = action.payload
        }
    }
})

export const {updateNonReadMessages, updateChatColorStatus} = wsChatSlice.actions; 

export default wsChatSlice.reducer;