import { baseBackendUrl } from "../little_components/websocket_comp";
import axios from "axios";
import { useEffect, useState } from "react";
import SendMessageSVG from "../../svg/svg_send_message";
import { t } from "i18next";
import { updateProfileAction } from "../store/profileUserInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import RatingSmiles from "../little_components/rating_smiles_comp";
import { Calendar } from "../little_components/calendar"


const ProfileMoodStatus = ({ setMoodNotificationInner=null }) => {

    // {/* Mood Statuses */ }
    const [calendarDateOut, setCalendarDateOut] = useState(null)

    const [allBaseMoodList, setAllBaseMoodList] = useState([])

    const [moodStatusHealth, setMoodStatusHealth] = useState(0)
    const [moodStatusRelationship, setMoodStatusRelationship] = useState(0)
    const [moodStatusFamily, setMoodStatusFamily] = useState(0)
    const [moodStatusWork, setMoodStatusWork] = useState(0)
    const [moodStatusFinance, setMoodStatusFinance] = useState(0)

    const [baseMoodListNonZero, setBaseMoodListNonZero] = useState(false)
    const [blockMoodList, setBlockMoodList] = useState(false)


    const [allDetailedMoodList, setAllDetaileMoodList] = useState([])

    const [moodStatusLonely, setMoodStatusLonely] = useState(0)
    const [moodStatusNervous, setMoodStatusNervous] = useState(0)
    const [moodStatusBored, setMoodStatusBored] = useState(0)
    const [moodStatusFocused, setMoodStatusFocused] = useState(0)
    const [moodStatusExcited, setMoodStatusExcited] = useState(0)
    const [moodStatusTired, setMoodStatusTired] = useState(0)
    const [moodStatusAsleep, setMoodStatusAsleep] = useState(0)

    const [detailedMoodListNonZero, setDetailedMoodListNonZero] = useState(false)
    const [blockDetailedMoodList, setBlockDetailedMoodList] = useState(false)



    const dispatch = useDispatch();
    const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)

    const profileUser = useSelector(state => state.profileUserSlice.profileUser);
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser);

    const user = profileUser.user
    const username = user.username

    const moodStatusData = profileUser.mood_status_data

    const [moodStatus, setMoodStatus] = useState(moodStatusData.mood_status)
    const [moodClarification, setMoodClarification] = useState(moodStatusData.mood_status_clarification)
    const [moodOwnOption, setMoodOwnOption] = useState(moodStatusData.mood_status_own_option ? moodStatusData.mood_status_own_option : '')


    // два раза грузится, оптимизировать надо
    useEffect(() => {
        const getMoodBaseList = async () => {
            const url = `${baseBackendUrl}/${username}/get_mood_base_list`;
            const response = await axios.post(url);
            // console.log(response.data)
            setAllBaseMoodList(response.data.mood_base_list)
            setAllDetaileMoodList(response.data.mood_detailed_list)
        }

        getMoodBaseList()
    }, [baseMoodListNonZero, username, detailedMoodListNonZero])

    // Здесь обновляется статус БАЗОВОЙ оценки настроения
    useEffect(() => {

        // Выделенная дата в календаре
        const currentDate = calendarDateOut;
        const onlyDate = `${currentDate?.getFullYear()}-${(currentDate?.getMonth() + 1).toString().padStart(2, '0')}-${currentDate?.getDate().toString().padStart(2, '0')}`

        // Сегодняшняя дата
        const todayDate = new Date()
        const todayOnlyDate = `${todayDate.getFullYear()}-${(todayDate.getMonth() + 1).toString().padStart(2, '0')}-${todayDate.getDate().toString().padStart(2, '0')}`

        // Если в базе нет сегодняшней даты, то отмечаем красный кружок уведомление на кнопку
        // setMoodNotificationInner(allBaseMoodList?.at(-1)?.date === todayOnlyDate ? false : true)

        // Если выделенная дата ровна сегодняшней дате
        if (onlyDate === todayOnlyDate) {
            // не блочим отметку настроя
            setBlockMoodList(false)

            // Если в базе нет сегодняшней даты
            if (allBaseMoodList?.at(-1)?.date !== todayOnlyDate) {

                const baseMoodList = [moodStatusHealth, moodStatusRelationship, moodStatusFamily, moodStatusWork, moodStatusFinance]

                // Проверяем, что каждый элемент массива не равен нулю
                const allNonZero = baseMoodList.every(item => item !== 0);

                // если все не нулевые, кнопка отправки становится активная
                if (allNonZero) {
                    // true
                    setBaseMoodListNonZero(allNonZero)
                }

                return
            }
            // Если выделенная дата не ровна сегодняшней дате
        } else {
            // базово стираем все отметки старых отметок настроения, чтобы потом подгрузить актуальные
            setMoodStatusHealth(0)
            setMoodStatusRelationship(0)
            setMoodStatusFamily(0)
            setMoodStatusWork(0)
            setMoodStatusFinance(0)

            // убираем кнопку отправки
            setBaseMoodListNonZero(false)

            // блочим отмечание смайликов
            setBlockMoodList(true)
        }

        // Смотрим какие дни настроя есть
        for (let i = 0; i < allBaseMoodList.length; i++) {
            // Блочим выбор
            setBlockMoodList(true)

            if (allBaseMoodList[i].date === onlyDate) {

                setMoodStatusHealth(allBaseMoodList[i].mood_list[0])
                setMoodStatusRelationship(allBaseMoodList[i].mood_list[1])
                setMoodStatusFamily(allBaseMoodList[i].mood_list[2])
                setMoodStatusWork(allBaseMoodList[i].mood_list[3])
                setMoodStatusFinance(allBaseMoodList[i].mood_list[4])

                setBaseMoodListNonZero(false)

                return
            } else {
                setMoodStatusHealth(0)
                setMoodStatusRelationship(0)
                setMoodStatusFamily(0)
                setMoodStatusWork(0)
                setMoodStatusFinance(0)

                setBaseMoodListNonZero(false)
            }

        }

    }, [calendarDateOut, allBaseMoodList, 
        // setMoodNotificationInner, 
        moodStatusHealth, moodStatusRelationship, moodStatusFamily, moodStatusWork, moodStatusFinance])

    // Здесь обновляется статус ДЕТАЛЬНОЙ оценки настроения
    useEffect(() => {

        // Выделенная дата в календаре
        const currentDate = calendarDateOut;
        const onlyDate = `${currentDate?.getFullYear()}-${(currentDate?.getMonth() + 1).toString().padStart(2, '0')}-${currentDate?.getDate().toString().padStart(2, '0')}`

        // Сегодняшняя дата
        const todayDate = new Date()
        const todayOnlyDate = `${todayDate.getFullYear()}-${(todayDate.getMonth() + 1).toString().padStart(2, '0')}-${todayDate.getDate().toString().padStart(2, '0')}`


        // Если выделенная дата ровна сегодняшней дате
        if (onlyDate === todayOnlyDate) {
            // не блочим отметку настроя
            setBlockDetailedMoodList(false)

            // Если в базе нет сегодняшней даты
            if (allDetailedMoodList?.at(-1)?.date !== todayOnlyDate) {
                const detailedMoodList = [moodStatusLonely, moodStatusNervous, moodStatusBored, moodStatusFocused, moodStatusExcited, moodStatusTired, moodStatusAsleep]

                // Проверяем, что каждый элемент массива не равен нулю
                const allNonZero = detailedMoodList.every(item => item !== 0);

                // если все не нулевые, кнопка отправки становится активная
                if (allNonZero) {
                    // true
                    setDetailedMoodListNonZero(allNonZero)
                }

                return
            }
            // Если выделенная дата не ровна сегодняшней дате
        } else {
            // базово стираем все отметки старых отметок настроения, чтобы потом подгрузить актуальные
            setMoodStatusLonely(0)
            setMoodStatusNervous(0)
            setMoodStatusBored(0)
            setMoodStatusFocused(0)
            setMoodStatusExcited(0)
            setMoodStatusTired(0)
            setMoodStatusAsleep(0)

            // убираем кнопку отправки
            setDetailedMoodListNonZero(false)

            // блочим отмечание смайликов
            setBlockDetailedMoodList(true)
        }

        // Смотрим какие дни настроя есть
        for (let i = 0; i < allDetailedMoodList.length; i++) {
            // Блочим выбор
            setBlockDetailedMoodList(true)

            if (allDetailedMoodList[i].date === onlyDate) {
                setMoodStatusLonely(allDetailedMoodList[i].mood_list[0])
                setMoodStatusNervous(allDetailedMoodList[i].mood_list[1])
                setMoodStatusBored(allDetailedMoodList[i].mood_list[2])
                setMoodStatusFocused(allDetailedMoodList[i].mood_list[3])
                setMoodStatusExcited(allDetailedMoodList[i].mood_list[4])
                setMoodStatusTired(allDetailedMoodList[i].mood_list[5])
                setMoodStatusAsleep(allDetailedMoodList[i].mood_list[6])

                setDetailedMoodListNonZero(false)

                return
            } else {
                setMoodStatusLonely(0)
                setMoodStatusNervous(0)
                setMoodStatusBored(0)
                setMoodStatusFocused(0)
                setMoodStatusExcited(0)
                setMoodStatusTired(0)
                setMoodStatusAsleep(0)


                setDetailedMoodListNonZero(false)
            }

        }

    }, [calendarDateOut, allDetailedMoodList, 
        // setMoodNotificationInner, 
        moodStatusLonely, moodStatusNervous, moodStatusBored, moodStatusFocused, moodStatusExcited, moodStatusTired, moodStatusAsleep])


    useEffect(() => {
        const emptyClarification = async () => {

            if (moodStatus !== moodStatusData.mood_status) {
                // console.log(moodStatus, '1', moodStatusData.mood_status)

                setMoodClarification('')
                const url = `${baseBackendUrl}/${username}/mood_empty`;
                const data = { authorizedUser: authorizedUser.username, clarification: '', own_option: '' };
                await axios.post(url, data);
            }
        }
        emptyClarification()
        // setUpdateProfile(!updateProfile)

        // eslint-disable-next-line
    }, [moodStatus])

    const handleMoodStatusChange = async (e) => {
        setMoodStatus(e.target.value)

        const url = `${baseBackendUrl}/${username}/mood_status`;
        const data = { authorizedUser: authorizedUser.username, mood_status: e.target.value };
        await axios.post(url, data);
        // setUpdateProfile(!updateProfile)
    };

    const handleClarificationChange = async (e) => {
        setMoodClarification(e.target.value)

        const url = `${baseBackendUrl}/${username}/mood_clarification`;
        const data = { authorizedUser: authorizedUser.username, clarification: e.target.value };
        await axios.post(url, data);
        // setUpdateProfile(!updateProfile)
    };

    const handleMyOwnStatusKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Отменить стандартное действие клавиши Enter

            const url = `${baseBackendUrl}/${username}/mood_own_option`;
            const data = { authorizedUser: authorizedUser.username, own_option: moodOwnOption };
            await axios.post(url, data);
            dispatch(updateProfileAction(!updateProfile))

        }
    };

    const handleMyOwnStatus = async (e) => {

        const url = `${baseBackendUrl}/${username}/mood_own_option`;
        const data = { authorizedUser: authorizedUser.username, own_option: moodOwnOption };
        await axios.post(url, data);
        dispatch(updateProfileAction(!updateProfile))
    };

    const clarification = () => {
        switch (moodStatus) {

            case 'status_life_goes_on':
                return (
                    <div>
                        <select className={`input_style_status`}
                            defaultValue={moodStatusData.mood_status_clarification ? moodStatusData.mood_status_clarification : "clarification_empty"} name="mood_status_clarification" size="1"
                            onChange={handleClarificationChange}
                            key={moodStatus}
                        >
                            <option value="profile-clarification_empty">{t('profile-clarification_empty')}</option>
                            <option value="profile-clarification_money">{t('profile-clarification_money')}</option>
                            <option value="profile-clarification_my_option">{t('profile-clarification_my_option')}</option>
                        </select>

                    </div>
                );

            case 'status_need_to_talk':
                return (
                    <div>
                        <select className={`input_style_status`}
                            defaultValue={moodStatusData.mood_status_clarification ? moodStatusData.mood_status_clarification : "clarification_empty"} name="mood_status_clarification" size="1"
                            onChange={handleClarificationChange}
                            key={moodStatus}
                        >
                            <option value="profile-clarification_empty">{t('profile-clarification_empty')}</option>
                            <option value="profile-clarification_want_meet">{t('profile-clarification_want_meet')}</option>
                            <option value="profile-clarification_want_advice">{t('profile-clarification_want_advice')}</option>
                            <option value="profile-clarification_share_problems">{t('profile-clarification_share_problems')}</option>
                            <option value="profile-clarification_boring">{t('profile-clarification_boring')}</option>
                            <option value="profile-clarification_my_option">{t('profile-clarification_my_option')}</option>

                        </select>

                    </div>
                );

            case 'status_disappointed':
                return (
                    <div>
                        <select className={`input_style_status`}
                            defaultValue={moodStatusData.mood_status_clarification ? moodStatusData.mood_status_clarification : "clarification_empty"} name="mood_status_clarification" size="1"
                            onChange={handleClarificationChange}
                            key={moodStatus}
                        >
                            <option value="profile-clarification_empty">{t('profile-clarification_empty')}</option>
                            <option value="profile-clarification_want_advice">{t('profile-clarification_want_advice')}</option>
                            <option value="profile-clarification_share_problems">{t('profile-clarification_share_problems')}</option>
                            <option value="profile-clarification_what_next">{t('profile-clarification_what_next')}</option>
                            <option value="profile-clarification_job">{t('profile-clarification_job')}</option>
                            <option value="profile-clarification_my_option">{t('profile-clarification_my_option')}</option>

                        </select>

                    </div>
                );

            case 'status_need_help':
                return (
                    <div>
                        <select className={`input_style_status`}
                            defaultValue={moodStatusData.mood_status_clarification ? moodStatusData.mood_status_clarification : "clarification_empty"} name="mood_status_clarification" size="1"
                            onChange={handleClarificationChange}
                            key={moodStatus}
                        >
                            <option value="profile-clarification_empty">{t('profile-clarification_empty')}</option>
                            <option value="profile-clarification_want_advice">{t('profile-clarification_want_advice')}</option>
                            <option value="profile-clarification_share_problems">{t('profile-clarification_share_problems')}</option>
                            <option value="profile-clarification_what_next">{t('profile-clarification_what_next')}</option>
                            <option value="profile-clarification_psychologist">{t('profile-clarification_psychologist')}</option>
                            <option value="profile-clarification_report_violence">{t('profile-clarification_report_violence')}</option>
                            <option value="profile-clarification_my_option">{t('profile-clarification_my_option')}</option>

                        </select>

                    </div>
                );

            default:
                return null; // Возвращаем null или что-то другое по умолчанию, если moodStatus не соответствует ни одному из case
        }
    }

    // Отправка базового настроя
    const handleBaseEmotionsClick = async () => {
        const baseMoodList = [moodStatusHealth, moodStatusRelationship, moodStatusFamily, moodStatusWork, moodStatusFinance]

        if (baseMoodListNonZero) {
            const url = `${baseBackendUrl}/${username}/mood_base_list`;
            const data = { authorizedUser: authorizedUser.username, moodList: baseMoodList, date: new Date(), moodListName: 'Mood_Base_List' };
            await axios.post(url, data);
        }
        setBaseMoodListNonZero(false)

    }

    const handleDetailedEmotionsClick = async () => {
        const detailedMoodList = [moodStatusLonely, moodStatusNervous, moodStatusBored, moodStatusFocused, moodStatusExcited, moodStatusTired, moodStatusAsleep]


        if (detailedMoodListNonZero) {
            const url = `${baseBackendUrl}/${username}/mood_base_list`;
            const data = { authorizedUser: authorizedUser.username, moodList: detailedMoodList, date: new Date(), moodListName: 'Mood_Detailed_List' };
            await axios.post(url, data);
        }

        setDetailedMoodListNonZero(false)

    }

    const onChangeCalendar = () => {
        setMoodStatusHealth(0)
        setMoodStatusRelationship(0)
        setMoodStatusFamily(0)
        setMoodStatusWork(0)
        setMoodStatusFinance(0)

        setMoodStatusLonely(0)
        setMoodStatusNervous(0)
        setMoodStatusBored(0)
        setMoodStatusFocused(0)
        setMoodStatusExcited(0)
        setMoodStatusTired(0)
        setMoodStatusAsleep(0)
    }

    const moodEveryDayCalendar = () => {
        return (
            <div className="flex flex-col gap-2">

                <div className="rounded-3xl p-2">
                    <p className="popup_title">{t('mood-calendar_title')}:</p>

                    <Calendar dateOut={setCalendarDateOut} allBaseMoodListInner={allBaseMoodList} allDetailedMoodListInner={allDetailedMoodList} onChange={onChangeCalendar} />
                </div>

                <div className="flex sm:flex-col">

                    <div className=" rounded-3xl p-10">



                        <div className="flex flex-col items-center justify-center relative p-2 gap-2">
                            <p className="popup_title">{t('mood-status_base_grade')}:</p>

                            <div className="absolute w-full h-full bg-green-600 opacity-5 rounded-3xl" />

                            <div className="flex flex-col gap-1">
                                <RatingSmiles value={moodStatusHealth} textInner={t('mood-status_health')} setSelectedSmileOutside={setMoodStatusHealth} blockContent={blockMoodList} />
                                <RatingSmiles value={moodStatusRelationship} textInner={t('mood-status_relationship')} setSelectedSmileOutside={setMoodStatusRelationship} blockContent={blockMoodList} />
                                <RatingSmiles value={moodStatusFamily} textInner={t('mood-status_family')} setSelectedSmileOutside={setMoodStatusFamily} blockContent={blockMoodList} />
                                <RatingSmiles value={moodStatusWork} textInner={t('mood-status_work')} setSelectedSmileOutside={setMoodStatusWork} blockContent={blockMoodList} />
                                <RatingSmiles value={moodStatusFinance} textInner={t('mood-status_finance')} setSelectedSmileOutside={setMoodStatusFinance} blockContent={blockMoodList} />
                            </div>

                            {new Date(calendarDateOut).getDate() === new Date().getDate() && authorizedUser.username === username
                                ?
                                (
                                    baseMoodListNonZero
                                        ?
                                        <button className="button_green"
                                            onClick={handleBaseEmotionsClick}
                                        >
                                            {t('mood-status-button-send')}
                                        </button>

                                        :
                                        (new Date(allBaseMoodList?.at(-1)?.date).getDate() === new Date().getDate())
                                            ? null
                                            :
                                            <div className="relative w-full h-[50px]">
                                                <button data-tooltip={t('mood-status-button-description')} className="absolute top-0 left-0 title-style title_style_top  button_grey_like_green">{t('mood-status-button-send')}</button>
                                            </div>
                                )
                                : null
                            }

                        </div>
                    </div>

                    <div className="rounded-3xl p-10">

                        <div className="flex flex-col items-center justify-center relative p-2 gap-2">
                            <p className="popup_title">{t('mood-status_detailed_grade')}:</p>

                            <div className="absolute w-full h-full bg-blue-600 opacity-5 rounded-3xl" />

                            <div className="flex flex-col gap-1">
                                <RatingSmiles textInner={t('mood-status_lonely')} value={moodStatusLonely} setSelectedSmileOutside={setMoodStatusLonely} blockContent={blockDetailedMoodList} />
                                <RatingSmiles textInner={t('mood-status_nervous')} value={moodStatusNervous} setSelectedSmileOutside={setMoodStatusNervous} blockContent={blockDetailedMoodList} />
                                <RatingSmiles textInner={t('mood-status_bored')} value={moodStatusBored} setSelectedSmileOutside={setMoodStatusBored} blockContent={blockDetailedMoodList} />
                                <RatingSmiles textInner={t('mood-status_focused')} value={moodStatusFocused} setSelectedSmileOutside={setMoodStatusFocused} blockContent={blockDetailedMoodList} />
                                <RatingSmiles textInner={t('mood-status_excited')} value={moodStatusExcited} setSelectedSmileOutside={setMoodStatusExcited} blockContent={blockDetailedMoodList} />
                                <RatingSmiles textInner={t('mood-status_tired')} value={moodStatusTired} setSelectedSmileOutside={setMoodStatusTired} blockContent={blockDetailedMoodList} />
                                <RatingSmiles textInner={t('mood-status_asleep')} value={moodStatusAsleep} setSelectedSmileOutside={setMoodStatusAsleep} blockContent={blockDetailedMoodList} />
                            </div>

                            {new Date(calendarDateOut).getDate() === new Date().getDate() && authorizedUser.username === username
                                ?
                                (
                                    detailedMoodListNonZero
                                        ?
                                        <button className="button_green"

                                            onClick={handleDetailedEmotionsClick}
                                        >
                                            {t('mood-status-button-send')}
                                        </button>

                                        :
                                        (new Date(allDetailedMoodList?.at(-1)?.date).getDate() === new Date().getDate())
                                            ? null
                                            :
                                            <div className="relative w-full h-[50px]">
                                                <button data-tooltip={t('mood-status-button-description')} className="absolute top-0 left-0 title-style title_style_top  button_grey_like_green">{t('mood-status-button-send')}</button>
                                            </div>
                                )
                                : null
                            }

                        </div>
                    </div>

                </div>


            </div>
        )
    }

    const baseMoodStatus = () => {
        return (
            <>
                {authorizedUser.username === username ?

                    // СВОЙ ПРОФИЛЬ
                    <form className="flex flex-col w-full pl-2 pr-2 gap-1">
                        <p className="popup_title">{t('profile-status_mood_title')}:</p>

                        {/* {console.log(moodStatusData.mood_status)} */}

                        {/* mood_status */}
                        <select className={`input_style_status ${moodStatusData.mood_status === 'status_need_help' ? 'bg-red-500' : null}`}
                            defaultValue={moodStatusData.mood_status ? moodStatusData.mood_status : "status_empty"} name="mood_status" size="1"
                            onChange={handleMoodStatusChange}
                        >

                            <option value="status_empty" disabled>{t('profile-status_empty')}</option>
                            <option value="status_need_help">{t('profile-status_need_help')}</option>
                            <option value="status_disappointed">{t('profile-status_disappointed')}</option>
                            <option value="status_need_to_talk">{t('profile-status_need_to_talk')}</option>
                            <option value="status_life_goes_on">{t('profile-status_life_goes_on')}</option>
                            <option value="status_everything_is_fine">{t('profile-status_everything_is_fine')}</option>
                            <option value="status_positive">{t('profile-status_positive')}</option>
                        </select>

                        {/* clarification */}
                        {clarification()}


                        {/* own_option */}
                        {
                            moodClarification === 'profile-clarification_my_option'
                                ?
                                <div className="flex w-full items-center justify-center">

                                    <textarea className={`input_style min-h-[8px] max-h-[300px] border-[1px] !rounded-3xl resize-none`}
                                        type="text" name="own_option"
                                        placeholder={t('profile-bio')} autoComplete="off" maxLength={150}
                                        value={moodOwnOption}
                                        onChange={(e) => { setMoodOwnOption(e.target.value) }}
                                        onKeyDown={handleMyOwnStatusKeyPress}
                                    />

                                    {
                                        moodOwnOption === moodStatusData.mood_status_own_option
                                            ? null
                                            : <div className="pl-2"
                                                onClick={handleMyOwnStatus}
                                            >
                                                <SendMessageSVG className='fill-[#1da1f2] hover:w-5 ease-in-out duration-500 hover:-rotate-180' />
                                            </div>
                                    }

                                </div>
                                : null
                        }

                    </form>
                    :
                    // ЧУЖОЙ ПРОФИЛЬ
                    (moodStatus === undefined
                        ? null
                        :
                        <div className="w-full flex flex-col gap-1">
                            {/* Название */}
                            <p className="popup_title">{t('profile-status_mood_title')}:</p>

                            {/* Основной статус */}
                            <p className={`input_style_status bg_bright_blue !text-white text-center                                      
                                ${moodStatus === 'status_disappointed' ? 'bg-yellow-500' : null}
                                ${moodStatus === 'status_need_help' ? 'bg-red-500' : null}
                                : null}`}>

                                {t(`profile-${moodStatus ? moodStatus : "status_empty"}`)}
                            </p>

                            {/* Уточнение */}
                            {
                                moodClarification === 'profile-clarification_my_option'
                                    ? null
                                    : moodClarification ? <p className="input_style_status bg_bright_blue !text-white text-center ">{t(moodClarification)}</p> : null
                            }

                            {/* Свой вариант */}
                            {
                                moodClarification === 'profile-clarification_my_option' && moodOwnOption
                                    ? <p className="input_style_status bg_bright_blue !text-white text-center ">{t(moodOwnOption)}</p>
                                    : null
                            }
                        </div>
                    )

                }
            </>
        )
    }

    return (
        <div className="profile_part_container !gap-10">
            {baseMoodStatus()}
            {moodEveryDayCalendar()}
        </div>
    )
}

export default ProfileMoodStatus
