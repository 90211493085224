import ExitCrossSVG from "../../../svg/exitCross_svg";
// import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const GradientWallpaperPopup = ({close, additionInfo}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    return (
            // Основа
            <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 lg:w-[800px] lg:h-[800px] sm:h-full sm:w-full">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                

                    <div className="flex flex-col gap-5 scale-[3.5]">
                    {/* Основа */}
                    
                                <div className="w-[150px] h-[150px] sm:w-[100px] sm:h-[100px] loader rotate-90 overflow-hidden">
                                    <div className="w-full h-full rounded-full benzin">
                                        <div className="w-full h-full rounded-full benzin">
                                            <div className="w-full h-full rounded-full benzin">
                                                <div className="w-full h-full rounded-full benzin">
                                                    <div className="w-full h-full rounded-full benzin">
                                                        <div className="w-full h-full rounded-full benzin">
                                                            <div className="w-full h-full rounded-full benzin">
                                                                
                                                            </div>     
                                                        </div>     
                                                    </div>     
                                                </div>     
                                            </div>     
                                        </div>     
                                    </div>                       
                                </div>
                
                    {/* Основа */}
                    </div>
                {/* <button className="button_red sticky bottom-0" type="button" onClick={handleClose}>{t('profile-closePopup')}</button> */}
            </div>
    </div>
    )
}

export default GradientWallpaperPopup;