import throttle from 'lodash/throttle';
import { updateDisplayNonOverflow } from '../../store/displayNonOverflowSlice';
import DropDownSVG from "../../../svg/dropDown_svg";
import TitleChange from '../title_change';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { moonPosition } from '../calculations/zodiac_calculations';

export const SolarSystemFromEarth = ({ moonInfo, setMoonInfo, cityLatLong, prevDeltaX, setPrevDeltaX, moonDate, setMoonDate, setTodayDate }) => {
    const dispatch = useDispatch()

    const handleThrottledSwipeMove = throttle((event) => {
        setTodayDate(false)

        let moveX
        let deltaX
        let finalDeltaX

        dispatch(updateDisplayNonOverflow(true))



        if (event.type === "touchmove") {
            moveX = event.touches[0].clientX;

            finalDeltaX = Math.floor((moveX) / 5);

            if (prevDeltaX > finalDeltaX) {
                deltaX = -100
            } else if (prevDeltaX < finalDeltaX) {
                deltaX = 100
            } else if (prevDeltaX === finalDeltaX) {
                deltaX = 0
            }

            setPrevDeltaX(finalDeltaX)
        }

        let currentDate = new Date(moonDate);
        setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX));
        setMoonInfo(moonPosition(currentDate, cityLatLong));


    }, 2000);


    const handleSwipeStart = (event) => {

        setTodayDate(false)

        const startX = event.clientX

        const handleSwipeMove = (event) => {
            let moveX
            let deltaX

            if (event.type === "mousemove") {
                moveX = event.clientX;
                deltaX = Math.floor((moveX - startX));

                let currentDate = new Date(moonDate);
                setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX))
                setMoonInfo(moonPosition(currentDate, cityLatLong))
            }
        };

        const handleSwipeEnd = () => {
            // Очищаем обработчики событий после окончания перетаскивания
            window.removeEventListener('mousemove', handleSwipeMove);
            window.removeEventListener('mouseup', handleSwipeEnd);
        };

        // Добавляем обработчики событий для перемещения мыши и окончания перетаскивания
        window.addEventListener('mousemove', handleSwipeMove);
        window.addEventListener('mouseup', handleSwipeEnd);
    };
    return (
        <>
            < TitleChange
                rusTitle="Солнечная система онлайн"
                engTitle="Solar system online"
                rusDescription="Интерактивная модель солнечной системы"
                engDescription="Interactive model of the solar system"
                ruskeyWords='Solar system, zodiac, planets, start'
                engkeyWords='Солнечная система, зодиак, звёзды'
            />

            <div className="flex items-center justify-center scale-[45%] lg:scale-[65%] sm:scale-[30%] rotate-180">
                <div className="flex items-center justify-center rotate-90 z-[10] p-4"
                    onMouseDown={(e) => handleSwipeStart(e)}
                    onTouchMove={handleThrottledSwipeMove}
                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                >
                    <div className="flex items-center justify-center flex-col cursor-pointer">
                        <DropDownSVG className='animate-pulse' />
                        <DropDownSVG className='w-4 h-4 animate-pulse' />
                        <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                    </div>
                </div>

                <div className="w-[900px] h-[900px] sm:w-[450px] sm:h-[450px] relative flex items-center justify-center cursor-pointer "

                >

                    <div className="w-full h-full flex items-center justify-center z-[1]">

                        {/* палка стрелец дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка стрелец дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка козерог дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 30 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка козерог дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 30 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка водолей дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 60 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка водолей дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 60 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рыбы дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 90 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  рыбы дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 90 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  овен дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 120 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  овен дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 120 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка телец дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 150 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  телец дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 150 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка близнецы дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 180 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка близнецы дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 180 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рак дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 210 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рак дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 210 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка лев дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 240 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  лев дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 240 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  дева дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 270 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка дева дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 270 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка весы дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 300 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка весы дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 300 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка скорпион  дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 330 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка скорпион  дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 330 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка скорпион стрелец */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка стрелец козерог */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 30}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка козерог водолей */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 60}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка водолей рыбы */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 90}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рыбы овен */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 120}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка овен телец */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 150}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка телец близнецы */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 180}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка близнецы рак */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 210}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рак лев */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 240}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка лев дева */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 270}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка дева весы */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 300}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка весы скорпион */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 330}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(530px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* Стрелец */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/sagittarius.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Стрелец знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/sagittarius.png" alt='earth' className=" pointer-events-none "></img>
                            </div>
                        </div>

                        {/* Стрелец дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className="flex title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 - 10}deg` }}
                            >
                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">9</p>
                                </div>
                            </div>
                        </div>

                        {/* Козерог */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 30}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-capricorn')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 30}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/capricorn.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Козерог знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 30 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-capricorn')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 30 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/capricorn.png" alt='earth' className="pointer-events-none "></img>
                            </div>
                        </div>

                        {/* Козерог дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 30 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 30 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">10</p>
                                </div>
                            </div>
                        </div>

                        {/* Водолей */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 60}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-aquarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 60}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/aquarius.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Водолей знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 60 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-aquarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 60 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/aquarius.png" alt='earth' className="pointer-events-none "></img>
                            </div>
                        </div>

                        {/* Водолей дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 60 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 60 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">11</p>
                                </div>
                            </div>
                        </div>

                        {/* Рыбы */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 90}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-pisces')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 90}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/pisces.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Рыбы знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 90 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-pisces')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 90 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/pisces.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Рыбы дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 90 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 90 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">12</p>
                                </div>
                            </div>
                        </div>


                        {/* Овен */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 120}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-aries')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 120}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/aries.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Овен знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 120 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-aries')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 120 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/aries.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Овен дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1]"
                            style={{ 'transform': `rotate(${90 + 15 - 120 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 120 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">1</p>
                                </div>
                            </div>
                        </div>

                        {/* Телец */}
                        <div className="absolute h-[40px] w-[40px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 150}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-taurus')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 150}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/taurus.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Телец знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 150 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-taurus')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 150 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/taurus.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Телец дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 150 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 150 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">2</p>
                                </div>
                            </div>
                        </div>

                        {/* Близнецы */}
                        <div className="absolute h-[40px] w-[40px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 180}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-gemini')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 180}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/gemini.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Близнецы знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 180 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-gemini')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 180 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/gemini.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Близнецы дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 180 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 180 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">3</p>
                                </div>
                            </div>
                        </div>

                        {/* Рак */}
                        <div className="absolute h-[40px] w-[40px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 210}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-cancer')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 210}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/cancer.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Рак знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 210 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-cancer')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 210 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/cancer.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Рак дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 210 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 210 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">4</p>
                                </div>
                            </div>
                        </div>

                        {/* Лев */}
                        <div className="absolute h-[40px] w-[40px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 240}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-leo')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 240}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/leo.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Лев знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 240 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-leo')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 240 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/leo.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Лев дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 240 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 240 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">5</p>
                                </div>
                            </div>
                        </div>

                        {/* Дева */}
                        <div className="absolute h-[40px] w-[40px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 270}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-virgo')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 270}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/virgo.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Дева знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 270 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-virgo')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 270 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/virgo.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Дева дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 270 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 270 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">6</p>
                                </div>
                            </div>
                        </div>

                        {/* Весы */}
                        <div className="absolute h-[40px] w-[40px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 300}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-libro')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 300}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/libro.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Весы знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 300 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-libro')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 300 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/libro.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Весы дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 300 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 300 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">7</p>
                                </div>
                            </div>
                        </div>

                        {/* Скорпион */}
                        <div className="absolute h-[40px] w-[40px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 330}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-scorpio')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 330}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/scorpio.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Скорпион знак */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 330 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-scorpio')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 330 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/scorpio.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Скорпион дом */}
                        <div className="absolute h-[30px] w-[30px] z-[1] "
                            style={{ 'transform': `rotate(${90 + 15 - 330 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(580px) rotate(${90 - 15 + 330 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">8</p>
                                </div>
                            </div>
                        </div>

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-earth')} className="h-[50px] w-[50px] z-[13] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Земля */}
                        {/* {console.log(moonInfo[18])} */}
                        <div className="relative  z-[1]"

                            // Кружение от солнца
                            // style={{ 'transform': `rotate(${-moonInfo[18]}deg ` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            {/* Выдвинуто от Солнца */}
                            <div
                                // style={{ 'transform': `translateX(110px)` }}
                                className="flex items-center justify-center"
                            >
                                {/* Орбита Луны */}
                                <div className="w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                                {/* Центр масс Земли*/}
                                <div
                                    className="absolute"
                                // style={{ 'transform': `rotate(${moonInfo[11]}deg)` }}
                                >

                                    <div
                                        style={{ 'transform': `translateX(0px) ` }}
                                    >
                                        <div
                                            // style={{'transform': `rotate(${moonInfo[11] + 95}deg)`}}
                                            className="w-[25px] h-[25px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate"
                                        ></div>
                                    </div>
                                </div>

                                {/* Земля */}
                                <div
                                    className="absolute w-[30px] h-[30px]"
                                    style={{ 'transform': `rotate(${-moonInfo[31]}deg)` }}
                                >
                                    {/* Кружение земли вокруг оси */}
                                    <div
                                        style={{ 'transform': `translateX(-7px) rotate(${-moonInfo[12]}deg) ` }}
                                    >
                                        <img src="/images/planets/earth/earth_from_top.png" alt='earth' className="pointer-events-none rotate-[120deg] border-slate-500 border-[1px] rounded-full"></img>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Орбита Луны */}
                        <div className="absolute w-[80px] h-[80px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-mercury')} className="z-[12] cursor-pointer rounded-full rotate-[180deg] absolute h-[100px] w-[100px] title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Луна */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[31]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(23px)` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/moon/moon.png" alt='earth' className="pointer-events-none h-[8px] w-[8px]"></img>
                            </div>
                        </div>

                        {/* Луна дубль */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[31]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/moon/moon.png" alt='earth' className="pointer-events-none h-[8px] w-[8px]"></img>
                            </div>
                        </div>

                        {/* Луна знак */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[31]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${moonInfo[31] - 180}deg` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/moon/moon_sign.png" alt='earth' className="pointer-events-none h-[40px] w-[40px]"></img>
                            </div>
                        </div>


                        {/* Орбита Меркурия */}
                        <div className="absolute w-[80px] h-[80px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-mercury')} className="z-[12] cursor-pointer rounded-full rotate-[180deg] absolute h-[100px] w-[100px] title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Меркурий */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[29]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(40px)` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/mercury/mercury.png" alt='earth' className="pointer-events-none h-[15px] w-[15px]"></img>
                            </div>
                        </div>

                        {/* Меркурий дубль */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[29]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/mercury/mercury.png" alt='earth' className="pointer-events-none h-[15px] w-[15px]"></img>
                            </div>
                        </div>

                        {/* Меркурий знак */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[29]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${moonInfo[29] - 180}deg` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/mercury/mercury_sign.png" alt='earth' className="pointer-events-none h-[40px] w-[40px]"></img>
                            </div>
                        </div>

                        {/* Орбита Венеры */}
                        <div className="absolute w-[140px] h-[140px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-venus')} className="z-[11] cursor-pointer rounded-full rotate-[180deg] absolute h-[160px] w-[160px] title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Венера */}
                        <div className="absolute h-[25px] w-[25px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[30]}deg` }}

                        // style={{ 'transform': `translateX(50px)` }}


                        >
                            <div
                                style={{ 'transform': `translateX(70px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/venus/venus.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Венера дубль */}
                        <div className="absolute h-[25px] w-[25px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[30]}deg` }}

                        // style={{ 'transform': `translateX(50px)` }}


                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/venus/venus.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Венера sign */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[30]}deg` }}

                        // style={{ 'transform': `translateX(50px)` }}


                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${moonInfo[30] - 180}deg` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/venus/venus_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>


                        {/* Орбита Солнца */}
                        <div className="absolute w-[220px] h-[220px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-sun')} className="z-[10] h-[250px] w-[250px] cursor-pointer rounded-full rotate-[180deg] absolute  title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Солнце */}
                        <div className="absolute h-[45px] w-[45px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[28]}deg` }}
                        >
                            <div
                                style={{ 'transform': `translateX(110px)` }}
                            >

                                <img src="/images/planets/sun/sun.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Солнце дубль */}
                        <div className="absolute h-[45px] w-[45px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[28]}deg` }}
                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                            >

                                <img src="/images/planets/sun/sun.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Солнце sign */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[28]}deg` }}
                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${180 + moonInfo[28]}deg` }}
                            >

                                <img src="/images/planets/sun/sun_sign.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>


                        {/* Орбита Марса */}
                        <div className="absolute w-[300px] h-[300px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-mars')} className="z-[9] h-[320px] w-[320px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Марс */}
                        <div className="absolute h-[20px] w-[20px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[32]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(150px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/mars/mars.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Марс дубль */}
                        <div className="absolute h-[20px] w-[20px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[32]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/mars/mars.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Марс sign */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[32]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${180 + moonInfo[32]}deg` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/mars/mars_sign.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита asteroids*/}
                        <div className="absolute w-[340px] h-[340px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита asteroids */}
                        <div className="absolute w-[350px] h-[350px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита asteroids */}
                        <div className="absolute w-[360px] h-[360px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита asteroids */}
                        <div className="absolute w-[370px] h-[370px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />

                        {/* Орбита Юпитера */}
                        <div className="absolute w-[440px] h-[440px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-jupiter')} className="z-[7] h-[470px] w-[470px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Юпитер */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[34]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/jupiter/jupiter2.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Юпитер дубль */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[34]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/jupiter/jupiter2.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Юпитер sign */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[34]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${180 + moonInfo[34]}deg` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/jupiter/jupiter_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Сатурна */}
                        <div className="absolute w-[540px] h-[540px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-saturn')} className="z-[6] h-[580px] w-[580px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Сатурн */}
                        <div className="absolute h-[35px] w-[35px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[35]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >

                            <div
                                style={{ 'transform': `translateX(270px)` }}
                                className="relative flex items-center justify-center"
                            >

                                {/* saturn Rings */}
                                <div className="absolute w-[40px] h-[40px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[55px] h-[55px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[65px] h-[65px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[70px] h-[70px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[75px] h-[75px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />

                                <img src="/images/planets/saturn/saturn.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Сатурн дубль */}
                        <div className="absolute h-[35px] w-[35px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[35]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >

                            <div
                                style={{ 'transform': `translateX(415px)` }}
                                className="relative flex items-center justify-center"
                            >

                                {/* saturn Rings */}
                                <div className="absolute w-[40px] h-[40px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[55px] h-[55px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[65px] h-[65px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />

                                <img src="/images/planets/saturn/saturn.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Сатурн sign */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[35]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >

                            <div
                                style={{ 'transform': `translateX(480px) rotate(${180 + moonInfo[35]}deg` }}
                                className="relative flex items-center justify-center"
                            >

                                <img src="/images/planets/saturn/saturn_sign.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Урана */}
                        <div className="absolute w-[620px] h-[620px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-uran')} className="z-[5] h-[660px] w-[660px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Уран */}
                        <div className="absolute h-[29px] w-[29px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[36]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(310px)` }}
                                className="relative flex items-center justify-center"
                            >

                                {/* uran Rings */}
                                <div className="absolute w-[40px] h-[40px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* uran Rings */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* uran Rings */}
                                <div className="absolute w-[55px] h-[55px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* uran Rings */}
                                <div className="absolute w-[65px] h-[65px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />


                                <img src="/images/planets/uran/uran.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Уран дубль */}
                        <div className="absolute h-[29px] w-[29px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[36]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                                className="relative flex items-center justify-center"
                            >

                                {/* uran Rings */}
                                <div className="absolute w-[40px] h-[40px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* uran Rings */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* uran Rings */}
                                <div className="absolute w-[55px] h-[55px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />


                                <img src="/images/planets/uran/uran.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Уран sign */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[36]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${180 + moonInfo[36]}deg` }}
                                className="relative flex items-center justify-center"
                            >


                                <img src="/images/planets/uran/uran_sign.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Нептуна */}
                        <div className="absolute w-[700px] h-[700px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-neptun')} className="z-[4] h-[730px] w-[730px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Нептун */}
                        <div className="absolute h-[28px] w-[28px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[37]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(350px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/neptun/neptun.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Нептун дубль */}
                        <div className="absolute h-[28px] w-[28px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[37]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/neptun/neptun.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Нептун sign */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[37]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${180 + moonInfo[37]}deg` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/neptun/neptun_sign.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Плутона */}
                        <div className="absolute w-[760px] h-[760px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-pluto')} className="z-[3] h-[780px] w-[780px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Плутон */}
                        <div className="absolute h-[8px] w-[8px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[38]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(380px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/pluto/pluto.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Плутон дубль */}
                        <div className="absolute h-[8px] w-[8px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[38]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(415px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/pluto/pluto.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Плутон sign */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[38]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(480px) rotate(${180 + moonInfo[38]}deg` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/pluto/pluto_sign.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-asteroids')} className="z-[2]  h-[850px] w-[850px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[800px] h-[800px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[810px] h-[810px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[820px] h-[820px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[830px] h-[830px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[840px] h-[840px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[850px] h-[850px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[860px] h-[860px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />

                        {/* Орбита до планет */}
                        <div className="absolute w-[910px] h-[910px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />
                        {/* Орбита домов */}
                        <div className="absolute w-[1010px] h-[1010px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />
                        {/* Орбита до знаками Зодиака */}
                        <div className="absolute w-[1110px] h-[1110px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />
                        {/* Орбита за знаками Зодиака */}
                        <div className="absolute w-[1210px] h-[1210px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />





                    </div>

                </div>

                <div className="flex items-center justify-center -rotate-90 z-[10] p-4 cursor-pointer"
                    onMouseDown={(e) => handleSwipeStart(e)}
                    onTouchMove={handleThrottledSwipeMove}
                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                >
                    <div className="flex items-center justify-center flex-col cursor-pointer">
                        <DropDownSVG className='animate-pulse' />
                        <DropDownSVG className='w-4 h-4 animate-pulse' />
                        <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                    </div>
                </div>

            </div >
        </>
    )
}






export const SolarSystemFromEarthMini = ({ moonInfo, setMoonInfo, cityLatLong, prevDeltaX, setPrevDeltaX, moonDate, setMoonDate, setTodayDate }) => {
    const dispatch = useDispatch()

    const handleThrottledSwipeMove = throttle((event) => {
        setTodayDate(false)

        let moveX
        let deltaX
        let finalDeltaX

        dispatch(updateDisplayNonOverflow(true))



        if (event.type === "touchmove") {
            moveX = event.touches[0].clientX;

            finalDeltaX = Math.floor((moveX) / 5);

            if (prevDeltaX > finalDeltaX) {
                deltaX = -10
            } else if (prevDeltaX < finalDeltaX) {
                deltaX = 10
            } else if (prevDeltaX === finalDeltaX) {
                deltaX = 0
            }

            setPrevDeltaX(finalDeltaX)
        }

        let currentDate = new Date(moonDate);
        setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX));
        setMoonInfo(moonPosition(currentDate, cityLatLong));


    }, 2000);


    const handleSwipeStart = (event) => {

        setTodayDate(false)

        const startX = event.clientX

        const handleSwipeMove = (event) => {
            let moveX
            let deltaX

            if (event.type === "mousemove") {
                moveX = event.clientX;
                deltaX = Math.floor((moveX - startX));

                let currentDate = new Date(moonDate);
                setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX))
                setMoonInfo(moonPosition(currentDate, cityLatLong))
            }
        };

        const handleSwipeEnd = () => {
            // Очищаем обработчики событий после окончания перетаскивания
            window.removeEventListener('mousemove', handleSwipeMove);
            window.removeEventListener('mouseup', handleSwipeEnd);
        };

        // Добавляем обработчики событий для перемещения мыши и окончания перетаскивания
        window.addEventListener('mousemove', handleSwipeMove);
        window.addEventListener('mouseup', handleSwipeEnd);
    };

    return (
        <>
            <TitleChange
                rusTitle="Зодиак онлайн"
                engTitle="Zodiac online"
                rusDescription="Интерактивная модель солнечной системы"
                engDescription="Interactive model of the solar system"
                ruskeyWords='Solar system, zodiac, planets, start'
                engkeyWords='Солнечная система, зодиак, звёзды'
            />

            <div className="flex items-center justify-center scale-[45%] lg:scale-[100%] sm:scale-[47%] rotate-180">
                <div className="flex items-center justify-center rotate-90 z-[10] p-4"
                    onMouseDown={(e) => handleSwipeStart(e)}
                    onTouchMove={handleThrottledSwipeMove}
                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                >
                    <div className="flex items-center justify-center flex-col cursor-pointer">
                        <DropDownSVG className='animate-pulse' />
                        <DropDownSVG className='w-4 h-4 animate-pulse' />
                        <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                    </div>
                </div>

                <div className="w-[900px] h-[900px] sm:w-[450px] sm:h-[450px] relative flex items-center justify-center cursor-pointer "

                >

                    <div className="w-full h-full flex items-center justify-center z-[1]">

                        {/* палка стрелец дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка стрелец дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка козерог дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 30 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка козерог дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 30 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка водолей дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 60 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка водолей дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 60 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рыбы дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 90 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  рыбы дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 90 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  овен дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 120 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  овен дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 120 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка телец дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 150 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  телец дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 150 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка близнецы дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 180 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка близнецы дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 180 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рак дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 210 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рак дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 210 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка лев дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 240 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  лев дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 240 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка  дева дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 270 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка дева дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 270 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка весы дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 300 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка весы дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 300 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка скорпион  дом 1 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 330 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка скорпион  дом 2 */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 330 + 20}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[50px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка скорпион стрелец */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка стрелец козерог */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 30}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка козерог водолей */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 60}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка водолей рыбы */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 90}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рыбы овен */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 120}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка овен телец */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 150}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка телец близнецы */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 180}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка близнецы рак */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 210}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка рак лев */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 240}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка лев дева */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 270}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка дева весы */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 300}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* палка весы скорпион */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${90 + 330}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(310px) rotate(${90}deg` }}
                            >

                                <div className={`w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted`}></div>
                            </div>
                        </div>

                        {/* Стрелец */}
                        <div className="absolute h-[40px] w-[40px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/sagittarius.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Стрелец знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-sagittarius')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/sagittarius.png" alt='earth' className=" pointer-events-none "></img>
                            </div>
                        </div>

                        {/* Стрелец дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_sagittarius')} className="flex title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 - 10}deg` }}
                            >
                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">9</p>
                                </div>
                            </div>
                        </div>

                        {/* Козерог */}
                        <div className="absolute h-[40px] w-[40px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 30}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-capricorn')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 30}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/capricorn.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Козерог знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 30 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-capricorn')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 30 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/capricorn.png" alt='earth' className="pointer-events-none "></img>
                            </div>
                        </div>

                        {/* Козерог дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 30 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_capricorn')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 + 5}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">10</p>
                                </div>
                            </div>
                        </div>

                        {/* Водолей */}
                        <div className="absolute h-[40px] w-[40px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 60}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-aquarius')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 60}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/aquarius.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Водолей знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 60 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-aquarius')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 60 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/aquarius.png" alt='earth' className="pointer-events-none "></img>
                            </div>
                        </div>

                        {/* Водолей дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 60 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_aquarius')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 60 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">11</p>
                                </div>
                            </div>
                        </div>

                        {/* Рыбы */}
                        <div className="absolute h-[40px] w-[40px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 90}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-pisces')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 90}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/pisces.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Рыбы знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 90 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-pisces')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 90 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/pisces.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Рыбы дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 90 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_pisces')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 90 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">12</p>
                                </div>
                            </div>
                        </div>


                        {/* Овен */}
                        <div className="absolute h-[40px] w-[40px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 120}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-aries')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 120}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/aries.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Овен знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 120 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-aries')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 120 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/aries.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Овен дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2]"
                            style={{ 'transform': `rotate(${90 + 15 - 120 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_aries')} className=" title-style title_style_right"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 120 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">1</p>
                                </div>
                            </div>
                        </div>

                        {/* Телец */}
                        <div className="absolute h-[40px] w-[40px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 150}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-taurus')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 150}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/taurus.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Телец знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 150 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-taurus')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 150 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/taurus.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Телец дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 150 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_taurus')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 150 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">2</p>
                                </div>
                            </div>
                        </div>

                        {/* Близнецы */}
                        <div className="absolute h-[40px] w-[40px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 180}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-gemini')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 180}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/gemini.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Близнецы знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 180 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-gemini')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 180 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/gemini.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Близнецы дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 180 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_gemini')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 180 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">3</p>
                                </div>
                            </div>
                        </div>

                        {/* Рак */}
                        <div className="absolute h-[40px] w-[40px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 210}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-cancer')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 210}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/cancer.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Рак знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 210 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-cancer')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 210 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/cancer.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Рак дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 210 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_cancer')} className=" title-style title_style_top"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 210 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">4</p>
                                </div>
                            </div>
                        </div>

                        {/* Лев */}
                        <div className="absolute h-[40px] w-[40px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 240}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-leo')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 240}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/leo.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Лев знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 240 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-leo')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 240 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/leo.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Лев дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 240 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_leo')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 240 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">5</p>
                                </div>
                            </div>
                        </div>

                        {/* Дева */}
                        <div className="absolute h-[40px] w-[40px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 270}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-virgo')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 270}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/virgo.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Дева знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 270 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-virgo')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 270 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/virgo.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Дева дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 270 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_virgo')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 270 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">6</p>
                                </div>
                            </div>
                        </div>

                        {/* Весы */}
                        <div className="absolute h-[40px] w-[40px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 300}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-libro')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 300}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/libro.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Весы знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 300 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-libro')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 300 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/libro.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Весы дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 300 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_libro')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 300 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">7</p>
                                </div>
                            </div>
                        </div>

                        {/* Скорпион */}
                        <div className="absolute h-[40px] w-[40px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 330}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-scorpio')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 330}deg` }}
                            >

                                <img src="/images/zodiac/zodiac/scorpio.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Скорпион знак */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 330 - 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-scorpio')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 330 + 10}deg` }}
                            >

                                <img src="/images/zodiac/zodiac3/scorpio.png" alt='earth' className="pointer-events-none"></img>
                            </div>
                        </div>

                        {/* Скорпион дом */}
                        <div className="absolute h-[30px] w-[30px] z-[2] "
                            style={{ 'transform': `rotate(${90 + 15 - 330 + 10}deg` }}
                        >
                            <div data-tooltip={t('profile-zodiac-home_scorpio')} className=" title-style title_style_left"
                                style={{ 'transform': `translateX(360px) rotate(${90 - 15 + 330 - 10}deg` }}
                            >

                                <div className="flex items-center justify-center w-full h-full">
                                    <p className="text-[25px] text-slate-500">8</p>
                                </div>
                            </div>
                        </div>

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-earth')} className="h-[50px] w-[50px] z-[13] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />





                        {/* Луна дубль */}
                        <div data-tooltip={t('zodiac-moon')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[31]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/moon/moon.png" alt='earth' className="pointer-events-none h-[8px] w-[8px]"></img>
                            </div>
                        </div>


                        {/* Луна знак */}
                        <div data-tooltip={t('zodiac-moon')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[31]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${moonInfo[31] - 180}deg` }}
                                className="rounded-full overflow-hidden"
                            >
                                {/* <div className="absolute w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted"></div> */}

                                <img src="/images/planets/planet_signs/moon_sign.png" alt='earth' className="pointer-events-none h-[40px] w-[40px]"></img>
                            </div>
                        </div>



                        {/* Луна Лилит знак */}
                        <div data-tooltip={t('zodiac-moon_lilit')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[39]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(250px)` }}
                                className="absolute top-[50%] w-[20px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${moonInfo[39] - 180}deg` }}
                                className="rounded-full overflow-hidden"
                            >
                                {/* <div className="absolute w-[1px] h-[150px] border-l-[1px] rotate-[0deg] border-dotted"></div> */}

                                <img src="/images/planets/planet_signs/lilith_sign.png" alt='earth' className="pointer-events-none h-[40px] w-[40px]"></img>
                            </div>
                        </div>


                        {/* Меркурий дубль */}
                        <div data-tooltip={t('zodiac-mercury')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[29]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/mercury/mercury.png" alt='earth' className="pointer-events-none h-[15px] w-[15px]"></img>
                            </div>
                        </div>

                        {/* Меркурий знак */}
                        <div data-tooltip={t('zodiac-mercury')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[29]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${moonInfo[29] - 180}deg` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/planet_signs/mercury_sign.png" alt='earth' className="pointer-events-none h-[40px] w-[40px]"></img>
                            </div>
                        </div>



                        {/* Венера дубль */}
                        <div data-tooltip={t('zodiac-venus')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[30]}deg` }}

                        // style={{ 'transform': `translateX(50px)` }}


                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/venus/venus.png" alt='earth' className="h-[25px] w-[25px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Венера sign */}
                        <div data-tooltip={t('zodiac-venus')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[30]}deg` }}

                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${moonInfo[30] - 180}deg` }}
                            >

                                <img src="/images/planets/planet_signs/venus_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>



                        {/* Солнце дубль */}
                        <div data-tooltip={t('zodiac-sun')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[28]}deg` }}
                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                            >

                                <img src="/images/planets/sun/sun.png" alt='earth' className="h-[45px] w-[45px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Солнце sign */}
                        <div data-tooltip={t('zodiac-sun')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[28]}deg` }}
                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${180 + moonInfo[28]}deg` }}
                            >

                                <img src="/images/planets/planet_signs/sun_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Марс дубль */}
                        <div data-tooltip={t('zodiac-mars')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[32]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/mars/mars.png" alt='earth' className="h-[20px] w-[20px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Марс sign */}
                        <div data-tooltip={t('zodiac-mars')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[32]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${180 + moonInfo[32]}deg` }}
                            >

                                <img src="/images/planets/planet_signs/mars_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>



                        {/* Юпитер дубль */}
                        <div data-tooltip={t('zodiac-jupiter')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[34]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/jupiter/jupiter2.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Юпитер sign */}
                        <div data-tooltip={t('zodiac-jupiter')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[34]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${180 + moonInfo[34]}deg` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/planet_signs/jupiter_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Сатурн дубль */}
                        <div data-tooltip={t('zodiac-saturn')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[35]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >

                            <div
                                style={{ 'transform': `translateX(200px)` }}
                                className="relative flex items-center justify-center"
                            >

                                {/* saturn Rings */}
                                <div className="absolute w-[40px] h-[40px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[55px] h-[55px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* saturn Rings */}
                                <div className="absolute w-[65px] h-[65px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />

                                <img src="/images/planets/saturn/saturn.png" alt='earth' className="h-[35px] w-[35px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Сатурн sign */}
                        <div data-tooltip={t('zodiac-saturn')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[35]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >

                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${180 + moonInfo[35]}deg` }}
                                className="relative flex items-center justify-center"
                            >

                                <img src="/images/planets/planet_signs/saturn_sign.png" alt='earth' className=" h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>



                        {/* Уран дубль */}
                        <div data-tooltip={t('zodiac-uran')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[36]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                                className="relative flex items-center justify-center"
                            >

                                {/* uran Rings */}
                                <div className="absolute w-[40px] h-[40px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* uran Rings */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />
                                {/* uran Rings */}
                                <div className="absolute w-[55px] h-[55px] outline-dashed outline-slate-700 rounded-full outline-[1px]" />


                                <img src="/images/planets/uran/uran.png" alt='earth' className=" h-[29px] w-[29px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Уран sign */}
                        <div data-tooltip={t('zodiac-uran')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[36]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >

                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${180 + moonInfo[36]}deg` }}
                                className="relative flex items-center justify-center"
                            >


                                <img src="/images/planets/planet_signs/uran_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>


                        {/* Нептун дубль */}
                        <div data-tooltip={t('zodiac-neptun')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[37]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/neptun/neptun.png" alt='earth' className="h-[28px] w-[28px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Нептун sign */}
                        <div data-tooltip={t('zodiac-neptun')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[37]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${180 + moonInfo[37]}deg` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/planet_signs/neptun_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>


                        {/* Плутон дубль */}
                        <div data-tooltip={t('zodiac-pluto')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[38]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(200px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/pluto/pluto.png" alt='earth' className="h-[8px] w-[8px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Плутон sign */}
                        <div data-tooltip={t('zodiac-pluto')} className="title-style title_style_bottom absolute z-[2]"
                            style={{ 'transform': `rotate(${-moonInfo[38]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            {/* Указатель */}
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                                className="absolute top-[50%] w-[50px] h-[1px] border-t-[1px] border-dashed border-blue-500"
                            />

                            <div
                                style={{ 'transform': `translateX(260px) rotate(${180 + moonInfo[38]}deg` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/planet_signs/pluto_sign.png" alt='earth' className="h-[40px] w-[40px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* tolltip */}
                        <div className="z-[1] h-[850px] w-[850px] cursor-pointer rounded-full rotate-[180deg] absolute"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />


                        {/* Орбита внутренняя */}
                        <div className="absolute w-[330px] h-[330px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />
                        {/* Орбита до планет */}
                        <div className="absolute w-[470px] h-[470px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />
                        {/* Орбита домов */}
                        <div className="absolute w-[570px] h-[570px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />
                        {/* Орбита до знаками Зодиака */}
                        <div className="absolute w-[670px] h-[670px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />
                        {/* Орбита за знаками Зодиака */}
                        <div className="absolute w-[770px] h-[770px] outline-dashed outline-slate-300 rounded-full outline-[1px]" />





                    </div>

                </div>

                <div className="flex items-center justify-center -rotate-90 z-[10] p-4 cursor-pointer"
                    onMouseDown={(e) => handleSwipeStart(e)}
                    onTouchMove={handleThrottledSwipeMove}
                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                >
                    <div className="flex items-center justify-center flex-col cursor-pointer">
                        <DropDownSVG className='animate-pulse' />
                        <DropDownSVG className='w-4 h-4 animate-pulse' />
                        <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                    </div>
                </div>

            </div>

            <div className='flex items-center justify-center flex-col gap-2 text-[10px] p-5 pb-10'>

                <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.mercury[0] ? 'text-red-500' : null}`}>
                    <p className='text-[16px]'>{t('zodiac-mercury')}</p>
                    {moonInfo[40]?.mercury[0] ? <p>{t('retrograd_now_to')}{moonInfo[40]?.mercury[3]}</p> : null}
                    <p>Следующий ретроград c {moonInfo[40]?.mercury[1]} - {moonInfo[40]?.mercury[2]}</p>
                </div>
                
                <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.venus[0] ? 'text-red-500' : null}`}>
                    <p className='text-[16px]'>{t('zodiac-venus')}</p>
                    {moonInfo[40]?.venus[0] ? <p>{t('retrograd_now_to')}{moonInfo[40]?.venus[3]}</p> : null}
                    <p>Следующий ретроград c {moonInfo[40]?.venus[1]} - {moonInfo[40]?.venus[2]}</p>
                </div>

                <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.mars[0] ? 'text-red-500' : null}`}>
                    <p className='text-[16px]'>{t('zodiac-mars')}</p>
                    {moonInfo[40]?.mars[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.mars[3]} </p> : null}
                    <p>Следующий ретроград c {moonInfo[40]?.mars[1]} - {moonInfo[40]?.mars[2]}</p>
                </div>

                <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.jupiter[0] ? 'text-red-500' : null}`}>
                    <p className='text-[16px]'>{t('zodiac-jupiter')}</p>
                    {moonInfo[40]?.jupiter[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.jupiter[3]} </p> : null}
                    <p>Следующий ретроград c {moonInfo[40]?.jupiter[1]} - {moonInfo[40]?.jupiter[2]}</p>
                </div>

                <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.saturn[0] ? 'text-red-500' : null}`}>
                    <p className='text-[16px]'>{t('zodiac-saturn')}</p>
                    {moonInfo[40]?.saturn[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.saturn[3]} </p> : null}
                    <p>Следующий ретроград c {moonInfo[40]?.saturn[1]} - {moonInfo[40]?.saturn[2]}</p>
                </div>

                <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.uran[0] ? 'text-red-500' : null}`}>
                    <p className='text-[16px]'>{t('zodiac-uran')}</p>
                    {moonInfo[40]?.uran[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.uran[3]} </p> : null}
                    <p>Следующий ретроград c {moonInfo[40]?.uran[1]} - {moonInfo[40]?.uran[2]}</p>
                </div>

                <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.neptun[0] ? 'text-red-500' : null}`}>
                    <p className='text-[16px]'>{t('zodiac-neptun')}</p>
                    {moonInfo[40]?.neptun[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.neptun[3]} </p> : null}
                    <p>Следующий ретроград c {moonInfo[40]?.neptun[1]} - {moonInfo[40]?.neptun[2]}</p>
                </div>

                <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.pluto[0] ? 'text-red-500' : null}`}>
                    <p className='text-[16px]'>{t('zodiac-pluto')}</p>
                    {moonInfo[40]?.pluto[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.pluto[3]} </p> : null}
                    <p>Следующий ретроград c {moonInfo[40]?.pluto[1]} - {moonInfo[40]?.pluto[2]}</p>
                    
                </div>

            </div>



        </>
    )
}