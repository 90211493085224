import { t } from "i18next";
import { ZodiacCalculations } from "../little_components/calculations/zodiac_calculations";
import { useEffect, useState } from "react";
import PopupContainer from "../little_components/popups_root";
import { formattedDateWithoutClockReversed, formattedTimeMin } from "../little_components/services";
import Checkbox from "../little_components/checkbox";
import { useSelector } from "react-redux";
import { moonPosition } from "../little_components/calculations/zodiac_calculations";
import { MoonVisual } from "../little_components/zodiac_components/moon";
import { MoonRelativeEarth, SunWithEarth } from "../little_components/zodiac_components/moon_sun_with_earth";
import { SolarSystem } from "../little_components/zodiac_components/solar_system";
import { SolarSystemFromEarth, SolarSystemFromEarthMini } from "../little_components/zodiac_components/solar_system_from_earth";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { baseBackendUrl } from "../little_components/websocket_comp";
import axios from "axios";
import { updateProfileUserInfo } from "../store/profileUserInfoSlice";
import { citiesLatLong } from "../little_components/calculations/zodiac_calculations";

const Zodiac = () => {

    const dispatch = useDispatch()
    const { username } = useParams();

    const getProfileInfo = async () => {
        const data = { authorizedUser: authorizedUser.username };
        const url = `${baseBackendUrl}/${username}`;
        const response = await axios.post(url, data);

        // Здесь запросили инфо о профиле и записали его в стор
        dispatch(updateProfileUserInfo(response.data))

    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if (username) { getProfileInfo() } }, [username])

    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    const user = profileUser?.user || authorizedUser
    const birthDate = user?.birth_date

    const [zodiacPopupOpen, setZodiacPopupOpen] = useState(false);
    // const [touchStartCoordinate, setTouchStartCoordinate] = useState(0)
    const [prevDeltaX, setPrevDeltaX] = useState(0)
    const [todayDate, setTodayDate] = useState(true)

    const [moonInfo, setMoonInfo] = useState([])

    const [moonDate, setMoonDate] = useState(new Date())

    const [solarSystemFull, setSolarSystemFull] = useState(false)

    const [selectedComponent, setSelectedComponent] = useState(localStorage.getItem('selectedZodiacComponent') || 'zodiacPlanets');

    // eslint-disable-next-line 
    const [cityLatLong, setCityLatLong] = useState(citiesLatLong['Москва'])

    const handleComponentChange = (component) => {
        if (selectedComponent === component) {
            setSelectedComponent('')
            localStorage.setItem('selectedZodiacComponent', 'zodiacPlanets')
        } else {
            setSelectedComponent(component);
            localStorage.setItem('selectedZodiacComponent', component)
        }
        // selectedComponent === component ?  setSelectedComponent('') :  setSelectedComponent(component);
    };

    const zodiac = ZodiacCalculations()[0];

    useEffect(() => {
        setMoonInfo(moonPosition(moonDate, cityLatLong));

        if (todayDate) {
            const intervalId = setInterval(() => {
                try {
                    setMoonDate(prevMoonDate => {
                        // Используйте предыдущее значение состояния
                        const newDate = new Date(prevMoonDate);
                        newDate.setSeconds(newDate.getSeconds() + 2);
                        return newDate;
                    });
                    setMoonInfo(moonPosition(moonDate, cityLatLong));
                } catch (error) {
                    console.log('Error with moonphase:', error);
                }
            }, 60000);

            // Очистка интервала при размонтировании компонента
            return () => clearInterval(intervalId);
        }

        // eslint-disable-next-line
    }, [moonDate]);




    const handleInputTimeChange = (e, date) => {
        const newDate = new Date(date)

        const [hours, minutes] = e.target.value.split(':').map(Number);
        newDate.setHours(hours)
        newDate.setMinutes(minutes)

        setMoonDate(newDate)
        setMoonInfo(moonPosition(newDate, cityLatLong))
    }

    const handleInputDateChange = (e) => {
        try {
            const newDate = new Date(e.target.value)
            setMoonDate(newDate)
            setMoonInfo(moonPosition(newDate, cityLatLong))
        } catch (inputError) {
            console.log(inputError)
        }

    }

    const handleMoonDateToday = ({ date = new Date() }) => {
        setTodayDate(true)
        const newDate = date
        setMoonDate(newDate)
        setMoonInfo(moonPosition(newDate, cityLatLong))
    }

    const ZodiacMenuButtons = () => {
        return (
            <div className="flex flex-col gap-2">
                <div className="br_border_line_gray" />
                <div className="flex justify-center gap-1 pl-2 pr-2">
                    {/* <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] !leading-[14px] ${selectedComponent === 'description' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('description')}>{t('zodiac-descriptionButton')}</button> */}
                    <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] !leading-[14px] ${selectedComponent === 'moon' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('moon')}>{t('zodiac-moonButton')}</button>
                    <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] !leading-[14px] ${selectedComponent === 'moonLook' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('moonLook')}>{t('zodiac-moonLookButton')}</button>
                    <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[8px] !leading-[14px] ${selectedComponent === 'earthLook' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('earthLook')}>{t('zodiac-earthLookButton')}</button>
                    <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[10px] !leading-[14px] ${selectedComponent === 'planets' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('planets')}>{t('zodiac-planetsButton')}</button>
                    <button className={`button_blue flex-1 !rounded-2xl sm:!h-[38px] sm:!p-1 sm:!text-[6px] !leading-[14px] ${selectedComponent === 'zodiacPlanets' ? 'opacity-70 !border-0' : null}`} onClick={() => handleComponentChange('zodiacPlanets')}>{t('zodiac-zodiacPlanetsButton')}</button>
                </div>
                <div className="br_border_line_gray" />
            </div>
        )
    }

    return (
        <div className="main_white_container w-[800px] sm:w-[100dvw] !p-0">
            <ZodiacMenuButtons />

            {/* {selectedComponent === 'description'
                ?
                <>

                    <p className="popup_title">{t(`profile-zodiac-${zodiac}`)}</p>

                    <div className="flex w-full flex-col items-center justify-center gap-2">

                        <div className="flex items-center justify-center gap-10">
                            <img className="w-10" src={`images/zodiac/zodiac/${zodiac}.png`} alt="zodiac" />
                            <img className="w-10" src={`images/planets/zodiac/${zodiac}.png`} alt="planets" />
                        </div>

                        <div className="w-fit flex justify-center items-center">
                            <button className="button_blue_rounded" onClick={() => { setZodiacPopupOpen(!zodiacPopupOpen) }}>
                                <p>{t('profile-zodiac-description-title')}</p>
                            </button>
                        </div>

                    </div>
                </>
                : null} */}

            {/* <div className="br_border_line_gray" /> */}


            <div className="w-full flex flex-col gap-10 bg-[#20272b] text-slate-300 touch_screen_non_selectable">
                {/* Звёздное небо */}
                <div className="absolute w-full h-full mix-blend-screen overflow-hidden">
                    <div className="absolute stars w-full h-full"></div>
                    <div className="absolute twinkling w-full h-full"></div>
                    {/* <div className="absolute bg-black w-full h-full mix-blend-overlay"></div> */}
                </div>

                {/* Первая */}
                {selectedComponent === 'moon'
                    ?
                    <>
                        <p className="popup_title">{t(`profile-moon-day`)}</p>

                        <MoonVisual moonInfoInner={moonInfo} setMoonInfoInner={setMoonInfo} cityLatLongInner={cityLatLong} prevDeltaX={prevDeltaX}
                            setPrevDeltaX={setPrevDeltaX} moonDate={moonDate} setMoonDate={setMoonDate} setTodayDate={setTodayDate}
                        />

                        < div className="br_border_line_gray" />
                    </>
                    : null}

                {/* Вторая */}
                {selectedComponent === 'moonLook'
                    ?
                    <>
                        <div className="flex flex-col w-full h-full" >
                            <p className="popup_title">{t(`zodiac-earth-spining`)}</p>
                            <div className="sticky top-0 flex gap-2 w-full items-center justify-center">
                                <input className='w-[200px]' type="date" value={formattedDateWithoutClockReversed(moonDate)} onChange={handleInputDateChange} />
                                <button className="button_blue_rounded !w-fit" onClick={handleMoonDateToday}>{t('profile-moon-today')}</button>
                            </div>
                            <div className="z-[2]">
                                <MoonRelativeEarth moonInfo={moonInfo} setMoonInfo={setMoonInfo} cityLatLong={cityLatLong}
                                    prevDeltaX={prevDeltaX} setPrevDeltaX={setPrevDeltaX} moonDate={moonDate} setMoonDate={setMoonDate} setTodayDate={setTodayDate}
                                />
                            </div>

                            {/* Солнце */}
                            <div className="h-[120px] overflow-hidden z-[1]">
                                <img className="pointer-events-none" src="/images/planets/sun/sun.png" alt='sun'></img>
                            </div>

                        </div>
                    </>
                    : null}

                {/* <div className="br_border_line_gray -mt-10" /> */}

                {/* Третья */}
                {selectedComponent === 'earthLook'
                    ?
                    <>
                        <p className="popup_title">{t(`zodiac-moonSun-spining`)}</p>
                        <div className="sticky top-0 flex gap-2 w-full items-center justify-center">
                            <input className='w-[200px]' type="date" value={formattedDateWithoutClockReversed(moonDate)} onChange={handleInputDateChange} />
                            <button className="button_blue_rounded !w-fit" onClick={handleMoonDateToday}>{t('profile-moon-today')}</button>
                        </div>
                        <SunWithEarth moonInfo={moonInfo} setMoonInfo={setMoonInfo} cityLatLong={cityLatLong}
                            prevDeltaX={prevDeltaX} setPrevDeltaX={setPrevDeltaX} moonDate={moonDate} setMoonDate={setMoonDate} setTodayDate={setTodayDate}
                        />
                        <div className="br_border_line_gray -mt-10" />
                    </>
                    : null}

                {/* Четвёртая */}
                {selectedComponent === 'planets'
                    ?
                    <>
                        <div>
                            <p className="popup_title">{t(`zodiac-planetary-system`)}</p>

                            <div className="sticky top-0 flex gap-2 w-full items-center justify-center">
                                <input className='w-[200px]' type="date" value={formattedDateWithoutClockReversed(moonDate)} onChange={handleInputDateChange} />
                                <button className="button_blue_rounded !w-fit" onClick={handleMoonDateToday}>{t('profile-moon-today')}</button>
                            </div>

                            {/* Солнечная система */}
                            <SolarSystem moonInfo={moonInfo} setMoonInfo={setMoonInfo} cityLatLong={cityLatLong}
                                prevDeltaX={prevDeltaX} setPrevDeltaX={setPrevDeltaX} moonDate={moonDate} setMoonDate={setMoonDate} setTodayDate={setTodayDate}
                            />
                        </div>

                        <div className="br_border_line_gray -mt-10" />
                    </>
                    : null}

                {/* Пятая */}
                {selectedComponent === 'zodiacPlanets'

                    ?
                    <div>
                        <p className="popup_title">{t(`zodiac-planetary-earthCenter`)}</p>

                        <div className="h-10 w-full flex items-center justify-center"><Checkbox checkedInner={solarSystemFull} handleCheckboxInner={() => setSolarSystemFull(!solarSystemFull)} text={t('zodiac-full_version')} textLeft={true} /></div>

                        <div className="sticky top-0 flex sm:flex-col gap-2 w-full items-center justify-center">

                            <div className='flex'>
                                <input className='w-[150px]' type="date" value={formattedDateWithoutClockReversed(moonDate)} onChange={handleInputDateChange} />
                                <input className='w-[110px]' type="time" value={formattedTimeMin(moonDate)} onChange={(e) => handleInputTimeChange(e, moonDate)} />
                            </div>

                            <div className="flex">
                                <button className="button_blue_rounded !w-fit" onClick={handleMoonDateToday}>{t('profile-moon-today')}</button>
                                <button className="button_blue_rounded !w-fit" onClick={() => handleMoonDateToday({ date: birthDate })}>{t('profile-zodiac-button_in_birth_date')}</button>
                            </div>

                        </div>


                        {/* Солнечная система */}
                        {solarSystemFull
                            ? <SolarSystemFromEarth moonInfo={moonInfo} setMoonInfo={setMoonInfo} cityLatLong={cityLatLong}
                                prevDeltaX={prevDeltaX} setPrevDeltaX={setPrevDeltaX} moonDate={moonDate} setMoonDate={setMoonDate} setTodayDate={setTodayDate}
                            />
                            : <SolarSystemFromEarthMini moonInfo={moonInfo} setMoonInfo={setMoonInfo} cityLatLong={cityLatLong}
                                prevDeltaX={prevDeltaX} setPrevDeltaX={setPrevDeltaX} moonDate={moonDate} setMoonDate={setMoonDate} setTodayDate={setTodayDate}
                            />
                        }

                    </div>
                    : null}

            </div>


            {zodiacPopupOpen ? <PopupContainer choosePopup={'zodiac_description'} setPopupOpen={setZodiacPopupOpen} additionInfo={zodiac} /> : null}

        </div >
    )
}

export default Zodiac;

