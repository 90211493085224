import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { useEffect, useState } from "react";
import PopupContainer from "../popups_root";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const ProfileRelationship = () => {
    const dispatch = useDispatch();

    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    const user = profileUser.user
    const username = user.username

    const havePair = profileUser.have_pair

    const [relationshipPairPopupOpen, setRelationshipPairPopupOpen] = useState(false);

    useEffect(() => {
        if (relationshipPairPopupOpen) {
                dispatch(updateDisplayNonOverflow(true))
            } else {
                dispatch(updateDisplayNonOverflow(false))
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relationshipPairPopupOpen])

    if (authorizedUser.username === username) {
        return (
            // {/* В ОТНОШЕНИЯХ МАТРИЦА СОВМЕСТИМОСТИ (если нет доавленных отношений, то на чужой странице не отображается весь блок) */}    
                <div className="profile_part_container">
                    <div className="flex justify-center items-center gap-2">
                        <p>{t('profile-inRelationship')}:</p>
    
                        {
                            !havePair?.family_name
                                ?  
                                    (
                                        <button
                                            className="z-[1] button_blue_rounded !overflow-visible !w-6 !h-6 !p-0"
                                            onClick={() => setRelationshipPairPopupOpen(!relationshipPairPopupOpen)}
                                            >
                                                +
                                        </button>
                                    )
                                : 
                                    (
                                        <button
                                            className="z-[1] button_blue_rounded !overflow-visible !w-fit !pl-3 !pr-3 !h-7 !p-0"
                                            onClick={() => setRelationshipPairPopupOpen(!relationshipPairPopupOpen)}
                                            >
                                                {havePair?.family_name}
                                        </button>
                                    )
                        }
    
                        {relationshipPairPopupOpen ? <PopupContainer choosePopup={'relationship_pair'} setPopupOpen={setRelationshipPairPopupOpen} additionInfo={null} /> : null}
                    </div>
    
                    {/* Отделяющая линия от верхнего блока */}
                    {/* <div className="border border-t-1 border-b-0 border-l-0 border-r-0 w-full" /> */}
                </div>
        )
    } else {
        return (
            <div className="profile_part_container">
                    
    
                        {
                            !havePair?.family_name
                                ?  
                                    null
                                : 
                                    (
                                        <div className="flex justify-center items-center gap-2">
                                            <p>{t('profile-inRelationship')}:</p>
                                            
                                            <button
                                                className="z-[1] button_blue_rounded !overflow-visible !w-fit !pl-3 !pr-3 !h-7 !p-0"
                                                onClick={() => setRelationshipPairPopupOpen(!relationshipPairPopupOpen)}
                                                >
                                                    {havePair?.family_name}
                                            </button>
                                        </div>
                                    )
                        }
    
                        {relationshipPairPopupOpen ? <PopupContainer choosePopup={'relationship_pair'} setPopupOpen={setRelationshipPairPopupOpen} additionInfo={null} /> : null}
    
                    {/* Отделяющая линия от верхнего блока */}
                    {/* <div className="border border-t-1 border-b-0 border-l-0 border-r-0 w-full" /> */}
                </div>
        )
    }
}

export default ProfileRelationship;