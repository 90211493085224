import { useState, useEffect } from 'react';
import { formattedDate } from "../services";
import { baseBackendUrl } from "../websocket_comp";
import Likes from "./likes";
import SavePost from "./savePostsComp";
import { Comments, ShowComments } from "./comments";
import DropDownMenu from './dropDownMenu';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useRef } from 'react';
import { t } from "i18next";


const postContentImageFunc = (postContentImageInner) => {
    return postContentImageInner ? (
        <img src={`${baseBackendUrl + postContentImageInner}`} alt="postImage" className="w-full" />
        //   <div id="post-image" className="w-full h-[300px] bg-center mt-2.5 mb-2.5 bg-contain bg-no-repeat" style={{ backgroundImage: `url(${baseBackendUrl + postContentImageInner})` }}></div>
    ) : null;
};


const PostUser = ({ firstName, lastName, dateCreated, anonymous, creater }) => {

    return (
        <div className="flex w-full sm:flex-col sm:gap-0 pl-2 pr-2 justify-between text-[16px]">
            {
                anonymous
                    ?
                    <div className='flex gap-2'>
                        <p className='bg_bright_blue text_inverted_color rounded-full pl-2 pr-2 h-fit'>{t('profile-anonymousPost')}</p>
                        {window.location.pathname === '/'
                            ? null
                            : <p>{t('profile-anonymousPostAddon')}</p>
                        }
                    </div>
                    :
                    (
                        <Link to={`/${creater.username}`} className=''>
                            <strong className="text-[14px] -mb-1 hover:text-blue-500 ease-in-out duration-300">{firstName} {lastName}</strong>
                            <p className="text-[10px] text-neutral-500">@{creater.username}</p>
                        </Link>
                    )
            }

            <p className="text-[10px] text-neutral-500">{dateCreated}</p>
        </div>
    )
}

const Post = ({ authorizedUser, post }) => {
    const [fullTextOpened, setFullTextOpened] = useState(false);
    const [fullTextButtonsOpen, setFullTextButtonsOpen] = useState(false);

    const postContentTextRef = useRef(null);


    const { id: postId, creater, content_text, content_image, likers, comment_count, savers, date_created, anonymous } = post;
    const { first_name, last_name, profile_pic } = creater;
    const postDateCreated = formattedDate(date_created); // отредактировать дату    

    const postContentTextCountLines = content_text && <p ref={postContentTextRef} className={`post-content whitespace-pre-line leading-normal text-[16px] ${fullTextOpened ? null : ''}`}>{content_text}</p>;
    const postContentText = content_text && <p ref={postContentTextRef} className={`post-content whitespace-pre-line leading-normal text-[16px] ${fullTextButtonsOpen ? fullTextOpened ? null : 'line-clamp-[15]' : null}`}>{content_text}</p>;

    const postContentImage = postContentImageFunc(content_image);
    const postLikersCount = likers.length;

    const [commentsOpened, setCommentsOpened] = useState(false);

    const [commentsLength, setCommentsLength] = useState(0);


    const navigate = useNavigate();

    const onlineUsers = useSelector(state => state.wsOnlineStatus.onlineUsers)

    const isOnline = Array.isArray(onlineUsers.usersOnline) ? onlineUsers.usersOnline.includes(creater.username) : null;


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setCommentsLength(comment_count), [])

    useEffect(() => {
        const containerHeight = postContentTextCountLines?.ref?.current?.clientHeight;

        const lines = containerHeight / 24

        setFullTextButtonsOpen(lines > 15);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content_text]);

    return (

        <div id="post" className="flex flex-col sm:w-screen sm:pl-2 sm:pr-2" key={postId}>
            {
                creater.username !== authorizedUser && anonymous && window.location.pathname === '/' + creater.username
                    ? null
                    :
                    (
                        <div>
                            {/* Сам пост */}
                            <div className="main_white_container sm:rounded-3xl">
                                {/* <p>Number of comments: {commentsLength}</p> */}
                                <div className='flex flex-col'>
                                    <div className='flex justify-between'>
                                        <div className="flex w-full items-center gap-2">

                                            {/* МИНИ фотка профиля тут аватарка */}
                                            {
                                                anonymous
                                                    ? null
                                                    :
                                                    (
                                                        <div className="w-[50px] h-[50px] shadow-md rounded-full">


                                                            <div className='' onClick={() => { navigate(`/${creater.username}`) }}>
                                                                <div className="small-profilepic button_ava_posts border border_color_rounded_elements" style={{ backgroundImage: `url(${baseBackendUrl + profile_pic})` }}></div>
                                                                <div className='w-full h-full flex justify-end relative'>

                                                                    {isOnline ? <div className={`absolute bottom-[2px] right-1 w-2 h-2 bg-green-500 rounded-full border-[1px] border_color_rounded_elements flex items-end justify-end`}></div> : null}
                                                                </div>
                                                            </div>


                                                        </div>
                                                    )
                                            }


                                            {/* Инфа о пользователе и DROP DOWN MENU */}

                                            <PostUser
                                                firstName={first_name}
                                                lastName={last_name}
                                                dateCreated={postDateCreated}
                                                anonymous={anonymous}
                                                creater={creater}
                                            />

                                        </div>

                                        <div>
                                            {creater.username === authorizedUser ? <DropDownMenu postIdInner={postId} user={creater} contentInner={[content_text, content_image]} /> : ''}
                                            {creater.username !== authorizedUser && authorizedUser === 'propoved' ? <DropDownMenu buttonColor="fill-red-500" postIdInner={postId} user={creater} contentInner={[content_text, content_image]} /> : ''}
                                        </div>
                                    </div>


                                    <div className="p-2">

                                        {/* Здесь отображается ТЕКСТ поста */}
                                        {postContentText}

                                        {fullTextButtonsOpen
                                            ? <button onClick={() => { setFullTextOpened(!fullTextOpened) }}>
                                                {fullTextOpened
                                                    ? <p className='text-gray-400'>{t('posts-closeFullText')}</p>
                                                    : <p className='text-gray-400'>{t('posts-openFullText')}</p>
                                                }
                                            </button>
                                            : null
                                        }


                                        {/* Здесь отображается ФОТО поста */}
                                        {postContentImage}

                                        {/* ЛАЙК КОММЕНТ СОХРАНИТЬ \/\/\/ */}
                                        <div className="flex pt-1">
                                            <Likes postIdInner={postId} postLikersInner={likers} authorizedUserInner={authorizedUser} postLikersInnerCount={postLikersCount} />

                                            {/* НЕ ПУТАТЬ, здесь находится только кнопка */}
                                            <Comments comment_count={commentsLength} showCommentsBool={() => setCommentsOpened(!commentsOpened)} />

                                            <SavePost authorizedUserInner={authorizedUser} postSaversInner={savers} postIdInner={postId} />
                                        </div>

                                    </div>
                                </div>

                                {/* Окно комменто, Здесь обновляются данные!!! */}
                                {commentsOpened ? <ShowComments authorizedUserInner={authorizedUser} postIdInner={postId} setCommentsLengthInner={setCommentsLength} /> : null}

                            </div>
                        </div>
                    )
            }




        </div>
    );
}

export default Post;