import {createSlice} from '@reduxjs/toolkit';

const displayNonOverflowrSlice = createSlice ({
    name: 'displayNonOverflow',
    initialState: {
        displayNonOverflow: false,
    },
    reducers: {
        updateDisplayNonOverflow(state, action) {
            // console.log(state,action)
            state.displayNonOverflow = action.payload
            // console.log(state.profileUser)

        }
    }
})

export const {updateDisplayNonOverflow} = displayNonOverflowrSlice.actions; 

export default displayNonOverflowrSlice.reducer;