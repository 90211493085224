// import ExitCrossSVG from "../../../svg/exitCross_svg";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { baseBackendUrl } from "../websocket_comp";
import { server_deploying_online_status } from "../websocket_comp";
import { formattedDate } from "../services";
import axios from "axios";
import SendMessageSVG from "../../../svg/svg_send_message";
import { getChatRoomNumber } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { updateChatColorStatus, updateNonReadMessages } from "../../store/wsChatSlice"
import DropDownSVG from "../../../svg/dropDown_svg";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import { Dropdown } from 'flowbite-react';
import { HiViewGrid } from 'react-icons/hi';
import ThreeDotsSVG from "../../../svg/svg_3_dots";
import { firebaseRequestPermission, onMessageListener, messaging } from "../firebase_settings";


const MessagesPopup = ({ close, additionInfo }) => {
    const [formData, setFormData] = useState('')
    const [messages, setMessages] = useState([])
    const [socket_messages, set_socket_messages] = useState()
    const [chatHistory, setChatHistory] = useState([]);
    const [inputFocus, setInputFocus] = useState(false)
    const [windowHeight, setWindowHeight] = useState(0)
    const [baseInnerHeigh, setBaseInnerHeigh] = useState(window.innerHeight)

    const [editMessages, setEditMessages] = useState(false);
    // const [focusInput, setFocusInput] = useState(false);

    const inputRef = useRef(null);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
        // eslint-disable-next-line
    }, [])

    const chatColorStatusInner = useSelector(state => state.wsChatStatus.chatColorStatus)

    const messageContainerRef = useRef(null);
    const [username, authorizedUser, firstName, lastName, profilePic, isOnline] = additionInfo


    const navigate = useNavigate();

    useEffect(() => {
        dispatch(updateNonReadMessages(messages))
        // eslint-disable-next-line
    }, [messages])


    const handleInputChange = (e) => {
        setFormData(e.target.value);
    };

    const chatRoomNumber = getChatRoomNumber(authorizedUser.username, username)

    useEffect(() => {
        if (!socket_messages && socket_messages?.readyState !== WebSocket.OPEN) {

            const socket = server_deploying_online_status === 'online' ? new WebSocket(`wss://${window.location.hostname}/api/ws/online_status?chat&${chatRoomNumber}`) : new WebSocket(`ws://${window.location.hostname}:8000/ws/online_status?chat&${chatRoomNumber}`)

            set_socket_messages(socket);

            socket.onopen = (e) => {
                console.log("WebSocket messages connection opened");

                socket.send(

                    JSON.stringify({

                        status: 'setOnline',

                        username: username,
                        userMessageBeenRead: false,

                        authorizedUserMessageBeenRead: true,
                        authorizedUser: authorizedUser.username,
                        firstName: authorizedUser.first_name,
                        lastName: authorizedUser.last_name,
                        message: formData,
                        date: new Date()
                    })
                );
                dispatch(updateChatColorStatus(!chatColorStatusInner))
                checkExistionUser();
            };

            socket.onmessage = (message_data) => {

                try {
                    const newData = JSON.parse(message_data.data);
                    // console.log(newData)
                    dispatch(updateChatColorStatus(!chatColorStatusInner))

                    setMessages((prevMessages) => [...prevMessages, newData]);
                    checkExistionUser();

                } catch (e) {
                    console.log('Error:', e.message);
                }
            };

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket_messages])

    const checkExistionUser = async () => {
        let url = `${baseBackendUrl}/chat_history`;

        const data = { roomGroupName: String(chatRoomNumber) };
        const response = await axios.post(url, data);

        setChatHistory(response.data.chat_history)
        // console.log(response.data.chat_history.at(-1))

        // new Notification('sda')


        // if ('Notification' in window) {
        //     Notification.requestPermission().then(perm => {
        //         if (perm === "granted") {
        //             const notification = new Notification(response.data.chat_history.at(-1).first_name + ' ' + response.data.chat_history.at(-1).last_name, {
        //                 body: response.data.chat_history.at(-1).message,
        //                 // tag: 'das'
        //             })

        //             notification.onclick = function () {
        //                 console.log('Уведомление было кликнуто');
        //                 // Добавьте дополнительные действия при клике
        //             };
        //         }
        //     })
        // } else {
        //     console.log('Уведомления не поддерживаются в этом браузере.');
        // }


    };

    // useEffect(() => {
    //     try {
    //         firebaseRequestPermission()
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }, [])


    // useEffect(() => {
    //     // console.log(onMessage(messaging))
    //     const readPush = onMessageListener()
    //     .then((payload) => console.log(payload.notification))
    //     // console.log(readPush, onMessageListener())
    //   },[messages])


    const handleChatButton = (e) => {
        if (formData.length) {
            if (socket_messages && socket_messages.readyState === WebSocket.OPEN) {

                socket_messages.send(
                    JSON.stringify({

                        status: 'message',

                        username: username,
                        userMessageBeenRead: false,

                        authorizedUserMessageBeenRead: true,
                        authorizedUser: authorizedUser.username,
                        firstName: authorizedUser.first_name,
                        lastName: authorizedUser.last_name,
                        message: formData,
                        device_token_inner: localStorage.getItem('pushToken'),
                        date: new Date()
                    })
                );
                setFormData("");
                setInputFocus(true)

            } else {
                console.error("WebSocket is not in the OPEN state.");
            }
        }

        if (inputRef.current) {
            inputRef.current.focus();
        }

    };

    // useEffect(() => {
    //     if (inputFocus) {
    //         inputRef.current.focus = true;
    //         console.log(inputRef.current.focus)
    //     } else {
    //         inputRef.current.focus = false
    //         console.log(inputRef.current.focus, 'false')
    //     }
    // },[inputFocus])


    useEffect(() => {
        // Прокрутка вниз при добавлении нового сообщения
        if (messageContainerRef.current) {

            // setTimeout(

            messageContainerRef.current.scrollIntoView({
                // behavior: "smooth",
                block: "end",
            })

            // 5000)

        }
    }, [messages, chatHistory])


    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            // Если нажата клавиша Enter, вызвать handleChatButton
            handleChatButton(e);
        }
    };

    const handleClose = () => {
        if (socket_messages) {
            socket_messages.close();
        }

        dispatch(updateDisplayNonOverflow(false))
        close()
    }


    if ("virtualKeyboard" in navigator) {
        const keyboard = navigator.virtualKeyboard
        keyboard.overlaysContent = false
        keyboard.addEventListener('geometrychange', () => {
            alert(keyboard, keyboard.boundingRect)
            console.log(keyboard, keyboard.boundingRect)
        })
    }

    const topInfo = () => {
        return (
            <div className="transpatent_bg flex w-full h-[50px] border-b-[1px] border_color items-center p-1 justify-between z-[1]">

                <div className="flex items-center justify-start gap-2 w-fit">

                    <div className="flex items-center justify-center p-2 pl-4 pr-4 stroke_color" onClick={() => handleClose()}>
                        <DropDownSVG className='rotate-90' />
                    </div>

                    {/* МИНИ фотка профиля тут */}
                    <div className="w-11 h-11 flex items-center justify-center">
                        <div className='' onClick={() => { navigate(`/${username}`) }}>
                            <div className='w-full h-full relative'>
                                <div className="small-profilepic !w-10 !h-10 button_ava_posts" style={{ backgroundImage: `url(${baseBackendUrl + profilePic})` }}></div>
                                {isOnline ? <div className={`absolute bottom-0 -left-1 w-3 h-3 bg-green-500 rounded-full border-2 border_color_rounded_elements`}></div> : null}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <div className="flex gap-1">
                            <p className="">{firstName}</p>
                            <p className="">{lastName}</p>
                        </div>

                        <div className="text-xs text-gray-400">{isOnline ? <p>{t('profile-online')}</p> : <p>{t('profile-offline')}</p>}</div>
                    </div>

                </div>

                {/* <div className="flex items-center justify-center p-2 pr-4" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div> */}


                <div className="w-fit p-3 stroke_color flex items-center justify-center z-10">

                    <Dropdown label="" dismissOnClick={true} className="rounded-xl transpatent_bg border_color" renderTrigger={() =>
                        <div className="flex gap-2">
                            {editMessages ? <div className="text-red-500" onClick={deleteChatMessages}>{t('messages-delete')}</div> : null}
                            {editMessages ? <div className="" onClick={() => { setEditMessages(!editMessages) }}>{t('messages-ready')}</div> : <ThreeDotsSVG className="fill_color w-7 h-7" />}
                        </div>
                    }>

                        {/* <Dropdown.Header>
                            <span className="block text-sm">Bonnie Green</span>
                        </Dropdown.Header> */}
                        <Dropdown.Item
                            className="flex gap-1 text_in_menu hover:text-blue-500"
                            icon={HiViewGrid}
                            onClick={() => { setEditMessages(!editMessages) }}>
                            <p>{t('messages-edit')}</p>
                        </Dropdown.Item>
                    </Dropdown>


                </div>


            </div>
        )
    }

    let messagesDeletelist = []

    const getMessagesToDelete = (eventBool, messageId) => {
        if (eventBool) {
            messagesDeletelist.push(messageId)
        } else {
            messagesDeletelist = messagesDeletelist.filter((id) => id !== messageId)
        }
    }

    const deleteChatMessages = async () => {
        let url = `${baseBackendUrl}/delete_chat_messages`;

        const data = { roomGroupName: String(chatRoomNumber), messagesIdList: messagesDeletelist };
        const response = await axios.post(url, data);

        setChatHistory(response.data.chat_history)
        setEditMessages(!editMessages)

        socket_messages.send(

            JSON.stringify({

                status: 'update',

                username: username,
                userMessageBeenRead: '',

                authorizedUserMessageBeenRead: '',
                authorizedUser: authorizedUser.username,
                firstName: authorizedUser.first_name,
                lastName: authorizedUser.last_name,
                message: '',
                date: new Date()
            })
        );
    };


    const messagesFunc = () => {
        return (
            <div className="w-full sm:w-[100dvw] h-full flex flex-col overflow-hidden">

                {/* Messages */}
                <div name='message' className="flex flex-col sm:h-[100dvh] h-full overflow-y-auto p-[6px] gap-2 overflow-x-hidden">

                    {/* История */}
                    {chatHistory ?
                        chatHistory.map((message, index) => (

                            <div className={`relative flex flex-col gap-2 rounded-xl p-2 ${message?.authorized_user === authorizedUser.username ? 'popup_messages_green' : 'popup_messages_blue'}`} key={index}>

                                {message.authorized_user_message_been_read
                                    ? <div className="w-3 h-3 bg-green-500 rounded-full absolute -top-1 -right-1 border-2 border_color_rounded_elements" />
                                    : <div className="w-3 h-3 bg-red-500 rounded-full absolute -top-1 -right-1 border-2 border_color_rounded_elements" />}

                                {message.user_message_been_read
                                    ? <div className="w-3 h-3 bg-green-500 rounded-full absolute -top-2 -right-2 border-2 border_color_rounded_elements" />
                                    : <div className="w-3 h-3 bg-red-500 rounded-full absolute -top-2 -right-2 border-2 border_color_rounded_elements" />}

                                <div className="flex flex-col gap-2 w-full">
                                    <div className="flex justify-between gap-2 items-center">
                                        <p className="">{message?.first_name}:</p>
                                        <p className="w-full text-right text-gray-400 text-xs">{formattedDate(message?.date)}</p>
                                        {editMessages && message?.authorized_user === authorizedUser.username
                                            ? <div className="w-6 h-5 overflow-hidden flex items-center justify-center rounded-full border">
                                                <input type='checkbox' onChange={(e) => { getMessagesToDelete(e.target.checked, message.id) }}></input>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <p className="break-words hyphens-auto overflow-auto ">{message?.message === 'deleted' ? t('messages-deleted') : message?.message}</p>
                                </div>

                            </div>

                        ))
                        :
                        null
                    }
                    <div ref={messageContainerRef} />

                </div>

            </div>
        )
    }

    const bottomInput = () => {
        return (
            <div className="w-full flex items-start justify-center relative border-t-[1px] border_color !h-[60px]">

                <input
                    className=" bg-transparent shadow-none lg:rounded-b-2xl sm:rounded-b-none rounded-t-none  w-full h-11 appearance-none resize-none leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm p-3"
                    ref={inputRef}
                    type="text"
                    name="chat_input"
                    placeholder={t('profile-messages-sendMessage')}
                    autoComplete="off"
                    value={formData}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    maxLength={500}
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    // autoFocus
                />
                {/* ${formData ? 'bg-slate-300' : null} */}
                <button className={`flex items-center justify-center absolute top-0 right-0 h-full w-[60px] duration-300 ease-in-out touch_screen_non_selectable select-none `}
                    onClick={(e) => handleChatButton(e)}
                    onTouchStart={(e) => handleChatButton(e)}
                >
                    {formData ? <SendMessageSVG className='pl-[2px] fill-[#1da1f2] w-6 h-6 ease-in-out duration-500 hover:-rotate-180' /> : null}
                </button>
            </div>
        )
    }


    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // console.log(isMobile, inputFocus, isMobile && inputFocus, window.innerHeight, windowHeight);
    // const isMobile2 = 'ontouchstart' in window;
    // console.log(isMobile2);

    useEffect(() => {

        if (isMobile & inputFocus) {
            setWindowHeight(baseInnerHeigh/2)
        } else if (isMobile & !inputFocus) {
            setWindowHeight(baseInnerHeigh)
        } else {
            setWindowHeight(baseInnerHeigh)
        }

        // window.scrollTo(0, inputRef);

        // inputRef.current.scrollIntoView({
        //     // behavior: "smooth",
        //     block: "end",
        // })

        messageContainerRef.current.scrollIntoView({
            // behavior: "smooth",
            block: "end",
        })

        // eslint-disable-next-line
    }, [inputFocus, window.innerHeight, windowHeight])

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // },[])

    // const contentStyle = {
    //     // Пример стилей для контента, который не будет подниматься при появлении клавиатуры
    //     position: isKeyboardOpen ? 'fixed' : 'static',
    //   };

    return (
        // <div className="relative h-[1000px] w-[100dvw]">
            <div
                // ${windowHeight}px
                style={ isMobile ? { height: `${windowHeight}px`, position: 'static' , bottom: '0px' } : null}
                className={`sm:!absolute sm:bottom-0 popup_window !bg-transparent w-[800px] h-full sm:rounded-none sm:w-screen text-[14px] overflow-hidden`}>
                <div className="popup_window relative p-2 sm:p-0 h-fit lg:!w-[800px] overflow-hidden !gap-1 flex flex-col">
                    {topInfo()}
                    {messagesFunc()}
                    {bottomInput()}

                </div>
            </div>
        // </div>
    )
}

export default MessagesPopup;