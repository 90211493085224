const DropDownSVG = ({className, fillColor="currentColor"}) => {
    return (
        <svg 
        width="1em" height="1em" 
        className={className} fill={fillColor}
        style={{pointerEvents: 'none'}}

        viewBox="0 -2 16 16"  xmlns="http://www.w3.org/2000/svg">
            <path  style={{pointerEvents: 'none'}} fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        </svg>
    )
}

export const DropDownSVGLeft = ({ fillColor="currentColor"}) => {
    return (
        <svg 
        width="1em" height="1em" 
        className="rotate-90" fill={fillColor}
        style={{pointerEvents: 'none'}}

        viewBox="0 -2 16 16"  xmlns="http://www.w3.org/2000/svg">
            <path  style={{pointerEvents: 'none'}} fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        </svg>
    )
}

export default DropDownSVG;
