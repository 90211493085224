
const ThreeDotsSVG = ({className}) => {
    return (
        <svg 
            className={className}   
            width="1.5em" height="1.5em"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={0}

            id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path d="M80,128a16,16,0,1,1-16-16A16.01833,16.01833,0,0,1,80,128Zm112-16a16,16,0,1,0,16,16A16.01833,16.01833,0,0,0,192,112Zm-64,0a16,16,0,1,0,16,16A16.01833,16.01833,0,0,0,128,112Z"/>
        </svg>
    )
}

export default ThreeDotsSVG;
