import { useState } from "react";
import axios from "axios"
import { baseBackendUrl } from "../websocket_comp";
import { useEffect } from "react";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const CodeApprove = ({close, additionInfo}) => {
    const [errorMessage, setErrorMessage] = useState(false)
    const [imputCode, setInputCode] = useState()
    const [seconds, setSeconds] = useState(15);

    const [approvalFunc, username, sendCode] = additionInfo
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }

    const checkCodeSubmit = async () => {
        const data = {approval_code: imputCode, username: username}
        const url = `${baseBackendUrl}/register_code_approve`

        // console.log(imputCode, )
        const response = await axios.post(url, data);
        // console.log(response.data)
        if (response.data.approve === 'yes') {
            approvalFunc()
            handleClose()
        } else {
            setErrorMessage(true)
        }
    }


    useEffect(() => {
      if (seconds > 0) {
        const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
        return () => clearTimeout(timer);
      }
    }, [seconds]);

    const resendCode = () => {
        sendCode()
        setSeconds(15)
    }

    return (
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
            <div className="popup_window p-8 sm:p-2 lg:w-[700px] h-fit sm:h-full sm:w-full">

                <strong>{t("reg-codeApprove")}</strong>
                
                <form className="flex flex-col gap-2 w-full" action="{% url 'register' %}" method="post" id="code_from_email_form" encType="multipart/form-data">
                    
                    {/* {% csrf_token %} */}
                    <input className="appearance-none shadow rounded-full w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm" 
                    type="text" name="approval_code" placeholder={t('reg-codeApproveFromMail')} onChange={(e) => {setInputCode(e.target.value)}} />
                    
                    {errorMessage ? <p className='text-red-500'>{t('reg-error-wrongCode')}</p> : ''}
                    
                    <div className="flex text-[10px] justify-end gap-2">
                        <div className="flex gap-2">
                            <p>{t('reg-codeApprove_not_comes')}</p>
                            <p className={seconds !== 0 ? "text-gray-500 cursor-none" : "hover:text-blue-500 cursor-pointer"} onClick={resendCode} disabled={seconds !== 0}>{t('reg-codeApprove_resend')}</p>
                        </div>

                        {seconds !== 0 
                        ?   <div className="flex gap-2">
                                <p>{t('reg-codeApprove_through')}</p><p>{seconds}</p>
                            </div>    
                        : null}
                                            
                    </div>

                    {/* <!-- Кнопки --> */}
                    <div className="flex">
                        <button id="approve_code_button_cancel" className="button_red" type="button" onClick={handleClose}>{t('profile-cancelButton')}</button>
                        <button id="approve_code_button" className="button_green" type="button" onClick={checkCodeSubmit}>{t('reg-codeApproveAccept')}</button>
                    </div>
                    

                </form>

            </div>
        </div>
    )
}

export default CodeApprove;