import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const language = localStorage.getItem('language');

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    lng: localStorage.getItem(language) || 'ru',
    fallbackLng: 'en',
    debug: false,
    detection: {
        order: ['queryString', 'cookie'],
        cashe: ['cookie']
    },
    interpolation: {
        escapeValue: false
    }
})

export default i18n