const MatrixSVG = ({className}) => {
    return (
        <svg 
        className={className}
        width="1.5em" height="1.5em"
        fill="currentColor"
        stroke="currentColor"

        version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
>
<path d="M2440 4793 c-115 -19 -246 -89 -335 -178 -96 -96 -153 -207 -179
-349 l-11 -58 -95 -42 c-117 -52 -234 -120 -275 -162 -70 -70 -68 -171 5 -244
68 -68 111 -64 268 27 115 66 165 88 170 73 2 -5 27 -43 55 -82 119 -166 297
-258 499 -258 117 0 176 14 283 65 106 51 178 112 242 203 25 37 50 71 53 75
8 9 79 -25 205 -98 74 -43 84 -46 138 -43 46 4 63 10 88 34 59 55 75 136 41
204 -9 19 -39 52 -67 73 -49 37 -249 143 -311 164 -29 10 -31 15 -37 72 -4 33
-20 96 -37 138 -73 188 -237 332 -431 378 -59 13 -209 18 -269 8z m271 -354
c67 -36 98 -68 137 -143 22 -43 26 -64 26 -136 0 -73 -4 -92 -27 -137 -34 -66
-84 -116 -150 -150 -45 -23 -64 -27 -137 -27 -72 0 -93 4 -136 26 -75 40 -107
70 -145 140 -31 58 -34 70 -33 148 0 72 4 93 26 136 38 73 69 106 129 139 71
38 102 45 182 40 51 -3 81 -11 128 -36z"/>
<path d="M1181 3617 c-67 -21 -144 -129 -227 -317 l-42 -94 -85 -17 c-133 -26
-226 -76 -323 -173 -128 -127 -184 -263 -184 -439 1 -186 57 -326 184 -453 98
-99 205 -156 330 -177 l78 -13 23 -57 c60 -146 160 -308 216 -350 70 -51 174
-21 217 63 33 63 24 113 -37 220 -27 47 -60 108 -75 137 l-26 52 62 33 c191
102 320 340 305 565 -15 219 -140 415 -345 538 -6 4 32 81 85 172 46 79 57
108 61 153 5 69 -22 114 -88 148 -48 24 -76 26 -129 9z m-70 -778 c67 -36 98
-68 137 -143 22 -43 26 -64 26 -136 0 -73 -4 -92 -27 -137 -15 -29 -43 -68
-62 -88 -20 -19 -59 -47 -88 -62 -45 -23 -64 -27 -137 -27 -72 0 -93 4 -136
26 -75 40 -107 70 -145 140 -31 58 -34 70 -33 148 0 72 4 93 26 136 38 73 69
106 129 139 71 38 102 45 182 40 51 -3 81 -11 128 -36z"/>
<path d="M3825 3616 c-69 -30 -111 -124 -85 -191 6 -16 40 -82 76 -145 35 -64
64 -123 64 -132 0 -8 -26 -31 -59 -51 -69 -43 -167 -144 -205 -213 -57 -102
-80 -189 -80 -309 0 -126 15 -191 72 -300 45 -85 145 -193 224 -242 l58 -36
-52 -96 c-111 -206 -111 -206 -104 -253 17 -112 115 -168 219 -124 58 24 131
131 212 312 l45 99 54 7 c231 30 436 203 507 428 29 91 35 226 15 320 -53 247
-252 445 -500 497 -39 8 -74 17 -77 21 -4 4 -23 45 -43 91 -44 104 -119 232
-160 276 -47 49 -123 67 -181 41z m486 -777 c67 -36 98 -68 137 -143 22 -43
26 -64 26 -136 0 -73 -4 -92 -27 -137 -34 -66 -84 -116 -150 -150 -45 -23 -64
-27 -137 -27 -72 0 -93 4 -136 26 -75 40 -107 70 -145 140 -31 58 -34 70 -33
148 0 72 4 93 26 136 38 73 69 106 129 139 71 38 102 45 182 40 51 -3 81 -11
128 -36z"/>
<path d="M2482 3020 c-62 -28 -81 -76 -82 -202 l0 -98 -97 0 c-127 -1 -175
-20 -203 -82 -40 -88 -12 -184 62 -218 33 -15 66 -20 141 -20 l97 0 0 -97 c1
-127 20 -175 82 -203 54 -25 102 -25 156 0 62 28 81 76 82 203 l0 97 98 0
c126 1 174 20 202 82 40 88 12 184 -62 218 -33 15 -66 20 -140 20 l-98 0 0 98
c-1 126 -20 174 -82 202 -24 11 -59 20 -78 20 -19 0 -54 -9 -78 -20z"/>
<path d="M2405 1581 c-145 -41 -286 -144 -365 -265 -23 -37 -44 -66 -46 -66
-8 0 -120 59 -199 105 -74 43 -84 46 -138 43 -47 -4 -63 -10 -88 -34 -42 -40
-59 -75 -59 -124 0 -54 20 -100 59 -133 49 -41 158 -103 267 -152 l99 -45 8
-61 c18 -142 111 -305 224 -393 117 -92 243 -136 394 -136 310 0 562 204 627
509 l18 84 95 42 c113 49 257 134 298 176 48 48 54 143 13 198 -34 46 -69 65
-131 69 -58 4 -62 2 -166 -57 -129 -74 -178 -96 -183 -81 -2 5 -27 43 -55 82
-86 119 -213 207 -352 243 -80 20 -243 19 -320 -4z m306 -342 c67 -36 98 -68
137 -143 22 -43 26 -64 26 -136 0 -73 -4 -92 -27 -137 -34 -66 -84 -116 -150
-150 -45 -23 -64 -27 -137 -27 -72 0 -93 4 -136 26 -75 40 -107 70 -145 140
-31 58 -34 70 -33 148 0 72 4 93 26 136 38 73 69 106 129 139 71 38 102 45
182 40 51 -3 81 -11 128 -36z"/>
</g>
</svg>

    )
}

export default MatrixSVG;
