import { useEffect, useState } from "react";

const Spinner = () => {

  const [progressBar, setProgressBar] = useState(0);
  // const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressBar(prevProgress => (prevProgress + 1) % 101);
    }, 20);

      // Установим таймер для перезагрузки страницы через 2 секунды
      // const reloadTimer = setTimeout(() => {
      //   clearInterval(interval); // Остановим интервал обновления прогресса
      //   window.location.reload(); // Перезагрузим страницу
      // }, 2000); // 2 секунды
  
      // Очистим таймер и интервал при размонтировании компонента
      return () => {
        clearInterval(interval);
        // clearTimeout(reloadTimer);
      };

    }, []);

  return (
    <div className="w-[100dvw] spinner-container cursor-wait flex justify-center rounded-full">
      {/* <img src="images/spinners/spinner.gif" alt="spinner" className="rounded-full " /> */}
    {/* <div className="w-[100px] h-10 bg-black"></div> */}
      <div className="w-full h-2 bg-green-400 rounded-full" style={{ width: `${progressBar}%` }}></div>
    </div>

  )

};

export default Spinner;