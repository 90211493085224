import ExitCrossSVG from "../../../svg/exitCross_svg";
import { useState, useEffect } from "react";
import PopupContainer from "../popups_root";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";

const MatrixDescriptionMenuPopup = ({close, additionInfo}) => { 
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateDisplayNonOverflow(true))
    // eslint-disable-next-line
    },[])

    const handleClose = () => {
        dispatch(updateDisplayNonOverflow(false))
        close()
    }   

    const [matrixDescriptionPopupOpen, setMatrixDescriptionPopupOpen] = useState(false);

    return (
        <div className="popup_window !bg-transparent w-[700px] sm:rounded-none sm:w-screen sm:h-[100dvh] text-[14px] overflow-auto">
                <div className="popup_window p-8 sm:p-2 w-fit h-fit sm:h-full sm:w-full">

                <div className="exit_cross_div" onClick={handleClose}>
                    <ExitCrossSVG className="exit_cross_svg" />
                </div>
                
                <div className="flex w-full h-full flex-col items-center overflow-x-hidden">

                    {/* ЗАГОЛОВОК */}
                    <div className="">
                        <p className="popup_title">{t('profile-matrixDescriptionMenu')}</p>
                    </div>
                    <p className="pb-8 pl-8 pr-8">{t('profile-baseMatrixDescription')}</p>

                
                    <div className="flex w-full flex-col gap-5">
                    {/* Основа */}
                    
                        <div className="relative">

                            {/* <!-- ОПИСАНИЕ ВАШИХ ЭНЕРГИЙ --> */}
                            <section className="h-fit relative p-8">
                                {/* <img className="top-0 left-0 absolute z-[2] mix-blend-overlay" src="/images/matrix_description/pic_1.png" alt='das'></img> */}
                                
                                {/* АНИМАЦИЯ */}
                                <div className="w-[40vw] h-[200px] absolute rounded-r-full bg-gradient-to-r from-cyan-500 to-blue-500 shadow-inside animation_bounce_from_left z-[1]"></div>
                                
                                <div className="flex gap-2 z-[2]">
                                    <div className="w-[40%]"></div>
                                    <div className="flex flex-col justify-between w-[60%] min-h-[200px] gap-2  z-[2]">
                                        <p className="text-[1.2em] font-bold">{t('profile-bigDescriptionBorder')}</p>
                                        <p>{t('profile-descriptionEnergiesMore')}</p>
                                        <div className="w-fit">
                                            <button id="matrix_description_button" className="button_blue_rounded" onClick={() => setMatrixDescriptionPopupOpen(!matrixDescriptionPopupOpen)}>{t('profile-bigDescriptionBorder_button')}</button>
                                        </div>
                                    </div>
                                </div>
                                {matrixDescriptionPopupOpen ? <PopupContainer t={t} choosePopup={'matrix_description'} setPopupOpen={setMatrixDescriptionPopupOpen} additionInfo={''} /> : null}
                            </section>



                        </div>

                    {/* Основа */}
                    </div>

                </div>
                
                <button className="button_red sticky bottom-2" type="button" onClick={handleClose}>{t('profile-closePopup')}</button>
            </div>
    </div>
    )
}

export default MatrixDescriptionMenuPopup;
